/* eslint-disable dot-notation */
import ComponentStructure from '../basic'
import { ColumnInfo } from 'vxe-table'

type FormControlComponent = {
  group?: string
}
type ColumnItem = { type: 'column', column: ColumnInfo & FormControlComponent }
type ColumnGroup = { type: 'groupColumn', title: string, children: (ColumnInfo & FormControlComponent)[] }

type TableVmInstance = {
  component: Record<string, any>
  renderColumns: (ColumnGroup | ColumnItem)[],
  showIndex: boolean
  rowExpansion?: any[]
  selectionMode: 'none' | 'multiple' | 'single'
}

type FormVmInstance = {
  component: Record<string, any>
  rowGroup: Record<string, any>[][]
}

export default class I18nForLwText extends ComponentStructure {
  name = 'lw-text'
  properties = ['title', 'group']
  tableVm: TableVmInstance | null = null
  tableDefaultIndex = 0

  matchUnvisibleElement (dom: HTMLElement): boolean {
    return dom.classList.contains('vxe-resizable')
  }

  // 表单组件可能会在 table 内，也可能会在 lw-form/lw-input-form 等表单组件内
  // TODO: 2023-5-10 ,如果设置了 lw-table 下的 lw-text visible 为 false, 那么会导致列依然存在，但是内容区域不会有内容，这样就可以根据序号判断元素的绑定关系。如果将来不这样做，记得再改变判断逻辑

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.lw-table')) {
      if (dom['__vue__'] &&
        dom['__vue__'].component &&
        dom['__vue__'].component.fields &&
        dom['__vue__'].component.fields.includes(this.component)
      ) {
        const tableVm = dom['__vue__'] as TableVmInstance
        this.tableVm = tableVm
        const index = dom['__vue__'].component.fields.indexOf(this.component)
        let targetIndex = 0
        if (tableVm.showIndex) targetIndex++
        if (tableVm.component.rowExpansion && tableVm.component.rowExpansion.length !== 0) targetIndex++
        if (tableVm.selectionMode !== 'none') targetIndex++
        this.tableDefaultIndex = targetIndex
        if (tableVm.renderColumns.some(o => o.type === 'groupColumn')) {
          if (this.component.group) {
            // 如果当前结构在一个分组中, 那么就要确定当前结构，在所有分组结构中排第几
            // 因为分组会按照 group 出现的顺序，把所有该 group 内容显示到一起，所以要考虑某些靠后的组件，他们的 group 却比较考前
            let groupIndex = -1
            const groupRecord = {}
            let isPassCurrentComponent = false
            for (let i = 0; i < tableVm.component.fields.length; i++) {
              const item = tableVm.component.fields[i]
              if (!item.group) continue
              if (!groupRecord[item.group]) {
                if (isPassCurrentComponent) {
                  // do nothing
                } else {
                  groupRecord[item.group] = true
                  groupIndex++
                }
              } else {
                if (isPassCurrentComponent && item.group === this.component.group) {
                  // do nothing
                } else {
                  groupIndex++
                }
              }
              if (item === this.component) {
                isPassCurrentComponent = true
              }
            }

            const headers = dom.querySelector('.vxe-table--header-wrapper')?.querySelectorAll('.vxe-header--row')
            if (headers) {
              // 如果不在分组内，那么第二个 vxe-header--row 元素内，就是分组的表头
              const result = this.judgeDomExistInViewport(headers[1].children[groupIndex] as HTMLElement, this.matchUnvisibleElement)
              console.log('has group ', this.component, headers[1], headers[1].children[groupIndex])
              return result
            }
          } else {
            // 如果不在一个分组中，那么把所有分组的结构先排除掉
            const groupRecord = {}
            for (let i = 0; i < index; i++) {
              const group = tableVm.component.fields[i].group
              if (group) {
                if (groupRecord[group] === undefined) {
                  groupRecord[group] = true
                } else {
                  // 对于出现在 index 前的表单组件，如果出现了重复的分组，那么每出现一个多余的，就要减去目标序列1
                  targetIndex--
                }
              }
            }
            targetIndex += index

            const headers = dom.querySelector('.vxe-table--header-wrapper')?.querySelectorAll('.vxe-header--row')
            if (headers) {
              // 如果不在分组内，那么第一个 vxe-header--row 元素内，就是没分组的表头
              return this.judgeDomExistInViewport(headers[0].children[targetIndex] as HTMLElement, this.matchUnvisibleElement)
            }
          }
        } else {
          targetIndex += index
          const headers = dom.querySelector('.vxe-table--header-wrapper .vxe-header--row') as HTMLElement | null
          if (headers) {
            return this.judgeDomExistInViewport(headers.children[targetIndex] as HTMLElement, this.matchUnvisibleElement)
          }
        }
        return null
      }
    }
    for (const form of document.querySelectorAll('.form-wrapper')) {
      // 如果一个 form 打开了新增弹窗，那么对于新增弹窗中的 lw-text, 会找到外层，因为是浅拷贝
      if (form['__vue__'] &&
        form['__vue__'].component &&
        form['__vue__'].component.components &&
        form['__vue__'].component.components.includes(this.component)
      ) {
        for (const item of form.querySelectorAll('.item-inner-wrapper')) {
          if (item['__vue__'] &&
            item['__vue__'].$parent &&
            item['__vue__'].$parent.component &&
            item['__vue__'].$parent.component === this.component
          ) {
            return this.judgeDomExistInViewport(item as HTMLElement)
          } else if (
            item['__vue__'] &&
            item['__vue__'].component &&
            item['__vue__'].component === this.component
          ) {
            return this.judgeDomExistInViewport(item as HTMLElement)
          }
        }
        return null
      }
    }
    return null
  }

  getChildComponents () {
    return []
  }

  getPropertyShownElement (property: string) {
    if (property === 'title') {
      return this.dom
        ? this.dom.classList.contains('vxe-header--column')
          ? this.dom.querySelector('.vxe-cell--title') as HTMLElement
          : this.dom.classList.contains('item-inner-wrapper')
            ? this.dom.querySelector('.el-form-item__label > label') as HTMLElement
            : null
        : null
    } else {
      if (this.tableVm) {
        let j = -1
        for (let i = 0; i < this.tableVm.renderColumns.length; i++) {
          const current = this.tableVm.renderColumns[i]
          if (current.type === 'groupColumn') {
            if (current.children.every(o => o.group === this.component[property])) {
              j = i
              break
            }
          }
        }
        if (j > -1) {
          const result = this.dom?.parentElement?.previousElementSibling?.children[j + this.tableDefaultIndex] as HTMLElement | null
          return result || null
        }
      }
      return null
    }
  }
}
