import _ from 'lodash'

interface IBreadcrumbItem {
  name: string;
  title: string;
}

export default class BreadcrumbItem {
  #item!: IBreadcrumbItem
  constructor () {
    this.#item = {} as IBreadcrumbItem
  }

  get item () {
    return _.cloneDeep(this.#item)
  }

  set item (item: IBreadcrumbItem) {
    console.warn('The property "item" is read-only')
  }

  getName (): string {
    return this.#item.name
  }

  setName (name: string): void {
    this.#item.name = name
  }

  getTitle (): string {
    return this.#item.title
  }

  setTitle (title: string): void {
    this.#item.title = title
  }
}
