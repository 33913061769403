





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import FilterRow from './filterRow.vue'
import BaseComponent from '../../BaseComponent'
import { filterGroupOperator } from '@/utils/const'

@Component({ name: 'FilterGroup', components: { FilterRow } })
class FilterGroup extends BaseComponent {
  @Prop() filter: any
  @Prop({ default: 0 }) filterIndex!: number
  @Prop({ default: false }) notPos!: boolean
  // @Prop() encodedLayoutName!: string
  @Prop() dataSetName!: string

  get connector (): string {
    if (this.filter.connector === 'and') {
      return this.$i18n('core', 'client.advanced-filter.logic.and')
    } else {
      return this.$i18n('core', 'client.advanced-filter.logic.or')
    }
  }

  // 添加条件/组
  addRowOrGroup (command: string) {
    this.filter.conditions.push()
    const conditions = this.filter.conditions
    let initValue = {}
    if (command === 'condition') {
      initValue = {
        type: 'condition'
      }
    }
    if (command === 'group') {
      initValue = {
        type: 'group',
        not: false,
        connector: 'and',
        conditions: []
      }
    }
    this.$emit('addCondition', Object.assign(this.filter, { conditions: [...conditions, initValue] }))
  }

  addCondition (index: number, filter: any) {
    this.$set(this.filter.conditions, index, filter)
  }

  handleNotCommand (command: boolean) {
    this.filter.not = command
  }

  handleConnectCommand (command: string) {
    this.filter.connector = command
  }

  handleGroupOrRow (command: string) {
    if (command === 'delete') this.deleteGroupOrRow()
    if (command === 'wrapInGroup') this.wrapInGroup()
  }

  wrapInGroupDisabled () {
    let flag = false
    const level = this._calculateFilterLevel(this.filter, this.filterIndex)
    if (level >= 4) flag = true
    return flag
  }

  _calculateFilterLevel (filter: any, index: number) {
    let level = index
    if (filter && filter.conditions) {
      ++index
      const levelList :any = []
      filter.conditions.forEach((condition: any) => {
        levelList.push(this._calculateFilterLevel(condition, index))
      })
      if (levelList.length > 0) {
        level = Math.max(...levelList)
      } else {
        level = index
      }
    }
    return level
  }

  // 包裹进组
  wrapInGroup () {
    this._formateRowToGroupValue(this.filter)
    const conditions = [
      {
        type: 'group',
        not: false,
        connector: 'and',
        conditions: this.filter.conditions
      }
    ]
    this.filter.conditions = conditions
  }

  // 删除条件/组
  deleteGroupOrRow () {
    this.$emit('deleteCondition')
  }

  deleteCondition (index: number) {
    this.filter.conditions.splice(index, 1)
  }

  // 条件变成组
  changeRowToGroup () {
    this.$emit('changeCondition')
  }

  _formateRowToGroupValue (data: any) {
    const { type, conditions } = data
    if (type === 'group' && Array.isArray(conditions)) {
      data.conditions = conditions.map(item => this._formateRowToGroupValue(item))
    }
    if (type === 'condition') {
      const row = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN] === data.operator) {
        const value1 = row.getData(data.layouts[0].components[0].field)
        const value2 = row.getData(data.layouts[0].components[1].field)
        data.value = [value1, value2]
      } else {
        const value = row.getData(data.layouts[0].id)
        data.value = value
      }
    }
    return data
  }

  changeCondition (index: number) {
    this._formateRowToGroupValue(this.filter)
    const initGroup = {
      type: 'group',
      not: false,
      connector: 'and',
      conditions: [this.filter.conditions[index]]
    }
    this.filter.conditions.splice(index, 1, initGroup)
  }

  mounted () {
    const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
    const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
    if (this.filter.type === 'condition') {
      // 编辑复制过滤组时，对between操作符进行处理
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(this.filter.operator)) {
        searchRow.setData(this.filter.layouts[0].components[0].field, this.filter.value[0])
        searchRow.setData(this.filter.layouts[0].components[1].field, this.filter.value[1])
        return
      }
      // select组件还有设置显示字段，后面拼接_label
      if (this.filter.is === 'lw-select') {
        searchRow.setData(this.filter.layouts[0].id + '_label', this.filter.label)
      }
      searchRow.setData(this.filter.layouts[0].id, this.filter.value)
    }
  }
}
export default FilterGroup
