import { formatDate as formatDateFn } from '@/utils/data'
import _ from 'lodash'
import moment from 'moment-timezone'
import stroe from './store'
import Clipboard from 'clipboard'
import LocalDateTime from '@/models/LocalDateTime'
import LocalTime from '@/models/LocalTime'
import LocalDate from '@/models/LocalDate'

// formatDate 方法将会根据首选项设置的日期格式对 传入的 LocalDateTime LocalDate LocalTime 进行转换
function formatDate (date: LocalDateTime | LocalDate | LocalTime): string {
  const dateStr = date.toString()
  const type = date instanceof LocalDateTime ? 'dateTime' : date instanceof LocalDate ? 'date' : 'time'
  return formatDateFn(dateStr, type)
}

/**
 * 根据首选项时区获取时间（获取到的是首选项时区的时间字符串 字符串中不带时区）
 */
function now (): LocalDateTime {
  const timeZone = stroe.getConfig('zoneId')
  const date = moment().tz(timeZone)
  return LocalDateTime.parse(date.format('yyyy-MM-DDTHH:mm:ss') + `.${date.millisecond()}`)
}

/**
 * 复制指定文字内容到剪贴板
 */
function copyToClipboard (content: string, successCallback: () => void, failCallback: () => void): void {
  const button = document.createElement('button')
  button.setAttribute('id', '__lw__clipboard__btn')
  button.style.display = 'none'
  document.body.appendChild(button)
  let clipboard: null | Clipboard = new Clipboard('#__lw__clipboard__btn', {
    text: () => content
  })
  clipboard.on('success', () => {
    _.isFunction(successCallback) && successCallback()
    clipboard && clipboard.destroy()
    clipboard = null
  })
  clipboard.on('error', (e) => {
    _.isFunction(failCallback) && failCallback(e)
    clipboard && clipboard.destroy()
    clipboard = null
  })
  button.click()
  document.body.removeChild(button)
}

function chooseFile (params: { multiple: boolean }, callback: (file: File | File[]) => void): void
function chooseFile (callback: (file: File) => void): void
function chooseFile (...params: any[]): void {
  let callback: (file: File | File[]) => void = () => 0
  let config = { multiple: false }
  if (params.length === 1) {
    callback = params[0]
  } else if (params.length === 2) {
    config = params[0]
    callback = params[1]
  }
  const onChange = (e: Event) => {
    const files = e && (e.target as HTMLInputElement)?.files
    if (config.multiple) {
      files && callback && callback(new Array(files.length).fill(0).map((o, index) => files.item(index) as File))
    } else {
      files && callback && callback(files[0])
    }
    document.body.removeChild(input)
  }
  const input = document.createElement('input')
  input.type = 'file'
  if (config.multiple) {
    input.setAttribute('multiple', 'true')
  }
  input.onchange = onChange
  input.style.display = 'none'
  document.body.appendChild(input)
  input.click()
}

export default {
  formatDate,
  now,
  copyToClipboard,
  chooseFile
}
