






























import Args from '@/models/Args'
import { DataRow } from '@/types/data'
import { attributeType, IntuitiveColorMainList } from '@/utils/const'
import { handleDataSetRetrieveForQueryByFilterComponent } from '@/utils/data'
import { Component } from 'vue-property-decorator'
import QueriableByFilterComponent from '../QueriableByFilterComponent'
import { LayoutModule } from '@/store/modules/layout'

const SemanticColorMainTrans: Record<string, string> = {
  primary: 'theme-6',
  secondary: 'gray-6',
  success: 'success-6',
  warn: 'warn-6',
  error: 'error-6'
}

@Component({ name: 'LwTimeline' })
export default class LwTimeline extends QueriableByFilterComponent {
  get timeLineDatas (): Array<any> {
    const rows = this.dataSet.rows || []
    return rows.map(row => {
      const timestamp = this.getFinalAttributeValue('itemTimestamp', { args: new Args(this.context, { row }) })
      const hideItemTimestamp = this.getFinalAttributeValue('hideItemTimestamp', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
      const itemTimestampPlacement = this.getFinalAttributeValue('itemTimestampPlacement', { args: new Args(this.context, { row }) })
      const itemIcon = this.getFinalAttributeValue('itemIcon', { args: new Args(this.context, { row }) }) || null
      let itemColorScheme = this.getFinalAttributeValue('itemColorScheme', { args: new Args(this.context, { row }) })
      // 主题色
      itemColorScheme = SemanticColorMainTrans[itemColorScheme] || itemColorScheme
      if (IntuitiveColorMainList.includes(itemColorScheme)) itemColorScheme = itemColorScheme + '-c'
      const color = itemColorScheme ? `var(--${itemColorScheme})` : null

      return {
        ...row,
        timestamp,
        hideItemTimestamp,
        itemTimestampPlacement,
        itemIcon,
        color
      }
    })
  }

  itemContent (row: DataRow): string {
    let result = ''
    if (this.component.itemContent) {
      result = this.getFinalAttributeValue('itemContent', { args: new Args(this.context, { row }), types: [attributeType.STRING, attributeType.NUMBER] })
    }
    return result
  }

  handleClick (row: DataRow) {
    this.component.onItemClick && this.runRunnableContent('onItemClick', { args: new Args(this.context, { row }) })
  }

  handleDataSetRetrieve (): void {
    handleDataSetRetrieveForQueryByFilterComponent.call(this, 'timeline')
  }

  created () {
    LayoutModule.loadDataSetFilters({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      keywordFields: [],
      quickFilterFields: [],
      allowedKeywordOperators: []
    })
  }

  mounted () {
    this.initRetrieve()
  }
}
