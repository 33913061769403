import ComponentStructure from '../basic'

export default class I18nForLwLabel extends ComponentStructure {
  properties = ['content']
  name = 'lw-label'

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.lw-label')) {
      // eslint-disable-next-line dot-notation
      if (dom['__vue__'] && dom['__vue__'].component === this.component) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      }
    }
    return null
  }

  getPropertyShownElement (property: string) {
    return this.dom
  }

  getChildComponents () {
    return []
  }
}
