/* eslint-disable dot-notation */
import ComponentStructure from '../basic'

export default class I18nForLwInputForm extends ComponentStructure {
  name = 'lw-input-form'
  properties = []

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.form-wrapper')) {
      if (dom['__vue__'] &&
        dom['__vue__'].$vnode.componentOptions.tag === 'lw-form' &&
        dom['__vue__'].component === this.component
      ) {
        return dom as HTMLElement
      }
    }
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    const BaseComponents = this.component.components || []
    if (this.dom && this.dom['__vue__']) {
      const vm = this.dom['__vue__']
      if (vm.openedPopupLayout && vm.openedPopupLayout.layouts && vm.openedPopupLayout.layouts instanceof Array) {
        BaseComponents.push(...vm.openedPopupLayout.layouts)
      }
    }
    return BaseComponents
  }
}
