






















































































































































import { Component, ProvideReactive, Watch } from 'vue-property-decorator'
import QueriableComponent from '../QueriableComponent'
import {
  attributeType,
  commonSemanticPartitions,
  dateOperators,
  fieldAttrs,
  fieldOperations,
  filterGroupOperator,
  filterOperator
} from '@/utils/const'
import { LayoutModule } from '@/store/modules/layout'
import logwire from '@/logwire'
import clickoutside from 'element-ui/src/utils/clickoutside'
import { addResizeListener, removeResizeListener } from '@/utils/dom'
import eventbus from '@/utils/event'
import FilterGroup from '../basic/advanceFilter/index.vue'
import partitionFilter from '../basic/advanceFilter/partitionFilter.vue'
import { doDelete, doSave, queryByFilter, queryById } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import _ from 'lodash'
import { getI18nContent } from '@/utils/layout'
import { checkValueIsEmpty, getPartition } from '@/utils/data'
import { getUuid, saveUserSettings } from '@/utils/common'

@Component({ name: 'LwSearchBar', directives: { clickoutside }, components: { FilterGroup, partitionFilter } })
class LwSearchBar extends QueriableComponent {
  $refs!: {
    operationsShowRef: any,
    operationsRef: any,
    searchBarRef: any,
    quickFilter: any,
    quickFilterReference: any,
    quickFilterForm: any,
    moreCondition: any,
    modeSelector: any,
    filterGroupDrop: any
  }

  matchModeVisible = false
  quickFilterVisible = false
  operations : Array<Record<string, any>> = this.component.operations
  operationsShow : Array<Record<string, any>> = this.component.operations
  operationsMore : Array<Record<string, any>> = []
  filterGroupVisible = false
  filterGroupForm = {
    id: null,
    version: 0,
    name: '',
    shared: false,
    filter: {
      type: 'group',
      not: false,
      connector: 'and',
      conditions: []
    }
  }

  currentFilterGroup = {}
  defaultFilterGroup = {}
  filterGroupOptions: Array<Record<string, any>> = []
  conditionVisible = false
  isMyFilter = true

  @ProvideReactive() filterLayoutName = ''
  @ProvideReactive() filterDataSetName = ''

  get displayMatchMode (): string {
    const map = {
      eq: this.$i18n('core', 'client.common.equal'),
      like: this.$i18n('core', 'client.common.contain'),
      llike: this.$i18n('core', 'client.common.start'),
      rlike: this.$i18n('core', 'client.common.end')
    }
    return map[this.keywordFilter.matchMode]
  }

  get keywordFilter (): Record<string, any> { return LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]?.keywordFilter }

  get filterGroupEnabled (): boolean {
    const filterGroupEnabled = this.component.filterGroupEnabled
      ? this.getFinalAttributeValue('filterGroupEnabled', { type: attributeType.BOOLEAN })
      : true
    const logined = !!LocalModule.user.id
    return logined && filterGroupEnabled
  }

  get partitionFilterEnabled (): boolean {
    return this.component.partitionFilterEnabled
      ? this.getFinalAttributeValue('partitionFilterEnabled', { type: attributeType.BOOLEAN })
      : false
  }

  get defaultCommonPartition (): string {
    let result = commonSemanticPartitions.LAST_MONTH
    if (this.component.defaultCommonPartition) {
      const partition = this.getFinalAttributeValue('defaultCommonPartition', { type: attributeType.STRING })
      if ([commonSemanticPartitions.TODAY, commonSemanticPartitions.LAST_WEEK, commonSemanticPartitions.LAST_MONTH, commonSemanticPartitions.LAST_3_MONTH, commonSemanticPartitions.LAST_6_MONTH, commonSemanticPartitions.LAST_YEAR].includes(partition)) result = partition
    }
    return result
  }

  get maxPartitionRangeDays (): number {
    let result = 366
    if (this.component.maxPartitionRangeDays) {
      const days = this.getFinalAttributeValue('maxPartitionRangeDays', { type: attributeType.NUMBER })
      if (days < 366) result = days
      if (days < 1) result = 1
    }
    return result
  }

  get keywordEnabled (): boolean {
    return this.component.keywordEnabled
      ? this.getFinalAttributeValue('keywordEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  get keywordFields (): Array<string> { return this.getFinalAttributeValue('keywordFields', { type: attributeType.ARRAY }) || [] }

  get keywordPlaceholder (): string { return this.getFinalAttributeValue('keywordPlaceholder') || '' }

  get allowedKeywordOperators (): Array<filterOperator> {
    let result = [filterOperator.LIKE, filterOperator.EQ, filterOperator.LEFT_LIKE, filterOperator.REGEXP_LIKE]
    if (this.component.allowedKeywordOperators) {
      result = this.getFinalAttributeValue('allowedKeywordOperators', { type: attributeType.STRING_ARRAY })
    }
    return result
  }

  @ProvideReactive() get evaluateScriptInTableControls (): boolean {
    return this.getFinalAttributeValue('evaluateScriptInTableControls', { type: attributeType.BOOLEAN }) || false
  }

  @Watch('quickFilterVisible')
  handleQuickFilterToggle (status: boolean) {
    const method = status ? 'addEventListener' : 'removeEventListener'
    window[method]('keyup', this.handleKeyEventForPopFilter)
    document.body[method]('click', this.handleDocumentClick)
  }

  @Watch('keywordFilter', { immediate: true, deep: true })
  sliceOperations (): void {
    this.operationsShow = this.operations
    this.$nextTick(() => {
      const { operationsShowRef } = this.$refs
      // 计算出按钮占据的宽度 - 72（省略号按钮占据的宽度）
      const showWidth : number = operationsShowRef.clientWidth - 72
      const nodes = operationsShowRef.childNodes
      let wrapWidth = 0
      if (nodes.length > 1) {
        for (let i = 0; i < nodes.length; i++) {
          // 循环加上每个按钮占据的宽度， 因为获取宽度时会自动省略小数点后的数字，所以为每个按钮占据的宽度+1，以免造成换行显示
          wrapWidth += nodes[i].offsetWidth + 1
          if (wrapWidth > showWidth) {
            // 当单个按钮宽度叠加的width大于总宽度的时候，超出部分在下拉框中显示
            this.operationsShow = this.operations.slice(0, i)
            this.operationsMore = this.operations.slice(i)
            return
          }
          this.operationsMore = []
        }
      }
    })
  }

  queryByPartition (partitionFilter: any) {
    LayoutModule.loadPartitionFilter({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      partitionFilter
    })
    // 每次查询前重置查询页码为 1, 防止查询页码超出最大页码数导致页面无数据
    LayoutModule.setTablePageNo({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      pageNo: 1
    })
    eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
  }

  addCondition (filter: any) {
    this.filterGroupForm.filter = filter
  }

  _formateSaveValue (data: any, valueList: any) {
    const { type, conditions } = data
    if (type === 'group' && Array.isArray(conditions)) {
      data.conditions = conditions.map(item => this._formateSaveValue(item, valueList))
    }
    if (type === 'condition') {
      const row = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
      const value = row.getData(data.layouts[0].id)
      data.value = value
      // lw-select 添加用于显示的label字段
      if (data.is === 'lw-select') {
        data.label = row.getData(data.layouts[0].components[0].field + '_label')
      }
      // lw-textarea 以换行分割成数组
      if ((data.is === 'lw-text' && [filterGroupOperator.IN, filterGroupOperator.NOT_IN].includes(data.operator)) || data.is === 'lw-textarea') {
        data.value = value ? value.split(/\r+|\n+/g).filter((v: string) => v) : value
      }
      if (['lw-switch', 'lw-checkbox'].includes(data.is)) data.value = Boolean(value)
      // 处理日期时间 between 的情况，传递数组
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(data.operator)) {
        const row = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
        const value1 = row.getData(data.layouts[0].components[0].field)
        const value2 = row.getData(data.layouts[0].components[1].field)
        data.value = [value1, value2]
      }
      if (![filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY].includes(data.operator)) valueList.push(data.value)
    }
    return data
  }

  saveFilterGroup () {
    const valueList: any = []
    this._formateSaveValue(this.filterGroupForm.filter, valueList)
    // 利用core.save进行保存,区分是编辑还是新增
    if (!this.filterGroupForm.name) {
      logwire.ui.message({ message: this.$i18n('core', 'client.advanced-filter.message.name-not-null'), type: 'error' })
      return
    }
    // 保存前校验，value值不能为空
    if (valueList.some(checkValueIsEmpty)) {
      logwire.ui.message({ message: this.$i18n('core', 'client.advanced-filter.message.value-not-null'), type: 'error' })
      return
    }
    const dataListForSave = {
      dataList: [
        {
          dataSetName: 'filterGroupDs',
          queryName: 'core.advanced_filter_search',
          recordList: [
            {
              currentData: {
                id: null,
                search_bar_control: this.layoutName + '.' + this.dataSetName,
                name: this.filterGroupForm.name,
                shared: this.filterGroupForm.shared,
                filter_json: JSON.stringify(this.filterGroupForm.filter),
                version: this.filterGroupForm.version
              },
              originalData: {},
              rowPersist: 'I'
            }
          ]
        }
      ]
    }
    if (this.filterGroupForm.id) {
      dataListForSave.dataList[0].recordList[0].currentData.id = this.filterGroupForm.id
      dataListForSave.dataList[0].recordList[0].rowPersist = 'U'
    }
    doSave({ layoutName: this.layoutName, namespace: 'core', queryName: 'core.advanced_filter_search', data: dataListForSave })
      .then((res: any) => {
        logwire.ui.message({ message: this.$i18n('core', 'client.advanced-filter.message.save-successfully'), type: 'success' })
        this.closeFilterGroup()
        // 保存并应用 设置为当前过滤组并重新查询
        this.changeCurrentFilterGroup(res.data.data.filterGroupDs.records[0])
      })
  }

  deleteCondition () {
    this.filterGroupForm.filter = {
      type: 'group',
      not: false,
      connector: 'and',
      conditions: []
    }
  }

  newCondition () {
    this.isMyFilter = true
    this.filterGroupVisible = true
    this.$refs.filterGroupDrop.hide()
  }

  noCondition () {
    this.filterGroupForm = {
      id: null,
      version: 0,
      name: '',
      shared: false,
      filter: {
        type: 'group',
        not: false,
        connector: 'and',
        conditions: []
      }
    }
    LayoutModule.loadAdvancedFilter({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      advancedFilter: this.filterGroupForm
    })
    // 每次查询前重置查询页码为 1, 防止查询页码超出最大页码数导致页面无数据
    LayoutModule.setTablePageNo({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      pageNo: 1
    })
    eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    this.currentFilterGroup = this.filterGroupForm
    this.$refs.filterGroupDrop.hide()
  }

  stopSelect () {
    return null
  }

  // 设置默认过滤组 先删除原先的过滤组
  setDefaultFilterGroup (item: any) {
    this.defaultFilterGroup = item
    saveUserSettings(this.layoutName, this.dataSetName,
      { default_advanced_filter_id: item.id },
      () => {
        // 设置成功之后刷新页面
        this.defaultFilterGroup = item
        logwire.ui.message({ message: this.$i18n('core', 'client.advanced-filter.message.set-default-successfully'), type: 'success' })
      }
    )
  }

  cancelDefaultFilterGroup (callback?: (res: Record<string, any>) => void) {
    this.defaultFilterGroup = {}
    saveUserSettings(this.layoutName, this.dataSetName, { default_advanced_filter_id: '' }, callback)
  }

  editFilterGroup (item: any) {
    this.isMyFilter = item.insert_user_id === LocalModule.user.id
    this.filterGroupForm.id = item.id
    this.filterGroupForm.version = item.version
    this.filterGroupForm.name = item.name
    this.filterGroupForm.shared = item.shared
    this.filterGroupForm.filter = JSON.parse(item.filter_json)
    // 编辑和复制的时候重新根据字段生成过滤组弹框内容
    this._formateFilterLayouts(this.filterGroupForm.filter)
    this.filterGroupVisible = true
    this.$refs.filterGroupDrop.hide()
  }

  _formateFilterLayouts (data: any) {
    const { type, conditions } = data
    if (type === 'group' && Array.isArray(conditions)) {
      const a = conditions.filter(item => !(item.type === 'group' && item.conditions.length === 0))
      data.conditions = conditions.filter(item => !(item.type === 'group' && item.conditions.length === 0)).map(item => this._formateFilterLayouts(item))
    }
    if (type === 'condition') {
      let options = null
      if (LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields) {
        options = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields.filter((item: { is: string }) => item.is !== 'lw-upload-file')
      } else {
        const queryName = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].queryName as string
        options = LayoutModule.resource[this.filterLayoutName].queryMeta[queryName]
        // 处理 list 的 options ,使其和table返回的数据一致
        options = options.map((item: any) => {
          item.field = item.name
          item.is = 'lw-text'
          return _.pick(item, ['is', 'field', 'title'])
        })
      }
      options = _.unionBy(options, 'field')
      options = options.map((item: any) => {
        if (item.title) {
          item.i18nTitle = getI18nContent(item.title)
        }
        return item
      })
      const optionMap = _.keyBy(options, 'field')
      const { layouts, is, field, operator } = data
      const option = optionMap[field]
      if (option.is === 'lw-timestamp') option.is = 'lw-date-time'
      data.is = option.is
      const filterOption = _.pick(_.cloneDeep(option), fieldAttrs['lw-form-item'].concat(fieldAttrs[option.is]))
      // 过滤掉initComponent中有 {eval} {function} {common} 的属性
      if (!this.evaluateScriptInTableControls) {
        for (const key in filterOption) {
          if (filterOption[key].includes('{eval}') || filterOption[key].includes('{function}') || filterOption[key].includes('{common}')) {
            delete filterOption[key]
          }
          if (['displayTemplate', 'dropDownRowTemplate', 'dropDownHeaderTemplate'].includes(key) && filterOption[key].includes('getCurrentRow')) {
            delete filterOption[key]
          }
        }
      }
      // 根据 is 和 operator 过滤，修改 layouts
      if (is === 'lw-text' && [filterGroupOperator.IN, filterGroupOperator.NOT_IN].includes(operator)) filterOption.is = 'lw-textarea'
      // lw-textarea 以换行分割成数组
      if ((is === 'lw-text' && [filterGroupOperator.IN, filterGroupOperator.NOT_IN].includes(operator)) || is === 'lw-textarea') {
        data.value = data.value.join('\n')
      }
      if (['lw-date', 'lw-date-time'].includes(is) && dateOperators.includes(operator)) {
        filterOption.is = 'lw-date'
      }
      if (!Object.keys(fieldOperations).includes(is)) {
        filterOption.is = 'lw-text'
      }
      layouts[0].components[0] = filterOption
      layouts[0].components[0].field = layouts[0].id
      // 修改区间选择 between 的组件
      // 根据操作符为 为空 不为空，设置条件值一栏不可见
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(operator)) {
        const id = field + '_' + getUuid()
        const copyComponent = _.cloneDeep(layouts[0].components[0])
        copyComponent.field = id
        layouts[0].components[1] = copyComponent
      }
      if ([filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY].includes(operator)) {
        this.$set(layouts[0].components[0], 'enabled', 'false')
      }
    }
    return data
  }

  copyFilterGroup (item: any) {
    this.isMyFilter = true
    this.filterGroupForm.name = item.name + '_copy'
    this.filterGroupForm.shared = item.shared
    this.filterGroupForm.filter = JSON.parse(item.filter_json)
    // 编辑和复制的时候重新根据字段生成过滤组弹框内容
    this._formateFilterLayouts(this.filterGroupForm.filter)
    this.filterGroupVisible = true
    this.$refs.filterGroupDrop.hide()
  }

  deleteFilterGroup (item: any) {
    const dataArr: any = []
    dataArr.push({
      id: item.id,
      version: item.version
    })
    const data = {
      query: 'core.advanced_filter_search',
      selectedRows: dataArr,
      params: {
        search_bar_control: this.layoutName + '.' + this.dataSetName
      }
    }
    const param: any = {
      message: this.$i18n('core', 'client.advanced-filter.message.is-delete', item.name),
      callback: (action: string) => {
        if (action === 'confirm') {
          // 点击确定之后进行删除操作
          const callback = () => {
            doDelete({ layoutName: this.layoutName, namespace: 'core', queryName: 'core.advanced_filter_search', data }).then((res: any) => {
              // 如果删除的是当前过滤组，则切换到无过滤组的状态
              const currentFilter: any = this.currentFilterGroup
              if (item.id === currentFilter.id) {
                this.noCondition()
              }
              logwire.ui.message({ message: this.$i18n('core', 'client.advanced-filter.message.delete-successfully'), type: 'success' })
            })
          }
          // 如果删除的是默认过滤组
          const defaultFilter: Record<string, any> = this.defaultFilterGroup
          if (item.id === defaultFilter.id) {
            this.cancelDefaultFilterGroup(callback)
          } else {
            callback()
          }
        }
      },
      distinguishCancelAndClose: true
    }
    logwire.ui.confirm(param)
  }

  getFilterGroups () {
    // 根据namespace + layoutName + datasetName + userID 去请求视图
    this.filterGroupOptions = [{ name: this.$i18n('core', 'client.advanced-filter.private'), id: 1 }]
    const data = {
      getTotalBy: 'count',
      pageNo: 1,
      pageSize: -1,
      queryParams: {
        search_bar_control: this.layoutName + '.' + this.dataSetName
      }
    }
    const sharedData = {
      getTotalBy: 'count',
      pageNo: 1,
      pageSize: -1,
      queryParams: {
        search_bar_control: this.layoutName + '.' + this.dataSetName
      }
    }
    Promise.all([
      queryByFilter({ layoutName: this.layoutName, namespace: 'core', queryName: 'core.advanced_filter_search', filter: data }),
      queryByFilter({ layoutName: this.layoutName, namespace: 'core', queryName: 'core.advanced_filter_search_shared', filter: sharedData })
    ])
      .then(res => {
        const [{ data: { data: privateFilterGroups } }, { data: { data: sharedFilterGroups } }] = res
        const currentFilterGroup: any = this.currentFilterGroup
        const defaultFilterGroup: any = this.defaultFilterGroup
        privateFilterGroups.rows.forEach((item: { private: boolean }) => {
          item.private = true
        })
        if (privateFilterGroups.rows.length > 0) {
          this.filterGroupOptions.push(...privateFilterGroups.rows)
        }
        sharedFilterGroups.rows.forEach((item: { private: boolean }) => {
          item.private = false
        })
        if (sharedFilterGroups.rows.length > 0) {
          this.filterGroupOptions.push({ name: this.$i18n('core', 'client.advanced-filter.public'), id: 2 })
          this.filterGroupOptions.push(...sharedFilterGroups.rows)
        }
        // 判断filterGroupOptions中的当前过滤组和默认过滤组
        this.filterGroupOptions.forEach(item => {
          item.current = false
          item.default = false
          if (currentFilterGroup.id && currentFilterGroup.id === item.id) {
            item.current = true
          }
          if (defaultFilterGroup.id && defaultFilterGroup.id === item.id) {
            item.default = true
          }
        })
      })
  }

  closeFilterGroup () {
    // 清空过滤组数据
    this.filterGroupVisible = false
    this.context.getOrCreateDataSet('__advancedFilterDs').removeRow(0)
    this.filterGroupForm = {
      id: null,
      version: 0,
      name: '',
      shared: false,
      filter: {
        type: 'group',
        not: false,
        connector: 'and',
        conditions: []
      }
    }
  }

  hideMatchModeSelector () {
    this.matchModeVisible = false
  }

  updateMatchMode (mode: string) {
    const prevMode = this.keywordFilter.matchMode
    this.keywordFilter.matchMode = mode
    this.matchModeVisible = false
    if (prevMode !== mode) {
      this.calcSearchInputPadding()
    }
  }

  handleKeyEventForPopFilter (e: any) {
    if (e.code === 'Escape' && this.quickFilterVisible) {
      this.quickFilterVisible = false
    }
  }

  handleDocumentClick (event: any) {
    if (event.target === document.body) {
      return
    } else if (event.target instanceof HTMLElement && event.target.id === 'app') {
      return
    }
    const { quickFilter, quickFilterReference } = this.$refs
    if (quickFilter.contains(event.target) ||
      quickFilterReference.contains(event.target) ||
      event.target.classList.contains('el-input__clear')
    ) return
    this.quickFilterVisible = false
  }

  handleSearch () {
    const search = () => {
      this.quickFilterVisible = false
      // 每次查询前重置查询页码为 1, 防止查询页码超出最大页码数导致页面无数据
      LayoutModule.setTablePageNo({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        pageNo: 1
      })
      this.component.onSearch
        ? this.runRunnableContent('onSearch')
        : eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    }
    this.component.quickFilter
      ? this.$refs.quickFilterForm.$refs.form.validate((valid: boolean) => {
        if (!valid) {
          if (!this.quickFilterVisible) { logwire.ui.message({ message: this.$i18n('core', 'client.tips.required-filter-filed-is-empty'), type: 'warning' }) }
          return
        }
        search()
      })
      : search()
  }

  handleReset () {
    LayoutModule.resetQuickFilter({
      layoutName: this.layoutName,
      dataSetName: this.dataSetName
    })
  }

  hideItems (is: string) {
    if (is === 'lw-button') {
      this.$refs.operationsRef && this.$refs.operationsRef.hide()
    }
  }

  calcSearchInputPadding (): void {
    this.$nextTick(() => {
      const { modeSelector, moreCondition } = this.$refs
      if (!modeSelector || !moreCondition) return
      const suffixWidth = moreCondition.clientWidth
      const prefixWidth = modeSelector.clientWidth
      const input: any = this.$el.querySelector('.search-input')?.querySelector('.el-input__inner')
      if (input) {
        input.style.paddingRight = suffixWidth + 10 + 'px'
        input.style.paddingLeft = prefixWidth + 10 + 'px'
      }
    })
  }

  // 修改当前过滤组
  changeCurrentFilterGroup (item: any) {
    // 将查询字符串对象之后过滤，然后发给服务端进行请求
    const filterGroup = JSON.parse(item.filter_json)
    const formateFilterGroup = this._formateFilterGroup(filterGroup)
    formateFilterGroup.name = item.name
    formateFilterGroup.id = item.id
    LayoutModule.loadAdvancedFilter({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      advancedFilter: formateFilterGroup
    })
    // 每次查询前重置查询页码为 1, 防止查询页码超出最大页码数导致页面无数据
    LayoutModule.setTablePageNo({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      pageNo: 1
    })
    eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    this.currentFilterGroup = item
    // 收起下拉框
    this.$refs.filterGroupDrop.hide()
  }

  _formateFilterGroup (data: any) {
    const { type, conditions } = data
    if (type === 'group' && Array.isArray(conditions)) {
      data.conditions = conditions.filter(item => !(item.type === 'group' && item.conditions.length === 0)).map(item => this._formateFilterGroup(item))
    }
    if (type === 'condition') {
      const { layouts, is, id, betweenClass, ...other } = data
      return other
    }
    return data
  }

  setDefaultAdvancedFilter (id: number) {
    queryById({ layoutName: this.layoutName, namespace: 'core', queryName: 'core.advanced_filter_by_id', id })
      .then(res => {
        const data = res.data.data
        // 查询到默认过滤之后，由于可以取消共享，所以先判断这个过滤组是否是自己的或者是共享的
        if (data.shared === true || data.insert_user_id === LocalModule.user.id) {
          this.defaultFilterGroup = data
          this.currentFilterGroup = data
          // 将查询字符串对象之后过滤，然后发给服务端进行请求
          const filterGroup = JSON.parse(data.filter_json)
          const formateFilterGroup = this._formateFilterGroup(filterGroup)
          formateFilterGroup.name = data.name
          formateFilterGroup.id = data.id
          LayoutModule.loadAdvancedFilter({
            layoutName: this.encodedLayoutName,
            dataSetName: this.dataSetName,
            advancedFilter: formateFilterGroup
          })
        }
        eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.init-retrieve`)
      })
      .catch(() => eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.init-retrieve`))
  }

  created (): void {
    this.dataSetName && this.$nextTick(() => {
      let quickFilterFields = this.component.quickFilter
      quickFilterFields = quickFilterFields ? quickFilterFields.map((field: any) => {
        const { quickFilterOperator, quickFilterSubQueryFilterOperator } = field
        if (!quickFilterOperator) field.quickFilterOperator = 'eq'
        if (field.is === 'lw-date-time' && dateOperators.includes(quickFilterOperator)) { field.is = 'lw-date' }
        if (quickFilterOperator === 'subQuery' && !quickFilterSubQueryFilterOperator) field.quickFilterSubQueryFilterOperator = 'eq'
        return field
      }) : []
      LayoutModule.loadDataSetFilters({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        keywordFields: this.getFinalAttributeValue('keywordFields', { type: attributeType.STRING_ARRAY }),
        quickFilterFields: quickFilterFields,
        allowedKeywordOperators: this.allowedKeywordOperators
      })
      // 获取用户分区过滤的对应信息，带入查询
      if (this.partitionFilterEnabled) {
        const partitionFilter = Object.assign(getPartition(this.defaultCommonPartition), { name: this.defaultCommonPartition })
        LayoutModule.loadPartitionFilter({
          layoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          partitionFilter
        })
      }
    })
  }

  mounted () {
    addResizeListener(this.$refs.searchBarRef, this.sliceOperations)
    addResizeListener(this.$el, this.calcSearchInputPadding)
    this.calcSearchInputPadding()
    this.filterLayoutName = this.encodedLayoutName
    this.filterDataSetName = this.dataSetName
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.deleteAdvancedFilter`, this.noCondition)
    // 当 user_table_setting 表中保存了 default_advanced_filter_id 信息，则获取用户默认过滤组的对应信息，带入查询
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.set_default_advancedFilter`, this.setDefaultAdvancedFilter)
  }

  beforeDestroy (): void {
    removeResizeListener(this.$refs.searchBarRef, this.sliceOperations)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.deleteAdvancedFilter`)
  }
}

export default LwSearchBar
