import { doAction, documentDirectDownloadPrepare } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import { getArchiveStorageAppend } from '@/utils/common'
import { getCsrfToken } from '@/utils/data'
import { downloadFile } from '@/utils/layout'
import axios, { AxiosResponse } from 'axios'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DownloadFile extends Vue {
  // 是否启用直传服务器
  get isDirectAccessStorageService () {
    return LocalModule.config.directAccessStorage
  }

  // 下载文件 直传则调用接口获取下载地址
  async _download (file: { name: string, code: string }, namespace: string): Promise<void> {
    const code = file.code
    // document-download 如果需要视频播放，需要加 &isDownload=false
    let url = `/api/open/${namespace}/core.document-download?code=${code}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
    console.log(url, this.isDirectAccessStorageService)
    if (this.isDirectAccessStorageService) {
      const { data: { data } } = await documentDirectDownloadPrepare({ namespace, data: { code } })
      const header = data.header
      if (header.Host) delete header.Host
      url = data.url
      axios.get(url, { headers: header, responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          downloadFile(res.data, file.name)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      downloadFile(url, file.name)
    }
  }

  // 打包下载全部文件
  async _downloadAll (codes: string[], zipName: string) {
    const filename = zipName + '.zip'
    const data = { filename, codes }
    const res = await doAction({ namespace: 'core', actionName: 'core.batch-document-download', data }, { responseType: 'blob' })
    const blob: Blob = new Blob([res.data], { type: 'application/octet-stream' })
    downloadFile(blob, filename)
  }
}
