











import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeType } from '@/utils/const'
import Args from '@/models/Args'

@Component({ name: 'lw-wrapper' })
class LwWrapper extends BaseComponent {
  get style (): Record<string, string> { return this.getFinalAttributeValue('style', { types: [attributeType.OBJECT, attributeType.STRING] }) }

  get clazz (): Record<string, string> { return this.getFinalAttributeValue('class', { types: [attributeType.STRING] }) }

  handleClick (evt: MouseEvent) {
    if (this.component.onClick) {
      evt.stopPropagation()
      const args = new Args(this.context, { row: this.currentRowInParent })
      this.runRunnableContent('onClick', { args })
    }
  }
}
export default LwWrapper
