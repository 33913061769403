





































import { getMaxZIndex } from '@/utils/dom'
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ name: 'LwUnmaskedDraweer' })
export default class LwUnmaskedDraweer extends Vue {
  @Prop({ type: Boolean, default: false }) appendToBody!: boolean
  @Prop({ type: Function }) beforeClose!: () => void
  @Prop({ type: String, default: '' }) customClass!: string
  @Prop({ type: Boolean, default: true }) closeOnPressEscape!: boolean
  @Prop({ type: Boolean, default: false }) destroyOnClose!: boolean
  @Prop({ type: Boolean, default: true }) showClose!: boolean
  @Prop({ type: [Number, String], default: '0' }) size!: number | string
  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: Boolean }) visible!: boolean
  @Prop({ type: Boolean, default: true }) withHeader!: boolean
  rendered = false
  opened = false
  closed = false
  _closing = false
  _closeTimer: number | null = null
  _openTimer: number | null = null
  _opening = false
  prevActiveElement: null | HTMLElement = null
  zIndex = 2000

  get drawerSize () {
    return typeof this.size === 'number' ? `${this.size}px` : this.size
  }

  get drawerStyle () {
    // 宽度 zIndex
    const result = {
      zIndex: this.zIndex,
      width: this.drawerSize
    }
    return result
  }

  @Watch('visible')
  setVisible (val: boolean) {
    if (val) {
      this.closed = false
      this.$emit('open');
      (this.$el as HTMLElement).style.transform = 'translate(100%)'
      if (this._opening) return
      if (!this.rendered) {
        this.rendered = true
        Vue.nextTick(() => {
          this.open()
        })
      } else {
        this.open()
      }
      if (this.appendToBody) {
        document.body.appendChild(this.$el)
      }
      this.prevActiveElement = document.activeElement as HTMLElement
      setTimeout(() => {
        (this.$el as HTMLElement).style.transform = 'unset'
      })
    } else {
      this.close()
      if (!this.closed) this.$emit('close')
      this.$nextTick(() => {
        if (this.prevActiveElement) {
          this.prevActiveElement.focus()
        }
      })
    }
  }

  afterEnter () {
    this.$emit('opened')
  }

  afterLeave () {
    this.$emit('closed')
  }

  open (options?: any) {
    if (!this.rendered) {
      this.rendered = true
    }
    const props = _.merge({}, this.$props || this, options)

    if (this._closeTimer) {
      clearTimeout(this._closeTimer)
      this._closeTimer = null
    }
    clearTimeout(this._openTimer as number)

    const openDelay = Number(props.openDelay)
    if (openDelay > 0) {
      this._openTimer = setTimeout(() => {
        this._openTimer = null
        this.doOpen(props)
      }, openDelay)
    } else {
      this.doOpen(props)
    }
  }

  doOpen (props: any) {
    if (this.$isServer) return
    if (this.opened) return

    this._opening = true

    const dom = this.$el as HTMLElement

    const modal = props.modal
    document.body.append(dom)
    this.zIndex = getMaxZIndex(0)
    this.opened = true

    this.doAfterOpen()
  }

  doAfterOpen () {
    this._opening = false
  }

  hide (cancel?: boolean) {
    if (cancel !== false) {
      this.$emit('update:visible', false)
      this.$emit('close')
      if (this.destroyOnClose === true) {
        this.rendered = false
      }
      this.closed = true
    }
  }

  closeDrawer () {
    const { beforeClose, hide } = this
    if (_.isFunction(beforeClose)) {
      beforeClose(hide)
    } else {
      hide()
    }
  }

  close () {
    if (this._openTimer !== null) {
      clearTimeout(this._openTimer)
      this._openTimer = null
    }
    clearTimeout(this._closeTimer as number)

    this.doClose()
  }

  doClose () {
    this._closing = true

    this.opened = false

    this.doAfterClose()
  }

  doAfterClose () {
    this._closing = false
  }

  mounted () {
    if (this.visible) {
      this.rendered = true
      this.open()
    }
  }
}

