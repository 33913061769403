import {
  attributeSymbol,
  attributeType,
  SCRIPT_CANCELED_FLAG,
  scriptResult,
  SCRIPT_ENVIRONMENT,
  popupLayoutType, APP_LOGIN_LAYOUT_NAME, layoutStatus, SCRIPT_SPECIAL_ENVIRONMENT, SCRIPT_ORIGINAL_ENVIRONMENT, CHECKED_ARCHIVE_STORAGE
} from '@/utils/const'
import _ from 'lodash'
import logwire from '@/logwire'
import { AssociatedContext, LayoutComponent, LayoutContext, LayoutFunction, PopupLayoutConfig } from '@/types/layout'
import { getUuid, tryEval, tryRunFunction } from '@/utils/common'
import store from '@/store'
import eventbus from '@/utils/event'
import { AppModule } from '@/store/modules/app'
import Args from '@/models/Args'
import { checkValueIsEmpty } from '@/utils/data'
import { LocalModule } from '@/store/modules/local'
import { getLoginEntry } from '@/http/api'
import router from '@/router'
import { LayoutModule } from '@/store/modules/layout'
import { MessageBox } from 'element-ui'
import client from 'webpack-theme-color-replacer/client'
import Socket from '@/models/Socket'

declare const window: Window & { [SCRIPT_ENVIRONMENT]: string, [SCRIPT_CANCELED_FLAG]: false } & typeof globalThis

export function toLoginLayout (params: Record<string, any> = {}): void {
  // 关闭所有抽屉和弹框
  eventbus.$emit('close-all-popup-layout')
  LocalModule.clearLocalModule()
  LayoutModule.clearLayoutModule()
  let data = {
    deviceType: 'browser',
    currentUrl: location.href
  }
  data = Object.assign({}, data, params)
  // 登录前退出归档模式
  sessionStorage.removeItem(CHECKED_ARCHIVE_STORAGE)
  getLoginEntry(data)
    .then(res => {
      const { data: { layoutName: loginLayout } } = res.data
      // 获取到 login layout 之后存在 localStorage 中以使用
      stashLoginLayoutToStorage(loginLayout)
      const { params: { layoutName }, query, fullPath } = router.currentRoute
      const isNeedRedirect = logwire.store.getConfig('core.ui.redirect-to-most-recent-page-on-relogin')
      if (layoutName === loginLayout) return
      const targetFullPath = `/layout/${loginLayout}`
      const notRedirectLayouts = ['core.logout', 'core.forget_password', 'core.set_new_password', 'core.new_device_login']
      if (layoutName && isNeedRedirect && !notRedirectLayouts.includes(layoutName)) query.redirect = layoutName
      if (fullPath !== targetFullPath) {
        router.push({
          path: targetFullPath,
          query
        })
      }
      // 断开socket连接,先判断是否有socket，当showFrame为false时，不启用socket
      const instance = Socket.getInstance()
      instance && instance.closeInstance()
    })
}

// 希望有一个结构，能够明确的知道当前所处的最顶层的 layout 是什么
// window[SCRIPT_ENVIRONMENT] 有可能因为触发时机的先后顺序，导致底部的 Layout 后触发，就拿不到弹窗中的 Layout 了
const queueForLayoutLevel: string[] = []

export function getLastestLayoutInQueue () {
  return queueForLayoutLevel[queueForLayoutLevel.length - 1]
}

export function addLayoutQueue (layoutName: string) {
  queueForLayoutLevel.push(layoutName)
}

export function removeLayoutQueue (layoutName?: string) {
  const lastLayout = queueForLayoutLevel.pop()
  if (lastLayout === layoutName) {
    return true
  } else if (layoutName) {
    if (lastLayout) {
      queueForLayoutLevel.push(lastLayout)
    }
    return false
  } else {
    // 如果没有传 layoutName
    queueForLayoutLevel.splice(0, queueForLayoutLevel.length)
    return false
  }
}

/**
 * 设置当前脚本内容运行时的 encodeLayoutName 以及脚本运行的原始 layoutName
 * 由于一些特殊的方法比如 logwire 对象下的方法，在运行时并不知道当前处于哪一个 layout，所以在脚本运行之前将当前 layoutName 注入到 window 当中
 */
export function setLayoutForOutsideComponent ({ layoutName, encodedLayoutName }: { layoutName: string, encodedLayoutName: string }) {
  const currentLayout = window[SCRIPT_ENVIRONMENT]
  if (currentLayout !== encodedLayoutName) {
    window[SCRIPT_ENVIRONMENT] = encodedLayoutName
  }
  window[SCRIPT_ORIGINAL_ENVIRONMENT] = layoutName
}

export function getOriginalLayoutForOutsideComponent () {
  return window[SCRIPT_ORIGINAL_ENVIRONMENT]
}

/**
 * 获取当前脚本内容运行时的 layoutName
 * @return {string} 当前脚本内容运行时的 layoutName
 */
export function getLayoutNameOutsideComponent (): string {
  if (window[SCRIPT_SPECIAL_ENVIRONMENT]) {
    const layoutName = window[SCRIPT_SPECIAL_ENVIRONMENT]
    delete window[SCRIPT_SPECIAL_ENVIRONMENT]
    return layoutName
  }
  return window[SCRIPT_ENVIRONMENT]
}

/**
 * 获取补全 namespace 之后的 layoutName
 * @param {string} layoutName 需要补全的 layoutName
 * @return {string} 补全后的 layoutName
 * */
export function getNamespacedLayoutName (layoutName?: string) {
  if (layoutName?.includes('.')) {
    return layoutName
  }
  const currentLayout = getLayoutNameOutsideComponent()
  const namespace = currentLayout.split('.')[0]
  return `${namespace}.${layoutName}`
}

export function isEventRegistered (eventName: string) {
  const events = (eventbus as any)._events
  return _.has(events, eventName) && !checkValueIsEmpty(events[eventName])
}

export function isSymbol (attributeValue: string, symbol: attributeSymbol): boolean {
  return attributeValue.startsWith(symbol)
}

export function isType (attributeValue: any, type: Array<string>): boolean {
  let isType = false
  const map = {
    [attributeType.STRING]: _.isString,
    [attributeType.BOOLEAN]: _.isBoolean,
    [attributeType.NUMBER]: _.isFinite,
    [attributeType.ARRAY]: _.isArray,
    [attributeType.OBJECT]: _.isObject,
    [attributeType.NUMBER_ARRAY]: (value: any) => _.isArray(value) && value.every(item => _.isFinite(item)),
    [attributeType.STRING_ARRAY]: (value: any) => _.isArray(value) && value.every(item => _.isString(item)),
    [attributeType.OBJECT_ARRAY]: (value: any) => _.isArray(value) && value.every(item => _.isObject(item))
  }
  type.forEach(t => {
    if (map[t] && map[t](attributeValue)) {
      isType = true
    }
  })
  return isType
}

/**
 * 处理一些特殊的属性，比如 height 属性，需要的是数字
 * */
export function parseSpecialAttribute (attributeValue: any, type: attributeType): any {
  const map = {
    [attributeType.BOOLEAN]: attribute2Boolean,
    [attributeType.NUMBER]: attribute2Number,
    [attributeType.NUMBER_ARRAY]: attribute2Numbers,
    [attributeType.STRING_ARRAY]: attribute2Strings,
    [attributeType.OBJECT]: null,
    [attributeType.STRING]: null
  }
  const func = map[type]
  return func ? func(attributeValue) : attributeValue
}

/**
 * 尝试将属性转为 boolean 类型，只转换字符串 "true" 和 "false"
 * */
export function attribute2Boolean (attributeValue: string): boolean | string {
  if (attributeValue === 'true') return true
  else if (attributeValue === 'false') return false
  return attributeValue
}

/**
 * 将属性强转成 boolean 类型，对于非字符串 "true" 和 "false" 的内容，返回期望的值，并且打印警告
 * */
export function attributeForce2Boolean (attributeName: string, component: LayoutComponent, expected: boolean): boolean {
  const attributeValue = component[attributeName]
  const { is } = component
  if (attributeValue === 'true') {
    return true
  } else if (attributeValue === 'false') {
    return false
  }
  attributeValue && warnAttributeTypeWrong(attributeName, is, attributeType.STRING, attributeType.BOOLEAN)
  return expected
}

/**
 * 尝试将属性转为 number 类型，只转换可以转换成整数的字符串
 * */
export function attribute2Number (attributeValue: string): number | string {
  const parsedValue = parseInt(attributeValue)
  return _.isFinite(parsedValue) ? parsedValue : attributeValue
}

/**
 * 将属性强转成 number 类型，对于不能转换成 number 类型的内容，返回期望的值，并且打印警告
 * */
export function attributeForce2Number (attributeName: string, component: LayoutComponent, expected: number): number {
  const attributeValue = component[attributeName]
  const { is } = component
  const parsedValue = parseInt(attributeValue)
  if (_.isFinite(parsedValue)) {
    return parsedValue
  }
  attributeValue && warnAttributeTypeWrong(attributeName, is, attributeType.STRING, attributeType.NUMBER)
  return expected
}

export function attribute2Numbers (attributeValue: string): Array<number> {
  return attributeValue ? attributeValue.split(',').map(item => parseInt(item)) : []
}

export function attribute2Strings (attributeValue: string): Array<string> {
  return attributeValue ? attributeValue.split(',').map(item => item.trim()) : []
}

export function getPlainAttributeValue (attributeValue: string, symbol: attributeSymbol): string {
  return attributeValue.replace(symbol, '').trim()
}

export function getDecodedName (encodedName: string): string {
  const pattern = /(_uuid-\w+)$/
  return encodedName.replace(pattern, '')
}

/**
 * 解析动态内容名中可能携带的参数
 * 例如 {function}buttonClick(abc，'def')
 * 函数名为 buttonClick
 * 参数为字符串 'abc', 'def' (所有的参数都会被当成字符串使用)
 * @param {string} attributeValue 开发者写的属性值
 * @return {{ resourceName, params }} 解析出来的资源名（函数名、i18n 词条名）以及参数列表，会被包裹成一个对象
 * */
export function getResourceWithParams (attributeValue: string): Record<string, any> {
  const pattern = /\((.+)\)/
  const [bracketString = '', paramsString = ''] = attributeValue.match(pattern) || []
  const params = paramsString.split(',').map(p => p.trim())
  const resultParams: Array<string> = []
  let temData = ''
  params.forEach((p, i) => {
    if (
      !temData &&
      ((p.startsWith('\'') && !p.endsWith('\'')) ||
      (p.startsWith('"') && !p.endsWith('"')) ||
      (p.startsWith('{') && !p.endsWith('}')) ||
      (p.startsWith('[') && !p.endsWith(']')))) {
      temData = p
    } else if (
      temData &&
      ((!p.startsWith('\'') && p.endsWith('\'')) ||
      (!p.startsWith('"') && p.endsWith('"')) ||
      (!p.startsWith('{') && p.endsWith('}')) ||
      (!p.startsWith('[') && p.endsWith(']')))) {
      temData += (`,${p}`)
      resultParams.push(temData)
      temData = ''
    } else {
      if (!temData) {
        resultParams.push(p)
      } else {
        temData += (`,${p}`)
      }
    }
  })
  return {
    resourceName: attributeValue.replace(bracketString, ''),
    params: resultParams?.map(param => param.replace(/^('|")|('|")$/g, '').trim())
  }
}

/**
 * 获取存储在 associatedContext 中的函数
 * @param {string} attributeValue 属性的值，比如 {function}getTitle
 * @param { AssociatedContext } associatedContext layout 相关的 functions 等内容
 * @return {function} 解析出的函数
 * */
export function getLayoutFunction (attributeValue: string, associatedContext: AssociatedContext): LayoutFunction {
  const functionName = getPlainAttributeValue(attributeValue, attributeSymbol.FUNCTION)
  const func = associatedContext.functions[functionName]
  return func
}

/**
 * 获取存储在 common 中的函数
 * @param {string} attributeValue 属性的值，比如 {common}getTitle
 * @param { LayoutContext } context layout 的 context
 * @param { Args } args layout 的 Args
 * @return {function} 解析出的函数
 * */
export function getCommonFunction (attributeValue: string, args: Args): LayoutFunction {
  let namespace = args.getContext()?.getNamespace()
  if (attributeValue.includes('.')) {
    const placeholder = attributeValue.match(/\w+\./)![0]
    attributeValue = attributeValue.replace(placeholder, '')
    namespace = placeholder.replace('.', '')
  }
  const common = logwire.common[namespace]
  const functionName = getPlainAttributeValue(attributeValue, attributeSymbol.COMMON)
  const func = common[functionName]
  return func
}

/**
 * 处理 {eval}、{function}、{common} 等可以被运行的内容
 * @param { string } attributeName 属性名
 * @param { LayoutComponent } component 组件
 * @param { LayoutContext } args 函数可用参数
 * @param { AssociatedContext } associatedContext layout 相关的 functions 等内容
 * @param {boolean} noWarning 不需要警告，一般用于获取值时不需要警告
 * @return { any } 内容运行之后的结果
 * */
export function runRunnableContent (attributeName: string, component: LayoutComponent, args: Args, associatedContext: AssociatedContext, noWarning = false): any {
  let result: any = scriptResult.NOT_RUNNABLE
  const attributeValue = component[attributeName]
  if (_.isUndefined(attributeValue)) {
    !noWarning && warnEventHandlerMissing(attributeName, component.is)
    return
  }
  if (isSymbol(attributeValue, attributeSymbol.EVAL)) {
    const namespace = args.getContext().getNamespace()
    const common = logwire.common[namespace]
    const string = `"use strict"; var args = param.args; var common = param.common; ${getPlainAttributeValue(attributeValue, attributeSymbol.EVAL)}`
    result = (associatedContext as any).evalFunc(string, { args, common })
  } else if (isSymbol(attributeValue, attributeSymbol.FUNCTION)) {
    const { resourceName, params } = getResourceWithParams(attributeValue)
    const func = getLayoutFunction(resourceName, associatedContext)
    // 当函数没有定义或者并不是一个函数时进行提醒并且返回 undefined
    if (!_.isFunction(func)) {
      !noWarning && warnLayoutFunctionMissing(getPlainAttributeValue(attributeValue, attributeSymbol.FUNCTION))
    } else {
      result = tryRunFunction(func, args, params)
    }
  } else if (isSymbol(attributeValue, attributeSymbol.COMMON) || isSymbol(attributeValue, attributeSymbol.COMMONWITHNULLARGS)) {
    let { resourceName, params } = getResourceWithParams(attributeValue)
    if (isSymbol(attributeValue, attributeSymbol.COMMONWITHNULLARGS)) {
      resourceName = resourceName.replace(attributeSymbol.COMMONWITHNULLARGS, attributeSymbol.COMMON)
    }
    const func = getCommonFunction(resourceName, args)
    // 当函数没有定义或者并不是一个函数时进行提醒并且返回 undefined
    if (!_.isFunction(func) && !noWarning) {
      warnCommonFunctionMissing(getPlainAttributeValue(attributeValue, attributeSymbol.COMMON))
    } else {
      if (isSymbol(attributeValue, attributeSymbol.COMMONWITHNULLARGS)) {
        result = tryRunFunction(func, args, params, true)
      } else {
        result = tryRunFunction(func, args, params)
      }
    }
  } else {
    // 当属性值并不属于 {eval}、{function}、{common} 中任何一个内容时给出提示
    !noWarning && warnContentNotRunnable(attributeName, component.is)
  }
  return result
}

/**
 * 由于任何属性都可能会被写成 {function} 或者 {eval} 等动态形式
 * 所以需要对属性值进行一个解析
 * 1. 在这个属性没有被定义时返回空内容
 * 2. 在属性值被写成 {function} 等动态内容时，返回动态内容运行结果
 * 3. 在属性值不符合期望类型时提醒并返回空值
 * @param { string } attributeName 属性名
 * @param { LayoutComponent } component 组件
 * @param { LayoutContext } args 函数可用参数
 * @param { AssociatedContext } associatedContext layout 相关的 functions 等内容
 * @param { Array | string } types 期望的属性类型
 * @return { any } 处理之后的属性值
 * */
export function getFinalAttributeValue (attributeName: string, component: LayoutComponent, args: Args, associatedContext: AssociatedContext, types: Array<attributeType> | attributeType = [attributeType.STRING]): any {
  // types 先统一处理为数组
  if (_.isString(types)) types = [types]
  // 先处理特殊的属性，比如 boolean 和 number 类型
  const attributeValue = component[attributeName]
  if (_.isUndefined(attributeValue)) return
  let finalAttributeValue: any = attributeValue
  // 检查是否是可执行内容并且是否有返回值
  const result = runRunnableContent(attributeName, component, args, associatedContext, true)
  // 返回的结果为 NOT_RUNNABLE 时代表并不是一个可执行的动态内容
  // 否则即使执行的结果为 undefined，也应该以返回结果作为最终属性值
  finalAttributeValue = result === scriptResult.NOT_RUNNABLE
    // 不是 可执行的动态内容 则返回值类型应该唯一，此时 type 中应只有一个值，默认为 attributeType.STRING
    ? parseSpecialAttribute(attributeValue, types[0])
    : result
  if (isSymbol(attributeValue, attributeSymbol.I18N)) {
    finalAttributeValue = getI18nContent(attributeValue)
  }
  const finalType = typeof finalAttributeValue
  const isExpectedType = isType(finalAttributeValue, types)
  // 不符合类型需求的内容进行提示并且忽略该内容
  !isExpectedType && warnAttributeTypeWrong(attributeName, component.is, finalType, types)
  return isExpectedType ? finalAttributeValue : undefined
}

export function getI18nContent (content: string, namespace?: string) {
  if (!isSymbol(content, attributeSymbol.I18N)) return content
  const { resourceName, params } = getResourceWithParams(content)
  const i18nCode = getPlainAttributeValue(resourceName, attributeSymbol.I18N)
  // 优先从 namespace 下取，没有则从 core 底下取内容
  const namespacedMessage = logwire.ui.getI18nMessage(i18nCode, params, namespace)
  const coreMessage = logwire.ui.getI18nMessage(i18nCode, params, 'core')
  return namespacedMessage === i18nCode ? coreMessage : namespacedMessage
}

const WARNING_PREFIX = 'Logwire Warning'

/**
 * 组件在错误的位置使用，比方 lw-table下配置了lw-cascader [该组件查询次数极多，列表滚动极其消耗性能]
 * @param sonComponent 子组件名称
 * @param parentComponent 父组件名称
 */
export function warnComponentUsedWrong (sonComponent: string, parentComponent: string): void {
  console.warn(`[${WARNING_PREFIX}] Component "${sonComponent}" cannot be a subcomponent of ${parentComponent}`)
}

/**
 * 在控制台打印组件属性类型不匹配故而被忽略的警告
 * @param {string} attributeName 属性名称
 * @param {string} componentIs 组件标签名
 * @param {string} type 实际类型
 * @param {Array<string>} expectedType 期望类型
 * */
export function warnAttributeTypeWrong (attributeName: string, componentIs: string, type: string, expectedType: Array<string> | string): void {
  let warnStr
  if (_.isString(expectedType)) {
    warnStr = `[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is ignored because expected type "${expectedType}" but got type "${type}"`
  } else if (expectedType.length === 1) {
    warnStr = `[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is ignored because expected type "${expectedType[0]}" but got type "${type}"`
  } else {
    let expectedTypeStr = ''
    expectedType.forEach((t, i) => {
      if (i === 0) {
        expectedTypeStr = `"${t}"`
      } else {
        expectedTypeStr += `,"${t}"`
      }
    })
    warnStr = `[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is ignored because expected type is one of ${expectedTypeStr} but got type "${type}"`
  }
  console.warn(warnStr)
}

/**
 * 在控制台打印 layout 下没找到对应函数的警告
 * @param {string} functionName 函数名
 * */
export function warnLayoutFunctionMissing (functionName: string): void {
  console.warn(`[${WARNING_PREFIX}] Layout function "${functionName}" not found`)
}

/**
 * 在控制台打印 common 下没找到对应函数的警告
 * @param {string} functionName 函数名
 * */
export function warnCommonFunctionMissing (functionName: string): void {
  console.warn(`[${WARNING_PREFIX}] Common function "${functionName}" not found`)
}

/**
 * 在控制台打印希望是一段运行内容（一般用于事件处理）但是得到的是不可运行内容的警告
 * @param {string} attributeName 属性名
 * @param {string} componentIs 组件标签名
 * */
export function warnContentNotRunnable (attributeName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is ignored because expected one of "${attributeSymbol.EVAL}","${attributeSymbol.FUNCTION}","${attributeSymbol.COMMON}" but got none`)
}

/**
 * 在控制台打印必填属性丢失的警告
 * @param {string} attributeName 属性名
 * @param {string} componentIs 组件标签名
 * */
export function warnAttributeMissing (attributeName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is required but isn't settled`)
}

/**
 * 在控制台打印事件处理函数丢失的警告
 * @param {string} attributeName 属性名
 * @param {string} componentIs 组件标签名
 * */
export function warnEventHandlerMissing (attributeName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is required to handle the event but isn't settled`)
}

/**
 * 在控制台打印使用已抛弃属性的的警告
 * @param {string} attributeName 属性名
 * @param {string} replacerAttributeName 代替品属性名
 * @param {string} componentIs 组件标签名
 * */
export function warnDeprecatedAttribute (attributeName: string, replacerAttributeName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] Attribute "${attributeName}" of "${componentIs}" is deprecated, use attribute "${replacerAttributeName}" instead`)
}

/**
 * 在控制台打印使用已抛弃接口的的警告
 * @param {string} api 抛弃接口
 * @param {string} replacedApi 替换接口
 * */
export function warnDeprecatedApi (api: string, replacedApi?: string): void {
  console.warn(`[${WARNING_PREFIX}] API "${api}" is deprecated` + replacedApi ? `, use API "${replacedApi}" instead` : '')
}

/**
 * 在控制台打印 action 丢失的警告
 * @param {string} id action id
 * */
export function warnActionMissing (layoutName: string, id: string) {
  // action丢失的提示要弹框显示
  logwire.ui.message({
    type: 'error',
    message: logwire.ui.getI18nMessage('client.tips.find-action-unsuccessfully', [layoutName, id])
  })
  console.warn(`[${WARNING_PREFIX}] Action "${id}" not found`)
}

/**
 * 在控制台打印事件被重复注册的警告
 * @param {string} eventName 事件名签名
 * @param {string} componentIs 组件标签名
 * */
export function warnDuplicatedEventRegistration (eventName: string, componentIs: string, duplicateKeys: Array<string>) {
  console.warn(`[${WARNING_PREFIX}] Duplicated registration of event "${eventName}" for "${componentIs}", please check whether ${duplicateKeys.map(key => `"${key}"`).join(' or ')}  is repeatedly settled for component with same "dataSet" `)
}

export function warnEventNotSupported (eventName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] Event ${eventName} is not supported for ${componentIs}`)
}

export function warnPaginationTotalByError () {
  console.error(`[${WARNING_PREFIX}] lw-pagination [getTotalBy] attribute must be [count] when [aggregationFields] attribute is set`)
}

/**
 * 检查一些需要用 v-html 渲染的模版中，是否正确使用 <%- %> 模版
 * */
export function checkTemplateSafety (attributeName: string, component: LayoutComponent): void {
  // 校验是否使用正确的转译方法
  const template = component[attributeName]
  if (!template) return
  const reg = /(<%-).*(%>)/g
  // TODO 有没有直接能用正则判断出来 args. 外层必须包裹 <%- %>
  if (template.replace(reg, '').indexOf('args.') > -1) {
    warnXSSAttackAwareness('template', component.is)
  }
}

export function warnXSSAttackAwareness (attributeName: string, componentIs: string): void {
  console.warn(`[${WARNING_PREFIX}] There is XSS attack risk when rendering the "${attributeName}" of component "${componentIs}", use "<%- template %>" and DataRow.getDataEscaped() to avoid it`)
}

export function percent2decimal (percent: string) : number {
  let str: number = parseInt(percent.replace('%', ''))
  str = str / 100
  return str
}

// 获取全局的 i18n 词条
export function getGlobalI18nMessage (namespace: string, code: string, placeholder?: string | string[]): string {
  const i18nValue = LocalModule.i18n?.[namespace]?.[code] || code
  return replaceParams(i18nValue, placeholder)
}

export function replaceParams (value: string, placeholder?: any): string {
  if (_.isArray(placeholder)) {
    for (let i = 0; i < placeholder.length; i++) {
      const reg = new RegExp(`\\{${i}}`, 'g')
      value = value.replace(reg, placeholder[i])
    }
  } else {
    value = value.replace(/\{0}/g, placeholder)
  }
  return value
}

// 判断进行舍、入处理
export function ceil (originalValue: any, decimalVal: string, decimalScale: number) : boolean {
  if (Number(originalValue) > 0) {
    return parseInt(decimalVal.split('')[decimalScale]) > 4
  } else {
    return parseInt(decimalVal.split('')[decimalScale]) < 5
  }
}

export function numberSplitByWan (number: any) {
  number += ''
  const rgx = /(\d+)(\d{4})/
  while (rgx.test(number)) {
    number = number.replace(rgx, '$1' + ',' + '$2')
  }
  return number
}

export function stashLoginLayoutToStorage (layoutName: string): void {
  window.localStorage.setItem(APP_LOGIN_LAYOUT_NAME, layoutName)
}

export function removeLoginLayoutFromStorage (): void{
  window.localStorage.removeItem(APP_LOGIN_LAYOUT_NAME)
}

export function getLoginLayoutFromStorage (): string {
  return window.localStorage.getItem(APP_LOGIN_LAYOUT_NAME) || ''
}

/**
 * 检查当前 layout 是否存在某个 dataSet 正在编辑，假如正在编辑，则弹出框进行处理
 * 当点击了 '保存' 或者 '保存并离开' 的情况下才会去执行后续的代码
 * @param {function} callback 后续逻辑代码函数
 * @param {function} saveCallback 保存的逻辑的代码
 * @param { string } targetLayoutName 目标Layout，也许手动指定 layout 比使用一个全局 window 对象更好？全局 window 对象不易控制
 * */
export function proceedAfterEditingDataSetCheck (callback: () => void, saveCallback?: () => void, targetLayoutName?: string): void {
  const layoutName = targetLayoutName || getLayoutNameOutsideComponent()
  const layout = LayoutModule.data[layoutName]
  const editingDataSet = layout?.editingDataSet
  const status = layout?.status
  const h = eventbus.$createElement
  if (editingDataSet && ![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(status as layoutStatus)) {
    const close = () => {
      MessageBox.close()
    }
    const abandonAndLeave = () => {
      LayoutModule.updateLayoutEditingDataSet({ layoutName, dataSet: '' })
      callback()
      close()
    }
    const saveAndLeave = () => {
      saveCallback ? saveCallback() : eventbus.$emit(`${editingDataSet}.save`, { defaultAfterSave: callback })
      close()
    }
    MessageBox({
      message: h('div', { class: 'lw-confirm-box' }, [
        h('div', { class: 'lw-confirm-title' }, [
          h('div', { class: 'lw-confirm-message' }, getGlobalI18nMessage('core', 'client.tip')),
          h('i', { class: 'el-icon-close', on: { click: close } })
        ]),
        h('div', { class: 'lw-confirm-message' }, [
          h('i', { class: 'el-icon-warning' }),
          h('span', undefined, getGlobalI18nMessage('core', 'client.tips.tip-before-leave'))
        ]),
        h('div', { class: 'lw-confirm-operations' }, [
          h('button', { class: 'el-button el-button--primary el-button--mini', on: { click: saveAndLeave } }, getGlobalI18nMessage('core', 'yes')),
          h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: abandonAndLeave } }, getGlobalI18nMessage('core', 'no')),
          h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: close } }, getGlobalI18nMessage('core', 'client.common.cancel'))
        ])
      ]),
      closeOnClickModal: false,
      showConfirmButton: false
    })
  } else {
    callback()
  }
}

/**
 * 切换主题色
 * @param {array<string>} colors 包括theme-1~10,header,menu,gray-1~14,warn-1~10,error-1~10
 * */
export function switchTheme (theme: Record<string, any>): void {
  // 遍历主题文件，设置css变量
  for (const color in theme.colors) {
    document.documentElement.style.setProperty('--' + color, theme.colors[color])
  }
  // 设置与主题色相关有透明度的颜色值
  document.documentElement.style.setProperty('--gray-1-alpha-10', theme.colors['gray-1'] + '1A')
  document.documentElement.style.setProperty('--gray-1-alpha-15', theme.colors['gray-1'] + '26')
  document.documentElement.style.setProperty('--gray-1-alpha-20', theme.colors['gray-1'] + '33')
  document.documentElement.style.setProperty('--gray-1-alpha-40', theme.colors['gray-1'] + '66')
  document.documentElement.style.setProperty('--gray-14-alpha-3', theme.colors['gray-14'] + '08')
  document.documentElement.style.setProperty('--gray-14-alpha-10', theme.colors['gray-14'] + '1A')
  document.documentElement.style.setProperty('--gray-14-alpha-15', theme.colors['gray-14'] + '26')
  // document.documentElement.style.setProperty('--gray-1-alpha-20', theme.colors['gray-1'] + '33')
  // document.documentElement.style.setProperty('--gray-6-alpha-50', theme.colors['gray-6'] + '80')
  // document.documentElement.style.setProperty('--theme-6-alpha-15', theme.colors['theme-6'] + '26')
}

export function findMasterFields (layoutName: string, queryName: string) : Array<string> {
  const queryMeta = LayoutModule.resource[layoutName].queryMeta
  const _metaFields = queryMeta?.[queryName]
  const metaFields: any[] = []
  if (_metaFields) {
    _metaFields.forEach((field : any) => {
      if (field.masterField) metaFields.push(field.name)
    })
  }
  return metaFields
}

export function downloadFile (param: string | Blob, fileName?: string) {
  const url = _.isString(param)
    ? param
    : window.URL.createObjectURL(param)
  const a = document.createElement('a')
  if (fileName) {
    a.download = fileName
  } else {
    a.download = `file-${getUuid()}`
  }
  a.href = url
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 释放内存
  !_.isString(param) && window.URL.revokeObjectURL(url)
}
