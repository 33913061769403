<script>
import DateTable from '../basic/dateTable.vue'
import DateRangePanel from 'element-ui/packages/date-picker/src/panel/date-range.vue'

export default {
  mixins: [DateRangePanel],

  components: {
    DateTable
  }
}
</script>
