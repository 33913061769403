
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import TextBase from './TextBase'

@Component({
  name: 'TableCellText'
})
class TableCellText extends Mixins(TableCell, TextBase) {}
export default TableCellText
