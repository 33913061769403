import { Component, Vue } from 'vue-property-decorator'
import { calculation, checkValueIsEmpty } from '@/utils/data'
import { numeralFormat } from '@/utils/number'
import { attribute2Boolean, attribute2Number } from '@/utils/layout'
import { calculationType } from '@/utils/const'

@Component
class DateTimeBase extends Vue {
  [propName: string]: any;

  get append (): string { return this.getFinalAttributeValue('append', { args: this.args }) }

  get prepend (): string { return this.component.prepend ? this.getFinalAttributeValue('prepend', { args: this.args }) : '' }

  get positiveOnly () : boolean { return checkValueIsEmpty(this.component.positiveOnly) ? false : attribute2Boolean(this.component.positiveOnly) as boolean }

  get formatEnabled () : boolean { return checkValueIsEmpty(this.component.formatEnabled) ? false : attribute2Boolean(this.component.formatEnabled) as boolean }

  get percentage (): boolean { return checkValueIsEmpty(this.component.percentage) ? false : attribute2Boolean(this.component.percentage) as boolean }

  get decimalScale () : number { return checkValueIsEmpty(this.component.decimalScale) ? 0 : attribute2Number(this.component.decimalScale) as number }

  get inputValue (): any {
    const dataRow = this.form.dataRow
    let result = dataRow[this.component.field]
    if (this.formatEnabled && this.percentage && result) {
      result = result * 100
    }
    return result
  }

  set inputValue (value) {
    let result = value
    if (this.formatEnabled && this.percentage && result !== '') {
      result = calculation([result, 100], calculationType.DIVISION)
    }
    this.form.dataRow[this.component.field] = result
  }

  get displayValue (): string {
    let result = this.format(this.inputValue)
    if (!checkValueIsEmpty(result)) {
      if (this.prepend) {
        result = this.prepend + result
      }
      if (this.append) {
        result += this.append
      }
    }
    return result
  }

  format (val: any) {
    if (val === 0) return 0
    if (checkValueIsEmpty(val)) return ''
    if (this.formatEnabled && val && Number(val)) {
      if (this.positiveOnly) val = Number(val.toString().replace('-', ''))
      return numeralFormat(val, this.decimalScale, this.component.thousandsGroupStyle, this.percentage)
    } else {
      return val
    }
  }
}

export default DateTimeBase
