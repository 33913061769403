




















import BaseComponent from '@/components/layout/BaseComponent'
import logwire from '@/logwire'
import { LayoutModule } from '@/store/modules/layout'
import { commonSemanticPartitions, filterGroupOperator, filterOperator, filterType } from '@/utils/const'
import { checkValueIsEmpty, formatDate } from '@/utils/data'
import eventbus from '@/utils/event'
import { getI18nContent } from '@/utils/layout'
import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

interface tableFilter{
  name: string,
  type: any,
  operator?: string
  value: Array<any>,
  clearable: boolean,
  showValue: boolean,
  field?: string
}

@Component({ name: 'FiltersForTable' })
export default class FiltersForTable extends BaseComponent {
  @Prop() dataSetName!: string

  searches: Array<tableFilter> = []
  commonPartitions = { [commonSemanticPartitions.TODAY]: 'today', [commonSemanticPartitions.LAST_WEEK]: 'last-week', [commonSemanticPartitions.LAST_MONTH]: 'last-month', [commonSemanticPartitions.LAST_3_MONTH]: 'last-3-month', [commonSemanticPartitions.LAST_6_MONTH]: 'last-6-month', [commonSemanticPartitions.LAST_YEAR]: 'last-year' }
  commonKeywordOps = { [filterOperator.EQ]: 'equal', [filterOperator.LIKE]: 'contain', [filterOperator.LEFT_LIKE]: 'start', [filterOperator.REGEXP_LIKE]: 'end' }

  get filters () {
    const filterArr: Array<tableFilter> = []
    const showFilters = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]
    const partitionFilter = showFilters.partitionFilter
    const advancedFilter = showFilters.advancedFilter
    if (partitionFilter && Object.keys(partitionFilter).length !== 0) {
      const name = this.$i18n('core', 'client.advanced-filter.partition.title')
      const value = [partitionFilter.name === 'custom' ? formatDate(partitionFilter.beginDate, 'date') + ' - ' + formatDate(partitionFilter.endDate, 'date')
        : this.$i18n('core', 'client.advanced-filter.partition.' + this.commonPartitions[partitionFilter.name])]
      filterArr.push({ name, value, type: filterType.PARTITION, clearable: false, showValue: true })
    }
    if (advancedFilter && Object.keys(advancedFilter).length !== 0 && advancedFilter.id) {
      const name = this.$i18n('core', 'client.advanced-filter.title')
      const value = [advancedFilter.name]
      filterArr.push({ name, value, type: filterType.ADVANCED, clearable: true, showValue: true })
    }
    return filterArr
  }

  addTableSearch () {
    this.searches = []
    const showFilters = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]
    const keywordFilter = showFilters.keywordFilter
    const quickFilter = showFilters.quickFilter
    // keywordFilter 和 quickFilter 不能直接用computed, 这两个要点击搜索才会生效
    if (keywordFilter && Object.keys(keywordFilter).length !== 0 && keywordFilter.value) {
      const name = this.$i18n('core', 'client.table.filter.keywords')
      const operator = this.$i18n('core', 'client.common.' + this.commonKeywordOps[keywordFilter.matchMode])
      const value = [keywordFilter.value]
      this.searches.push({ name, operator, value, type: filterType.KEYWORDS, clearable: true, showValue: true })
    }
    if (quickFilter && Object.keys(quickFilter).length !== 0) {
      quickFilter.fields.forEach((item: any) => {
        const name = getI18nContent(item.title)
        const quickFilterOperator = item.quickFilterOperator !== 'subQuery' ? item.quickFilterOperator : item.quickFilterSubQueryFilterOperator
        const operator = this.$i18n('core', 'client.advanced-filter.operator.' + quickFilterOperator)
        let initValue = quickFilter.data[item.field]
        // 过滤quickFilter中value为空的值
        if (!checkValueIsEmpty(initValue)) {
          // 特殊处理 时间区间 datetimerange/ daterange/monthrange 的显示样式
          if (item.type && ['datetimerange', 'daterange', 'monthrange'].includes(item.type) && Array.isArray(initValue)) {
            if (item.is === 'lw-date-time') {
              initValue = initValue.map(item => formatDate(item, 'dateTime'))
            }
            initValue = initValue[0] + '~' + initValue[1]
          } else {
            // 特殊处理 datetime的显示样式
            if (item.is === 'lw-date-time') {
              initValue = formatDate(initValue, 'dateTime')
            }
          }
          // 特殊处理 boolean类型值的显示样式
          if (typeof initValue === 'boolean') {
            initValue = initValue ? this.$i18n('core', 'client.table.filter.value.true') : this.$i18n('core', 'client.table.filter.value.false')
          }
          // 特殊处理 lw-select lw-choice lw-cascader lw-recursive lw-radio
          if (['lw-select', 'lw-choice', 'lw-cascader', 'lw-recursive', 'lw-radio'].includes(item.is)) {
            const displayValue: any = { [item.field]: '' }
            eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.${item.field}.getDisplayValue`, displayValue)
            initValue = displayValue[item.field]
          }
          let value = [initValue]
          // 特殊处理 in notIn isNull isNotNull
          if ([filterGroupOperator.NOT_IN, filterGroupOperator.IN].includes(quickFilterOperator)) {
            value = initValue instanceof Array
                ? initValue
                : typeof initValue === 'string'
                  ? initValue.split(/\r+|\n+/g).filter((v: string) => v)
                  : [initValue]
          }
          let showValue = true
          if ([filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY].includes(quickFilterOperator)) {
            showValue = false
          }
          this.searches.push({ name, type: filterType.QUICK, operator, value, clearable: true, showValue, field: item.field })
        }
      })
    }
    this.$forceUpdate()
  }

  handleDelete (item: any) {
    const type = item.type
    const filters = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]
    if (type === filterType.ADVANCED) {
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.deleteAdvancedFilter`)
    }
    if (type === filterType.KEYWORDS) {
      Vue.set(filters.keywordFilter, 'value', '')
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    }
    if (type === filterType.QUICK) {
      Vue.set(filters.quickFilter.data, item.field, '')
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    }
  }

  clearAll () {
    const all = this.filters.concat(this.searches)
    const filters = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]
    const quick = all.filter(item => item.type === filterType.QUICK)
    if (quick && quick.length > 0) {
      quick.forEach((item: any) => {
        Vue.set(filters.quickFilter.data, item.field, '')
      })
    }
    if (all.some(item => item.type === filterType.KEYWORDS)) {
      Vue.set(filters.keywordFilter, 'value', '')
    }
    if (all.some(item => item.type === filterType.ADVANCED)) {
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.deleteAdvancedFilter`)
    } else {
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`)
    }
  }

  mounted () {
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.addTableSearch`, this.addTableSearch)
  }

  beforeDestroy () {
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.addTableSearch`)
  }
}
