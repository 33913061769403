
















import { Component, Mixins } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import TagContent from '@/models/TagContent'
import { attributeType, IntuitiveColorMainList, IntuitiveColorAllList, IntuitiveColorLightListWithBlackFont, IntuitiveColorLightListWithWhiteFont } from '@/utils/const'
import _ from 'lodash'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'
import { LayoutFunction } from '@/types/layout'
import Args from '@/models/Args'
import { formatDataRow } from '@/utils/data'
import FormControlComponent from '@/components/layout/FormControlComponent'

type Tag = {
  content: string,
  colorScheme: string,
  size: string,
  style: any
  onClick: LayoutFunction
}

@Component({ name: 'LwTags' })
export default class LwTags extends FormControlComponent {
  get contentArr (): Array<TagContent> | Array<string> {
    let result = this.getFinalAttributeValue('content', { args: this.argsWithRow, types: [attributeType.STRING, attributeType.ARRAY] })
    if (_.isString(result)) {
      result = result.split(',')
    }
    return result
  }

  // TODO  文档需注明 只传入一个值，不能传入逗号隔开的字符串，如想设置多种 colorScheme，请使用 TagContent 对象
  get colorScheme () { return this.getFinalAttributeValue('colorScheme', { args: this.argsWithRow }) }

  // TODO  文档需注明 只传入一个值，不能传入逗号隔开的字符串，如想设置多种 size，请使用 TagContent 对象
  get size () { return this.getFinalAttributeValue('size', { args: this.argsWithRow }) }

  get tagsArr (): Array<Tag> {
    const result: Array<Tag> = []
    // tag 的数量以 content 为主，有 content 才认为有一个 tag
    this.contentArr.forEach((c: TagContent | string, i: number) => {
      let content: any
      let colorScheme = this.colorScheme || 'primary'
      let size = this.size || 'small'
      let onClick: LayoutFunction | null
      if (_.isString(c)) {
        content = c
      } else {
        // 如果是 TagContent 对象则调用对应方法获取属性值
        content = c.getContent()
        colorScheme = c.getColorScheme() || colorScheme
        size = c.getSize() || size
        onClick = c.getOnClick()
      }
      let style = {}
      if (IntuitiveColorMainList.includes(colorScheme) || IntuitiveColorAllList.includes(colorScheme)) {
        // TODO 区分暗黑模式
        let color = 'var(--gray-11)'
        if (IntuitiveColorMainList.includes(colorScheme)) colorScheme = colorScheme + '-c'
        if (IntuitiveColorLightListWithWhiteFont.includes(colorScheme)) color = 'var(--gray-1)'
        style = {
          color: color,
          background: 'var(--' + colorScheme + ')',
          borderColor: 'var(--' + colorScheme + ')'
        }
      }

      const finalOnClick = () => {
        let args = new Args(this.context, { getCurrentContent: () => content })
        if (this.dataRow) {
          args = new Args(this.context, {
            row: formatDataRow(this.dataRow),
            getCurrentContent: () => content,
            evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow')
          })
        }
        _.isFunction(onClick)
          ? onClick(args)
          : (this.component.onContentClick && this.runRunnableContent('onContentClick', { args }))
      }

      result.push({
        content,
        colorScheme,
        size,
        style,
        onClick: finalOnClick
      })
    })
    return result
  }

  getColorScheme (scheme: string) {
    scheme = scheme.toLowerCase()
    return `theme-${scheme}`
  }
}
