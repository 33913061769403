























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { attributeType, IntuitiveColorAllList, IntuitiveColorLightListWithWhiteFont, IntuitiveColorMainList, SCREEN_SIZE_BREAK_POINTS } from '@/utils/const'
import { LayoutModule } from '@/store/modules/layout'
import Args from '@/models/Args'
import { addResizeListener, calculateHeight, isHidden, px2rem, removeResizeListener } from '@/utils/dom'
import { attributeForce2Number } from '@/utils/layout'
import { DataRow } from '@/types/data'
import _ from 'lodash'
import { handleDataSetRetrieveForQueryByFilterComponent } from '@/utils/data'
import TableSelectAllTip from '@/components/layout/inner/TableSelectAllTip.vue'
import eventbus from '@/utils/event'
import { Bind, Debounce } from 'lodash-decorators'
import FieldsSort from '../basic/advanceFilter/fieldsSort.vue'
import { LocalModule } from '@/store/modules/local'
import { doAction, doSave, queryByFilter } from '@/http/api'
import logwire from '@/logwire'
import FiltersForTable from '../inner/FiltersForTable.vue'
import QueriableByFilterComponent from '../QueriableByFilterComponent'
import { getUserSettings, saveUserSettings } from '@/utils/common'

const colorSchemeClassMap = {
  primary: 'lw-list-item__primary',
  secondary: 'lw-list-item__secondary',
  success: 'lw-list-item__success',
  warn: 'lw-list-item__warn',
  error: 'lw-list-item__error'
}

@Component({ name: 'LwList', components: { TableSelectAllTip, FieldsSort, FiltersForTable } })
export default class LwList extends QueriableByFilterComponent {
  currentColumn = 12
  isSelectAll = false
  ignoreSelectAllChange = false
  radioSelectRow: null | DataRow = null
  listHeight: string | number = 0
  loadingFlag = false
  resizeTimer: number | null = null
  dialogSortVisible = false
  settingDateCopy: any = {}
  settingDate: any = {
    id: null,
    version: 0,
    user_id: LocalModule.user.id,
    table_control: '',
    all_fields: [],
    sort_enabled: false,
    sort: [],
    sort_json: ''
  }

  $refs!: {
    list: HTMLElement
  }

  get rows () {
    return this.dataSet?.rows || []
  }

  // 与 table 组件保持同步，不支持 script 形式
  get selectionMode (): string { return this.component.selectionMode || 'multiple' }

  get height (): string {
    let result = '100%'
    if (this.component.height) {
      result = this.getFinalAttributeValue('height')
    }
    return result
  }

  get crossPageSelectAllEnabled (): boolean {
    return this.component.crossPageSelectAllEnabled
      ? this.getFinalAttributeValue('crossPageSelectAllEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  get minHeight (): number {
    let result = 150
    if (this.component.minHeight) {
      result = parseInt(this.component.minHeight.replace('px', ''))
    }
    return result
  }

  get maxHeight (): number {
    let result = 2000
    if (this.component.maxHeight) {
      result = parseInt(this.component.maxHeight.replace('px', ''))
    }
    return result
  }

  get selectAllEnabled ():boolean {
    let result = true
    if (this.component.selectAllEnabled) {
      result = this.getFinalAttributeValue('selectAllEnabled', { types: [attributeType.BOOLEAN] })
    }
    return result
  }

  get gutterVertical (): number {
    // 给个默认值 10
    let result = 10
    if (this.component.gutterVertical) {
      result = this.getFinalAttributeValue('gutterVertical', { type: attributeType.NUMBER })
    }
    return result
  }

  get gutterHorizontal (): number {
    // 给个默认值 10
    let result = 10
    if (this.component.gutterVertical) {
      result = this.getFinalAttributeValue('gutterHorizontal', { type: attributeType.NUMBER })
    }
    return result
  }

  get columns (): number[] {
    let result = [1, 1, 1, 1, 1]
    let { columns: attrColumns } = this.component
    if (attrColumns && attrColumns.indexOf(',') > -1) {
      result = attrColumns.split(',')
    } else if (attrColumns) {
      attrColumns = parseInt(attrColumns)
      for (let i = 1; i < 6; i++) {
        let col = 1
        if ((attrColumns - 4 + i) > 0) col = attrColumns - 4 + i
        result[i - 1] = col
      }
    }
    return result
  }

  get colSpan () {
    let result = 1
    if (this.component.item[0].colSpan) {
      result = attributeForce2Number('colSpan', this.component.item[0], 1)
    }
    return result
  }

  get listStyle () {
    const height = this.listHeight === 'unset' ? 'unset' : this.listHeight + 'px'
    return {
      height,
      maxHeight: this.maxHeight + 'px',
      minHeight: this.minHeight + 'px',
      overflow: 'auto'
    }
  }

  get listTotal () {
    const total = LayoutModule.data[this.encodedLayoutName]?.systemState?.[this.dataSetName]?.total
    return total
  }

  get isIndeterminate () {
    let result = false
    const selectedLength = LayoutModule.data[this.encodedLayoutName]?.systemState?.[this.dataSetName]?.selection?.length || 0
    if (selectedLength < this.rows.length && selectedLength !== 0) {
      result = true
    }
    return result
  }

  // 判断是否使用小蓝点标识
  get useListSetting () {
    return this.settingDate.sort_enabled
  }

  @Watch('dataSet.rows')
  dataSetRowsChange (newRows: DataRow[]) {
    const selection: Array<any> = []
    newRows.forEach((row: any) => {
      let isSelected = row.isSelected || false
      this.component.itemSelected && (
        isSelected = this.getFinalAttributeValue('itemSelected', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN }) as boolean
      )
      isSelected && selection.push(row)
      this.$set(row, 'isSelected', isSelected)
    })
    this.updateSelection(selection)
    this.isSelectAll = false
    this.ignoreSelectAllChange = true
  }

  @Watch('isSelectAll')
  isSelectAllChange (value: boolean, oldValue: boolean) {
    if (!value) {
      LayoutModule.setTableSelectAll({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        selectAll: false
      })
    }
    (!this.isIndeterminate || value) && this.rows.forEach(row => {
      if (!(row as any).selectable) return
      // 全选的时候没有触发 handleCheckChange 事件，所以这里手动触发一下相对应的 onItemSelectChange 事件
      row.isSelected = value
      if (!(this.ignoreSelectAllChange && oldValue && !value)) {
        // 翻页和点击搜索导致的 全选变动，不应该触发 onItemSelectChange 事件
        if (this.component.onItemSelectChange) {
          this.runRunnableContent('onItemSelectChange', { args: new Args(this.context, { row, isSelected: value }) })
        }
      }
    })
    this.updateSelection()
    this.ignoreSelectAllChange = false
  }

  // 点击排序设置之后，将queryMeta的值传入FieldSort组件中
  handleListSetting (command: string) {
    if (command === 'sort') {
      // 根据query_meta生成排序列表
      this.settingDate.table_control = this.layoutName + '.' + this.dataSetName
      const queryMetaList = LayoutModule.resource[this.layoutName].queryMeta[this.query]
      this.settingDate.all_fields = []
      queryMetaList.forEach((item: any) => {
        const settingItem = {
          name: item.name,
          title: item.title
        }
        this.settingDate.all_fields.push(settingItem)
      })
      this.settingDateCopy = _.cloneDeep(this.settingDate)
      this.dialogSortVisible = true
    }
  }

  // mounted 查询user + list对应的设置信息，找到后保存在vuex中的orderBy，带到查询条件上
  // 另外将排序设置的数据保存在settingDate
  queryListSetting (cb: any, init?: string) {
    const successCallback = (res: Record<string, any>) => {
      const rows = res.rows
      if (rows.length > 0) {
        if (init === 'init') {
          // 校验排序字段的有效性, 排序字段是否包含在queryMeta中
          const allFieldsName: any = []
          const sortFieldsName = []
          const queryMetaList = LayoutModule.resource[this.layoutName].queryMeta[this.query]
          for (const item of queryMetaList) {
            allFieldsName.push(item.name)
          }
          const sortFields = JSON.parse(rows[0].sort_json)
          for (const item of sortFields) {
            sortFieldsName.push(item.name)
          }
          if (!sortFieldsName.every((item) => allFieldsName.includes(item))) {
            // 将保存的setting全改了
            rows[0].sort_enabled = false
            rows[0].sort_json = ''
            logwire.ui.message({
              type: 'info',
              message: this.$i18n('core', 'client.table-setting.check-sort-valid')
            })
            saveUserSettings(this.layoutName, this.dataSetName, rows[0],
              () => {
                this.settingDate = Object.assign(this.settingDate, rows[0])
                cb()
              }
            )
            return
          }
        }
        this.setCurrentSetting(rows)
      }
      cb()
    }
    getUserSettings(this.layoutName, this.dataSetName, successCallback, e => { cb() })
  }

  setCurrentSetting (rows: any) {
    this.settingDate = Object.assign(this.settingDate, rows[0])
    // 解析排序列
    this.settingDate.sort = JSON.parse(rows[0].sort_json)
    // 将排序字段设置到查询条件中，enabled的时候清除orderBy
    let sort: any = []
    this.settingDate.sort.forEach((item: any) => {
      sort.push({ field: item.name, order: item.order })
    })
    if (!this.settingDate.sort_enabled) sort = []
    LayoutModule.loadDataSetOrderBy({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      orderBy: sort
    })
  }

  closeSortDialog () {
    this.dialogSortVisible = false
  }

  saveSortDialog (res: any) {
    this.queryListSetting(this.handleDataSetRetrieve)
    this.dialogSortVisible = false
  }

  itemSelectable (row: DataRow) {
    let result = true
    if (this.component.itemSelectable) {
      result = this.getFinalAttributeValue('itemSelectable', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
    }
    (row as any).selectable = result
    return result
  }

  // 与 table 抓取数据走同一个方法
  handleDataSetRetrieve (params?: { searchBarInit?: boolean, scrollToTop?: boolean }) {
    // 判断是否是searchBar中首次触发，如果是要根据retrieveOnLayoutLoad判断是否立即显示数据
    if (params && params.searchBarInit && !this.retrieveOnLayoutLoad) {
      return
    }
    if (params && params.scrollToTop) {
      this.$refs.list.scrollTo({ top: 0 })
    }
    handleDataSetRetrieveForQueryByFilterComponent.call(this, 'list')
  }

  getLayoutSetting () {
    // 根据 form 的 clientWidth 判断当前应使用的列数
    const { clientWidth } = this.$el
    const breakPoints = SCREEN_SIZE_BREAK_POINTS
    if (clientWidth < breakPoints[0]) {
      this.currentColumn = this.columns[0]
    } else if (clientWidth > breakPoints[3]) {
      this.currentColumn = this.columns[4]
    } else {
      for (let i = 0; i < breakPoints.length; i++) {
        if (breakPoints[i] <= clientWidth && clientWidth <= breakPoints[i + 1]) {
          this.currentColumn = this.columns[i + 1]
          break
        }
      }
    }
  }

  getItemStyle (index: number) {
    const colCount = Math.floor(this.currentColumn / this.colSpan)
    const percent = (this.colSpan / this.currentColumn) * 100
    const currentLineIndex = index % colCount
    const isFirst = currentLineIndex === 0
    const isLast = (currentLineIndex + 1) === colCount
    const remainItemLength = ((this.rows.length - 1) - index)
    const isLastRow = (colCount - (currentLineIndex + 1)) >= remainItemLength
    const paddingLeft = isFirst ? 0 : px2rem(this.gutterHorizontal / 2)
    const paddingRight = isLast ? 0 : px2rem(this.gutterHorizontal / 2)
    const paddingBottom = isLastRow ? 0 : px2rem(this.gutterVertical)
    return {
      flexGrow: 0,
      flexShrink: 0,
      width: percent + '%',
      paddingLeft,
      paddingRight,
      paddingBottom
    }
  }

  getItemColor (row: DataRow) {
    let result = {}
    if (this.component.itemColorScheme) {
      let colorScheme = this.getFinalAttributeValue('itemColorScheme', { args: new Args(this.context, { row }) })
      if (IntuitiveColorMainList.includes(colorScheme) || IntuitiveColorAllList.includes(colorScheme)) {
        // TODO 区分暗黑模式
        let color = 'var(--gray-11)'
        if (IntuitiveColorMainList.includes(colorScheme)) colorScheme = colorScheme + '-c'
        if (IntuitiveColorLightListWithWhiteFont.includes(colorScheme)) color = 'var(--gray-1)'
        result = {
          color: color,
          background: 'var(--' + colorScheme + ')'
        }
      }
    }
    return result
  }

  getItemClass (row: DataRow) {
    let result = ''
    if (this.component.itemColorScheme) {
      const colorScheme = this.getFinalAttributeValue('itemColorScheme', { args: new Args(this.context, { row }) })
      result = colorSchemeClassMap[colorScheme]
    }
    return result
  }

  handleDataSetNew () {
    // do nothing
  }

  handleDataSetEdit () {
    // do nothing
  }

  handleDatasetExpressEdit () {
    // do nothing
  }

  handleDatasetHeaderDetailEdit () {
    // do nothing
  }

  handleDatasetHeaderDetailNew () {
    // do nothing
  }

  headerDetailStatusNew () {
    // do nothing
  }

  handleStatusCancel () {
    // do nothing
  }

  handleDataSetDelete () {
    // do nothing
  }

  handleDataSetSave () {
    // do nothing
  }

  handleDataRowsReset () {
    // do nothing
  }

  generateUpdatedRows () {
    // do nothing
  }

  resetStatus () {
    // do nothing
  }

  handleMouseEnter (row: DataRow) {
    if (this.component.onItemMouseOver) {
      this.runRunnableContent('onItemMouseOver', { args: new Args(this.context, { row }) })
    }
  }

  handleMouseOut (row: DataRow) {
    if (this.component.onItemMouseOut) {
      this.runRunnableContent('onItemMouseOut', { args: new Args(this.context, { row }) })
    } else if (this.component.onItemMouseLeave) {
      this.runRunnableContent('onItemMouseLeave', { args: new Args(this.context, { row }) })
    }
  }

  handleClick (row: DataRow, e: Event) {
    // el-checkbox 内部实现导致其内部 span 也会触发点击事件
    const { target }: any = e
    if (target.tagName === 'SPAN' && target.classList.contains('el-checkbox__inner')) return
    if (this.component.onItemClick) {
      this.runRunnableContent('onItemClick', { args: new Args(this.context, { row }) })
    }
  }

  handleCheckChange (event: any, row: DataRow) {
    let isSelected
    let selection: Array<DataRow>
    if (this.selectionMode === 'single') {
      // radio 的 change 上的 event 参数 -- 当前选中的 label 值，这里也就是 row
      this.rows.forEach(r => {
        r.isSelected = false
      })
      event.isSelected = true
      isSelected = true
      selection = [event]
    } else {
      // checkBox 的 change 上的 event 参数 -- 当前选中状态
      isSelected = event
      selection = this.rows.filter(r => r.isSelected)
    }
    this.isSelectAll = selection.length === this.rows.length
    // this.isIndeterminate = selection.length > 0 && !this.isSelectAll
    if (this.component.onItemSelectChange) {
      this.runRunnableContent('onItemSelectChange', { args: new Args(this.context, { row, isSelected }) })
    }
    this.updateSelection(selection)
    if (!isSelected) {
      this.isSelectAll = false
    }
  }

  calculateHeight () {
    if (!isHidden(this.$el as HTMLElement)) {
      this.listHeight = calculateHeight(this.height, this.$el as HTMLElement, this.minHeight, this.maxHeight)
    }
  }

  handleDataRowSelectedChange ({ row, select }: { row: DataRow, select: boolean }) {
    if (this.dataSet.rows.indexOf(row) !== -1) {
      if (this.selectionMode === 'single') {
        this.rows.forEach(row => {
          this.$set(row, 'isSelected', false)
        })
        if (select) {
          this.$set(row, 'isSelected', true)
          this.radioSelectRow = row
        }
      }
      this.updateSelection()
    }
  }

  updateSelection (selection?: Array<DataRow>) {
    if (!selection) {
      selection = this.rows.filter(row => row.isSelected)
    }
    LayoutModule.setTableSelection({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      selection
    })
  }

  /**
   * 2023-04-10 去除了 debounce 和前后移除绑定事件的代码，和 Table 一样都直接计算
   * 发现有防抖的话，页面会有滚动条一闪而过
   */
  handleResize () {
    this.calculateHeight()
  }

  created () {
    const aggregationFields = this.getFinalAttributeValue('aggregationFields', { type: attributeType.STRING_ARRAY })
    eventbus.$on('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
    LayoutModule.initTableExtraData({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      pageSize: -1,
      queryName: this.query,
      aggregationFields
    })
  }

  mounted () {
    this.getLayoutSetting()
    if (!(this.height.endsWith('%') || this.height === 'auto')) {
      this.listHeight = this.height
    } else {
      this.calculateHeight()
      addResizeListener(this.$el, this.handleResize)
      window.addEventListener('resize', this.handleResize)
    }
    this.getLayoutSetting()
    addResizeListener(this.$el, this.getLayoutSetting)
    window.addEventListener('resize', this.getLayoutSetting)
    // 根据list排序查询数据
    this.queryListSetting(this.listInitRetrieve, 'init')
  }

  listInitRetrieve () {
    this.queriableComponentComplete[this.dataSetName] = true
    if (!Object.keys(this.searchBarComplete).includes(this.dataSetName) || this.searchBarComplete[this.dataSetName]) {
      this.initRetrieve()
    }
  }

  beforeDestroy () {
    removeResizeListener(this.$el, this.handleResize)
    removeResizeListener(this.$el, this.getLayoutSetting)
    window.removeEventListener('resize', this.handleResize)
    eventbus.$off('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
  }
}
