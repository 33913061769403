import { Component, Inject, InjectReactive, Prop, Vue } from 'vue-property-decorator'
import { AssociatedContext, LayoutComponent, LayoutContext } from '@/types/layout'
import Args from '@/models/Args'
import {
  attributeType
} from '@/utils/const'
import { getFinalAttributeValue, runRunnableContent, setLayoutForOutsideComponent } from '@/utils/layout'

@Component
class BaseComponentCommon extends Vue {
  @Prop() component!: LayoutComponent;
  @Inject() context!: LayoutContext;
  @Inject() associatedContext!: AssociatedContext;
  @InjectReactive() layoutName!: string;
  @InjectReactive() encodedLayoutName!: string;
  @InjectReactive() popupLayoutStatus!: string;
  @InjectReactive() accessibleResourcesWithEdit!: boolean;

  runRunnableContent (attributeName: string, param?: { args?: Args, noWarning?: boolean }): any {
    // 设置当前脚本运行时的 layoutName
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // 没有特殊的需求，那么 args 则默认只带有 context
    const args = param?.args || new Args(this.context)
    const noWarning = param?.noWarning || false
    return runRunnableContent(attributeName, this.component, args, this.associatedContext, noWarning)
  }

  getFinalAttributeValue (attributeName: string, param?: { args?: Args, types?: Array<attributeType> }): any;
  getFinalAttributeValue (attributeName: string, param?: { args?: Args, type?: attributeType }): any;
  getFinalAttributeValue (attributeName: string, param?: any): any {
    // 设置当前脚本运行时的 layoutName
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // 没有特殊的需求，那么 args 则默认只带有 context
    const args = param?.args || new Args(this.context)
    // types 允许多种类型的返回值
    // 多种返回值类型仅在 属性为 可执行的动态内容{function}、{eval}等 时有效
    // 若为不可执行内容，则只有一种返回值类型，取 Array<attributeType> 的第一个值，默认为 attributeType.STRING
    let types = [attributeType.STRING]
    if (param?.types) {
      types = param?.types
    } else if (param?.type) {
      types = [param?.type]
    }
    return getFinalAttributeValue(attributeName, this.component, args, this.associatedContext, types)
  }
}

export default BaseComponentCommon
