








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HelpText extends Vue {
  @Prop() tableRow: any
  @Prop() title!: string
  @Prop() help!: string
}
