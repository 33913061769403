<template>
<div class="lw-tree-wrapper" v-show="visible">
  <el-input
    ref="filter"
    v-show="showFilter"
    :placeholder="filterPlaceholder"
    v-model="filterText">
  </el-input>
  <el-tree
    class="lw-tree"
    :data="treeData"
    :show-checkbox="showCheckbox"
    node-key="id"
    ref="tree"
    :style="treeStyle"
    :expand-on-click-node="true"
    :filter-node-method="filterNode"
    :default-expanded-keys="defaultExpandedKeys"
    :render-content="renderContent"
    :render-after-expand="false"
    highlight-current
    @node-click="handleNodeClick"
    @check-change="handleCheckedChange"
    @node-expand="handleNodeExpand"
    @node-collapse="handleNodeCollapse"/>
</div>
</template>

<script>
import BaseComponent from '@/components/layout/BaseComponent'
import eventbus from '@/utils/event'
import _ from 'lodash'
import { LayoutModule } from '@/store/modules/layout'
import logwire from '@/logwire'
import { attributeType } from '@/utils/const'
import Args from '@/models/Args'
import { forEachOfTreeData, getEventName } from '@/utils/data'
import { addResizeListener, calculateHeight, isHidden, removeResizeListener } from '@/utils/dom'

export default {
  name: 'lw-tree',
  mixins: [BaseComponent],

  data () {
    return {
      filterText: '',
      defaultExpandedKeys: [],
      treeHeight: 0,
      treeData: [],
      handleResize: null
    }
  },

  computed: {
    dataTreeName () {
      return this.component.dataTree
    },
    dataSet () {
      return LayoutModule.data[this.encodedLayoutName]?.dataSet
    },
    dataTree () {
      return this.dataSet[this.dataTreeName]
    },
    showFilter () {
      let result = true
      if (this.component.showFilter) {
        result = this.getFinalAttributeValue('showFilter', { type: attributeType.BOOLEAN })
      }
      return result
    },
    filterPlaceholder () {
      let result = this.$i18n('core', 'client.common.enter-search-keywords')
      if (this.component.filterPlaceholder) {
        result = this.getFinalAttributeValue('filterPlaceholder')
      }
      return result
    },
    showCheckbox () {
      let result = 'multiple'
      if (this.component.selectionMode) {
        result = this.getFinalAttributeValue('selectionMode')
      }
      return result === 'multiple'
    },
    height () {
      let result = '100%'
      if (this.component.height) {
        result = this.getFinalAttributeValue('height')
      }
      return result
    },
    minHeight () {
      let result = 150
      if (this.component.minHeight) {
        result = parseInt(this.component.minHeight.replace('px', ''))
      }
      return result
    },
    maxHeight () {
      let result = 2000
      if (this.component.maxHeight) {
        result = parseInt(this.component.maxHeight.replace('px', ''))
      }
      return result
    },
    treeStyle () {
      const height = this.treeHeight === 'unset' ? 'unset' : this.treeHeight + 'px'
      return {
        height,
        overflowY: 'auto'
      }
    }
  },

  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    'dataTree.nodes': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTreeData()
      }
    }
  },

  methods: {
    filterNode (value, data) {
      if (!value || !this.showFilter) return true
      let result = data.label.indexOf(value) !== -1
      if (this.component.filterLogic) {
        const args = new Args(this.context, {
          getCurrentNode: () => {
            return data
          },
          getFilterText: () => {
            return value
          }
        })
        result = this.getFinalAttributeValue('filterLogic', { args, type: attributeType.BOOLEAN })
      }
      return result
    },
    renderContent (h, { node, data, store }) {
      let itemContent
      if (this.component.template) {
        const template = this.getFinalAttributeValue('template', { args: new Args(this.context, { getCurrentNode: () => data }) })
        itemContent = _.template(template)({ node, data, logwire })
      } else {
        itemContent = `<span>${node.label}</span>`
      }

      return (
        <span class="custom-tree-node" style="display: flex; flex: 1;" domPropsInnerHTML={itemContent}></span>
      )
    },
    handleRetrieve () {
      this.component.onRetrieve && this.runRunnableContent('onRetrieve')
    },
    handleDataSetRetrieve () {
      if (this.component.query) {
        // 平台默认支持的query
      } else if (this.component.onRetrieve) {
        this.runRunnableContent('onRetrieve')
      }
    },
    handleNodeClick (data, { checked }) {
      this.doCustomMethod('onNodeClick', data)
    },
    // node - 节点本身
    // checked - 节点当前勾选状态
    // indeterminate - 节点的子树中是否有被选中的节点
    handleCheckedChange (node, checked, indeterminate) {
      const getSelectedStatus = () => checked
      this.doCustomMethod('onNodeSelectChange', node, { getSelectedStatus })
    },
    handleNodeExpand (data, { checked }) {
      this.doCustomMethod('onNodeExpand', data)
    },
    handleNodeCollapse (data, { checked }) {
      this.doCustomMethod('onNodeCollapse', data)
    },
    doCustomMethod (method, node, params) {
      if (this.component[method]) {
        const getCurrentNode = () => node
        const args = new Args(this.context, Object.assign({ getCurrentNode }, params))
        this.runRunnableContent(method, { args })
      }
    },
    // computed 中会随着 dataset 的变动不断重新赋值
    getTreeData () {
      const nodes = (this.dataTree && this.dataTree.nodes) || []
      const defaultExpandedKeys = []
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      forEachOfTreeData(nodes, 'children', (node) => {
        const getCurrentNode = () => {
          return node
        }

        const args = new Args(_this.context, { getCurrentNode })

        // 调用 nodeSelectable 判断节点是否被禁用勾选框
        if (_this.component.nodeSelectable) {
          node.disabled = _this.component.nodeSelectable
            ? !_this.runRunnableContent('nodeSelectable', { args })
            : false
        }

        // 调用 expandOnRetireve 判断节点是否展开
        if (_this.component.expandOnRetrieve) {
          const isExpanded = _this.getFinalAttributeValue('expandOnRetrieve', { args })
          isExpanded && defaultExpandedKeys.push(node.id)
        }
      })
      this.defaultExpandedKeys = defaultExpandedKeys
      this.treeData = nodes
    },
    getSelectedNodes (payload) {
      payload.nodes = this.$refs.tree.getCheckedNodes()
    },
    setCheckedNodes (keys) {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(keys)
      })
    },
    calculateHeight () {
      this.treeHeight = calculateHeight(this.height, this.$el, this.minHeight, this.maxHeight)
    }
  },

  created () {
    LayoutModule.initTreeData({
      layoutName: this.encodedLayoutName,
      dataTreeName: this.dataTreeName
    })
    this.handleResize = _.debounce(() => {
      this.calculateHeight()
    }, 60)
    eventbus.$on(getEventName(this.encodedLayoutName, this.dataTreeName, 'retrieve'), this.handleRetrieve)
    eventbus.$on(getEventName(this.encodedLayoutName, this.dataTreeName, 'setDataTreeCheckedNodes'), this.setCheckedNodes)
    eventbus.$on(getEventName(this.encodedLayoutName, this.dataTreeName, 'getDataTreeSelectedNodes'), this.getSelectedNodes)
  },

  mounted () {
    this.getTreeData()
    if (!(this.height.endsWith('%') || this.height === 'auto')) {
      this.treeHeight = this.height
    } else {
      this.calculateHeight()
      addResizeListener(this.$el, this.handleResize)
      window.addEventListener('resize', this.handleResize)
    }
  },

  beforeDestroy () {
    eventbus.$off(getEventName(this.encodedLayoutName, this.dataTreeName, 'retrieve'), this.handleRetrieve)
    eventbus.$off(getEventName(this.encodedLayoutName, this.dataTreeName, 'setDataTreeCheckedNodes'), this.setCheckedNodes)
    eventbus.$off(getEventName(this.encodedLayoutName, this.dataTreeName, 'getDataTreeSelectedNodes'), this.getSelectedNodes)
    removeResizeListener(this.$el, this.handleResize)
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="less" scoped>
  .lw-tree--wrapper {
    overflow-y: auto;
  }
</style>
