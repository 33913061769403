


























import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { Bind, Debounce } from 'lodash-decorators'
import { SCREEN_SIZE_BREAK_POINTS } from '@/utils/const'

type dividerInfoType = {
  startX: number,
  startWidth: number,
  wrapperWidth: number,
  opposite?: boolean,
  num?: number
}

type renderGroupType = {
  left: Array<Record<string, any>>,
  middle: Array<Record<string, any>>,
  right: Array<Record<string, any>>,
}

// TODO 是否要做拖动改变宽度
@Component({ name: 'LwRegion2' })
export default class LwRegion2 extends BaseComponent {
  colInRow = 2
  resizeTargetRef = ''
  dividerInfo: dividerInfoType | null = null
  renderGroup: renderGroupType = {
    left: [],
    middle: [],
    right: []
  }

  get ratios (): Array<number> {
    const { name } = this.$options
    const { ratio } = this.component
    // 这里目前处理的情况有 lw-region-2 和 lw-region-3 两种
    // 默认1：1 或 1：1：1 没写全部分都默认为 1
    const expectedLength = (name === 'LwRegion2' ? 2 : 3)
    return ratio
      ? ratio.split(':').length < expectedLength
        ? ratio.split(':').concat(new Array(expectedLength - ratio.split(':').length).fill(1))
        : ratio.split(':')
      : new Array(expectedLength).fill(1)
  }

  @Bind()
  @Debounce(500)
  handleWindowResize (): void {
    const { innerWidth } = window
    if (innerWidth < SCREEN_SIZE_BREAK_POINTS[0]) {
      this.colInRow = 1
    } else {
      if (this.colInRow !== 2) {
        this.colInRow = 2
      }
    }
  }

  handlerResizeStart (elRef: string, e: MouseEvent & { target: HTMLElement}, opposite: boolean, num: number): void {
    const { target } = e
    // 添加类名用于显示分割线，避免鼠标移动太快导致分割线消失
    target.classList.add('active')
    const { wrapper } = this.$refs
    this.dividerInfo = {
      startX: e.pageX,
      startWidth: (this.$refs[elRef] as HTMLElement).clientWidth,
      wrapperWidth: (wrapper as HTMLElement).clientWidth,
      opposite,
      num
    }
    this.resizeTargetRef = elRef
    document.addEventListener('mousemove', this.mouseMoveHandler)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  mouseMoveHandler (e: MouseEvent): void {
    if (!this.dividerInfo) return
    const { startX, startWidth, wrapperWidth, opposite, num } = this.dividerInfo
    const { pageX: endX } = e
    const offset = endX - startX
    const className = this.resizeTargetRef === 'left' ? 'lw-region-right' : 'lw-region-left'
    // 元素最大宽度为元素宽度 - 分割线数量 * 20(保证其余的部分也能有 20 的最小宽度)，最小 20
    const resizedCol = this.$el.querySelector(`.resized.${className}`)
    const resizeTarget = this.$refs[this.resizeTargetRef] as HTMLElement
    let calcWrapperWidth
    if (resizedCol && num) {
      calcWrapperWidth = wrapperWidth - resizedCol.clientWidth - (num - 1) * 62
    }
    const width = Math.min(calcWrapperWidth || wrapperWidth - (num ? num * 62 : 62), Math.max((opposite ? (-offset) : offset) + startWidth, 50))
    // 被改变宽度的元素不允许伸展也不压缩
    resizeTarget.classList.add('resized')
    resizeTarget.style.flexGrow = '0'
    resizeTarget.style.flexShrink = '0'
    resizeTarget.style.flexBasis = `${(width / wrapperWidth * 100)}%`
  }

  handleMouseUp (): void {
    document.removeEventListener('mousemove', this.mouseMoveHandler)
    const activeDivider = this.$el.querySelector('.divider.active')
    activeDivider && activeDivider.classList.remove('active')
    // 清掉分割线的信息，避免不同分割线前后拖动时取到错误的信息
    this.dividerInfo = null
  }

  created (): void {
    const { left, middle, right } = this.component
    this.renderGroup = { left, middle, right }
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}
