// 对当前时间做了调整
// 原本 el-data-picker 使用 new Date() 方式获取当前时间
// 改为根据首选项时区获取当前时间 logwire.util.getDate()
// 改动在 ./basic/dateTable 以及 ./panel/date 中

import DatePicker from './picker/DataPicker.vue'

(DatePicker as any).install = function (vue: any) {
  vue.component(DatePicker.name, DatePicker)
}

export default DatePicker