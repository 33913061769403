import { doSave, getLayout } from '@/http/api'
import logwire from '@/logwire'
import Args from '@/models/Args'
import { LayoutModule } from '@/store/modules/layout'
import { attributeType, EDIT_LAYOUT_DATASET_NAME, EDIT_LAYOUT_POPUP_NAME_SUFFIX, layoutStatus, operationType, rowPersist } from '@/utils/const'
import { forEachOfTreeData, formatDataRow, getDataListForSave } from '@/utils/data'
import eventbus from '@/utils/event'
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator'
import FormControlComponent from '../FieldBoundComponent'
import PopupLayoutOfSelect from '@/views/PopupLayoutOfSelect.vue'
import _ from 'lodash'
import { LayoutComponent, PopupLayoutConfig } from '@/types/layout'
import { ElSelect } from 'element-ui/types/select'
import { warnAttributeMissing } from '@/utils/layout'

// 和 Select.vue 文件里的 Option 定义相同
interface Option {
  label: string; // 显示到el-select的输入框的文本
  value: string;
  content: string // 显示到下拉弹窗内的文本
  raw: Record<string, any> // 后端返回的源数据对象
}

type LwSelectInstance = {
  multiple: boolean
  inputValue: string | string[]
  ignoreInputValueChange: boolean
  parentTable ?: {
    cacheFieldOption ({ field, option }: { field: string, option: Record<string, any>}): void
  }

  $refs: {
    select?: ElSelect & {
      handleClose: () => void
      setSelected: () => void
    },
    selectOperation: { // PopupLayoutOfSelect 组件
      show: (config: any) => void
    }
  }

  handleRemoteSearch (queryString: string, callback?: (options?: any) => void): void
  handleChangeByElement (value: string | string[]): void
}

type LwAutoCompleteInstance = {
  querySearch (queryString: string, cb: any, isTurnPage?: boolean): void
}

@Component({ components: { PopupLayoutOfSelect } })
export default class selectOperation extends FormControlComponent {
  selectStatus = ''

  get isSelect () {
    return this.$options.name === 'LwSelect'
  }

  get newable () {
    let result = false
    if (this.component.newable && this.component.dropDownQuery && this.component.editLayout && (!this.isSelect || (this.isSelect && this.component.labelFieldInDropDown))) {
      result = this.getFinalAttributeValue('newable', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get editable () {
    let result = false
    if (this.component.editable && this.component.dropDownQuery && this.component.editLayout && (!this.isSelect || (this.isSelect && this.component.labelFieldInDropDown))) {
      result = this.getFinalAttributeValue('editable', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get cloneable () {
    let result = false
    if (this.component.cloneable && this.component.dropDownQuery && this.component.editLayout && (!this.isSelect || (this.isSelect && this.component.labelFieldInDropDown))) {
      result = this.getFinalAttributeValue('cloneable', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get editDataSetName () { return this.component.editDataSet || EDIT_LAYOUT_DATASET_NAME }

  get uncloneableFields (): Array<string> {
    let result = []
    if (this.component.uncloneableFields) {
      result = this.getFinalAttributeValue('uncloneableFields', { type: attributeType.STRING }) || ''
      result = result.split(',').map((f: string) => f.trim())
    }
    return result
  }

  handleSelectNew () {
    this.selectStatus = layoutStatus.NEW
    this.openSelectEditLayout(layoutStatus.NEW, {})
  }

  handleSelectEdit (option: any) {
    this.selectStatus = layoutStatus.EDIT
    const dataRow = formatDataRow(option)
    this.openSelectEditLayout(layoutStatus.EDIT, dataRow)
  }

  handleSelectClone (option: any) {
    const copyOption = _.cloneDeep(option)
    this.selectStatus = layoutStatus.NEW
    delete copyOption.id
    delete copyOption.version
    this.isSelect && delete copyOption[(this as any).valueFieldInDropDown]
    this.uncloneableFields.forEach(f => {
      delete copyOption[f]
    })
    const dataRow = formatDataRow(copyOption)
    this.openSelectEditLayout(layoutStatus.NEW, dataRow)
  }

  replaceLwForm (layouts: Array<LayoutComponent>) {
    forEachOfTreeData(layouts, 'components', (component: LayoutComponent) => {
      if (component.is === 'lw-form') {
        component.is = 'lw-input-form'
      }
    })
  }

  openSelectEditLayout (mode: layoutStatus, row: any) {
    const { editLayout } = this.component
    const dataRow = formatDataRow(row)
    const drawerParams: Partial<PopupLayoutConfig> = {
      layoutName: editLayout.includes('.')
        ? editLayout
        : `${this.context.getNamespace()}.${editLayout}`,
      editLayout: editLayout.includes('.')
        ? editLayout
        : `${this.context.getNamespace()}.${editLayout}`,
      editDataSet: this.editDataSetName,
      dataRow: dataRow,
      layoutStatus: mode
    }
    // 为所要编辑的 DataSet 进行赋值
    if (editLayout) {
      if (this.isSelect) {
        const selectVm = this as unknown as LwSelectInstance
        selectVm.$refs.select && selectVm.$refs.select.handleClose()
        selectVm.$refs.selectOperation && selectVm.$refs.selectOperation.show(drawerParams)
      } else {
        const { autocomplete } = this.$refs as any
        autocomplete && autocomplete.close()
        const { selectOperation } = this.$refs as any
        selectOperation && selectOperation.show(drawerParams)
      }
    } else {
      warnAttributeMissing('editLayout', this.component.is)
    }
  }

  handleSelectSave ({ rows, closeEditLayout }: { rows: any, closeEditLayout: () => void }) {
    rows[0].rowPersist = this.selectStatus === layoutStatus.NEW ? rowPersist.INSERT : rowPersist.UPDATE
    const dataListForSave = {
      dataList: [
        {
          dataSetName: this.component.dropDownQuery,
          queryName: this.component.dropDownQuery,
          recordList: rows
        }
      ]
    }
    doSave({ layoutName: this.editLayoutName || this.layoutName, namespace: this.context.getNamespace(), queryName: this.component.dropDownQuery, data: dataListForSave })
      .then(res => {
        const { data: { data } } = res
        const { valueFieldInDropDown, component: { dropDownQuery, labelFieldInDropDown, keywordFieldsInDropDown } } = this as any
        const option = data?.[dropDownQuery]?.records?.[0]
        const value = option?.[valueFieldInDropDown]
        const label = this.isSelect ? option?.[keywordFieldsInDropDown] || '' as string : value
        // 保存成功
        if (this.isSelect) {
          const selectVm = this as unknown as LwSelectInstance
          selectVm.handleRemoteSearch(label, (res) => {
            if (res.rows.length) {
            // 保存成功后，此条数据在当前的查询范围之内，则直接对字段赋值、
              this.$nextTick(() => {
                selectVm.ignoreInputValueChange = true
                if (selectVm.multiple) {
                  selectVm.inputValue = selectVm.inputValue instanceof Array ? [...selectVm.inputValue, value] : [value]
                  selectVm.handleChangeByElement(selectVm.inputValue)
                } else {
                  selectVm.inputValue = value
                  selectVm.handleChangeByElement(selectVm.inputValue)
                }
                // 在表格的快速编辑时，缓存好选中的选项，方便后续做改动使用

                this.$nextTick(() => selectVm.$refs.select?.setSelected())
                if (selectVm.parentTable) {
                  selectVm.parentTable.cacheFieldOption({ field: this.field, option })
                }
              })
            } else {
              // 否则，做出提示
              const { warning } = logwire.ui.message
              warning && warning(logwire.ui.getI18nMessage('client.select.not-match-filter', [], 'core'))
            }
            closeEditLayout()
          })
        } else {
          const autoVm = this as unknown as LwAutoCompleteInstance
          autoVm.querySearch(label, (res: any) => {
            if (res.length) {
              this.inputValue = value
            } else {
              // 否则，做出提示
              const { warning } = logwire.ui.message
              warning && warning(logwire.ui.getI18nMessage('client.select.not-match-filter', [], 'core'))
            }
            closeEditLayout()
          })
        }
      })
  }
}
