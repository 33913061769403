





















import { Component, Mixins, Prop } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeType, SemanticColorSchemes } from '@/utils/const'
import clickoutside from 'element-ui/src/utils/clickoutside'
import _ from 'lodash'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'

@Component({ name: 'LwButtonGroup', directives: { clickoutside } })
class LwButtonGroup extends Mixins(BaseComponent, BaseComponentWithRow) {
  inDropdown = false
  $refs!: {
    buttonGroupItemWrapper: any
    btnGroupPop: any
  }

  get placement (): string {
    if (this.inDropdown) return 'right-start'
    return 'bottom-end'
  }

  get size (): string { return this.getFinalAttributeValue('size') || 'small' }

  get colorScheme (): SemanticColorSchemes {
    let result = SemanticColorSchemes.SECONDARY
    if (this.component.colorScheme) {
      let colorAttr = this.getFinalAttributeValue('colorScheme', { args: this.argsWithRow })
      if (_.values(SemanticColorSchemes).includes(colorAttr) || colorAttr === 'text') {
        if (colorAttr === SemanticColorSchemes.WARN) colorAttr = 'warning'
        if (colorAttr === SemanticColorSchemes.ERROR) colorAttr = 'danger'
        result = colorAttr
      }
    }
    return result
  }

  get groupClickable (): boolean { return this.getFinalAttributeValue('groupClickable', { args: this.argsWithRow, type: attributeType.BOOLEAN }) || false }

  groupClick () :void {
    if (!this.groupClickable) return
    const button = this.$refs.buttonGroupItemWrapper.childNodes[0]?.querySelector('button')
    // eslint-disable-next-line no-unused-expressions
    button?.click()
  }

  handleClickOutside () :void {
    this.$refs.btnGroupPop.doClose()
  }

  mounted () {
    if (this.$parent?.$options.name === 'ElDropdownItem') this.inDropdown = true
  }
}
export default LwButtonGroup
