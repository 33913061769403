import { Component, Inject, InjectReactive, Prop } from 'vue-property-decorator'
import { attributeRunType, attributeSymbol, attributeType } from '@/utils/const'
import {
  isSymbol,
  warnAttributeMissing
} from '@/utils/layout'
import componentAttributes from '@/utils/componentAttributes'
import _ from 'lodash'
import Args from '@/models/Args'
import { DataRow as IDataRow } from '@/types/data'
import DataRow from '@/models/DataRow'
import logwire from '@/logwire'
import BaseComponentCommon from './mixin/BaseComponentCommon'

@Component
class BaseComponent extends BaseComponentCommon {
  @Prop() currentRowOfParent!: IDataRow;
  @Inject() popupLayout!: boolean;
  @Inject() panelLayout!: boolean;
  @InjectReactive() accessibleResources!: Array<string>;
  @InjectReactive() anonymousAccessible !: boolean
  @InjectReactive() editLayoutName!: string | null

  get title (): string {
    const getCurrentRow = () => { return this.currentRowInParent ? new DataRow(this.currentRowInParent) : undefined }
    return this.getFinalAttributeValue('title', { args: (this as any).argsWithRow || new Args(this.context, { getCurrentRow }) })
  }

  // TODO 文档生命不允许 resourceCode 设置动态值
  get resourceCode (): string { return this.component.resourceCode }

  get visible (): boolean {
    return this.component.visible
      ? this.getFinalAttributeValue('visible', { args: (this as any).argsWithRow || new Args(this.context), type: attributeType.BOOLEAN })
      : true
  }

  get currentRowInParent (): IDataRow | undefined {
    let result = this.currentRowOfParent
    let parent = this.$parent
    while (!result && parent) {
      result = (parent as any).currentRowOfParent
      parent = parent.$parent
    }
    return result
  }

  /**
   * 检查属性是否正确设置
   * @param {string} attributeName 属性名
   * @param {string} attributeValue 属性值
   * */
  checkAttributeSettled (attributeName: string, attributeValue: any): boolean {
    _.isUndefined(attributeValue) && warnAttributeMissing(attributeName, this.component.is)
    return _.isUndefined(attributeValue)
  }

  created () {
    // 对属性进行格式校验
    if (!this.component) return
    const { is } = this.component
    const component = componentAttributes[is]
    if (!component) return
    for (const attr in component) {
      if (this.component[attr]) {
        if (component[attr].type === attributeRunType.ONLY_SCRIPT) {
          if (!(isSymbol(this.component[attr], attributeSymbol.COMMON) ||
            isSymbol(this.component[attr], attributeSymbol.EVAL) ||
            isSymbol(this.component[attr], attributeSymbol.FUNCTION) ||
            isSymbol(this.component[attr], attributeSymbol.COMMONWITHNULLARGS))
          ) {
            const { message: { error }, getI18nMessage } = logwire.ui
            const message = getI18nMessage('client.tips.attribute-value-not-runnable', [is, attr], 'core')
            error && error(message)
            break
          }
        } else if (component[attr].type === attributeRunType.ONLY_STATIC) {
          if (isSymbol(this.component[attr], attributeSymbol.COMMON) ||
            isSymbol(this.component[attr], attributeSymbol.EVAL) ||
            isSymbol(this.component[attr], attributeSymbol.FUNCTION) ||
            isSymbol(this.component[attr], attributeSymbol.COMMONWITHNULLARGS)
          ) {
            const { message: { error }, getI18nMessage } = logwire.ui
            const message = getI18nMessage('client.tips.attribute-value-not-static', [is, attr], 'core')
            error && error(message)
            break
          }
        }
      }
    }
  }
}

export default BaseComponent
