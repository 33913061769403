import Vue from 'vue'
import Vuex from 'vuex'
import { AppState } from '@/store/modules/app'
import { LocalState } from '@/store/modules/local'
import { LayoutState } from '@/store/modules/layout'

Vue.use(Vuex)

interface RootState {
  app: AppState;
  layout: LayoutState;
  local: LocalState;
}

export default new Vuex.Store<RootState>({})
