
























import BaseComponent from '@/components/layout/BaseComponent'
import { Component, ProvideReactive } from 'vue-property-decorator'

@Component({ name: 'QuickEditForTable' })
export default class QuickEditForTable extends BaseComponent {
  parentComponent: any = null
  parentRow: any = null
  editField = ''
  checked = false
  updateRowsLength = 0
  @ProvideReactive() evaluatingBase = 'tableRow'

  async handleOperate (type: string) {
    if (type === 'confirm') {
      // 表单校验
      await (this.$refs.form as any).validateData?.()
    }
    this.$emit('handle-operate', { batch: this.checked, type, val: this.parentRow.currentData[this.editField], row: this.parentRow.currentData })
    this.checked = false
  }
}
