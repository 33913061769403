























































































































































































import { Component, Inject, InjectReactive, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import BoardMenu from './BoardMenu.vue'
import { LocalModule } from '@/store/modules/local'
import logwire from '@/logwire'
import { toLoginLayout, proceedAfterEditingDataSetCheck, getI18nContent, setLayoutForOutsideComponent, getLayoutNameOutsideComponent, downloadFile } from '@/utils/layout'
import { doAction, getVersion, getGatewayVersion, doOpenApi } from '@/http/api'
import { getArchiveStorageAppend, getQuery } from '@/utils/common'
import { Notification, Notifications } from '@/types/common'
import DataRow from '@/models/DataRow'
import { formatDataRow, getCsrfToken, formatDate } from '@/utils/data'
import { notificationType, environmentType, SCRIPT_SPECIAL_ENVIRONMENT, SCRIPT_ENVIRONMENT, CHECKED_ARCHIVE_STORAGE } from '@/utils/const'
import QRCode from 'qrcodejs2'
import NofiticationMonitor from './fixed-panel/NofiticationMonitor.vue'
import { getFeedbackInstance, getFeedbackConfig, getFeedbackInstanceReady } from '@/utils/feedback'
/**
 * 左边：标题通过前端初始化时从后端获取一份客户端配置，配置中的一个选项值是系统标题
 * 中间：显示收藏的菜单，/api/menu中的favorite_menus
 * 右边：v1通过/api/notification/summary获取消息信息，通过/api/user获取数据源和用户信息
 *      归档数据暂时不做  ADMIN 那里菜单是写死的 消息提示没有后台请求，后面准备通过 websocket 通知给前端
 */

@Component({
  components: {
    BoardMenu,
    NofiticationMonitor
  }
})
export default class Header extends Vue {
  isFeedbackVisible = false
  feedbackTodoCount=0
  feedbackNeedAcceptCount=0
  menuFavor : Array<Record<string, any>> = []
  menuFavorMore : Array<Record<string, any>> = []
  dataSources : Array<string> = ['当前数据库', '归档数据库1', '归档数据库2']
  activeNotificationType = 'messages'
  defaultAvatar = false
  aboutDetailVisible = false
  qrcodeVisible = false
  aboutDetail: Array<Record<string, any>> = []
  qrcode: typeof QRCode | null = null
  // api/open/book/core.get-asset?path=book/asset/favicon.ico
  qrOption = {
    text: `${location.protocol}//${location.host}/api/open/core/core.get-asset?isDownload=false&path=core/asset/app-guide/app.html&serverName=${logwire.store.getConfig('applicationName')}`,
    width: 256,
    height: 256,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: QRCode.CorrectLevel.H
  }

  $refs!: {
    boardMenu: any
    menuFavorShow: any,
    notificationMonitor: NofiticationMonitor
  }

  get environmentType (): string | undefined {
    return (LocalModule.config as any).environmentType
  }

  get isProdEnv (): boolean {
    return this.environmentType === environmentType.PROD
  }

  get notifications (): Notifications {
    return LocalModule.notifications
  }

  get notificationsInfo (): Record<string, any> {
    // eslint-disable-next-line camelcase
    const { todo_count = 0, message_count = 0 } = this.notifications
    // eslint-disable-next-line camelcase
    const total = todo_count + message_count
    // eslint-disable-next-line camelcase
    if (message_count === 0) {
      this.activeNotificationType = 'todo'
    } else {
      this.activeNotificationType = 'messages'
    }
    return {
      totalCount: total > 0 ? total : 0,
      messageCount: message_count,
      todoCount: todo_count
    }
  }

  get displayNotifications () {
    return this.notifications[`latest_${this.activeNotificationType}`] || []
  }

  get avatar () : string {
    if (LocalModule.user.avatar) {
      const code = LocalModule.user.avatar.split('code=')
      this.defaultAvatar = false
      return `/api/open/core/core.document-preview?code=${code[1]}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
    } else {
      this.defaultAvatar = true
      return ''
    }
  }

  get username () : string {
    return LocalModule.user.nickname || LocalModule.user.full_name
  }

  get firstName () : string {
    return this.username?.charAt(0).toUpperCase()
  }

  get favoriteMenus (): Array<Record<string, any>> {
    return LocalModule.favoriteMenus
  }

  get archiveStorages (): Array<Record<string, any>> {
    return LocalModule.archiveStorages
  }

  get archiveStorageTitle () {
    const archiveStorage = this.archiveStorages.find(item => item.key === this.activeDataSource)
    return archiveStorage?.title
  }

  switchDataSource (key: string) {
    key ? sessionStorage.setItem(CHECKED_ARCHIVE_STORAGE, key) : sessionStorage.removeItem(CHECKED_ARCHIVE_STORAGE)
    window.location.reload()
  }

  get activeDataSource () {
    return sessionStorage.getItem(CHECKED_ARCHIVE_STORAGE)
  }

  get userOptions (): Array<Record<string, any>> {
    let result: Array<Record<string, any>> = []
    if (LocalModule.i18n.core) {
      result = [
        {
          label: this.$i18n('core', 'client.header.user-settings'),
          link: 'core.user_settings'
        }
      ]
    }
    return result
  }

  get headerTheme (): any { return logwire.store.getConfig('headerTheme') }

  get applicationName (): any { return logwire.store.getConfig('applicationName') }

  get logoUrl (): any { return logwire.store.getConfig('logoUrl') }

  getFavorMoreByClick (): void {
    this.closeBoardMenu()
    this.getFavorMore()
  }

  @Watch('favoriteMenus', { immediate: true, deep: true })
  getFavorMore (): void {
    this.menuFavor = this.favoriteMenus
    this.$nextTick(() => {
      const { menuFavorShow } = this.$refs
      const showWidth : number = menuFavorShow.clientWidth
      const menuFavorMoreButtonWidth = 30 + 32 // 宽度30, 左右间距 32
      const nodes = menuFavorShow.childNodes
      let wrapWidth = 0
      for (let i = 0; i < nodes.length; i++) {
        wrapWidth += nodes[i].clientWidth
        if (wrapWidth > showWidth) {
          // 有可能最后一个按钮过长，导致显示更多按钮后，位置不够显示
          while (i > -1 && (wrapWidth - nodes[i].clientWidth + menuFavorMoreButtonWidth > showWidth)) {
            wrapWidth -= nodes[i].clientWidth
            i--
          }
          // 这里添加menuFavor代替favoriteMenus作为显示，是因为clientWidth是四舍五入的值，在计算时可能产生误差，导致显示菜单和下拉菜单有重复内容
          this.menuFavor = this.favoriteMenus.slice(0, i)
          this.menuFavorMore = this.favoriteMenus.slice(i)
          return
        }
        this.menuFavorMore = []
      }
    })
  }

  createQRCode () {
    this.qrcodeVisible = true
    if (!this.qrcode) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(document.querySelector('#qrcode'), this.qrOption)
      })
    }
  }

  downloadQrcode () {
    const base64Str = this.qrcode._el.getElementsByTagName('img')[0].src
    downloadFile(base64Str, 'APP')
  }

  logoLoadError (e: any) {
    e.target.src = require('../assets/logo.png')
  }

  formatDate (date: string | Date, type: string) {
    return formatDate(date, type)
  }

  getNotifications (visibleStatus: boolean) {
    this.closeBoardMenu()
    visibleStatus && LocalModule.loadNotifications()
  }

  // 在菜单打开的时候，点击头部部分按钮直接关闭菜单再触发
  closeBoardMenu () {
    this.$refs.boardMenu.close()
  }

  handleNotification (notification: Notification) {
    logwire.ui.openLayoutInDialog({
      layoutName: 'core.notification_detail',
      width: '70%',
      params: {
        notification: new DataRow(formatDataRow(notification))
      }
    })
  }

  toggleBoardMenu () {
    this.$refs.boardMenu.toggle()
  }

  showNotification (type: string) {
    this.activeNotificationType = type
  }

  getNotificationContent (item: Notification, content: string): string {
    if (!window[SCRIPT_SPECIAL_ENVIRONMENT] && !window[SCRIPT_ENVIRONMENT]) {
      const { layoutName } = this.$route.params
      setLayoutForOutsideComponent({ layoutName, encodedLayoutName: layoutName })
    }
    return getI18nContent(item[content], item.namespace)
  }

  getNotificationTime (time: Date): string {
    return this.formatDate(time, 'date')
  }

  viewAllNotification (): void {
    logwire.ui.pushSwitch({ layoutName: 'core.notification' })
  }

  goLayout (layoutName: string) :void {
    logwire.ui.pushSwitch({ layoutName })
  }

  logout (): void {
    logwire.ui.pushSwitch({ layoutName: 'core.logout' } as any)
  }

  goFavorLayout (menu: Record<string, any>): void {
    if (menu.link) {
      const linkType = menu.linkType || 'layout'
      if (linkType === 'layout') {
        const [layoutName, queryString] = menu.link.split('?')
        const params = getQuery(queryString)
        logwire.ui.pushSwitch({ layoutName: layoutName, params } as any)
      } else {
        window.location.href = menu.link
      }
    }
  }

  async showAbout () {
    Promise.all([getVersion(), getGatewayVersion()])
      .then((res: any) => {
        const [{ data: { data: backendVersionData } }, { data: { data: gatwayVersion } }] = res
        this.aboutDetail = { ...backendVersionData, ...gatwayVersion }
        this.aboutDetailVisible = true
      })
  }

  handleShowFeedbacks () {
    getFeedbackInstanceReady().then(feedback => {
      feedback.showListPage()
    })
  }

  handleShowTasks () {
    this.closeBoardMenu()
    this.$refs.notificationMonitor.showTasks = true
  }

  created () {
    getFeedbackConfig().then(res => {
      this.isFeedbackVisible = !!res.data.feedbackEnabled
      if (this.isFeedbackVisible) {
        getFeedbackInstance().then(feedback => {
          feedback.on('todoUpdated', evt => {
            this.feedbackTodoCount = evt.unreadMsgCount
            this.feedbackNeedAcceptCount = evt.needUserAcceptanceMsgCount
          })
        })
      }
    })
  }

  mounted () {
    window.addEventListener('resize', this.getFavorMore)
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', this.getFavorMore)
  }
}
