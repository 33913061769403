import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import logwire from '@/logwire'
import './utils/clamp.min'
import './style/tableCell.less'
import './style/dark/index.less'
import './style/dark/table.less'
import './style/light/index.less'
import './style/light/table.less'
import './style/common.less'
import './style/light/common.less'
import './style/dark/common.less'
import './assets/icon/iconfont.css'
import locale from 'element-ui/lib/locale'
import { Loading, Dialog, Drawer, Button, ButtonGroup, Form, FormItem, Input, RadioGroup, Radio, Popover, Option, Tree, TimePicker, Checkbox, Switch, Dropdown, DropdownMenu, DropdownItem, Cascader, Tag, Breadcrumb, BreadcrumbItem, Card, TimeSelect, Tooltip, Badge, Timeline, TimelineItem } from 'element-ui'
import Upload from '@/3rd-party-extension/element/packages/upload'
import Select from '@/3rd-party-extension/element/packages/select'
import ElAutocomplete from '@/3rd-party-extension/element/packages/autocomplete'
import Layout from '@/views/Layout.vue'
import PopupLayout from '@/views/PopupLayout.vue'
import LwButton from '@/components/layout/basic/Button.vue'
import LwButtonGroup from '@/components/layout/basic/ButtonGroup.vue'
import LwCascader from '@/components/layout/form-item/Cascader.vue'
import LwForm from '@/components/layout/containers/Form.vue'
import LwText from '@/components/layout/form-item/text/index.vue'
// import LwText from '@/components/layout/form-item/text/Text.vue'
// import LwTextarea from '@/components/layout/form-item/textarea/Textarea.vue'
import LwTextarea from '@/components/layout/form-item/textarea/index.vue'
// import LwTimestamp from '@/components/layout/form-item/timestamp/Timestamp.vue'
import LwTimestamp from '@/components/layout/form-item/timestamp/index.vue'
// import LwChoice from '@/components/layout/form-item/choice/Choice.vue'
import LwChoice from '@/components/layout/form-item/choice/index.vue'
import LwSelect from '@/components/layout/form-item/select/Select.vue'
// import LwSelect from '@/components/layout/form-item/select/index.vue'
import LwSwitch from '@/components/layout/form-item/Switch.vue'
// import LwDate from '@/components/layout/form-item/date/Date.vue'
import LwDate from '@/components/layout/form-item/date/index.vue'
// import LwDateTime from '@/components/layout/form-item/dateTime/DateTime.vue'
import LwDateTime from '@/components/layout/form-item/dateTime/index.vue'
// import LwTime from '@/components/layout/form-item/time/Time.vue'
import LwTime from '@/components/layout/form-item/time/index.vue'
import LwCheckbox from '@/components/layout/form-item/Checkbox.vue'
import LwPagination from '@/components/layout/basic/Pagination.vue'
import LwRadio from '@/components/layout/form-item/Radio.vue'
import LwRegion from '@/components/layout/containers/Region.vue'
import LwRecursive from '@/components/layout/form-item/Recursive.vue'
import LwSearchBar from '@/components/layout/basic/SearchBar.vue'
import LwTree from '@/components/layout/basic/Tree.vue'
import LwWrapper from '@/components/layout/containers/Wrapper.vue'
import LwTable from '@/components/layout/basic/Table.vue'
import LwHtml from '@/components/layout/basic/Html.vue'
// import LwNumber from '@/components/layout/form-item/number/Number.vue'
import LwNumber from '@/components/layout/form-item/number/index.vue'
import LwRegion2 from '@/components/layout/containers/Region2.vue'
import LwRegion3 from '@/components/layout/containers/Region3.vue'
import LwToolbar from '@/components/layout/basic/Toolbar.vue'
import LwInputForm from '@/components/layout/containers/InputForm.vue'
import LwDynamic from '@/components/layout/basic/Dynamic.vue'
import LwTabs from '@/components/layout/containers/Tabs.vue'
import LwTags from '@/components/layout/basic/Tags.vue'
import LwBreadcrumb from '@/components/layout/basic/Breadcrumb.vue'
import TableCell from '@/components/layout/inner/TableCell.vue'
import LwUploadFile from '@/components/layout/form-item/uploadFile/UploadFile.vue'
import Panel from '@/components/layout/inner/Panel.vue'
import LwLabel from '@/components/layout/basic/Label.vue'
import LwCard from '@/components/layout/containers/Card.vue'
import Board from '@/components/layout/containers/Board.vue'
import LwSection from '@/components/layout/containers/Section.vue'
import Image from '@/components/layout/basic/Image.vue'
import List from '@/components/layout/basic/List.vue'
// import LwAutoComplete from '@/components/layout/form-item/autoComplete/AutoComplete.vue'
import LwAutoComplete from '@/components/layout/form-item/autoComplete/index.vue'
import DatePicker from '@/3rd-party-extension/element/packages/data-picker/index'
import LwEcharts from '@/components/layout/basic/Echarts.vue'
import LwTimeLine from '@/components/layout/basic/Timeline.vue'

import '@/3rd-party-extension/Table/index'
import Pagination from '@/3rd-party-extension/element/packages/pagination'

import 'vxe-table/lib/style.css'

import { getGlobalI18nMessage } from '@/utils/layout'

import { dragFnWithMessageAndDialog } from '@/utils/dom'
import { setLocale } from '@/utils/common'
import '@/utils/dialogScale.js'
// import { setClientError } from './utils/feedback'

[
  Dialog,
  Drawer,
  Button,
  ButtonGroup,
  Form,
  FormItem,
  Input,
  Option,
  Checkbox,
  Popover,
  RadioGroup,
  Radio,
  Tree,
  DatePicker,
  TimePicker,
  TimeSelect,
  Switch,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Cascader,
  Tag,
  LwTree,
  Upload,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  ElAutocomplete,
  Tooltip,
  Badge,
  Timeline,
  TimelineItem
].forEach(c => Vue.component(c.name, c));

// typescript 写的组件
[
  Layout,
  PopupLayout,
  LwEcharts,
  LwButton,
  LwButtonGroup,
  LwCascader,
  LwForm,
  LwPagination,
  LwRegion,
  LwRecursive,
  LwSearchBar,
  LwWrapper,
  LwRadio,
  LwSearchBar,
  LwText,
  LwTextarea,
  LwTimestamp,
  LwChoice,
  LwSelect,
  LwSwitch,
  LwDate,
  LwDateTime,
  LwTime,
  LwCheckbox,
  LwHtml,
  LwNumber,
  LwRegion2,
  LwRegion3,
  LwToolbar,
  LwInputForm,
  LwTabs,
  LwTags,
  LwDynamic,
  TableCell,
  LwUploadFile,
  LwBreadcrumb,
  Panel,
  LwLabel,
  LwCard,
  Board,
  Image,
  List,
  LwSection,
  LwAutoComplete,
  LwTimeLine,
  LwTable
].forEach(c => Vue.component((c as any).options.name, c))

Vue.config.productionTip = false
Vue.config.errorHandler = (error, vm, info) => {
  // TODO 处理异常
  // 当遇到代码执行出现错误导致脚本无法向下执行时可以进行提示或者别的操作
  console.log(error)
  // setClientError(error)
}
Vue.use(Loading.directive)
Vue.use(Pagination)
Vue.use(Select)

declare const window: Window & { logwire: Record<string, unknown> }
window.logwire = logwire

// vue 原型上挂载 获取全局 i18n 词条的方法
Vue.prototype.$i18n = getGlobalI18nMessage
setLocale()

dragFnWithMessageAndDialog()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
