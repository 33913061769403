
























import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import { reject } from 'lodash'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'CropImage' })
class CropImage extends Vue {
  @Prop() image!: File

  crop: null | Cropper = null
  imgSrc = ''
  cropVisible = false
  resove: any
  reject: any
  wrapStyle = {
    height: '400px'
  }

  initCanvas (): void {
    // FileReader 对 IE 的支持只到 10+
    const reader = new FileReader()
    const _this = this as any
    reader.onload = function () {
      _this.imgSrc = this.result as string
    }
    reader.readAsDataURL(this.image)
  }

  initCropper () {
    // 初始化一个 裁剪对象
    this.crop = new Cropper(this.$refs.cropSource as HTMLImageElement, {
      // 定义裁剪的视图模式 0, 1, 2, 3 默认 0
      // 0 - 不做限制,裁剪框可以超出画布
      // 1 - 限制裁剪框不超过画布的大小
      // 2 - 限制最小画布大小以适合容器。如果画布和容器的比例不同，则最小画布将被维度之一的额外空间包围
      // 3 - 限制最小画布尺寸以填充适合容器。如果画布和容器的比例不同，容器将无法在其中一个维度中容纳整个画布
      viewMode: 1,
      // // 拖动模式 'copy', 'move', 'none' 默认 'copy  '
      // // copy - create a new crop box
      // // move - move the canvas
      // // none - do nothing
      dragMode: 'move'
    })
  }

  // 对外提供 初始化函数， 会在裁剪确定后 返回裁剪结果
  init () {
    return new Promise((resolve, reject) => {
      this.resove = resolve
      this.reject = reject
      this.cropVisible = true
      this.imgSrc = ''
      this.$nextTick(function () {
        this.initCanvas()
      })
    })
  }

  close () {
    this.cropVisible = false
    this.$nextTick(function () {
      this.crop && this.crop.destroy()
      this.reject('canel')
    })
  }

  zoomIn () {
    this.crop && this.crop.zoom(0.1)
  }

  zoomOut () {
    this.crop && this.crop.zoom(-0.1)
  }

  handleCrop () {
    if (this.crop) {
      // 裁剪图片返回 base64 格式
      const result = this.crop.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/jpeg')
      this.resove(result)
      this.close()
    }
  }
}
export default CropImage
