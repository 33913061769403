





































import { Component } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attribute2Number, attributeForce2Number } from '@/utils/layout'

@Component({ name: 'LwTextarea' })
class LwTextarea extends FormControlComponent {
  isFocus = false
  hovering = false

  // 不支持脚本
  get maxLength (): number { return attributeForce2Number('maxLength', this.component, Number.MAX_SAFE_INTEGER) }

  // 不支持脚本
  get rows (): number {
    let result = 2
    if (this.component.lines) {
      result = attributeForce2Number('lines', this.component, 2)
    } else if (this.component.rows) {
      // TODO 兼容老版本，之后版本废除
      result = attributeForce2Number('rows', this.component, 2)
    }
    return result
  }

  get showClear () {
    const nativeInputValue = this.inputValue === null || this.inputValue === undefined ? '' : String(this.inputValue)
    return (this.isFocus || this?.hovering) && nativeInputValue
  }

  clear () {
    this.inputValue = ''
  }

  handleMouseenter () {
    this.hovering = true
    this.$nextTick(this.calcIconOffset)
  }

  handleInput () {
    this.isFocus = true
    this.calcIconOffset()
    this.component.onInput && this.runRunnableContent('onInput', { args: this.args })
  }

  handleTextareaBlur (e: any) {
    this.isFocus = false
    this.handleBlur(e)
  }

  calcIconOffset () {
    const textareaEl = this.$el.querySelector('textarea')
    if (!textareaEl) return
    const el: any = this.$el.querySelector('.el-input__suffix')
    if (!el) return
    if (textareaEl.scrollHeight > textareaEl.clientHeight) {
      el.style.transform = 'translateX(-10px)'
    } else {
      el.removeAttribute('style')
    }
  }
}

export default LwTextarea
