import { Component, Vue } from 'vue-property-decorator'
import store from '@/logwire/store'
import { formatDate as formatDateFn } from 'element-ui/src/utils/date-util'

@Component
class TimestampBase extends Vue {
  [propName: string]: any;

  get displayValue (): string {
    // 转换为用户首选项中设置的日期格式 + 毫秒
    if (!this.inputValue) return this.inputValue
    return this.formatTimestamp(this.inputValue)
  }

  formatTimestamp (date: string): string {
    const formatDate = store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
    const formatTime = store.getConfig('core.i18n.time-format') === '24-hour'
      ? 'HH:mm:ss'
      : 'hh:mm:ss A'
    let format = 'yyyy-MM-dd HH:mm:ss'
    format = formatDate + ' ' + formatTime
    const dateCopy = (date as string).slice(0, -4)
    const millisecond = (date as string).slice(-3)
    return formatDateFn(dateCopy, format) + ' ' + millisecond
  }
}

export default TimestampBase
