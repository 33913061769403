





















































import { Component } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attribute2Number, attribute2Strings, attributeForce2Boolean, attributeForce2Number, checkTemplateSafety, warnAttributeMissing } from '@/utils/layout'
import { attributeType, filterOperator } from '@/utils/const'
import Args from '@/models/Args'
import { countByFilter, queryByFilter } from '@/http/api'
import _, { bind } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { formatDataRow, formatMasterFilter } from '@/utils/data'
import logwire from '@/logwire'
import selectOperation from '../../mixin/selectOperation'

@Component({ name: 'LwAutoComplete' })
class LwAutoComplete extends selectOperation {
  pageNo = 1
  total = 0
  debounce = 500
  queryString = ''
  setOptionsCallback: any = null

  // pageSize 不支持动态
  get pageSize (): number {
    return attributeForce2Number('dropDownPageSizes', this.component, 20)
  }

  get pageCount (): number { return Math.ceil(this.total / this.pageSize) }

  get valueFieldInDropDown (): string { return this.component.valueFieldInDropDown || 'value' }

  get prevButtonDisabled (): boolean { return this.pageNo === 1 }

  get nextButtonDisabled (): boolean { return this.pageNo === this.pageCount }

  get dropDownHeaderTemplate (): string {
    checkTemplateSafety('dropDownHeaderTemplate', this.component)
    const { dropDownHeaderTemplate } = this.component
    const result = dropDownHeaderTemplate
      ? _.template(dropDownHeaderTemplate)({ args: this.args, logwire })
      : ''
    return result
  }

  get rules (): Record<string, any> | void {
    return this.required ? {
      required: this.required,
      message: this.$i18n('core', 'client.form.is-required', this.title),
      trigger: 'change'
    } : undefined
  }

  @Bind()
  @Debounce(500)
  querySearch (queryString: string, cb: any, isTurnPage = false) {
    queryString = queryString || ''
    this.queryString = queryString
    // 如果是由输入框输入引起的搜索，应该重置页码数为 1
    if (!isTurnPage) this.pageNo = 1
    if (!this.setOptionsCallback) this.setOptionsCallback = cb
    const { onDropDownRetrieve, dropDownQuery, keywordFieldsInDropDown } = this.component
    if (!onDropDownRetrieve && !dropDownQuery) {
      warnAttributeMissing('dropDownQuery', this.component.is)
      return
    } else if (dropDownQuery && !keywordFieldsInDropDown) {
      // 写了 dropDownQuery 还需要 keywordFieldsInDropDown 才能正常使用
      warnAttributeMissing('keywordFieldsInDropDown', this.component.is)
      return
    }
    let masterFilter = this.getFinalAttributeValue('dropDownMasterFilter', { args: this.args, types: [attributeType.OBJECT, attributeType.OBJECT_ARRAY] })
    masterFilter = formatMasterFilter(masterFilter)
    const data: any = {
      getTotalBy: 'count',
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      keywordFilter: {
        value: queryString,
        matchMode: filterOperator.LIKE,
        fields: this.getFinalAttributeValue('keywordFieldsInDropDown', { type: attributeType.STRING_ARRAY })
      }
    }
    if (masterFilter) {
      data.masterFilter = masterFilter
    }

    const queryParams = this.getFinalAttributeValue('dropDownQueryParams', { args: this.args, type: attributeType.OBJECT })
    queryParams && (data.queryParams = queryParams)

    // TODO setDropDownData 变更为 applyDropDownData，为保证兼容性，持续兼容几个版本后移除
    const setDropDownData = (data: Record<string, any>) => {
      applyDropDownData(data)
    }
    // applyDropDownData 为变更后内容
    const applyDropDownData = (data: Record<string, any>) => {
      if (queryString === this.queryString) {
        cb(data.rows)
        this.total = data.total
      }
    }
    const getQueryParams = () => {
      return {
        queryString,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
    }
    const failCallback = () => {
      this.total = 0
      const result:Array<any> = []
      cb(result)
    }
    const layoutName = this.editLayoutName || this.layoutName
    if (onDropDownRetrieve) {
      this.runRunnableContent('onDropDownRetrieve', { args: new Args(this.context, { row: this.form.dataRow, getQueryParams, setDropDownData, applyDropDownData, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') }) })
    } else {
      Promise.all([
        queryByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: data }, { silent: true }),
        countByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: data }, { loadingLevel: 'none' })
      ]).then(([resQuery, resCount]) => {
        const data = {
          ...resQuery.data.data,
          ...resCount.data.data
        }
        applyDropDownData(data)
      }).catch(_ => {
        failCallback()
      })
    }
  }

  switchPage (command: string): void {
    switch (command) {
      case 'first':
        if (this.prevButtonDisabled) return
        this.pageNo = 1
        break
      case 'prev':
        if (this.prevButtonDisabled) return
        --this.pageNo
        break
      case 'next':
        if (this.nextButtonDisabled) return
        ++this.pageNo
        break
      case 'last':
        if (this.nextButtonDisabled) return
        this.pageNo = this.pageCount
        break
    }
    this.querySearch(this.queryString, this.setOptionsCallback, true)
  }

  handleFocus (e: any): void {
    _.isFunction(this.setOptionsCallback) && this.setOptionsCallback([])
    this.component.onFocus && this.runRunnableContent('onFocus')
  }

  handleChange (value: any) {
    if (this.form.asQuickEditForm) {
      return
    }
    const getSelectedOption = () => value
    const args = new Args(this.context, { row: formatDataRow(this.form.dataRow), getSelectedOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    this.component.onChange && this.runRunnableContent('onChange', { args })
  }

  getRowTemplate ({ item }: any): string {
    const { dropDownRowTemplate } = this.component
    const getCurrentOption = () => item
    const args = new Args(this.context, { row: this.form.dataRow, getCurrentOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    const result = dropDownRowTemplate
      ? _.template(this.getFinalAttributeValue('dropDownRowTemplate'))({ args, logwire })
      : item[this.valueFieldInDropDown]
    return result
  }
}

export default LwAutoComplete
