





import { Component, Mixins } from 'vue-property-decorator'
import BaseComponent from '../BaseComponent'
import { checkTemplateSafety, warnXSSAttackAwareness } from '@/utils/layout'
import Args from '@/models/Args'
import _ from 'lodash'
import logwire from '@/logwire'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'

@Component({ name: 'LwHtml' })
export default class LwHtml extends Mixins(BaseComponent, BaseComponentWithRow) {
  get templateHtml (): string {
    const { template } = this.component
    checkTemplateSafety('template', this.component)
    // 每次获取 templateHtml 都去执行一下 afterLoad
    this.component.afterRender && this.$nextTick(() => this.runRunnableContent('afterRender', { args: this.argsWithRow }))
    const result = template
      ? _.template(template)({ args: this.argsWithRow, logwire })
      : ''
    return result
  }
}
