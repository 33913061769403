












import { Component, Inject, InjectReactive, Prop, Provide, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import { doAction, getLayout } from '@/http/api'
import {
  AssociatedContext,
  LayoutComponent,
  LayoutContext,
  LayoutFunction,
  LayoutMeta,
  LayoutResource,
  PopupLayoutConfig
} from '@/types/layout'
import { LayoutModule } from '@/store/modules/layout'
import { getUuid, tryEval, tryRunFunction } from '@/utils/common'
import DataSet from '@/models/DataSet'
import DataTree from '@/models/DataTree'
import { DataSet as IDataSet } from '@/types/data'
import Aside from '@/components/Aside.vue'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'
import DataPrinter from '@/components/DataPrinter.vue'
import eventbus from '@/utils/event'
import logwire from '@/logwire'
import { ActionParams } from '@/logwire/network'
import {
addLayoutQueue,
  getFinalAttributeValue,
  getLayoutNameOutsideComponent,
  isSymbol,
  removeLayoutQueue,
  runRunnableContent,
  setLayoutForOutsideComponent,
  warnActionMissing
} from '@/utils/layout'
import { attributeSymbol, attributeType, layoutStatus, SCRIPT_ORIGINAL_ENVIRONMENT, SCRIPT_SPECIAL_ENVIRONMENT } from '@/utils/const'
import _ from 'lodash'
import { AppModule } from '@/store/modules/app'
import { LocalModule } from '@/store/modules/local'
import Args from '@/models/Args'
import { setDocumentTitle } from '@/utils/dom'
import { handleRequestError } from '@/http'
import { formatDataRow, getTraceIds } from '@/utils/data'
import LayoutClass from '@/models/Layout'
import FixedPanel from '@/components/fixed-panel/FixedPanel.vue'
import { timerIdMap } from '@/logwire/ui'

declare const window: Window & { common?: Record<string, LayoutFunction> }

@Component({ name: 'Layout', components: { Aside, Header, Content, DataPrinter, FixedPanel } })
class Layout extends Vue {
  @Prop() layout!: PopupLayoutConfig

  loading = true
  forceShowFrame = false
  layoutNameSuffix = getUuid().split('-')[0]
  isLayoutFinished = false
  $refs!: {
    content: any
  }

  @Inject() popupLayout!: boolean

  @InjectReactive() editLayoutName!: string | null

  @ProvideReactive() get layoutName (): string {
    return this.layout?.layoutName || this.$route.params.layoutName as string
  }

  @ProvideReactive() get encodedLayoutName (): string {
    const suffix = this.layoutNameSuffix
    return this.layout?.createNewLayoutDataWithSuffix
      ? `${this.layoutName}_uuid-${suffix}`
      : this.layoutName
  }

  @Provide() context: LayoutContext = {
    getOrCreateDataSet: (dataSetName: string) => {
      const data = LayoutModule.data[this.encodedLayoutName].dataSet[dataSetName]
      if (!data) {
        LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: Object.assign({} as IDataSet, { dataSetName }) })
      }
      return new DataSet(LayoutModule.data[this.encodedLayoutName].dataSet[dataSetName], this.encodedLayoutName)
    },
    getDataSet: (dataSetName: string) => {
      const data = LayoutModule.data[this.encodedLayoutName].dataSet[dataSetName]
      if (data) {
        return new DataSet(LayoutModule.data[this.encodedLayoutName].dataSet[dataSetName], this.encodedLayoutName)
      }
      console.warn(`Dataset named ${dataSetName} does not exist`)
    },
    setDataSet: (dataSetName: string, dataSet: DataSet | IDataSet) => {
      if (dataSet instanceof DataSet) {
        const originalDatasetName = (dataSet as DataSet).getDataSetName()
        const originalDataset = LayoutModule.data[this.encodedLayoutName].dataSet[originalDatasetName]
        const newDataset = _.cloneDeep(originalDataset)
        newDataset.dataSetName = dataSetName
        LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: newDataset })
      } else {
        LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: Object.assign(dataSet as IDataSet, { dataSetName }) })
      }
    },
    getState: (stateName: string) => {
      return LayoutModule.data[this.encodedLayoutName].state[stateName]
    },
    setState: (stateName, state) => {
      LayoutModule.setCustomState({ layoutName: this.encodedLayoutName, stateName, state })
    },
    getLayoutParam: (paramName: string) => {
      return LayoutModule.data[this.encodedLayoutName]?.params?.[paramName]
    },
    getLayoutStatus: () => {
      return LayoutModule.data[this.encodedLayoutName]?.status as layoutStatus
    },
    getNamespace: () => {
      const strings = this.layoutName.split('.')
      return strings.length > 1 ? strings[0] : 'core'
    },
    getOrCreateDataTree: (dataTreeName: string) => {
      // TODO: DataTree 的概念仅仅是为了角色授权的功能创建的，其他地方以及项目开发者根本不会用到的，是不是应该剔除？
      const data = LayoutModule.data[this.encodedLayoutName]?.dataSet[dataTreeName] as any
      if (!data) {
        LayoutModule.initTreeData({
          layoutName: this.encodedLayoutName,
          dataTreeName
        })
        return new DataTree(LayoutModule.data[this.encodedLayoutName].dataSet?.[dataTreeName] as any, this.encodedLayoutName)
      } else if (data.dataTreeName) {
        return new DataTree(LayoutModule.data[this.encodedLayoutName].dataSet[dataTreeName] as any, this.encodedLayoutName)
      }
      console.warn(`Found an existing DataSet by name ${dataTreeName}. Please give another name for the DataTree.`)
    }
  }

  @Provide() associatedContext: AssociatedContext = {
    variables: {},
    functions: {},
    i18n: {},
    actions: []
  }

  // 页面权限，所要关心的是 namespaace.layoutName:__edit (编辑、新增和保存权限)
  @ProvideReactive() get accessibleResources (): Array<string> {
    return this.layoutMeta?.accessibleResources || []
  }

  @ProvideReactive() get anonymousAccessible (): boolean {
    return this.layoutMeta?.anonymousAccessible || false
  }

  // 页面是否需要受权限管控
  get accessControlled (): boolean {
    return this.layoutMeta?.accessControlled || false
  }

  @ProvideReactive() get accessibleResourcesWithEdit () {
    // 管理员权限不受控
    if (logwire.store.getUser().admin || !this.accessControlled) return true
    let result = false
    for (const i of this.accessibleResources) {
      const resources = i.split(':')[1]
      if (resources === '__edit') {
        result = true
      }
    }
    return result
  }

  get showContent (): boolean {
    return !!this.layoutMeta && !this.loading
  }

  get layoutTitle (): string {
    let layoutTitle = ''
    if (this.layoutMeta && this.isLayoutFinished) {
      setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
      layoutTitle = getFinalAttributeValue('title', this.layoutMeta, new Args(this.context), this.associatedContext, attributeType.STRING)
    }
    return layoutTitle
  }

  @Watch('layoutTitle')
  changeLayoutTitle (layoutTitle: string) {
    if (getLayoutNameOutsideComponent() !== this.layoutName) return
    if (this.popupLayout) {
      this.$emit('get-layout-finished', layoutTitle)
    } else {
      this.setDocumentTitle(layoutTitle)
    }
  }

  // layout 切换时修改标签页 title
  setDocumentTitle (layoutTitle: string) {
    const title = layoutTitle || logwire.store.getConfig('applicationName')
    if (title) setDocumentTitle(title)
  }

  get layoutMeta (): LayoutMeta {
    return this.layoutResource?.metaData
  }

  get layoutComponents (): Array<LayoutComponent> {
    // 为了速度考虑，在进行平台默认编辑时，发起一次查询，把 layout 拿到，这样打开 popuplayout 时可以把元素先渲染出来, 然后进 layout.vue 后再查询一次做
    // 但是会不会导致 meta 等数据还没存入，下属组件就使用了？
    // 还是说，根据 layoutComponents 判断，往外发事件，告知 PopupLayout 渲染？
    return this.layoutMeta?.components || this.layout?.layouts
  }

  get layoutResource (): LayoutResource {
    // const encodedLayoutName = getDecodedName(this.layoutName)
    const onOpen = this.layout?.onOpen
    return _.isFunction(onOpen)
      ? LayoutModule.resource[this.encodedLayoutName]
      : LayoutModule.resource[this.layoutName]
  }

  get showFrame (): boolean {
    // 如果在 popup layout 内部，则永远不会出现 header
    if (this.popupLayout) {
      return false
    } else {
      if (this.forceShowFrame) return true
      // 没有 layoutComponent 时返回 false，避免从有框架变成无框架的闪烁
      if (!this.layoutComponents) return false
      const showFramework = this.layoutResource.metaData?.showFramework
      return _.isUndefined(showFramework)
        ? true
        : getFinalAttributeValue('showFramework', this.layoutResource.metaData, new Args(this.context), this.associatedContext, attributeType.BOOLEAN)
    }
  }

  @Watch('layoutName', { immediate: true, deep: true })
  handleLayoutSwitch (nextLayout: string, prevLayout: string): void {
    // 重置 forceShowFrame 状态
    this.forceShowFrame = false
    // if (nextLayout === prevLayout) return
    this.unbindAllPrevLayoutEvents(prevLayout)
    this.removeAllTimers(prevLayout)
    // 清除上个页面记录的 WebSocket 监听绑定
    LayoutModule.clearUiWebSocketListener({ layoutName: prevLayout })

    if (this.layout) {
      addLayoutQueue(nextLayout)
    } else {
      removeLayoutQueue()
      addLayoutQueue(nextLayout)
    }

    this.loading = true
    const params = this.layout ? { ...this.layout.params } : { ...this.$route.query, ...this.$route.params }
    if (this.layoutResource) {
      // nextTick 处理保证能 destroy 整个页面
      this.$nextTick(() => {
        this.loading = false
        // 当发现 store 中已经存在目标 layout 的 root 节点时，不再初始化节点，而是使用原节点
        // 菜单跳转时，始终创建新节点，因为在跳转前会清除掉目标 layout 的 root 内容，详情见 logwire.ui.pushSwitch
        this.handleLayout(this.layoutResource)
        if (!LayoutModule.data[this.encodedLayoutName]) {
          LayoutModule.loadLayoutDataRoot(this.encodedLayoutName)
        }
        LayoutModule.loadLayoutParams({ layoutName: this.encodedLayoutName, params })
        this.handleAssociatedContext(this.layoutResource)
        this.handlePopupLayoutParams()
        this.handleAfterLayoutLoad()
      })
    } else {
      getLayout({ layout: nextLayout })
        .then(res => {
          // 组件刚被创建时还没有 this，所以需要 $nextTick
          this.$nextTick(async () => {
            const { data } = res.data
            // 如果有 panels 节点，在 components 中添加多个 lw-panel
            const panels = data.metaData?.panels
            const components = data.metaData?.components
            if (panels && components) {
              components.push(...panels.map((p: { is: string }) => {
                p.is = 'lw-panel'
                return p
              }))
            }
            this.handleLayout(data)
            const resource = {
              ...data,
              layoutName: this.encodedLayoutName
            }
            LayoutModule.loadLayoutDataRoot(this.encodedLayoutName)
            LayoutModule.loadLayoutResource(resource)
            LayoutModule.loadLayoutParams({ layoutName: this.encodedLayoutName, params })
            this.handleAssociatedContext(resource)
            this.handlePopupLayoutParams()
            const { metaData: { anonymousAccessible } } = data
            await this.getNecessaryProfiles(anonymousAccessible)
          })
        })
        .catch(error => {
          setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
          if (error.response.status === 403) {
            // 当没有页面权限的时候，要保证框架结构显示出来，不然用户除了关闭当前 tab 标签页或者后退以外没有办法继续操作
            eventbus.$on(`${this.encodedLayoutName}.leave`, this.handleLayoutLeave)
            if (_.isEmpty(LocalModule.user)) {
              LocalModule.loadNecessaryProfiles(() => {
                this.forceShowFrame = true
              })
            } else {
              this.forceShowFrame = true
            }
            this.popupLayout && logwire.ui.closeCurrentLayout()
          } else if (error.response.status === 422 || error.response.status === 500) {
            // 如果报错，不再跳转到 / ,因为报错时跳转后，就无法再查看报错信息了
            // window.location.href = '/'
          }
        })
    }
    eventbus.$off(`${this.encodedLayoutName}.close-layout`)
    eventbus.$on(`${this.encodedLayoutName}.close-layout`, (params: Record<string, any>) => {
      if (this.popupLayout) {
        eventbus.$emit('close-popup-layout', { encodedLayoutName: this.encodedLayoutName, params })
      } else {
        this.$router.back()
      }
      // 离开当前页面时，移除当前页面的 layout 数据
      LayoutModule.removeLayoutDataRoot(this.encodedLayoutName)
    })
  }

  @Watch('encodedLayoutName', { immediate: true })
  onEncodedLayoutNameChange (val: string) {
    this.$emit('change-encodedLayoutName', val)
  }

  // 页面关闭时候的逻辑处理，用于 弹出框或者抽屉中
  closeLayout ({ proceed, getOnCloseParams, setOnCloseParams }: { proceed: () => void, getOnCloseParams: () => any, setOnCloseParams: (params: any) => void }) {
    if (this.layoutMeta?.beforeClose) {
      runRunnableContent('beforeClose', this.layoutMeta, new Args(this.context, { proceed, getOnCloseParams, setOnCloseParams }), this.associatedContext, false)
    } else {
      proceed()
    }
  }

  // 如果在弹窗内没有传入 layouts, 并且具有 dataRow 属性，认为是通过组件自身的编辑功能，打开了一个弹窗, 此时创建一个 editingRow 的 dataset 结构
  handlePopupLayoutParams () {
    if (this.editLayoutName && this.layout?.dataRow) {
      LayoutModule.loadLayoutDataSet({
        layoutName: this.layout.layoutName,
        data: {
          dataSetName: this.layout.editDataSet,
          rows: [this.layout.dataRow]
        }
      })
    }
    if (this.layout?.layoutStatus) {
      LayoutModule.updateLayoutStatus({
        layoutName: this.layout.layoutName,
        status: this.layout.layoutStatus
      })
    }
  }

  // 处理打开的 dialog/drawer 页面的 layout xml结构，添加js方法
  handleLayout (resource: any) {
    const onOpen = this.layout?.onOpen
    if (_.isFunction(onOpen)) {
      // 缓存修改前的页面
      const originalResource = _.cloneDeep(resource)
      this.layout.handleCache = () => {
        // LayoutModule.resource[this.layoutName] = null
        const date = {
          ...originalResource,
          layoutName: this.layoutName
        }
        LayoutModule.loadLayoutResource(date)
      }
      // 运行 onOpen 方法
      window[SCRIPT_SPECIAL_ENVIRONMENT] = this.layout.sourceLayoutName
      const sourceLayoutContext = (this.$parent as any).context
      tryRunFunction(onOpen,
        new Args(sourceLayoutContext,
          {
            getLayout: () => { return new LayoutClass(resource.metaData) },
            appendLayoutFunctions: (scriptObj: any) => {
              _.forIn(scriptObj, function (value, key) {
                const prepend = key.startsWith('layout.functions') ? '' : 'var '
                resource.javascript += '\n' + prepend + key + '=' + value.toString() + ';'
              })
            }
          }))
    }
  }

  /**
   * 在 layout 切换时手动解绑上一个 layout 相关的所有事件
   * 需要这么做的原因是，在组件的 beforeDestroy 调用时，页面的 layoutName 已经是切换后的页面了
   * 这样一来，beforeDestroy 里面写的解绑函数无法起到作用
   * */
  unbindAllPrevLayoutEvents (prevLayout: string) {
    const events = (eventbus as any)._events
    for (const eventName in events) {
      if (eventName.startsWith(`${prevLayout}.`)) {
        eventbus.$off(eventName)
      }
    }
  }

  removeAllTimers (layoutName: string) {
    const timers = timerIdMap.get(layoutName) || []
    for (const id of timers) {
      clearTimeout(id)
    }
  }

  async getNecessaryProfiles (anonymousAccessible: boolean): Promise<void> {
    const callback = () => {
      this.loading = false
      this.handleAfterLayoutLoad()
    }
    !anonymousAccessible && _.isEmpty(LocalModule.user)
      ? await LocalModule.loadNecessaryProfiles(callback)
      : callback()
  }

  handleAssociatedContext (resource: LayoutResource): void {
    eventbus.$on(`${this.encodedLayoutName}.do-action`, this.handleAction)
    eventbus.$on(`${this.encodedLayoutName}.leave`, this.handleLayoutLeave)
    // 获取到下一个 layout 相关内容后清空当前 layout 相关内容
    this.clearAssociatedContext()
    const { javascript, metaData } = resource
    this.associatedContext.actions = metaData.actions
    tryEval(`"use strict"; var layout = param.layout; ${javascript}\r\n; layout.evalFunc = function (string, param) { return eval(string) }; layout;`, { layout: this.associatedContext })
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    this.$nextTick(() => { this.isLayoutFinished = true })
  }

  handleAfterLayoutLoad (): void {
    this.layoutMeta?.afterLoad && this.$nextTick(() => {
      setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
      runRunnableContent('afterLoad', this.layoutMeta, new Args(this.context), this.associatedContext, false)
    })
  }

  clearAssociatedContext (): void {
    // TODO 删掉 variables
    this.associatedContext.variables = {}
    this.associatedContext.functions = {}
    // TODO 删掉 actions
    this.associatedContext.actions = []
  }

  /**
   * 处理页面离开前逻辑
   * 当开发者写了，beforeUnload 时，就必须手动调用 args.proceed() 才能保证页面正常离开
   * */
  handleLayoutLeave ({ callback }: { callback: () => void }): void {
    this.layoutMeta?.beforeUnload
      ? runRunnableContent('beforeUnload', this.layoutMeta, new Args(this.context, { proceed: callback }), this.associatedContext, false)
      : callback()
  }

  handleAction (actionParam: ActionParams) {
    const layoutNameWhenActionTrigger = getLayoutNameOutsideComponent()
    const { code, name, successCallback, failCallback, data, silent, options } = actionParam
    let layoutName = actionParam.layoutName
    if (!layoutName) {
      layoutName = this.editLayoutName || this.layoutName
    }
    let actionName = ''
    let actionSubName: string | undefined
    if (code) {
      // 如果定义了 code，则使用 code 去查找 action
      const action = this.associatedContext.actions?.find(action => action.code === code)
      if (action) {
        actionName = action.name.split(':')[0]
        actionSubName = action.name.split(':')[1] || action.subName
      } else {
        if (name) {
          // 如果没有找到 action 但是定义了 name
          actionName = name.split(':')[0]
          actionSubName = name.split(':')[1]
        } else {
          // 找不到 action 抛出警告
          warnActionMissing(layoutName, code)
          return
        }
      }
    } else if (name) {
      actionName = name.split(':')[0]
      actionSubName = name.split(':')[1]
    }

    if (!actionName) return
    let namespace = this.context.getNamespace()
    if (layoutName.includes('.')) {
      namespace = layoutName.split('.')[0]
    }
    const { currentEventOwnerUId } = AppModule
    AppModule.updateProcessingEventOwner({ uid: currentEventOwnerUId })
    // 为每个 action 生成一个 uid
    const uid = getUuid()
    AppModule.updateAppCurrentEventOwner(uid)
    doAction({ layoutName, namespace, actionName, actionSubName, data }, { ...options, silent: true, userSilent: silent || false, __traceId: uid })
      .then(response => {
        window.common = logwire.common[namespace]
        const { success } = logwire.ui.message
        const traceId = uid
        const traceIdArr = getTraceIds()
        const layoutNameWhenActionResponse = getLayoutNameOutsideComponent()
        // 假如请求前后的 layoutName 不一致，那么说明很有可能在请求未响应的时候，页面发生了切换
        // 这个时候直接抛弃对请求响应的处理，错误同理
        if (layoutNameWhenActionResponse === layoutNameWhenActionTrigger) {
          _.isFunction(successCallback)
            ? successCallback(response.data, response)
            : success && traceIdArr.includes(traceId) && success(response.data.message || '')
        }
      })
      .catch(errorInfo => {
        const traceId = uid
        const traceIdArr = getTraceIds()
        window.common = logwire.common[namespace]

        // 对于 axios 和 doAction 的异常处理，更希望 axios 内不处理，而交给 doAction 的 catch
        // 所以 axios 内是用的 silent:true, 而 catch 阶段内重新赋值为用户值，这样再次执行 handleRequestError 时就是用户选择的值
        if (errorInfo?.response?.config) {
          errorInfo.response.config.silent = silent
        }
        const layoutNameWhenActionResponse = getLayoutNameOutsideComponent()
        if (layoutNameWhenActionResponse === layoutNameWhenActionTrigger) {
          _.isFunction(failCallback)
            ? failCallback(errorInfo.response ? errorInfo.response.data : errorInfo)
            : (traceIdArr.includes(traceId) && errorInfo.response && !silent)
              ? handleRequestError(errorInfo.response)
              : console.log(errorInfo)
        }
      })
      .finally(() => {
        delete window.common
        AppModule.updateAppCurrentEventOwner('')
        AppModule.updateProcessingEventOwner({ uid: currentEventOwnerUId, type: 'remove' })
      })
  }

  beforeDestroy () {
    // 如果是弹窗页面，使用 pushSwitch 时无法销毁弹窗的数据，所在在这里手动删除
    if (this.popupLayout) LayoutModule.removeLayoutDataRoot(this.encodedLayoutName)
    eventbus.$off(`${this.encodedLayoutName}.close-layout`)
  }
}
export default Layout
