import { LayoutComponent } from '@/types/layout'
import Component from './Component'

class ComponentNode {
  components!: Array<LayoutComponent>

  constructor (componentNode : Array<LayoutComponent> | null = null) {
    if (componentNode) {
      this.components = componentNode
    } else {
      this.components = []
    }
  }

  // 遍历当前节点下的子节点, 只遍历一层
  forEachComponent (callback: (Component: Component, index: number) => void) {
    this.components.forEach((item: LayoutComponent, index: number) => {
      callback(new Component(item.is, item), index)
    })
  }

  // 根据索引获取 Component 对象
  getComponent (index: number): Component|null {
    if (this.components) {
      return new Component(this.components[index].is, this.components[index])
    } else {
      return null
    }
  }

  appendComponent (component: Component): void {
    this.components.push(component.component)
  }

  removeComponent (index: number): void {
    this.components.splice(index, 1)
  }

  // 索引位置添加子节点
  insertComponent (index: number, component: Component): void{
    this.components.splice(index, 0, component.component)
  }
}

export default ComponentNode
