<script>
import index from 'element-ui/packages/upload/src/index.vue'
import UploadListModify from '@/3rd-party-extension/element/packages/upload/src/upload-list.vue';

export default {
  name: 'ElUpload',

  mixins: [index],

  components: {
    UploadListModify
  },

  props: {
    onDownLoad: Function
  },

  methods: {
    handleDownLoad(file, raw) {
      if (raw) {
        file = this.getFile(raw);
      }
      if(this.onDownLoad) {
        this.onDownLoad(file)
      }
    }
  },

  render(h) {
    let uploadList;

    if (this.showFileList) {
      uploadList = (
        <UploadListModify
          disabled={this.uploadDisabled}
          listType={this.listType}
          files={this.uploadFiles}
          on-remove={this.handleRemove}
          on-downLoad={this.handleDownLoad}
          handlePreview={this.onPreview}>
          {
            (props) => {
              if (this.$scopedSlots.file) {
                return this.$scopedSlots.file({
                  file: props.file
                });
              }
            }
          }
        </UploadListModify>
      );
    }

    const uploadData = {
      props: {
        type: this.type,
        drag: this.drag,
        action: this.action,
        multiple: this.multiple,
        'before-upload': this.beforeUpload,
        'with-credentials': this.withCredentials,
        headers: this.headers,
        name: this.name,
        data: this.data,
        accept: this.accept,
        fileList: this.uploadFiles,
        autoUpload: this.autoUpload,
        listType: this.listType,
        disabled: this.uploadDisabled,
        limit: this.limit,
        'on-exceed': this.onExceed,
        'on-start': this.handleStart,
        'on-progress': this.handleProgress,
        'on-success': this.handleSuccess,
        'on-error': this.handleError,
        'on-preview': this.onPreview,
        'on-remove': this.handleRemove,
        'on-handleDownLoad': this.handleDownLoad,
        'http-request': this.httpRequest
      },
      ref: 'upload-inner'
    };

    const trigger = this.$slots.trigger || this.$slots.default;
    const uploadComponent = <upload {...uploadData}>{trigger}</upload>;

    return (
      <div>
        { this.listType === 'picture-card' ? uploadList : ''}
        {
          this.$slots.trigger
            ? [uploadComponent, this.$slots.default]
            : uploadComponent
        }
        {this.$slots.tip}
        { this.listType !== 'picture-card' ? uploadList : ''}
      </div>
    );
  }
};
</script>
