











import { Component, Vue, Prop, InjectReactive, ProvideReactive } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { LayoutComponent } from '@/types/layout'
import logwire from '@/logwire'
import { layoutStatus } from '@/utils/const'
import { LayoutModule } from '@/store/modules/layout'

@Component
export default class Content extends Vue {
  @Prop() layoutComponents!: Array<LayoutComponent>
  @InjectReactive() encodedLayoutName!: string
  @ProvideReactive() searchBarComplete = {}
  @ProvideReactive() queriableComponentComplete = {}

  get contentLoading (): boolean {
    return AppModule.loadingLayoutNameArr.indexOf(this.encodedLayoutName) !== -1
  }

  get currentLayoutStatus (): layoutStatus {
    return LayoutModule.data?.[this.encodedLayoutName]?.status as layoutStatus
  }

  get onError (): boolean {
    return this.currentLayoutStatus === layoutStatus.ERROR
  }

  get hasNoOrOneRegion (): boolean {
    // 当弹框或抽屉内的content中没有region子组件，或者只有一个子组件切为region,背景色设为白色
    let flag = false
    if (this.layoutComponents.length === 1 && this.layoutComponents[0]?.is === 'lw-region') {
      flag = true
    } else if (!this.layoutComponents.some(item => item.is === 'lw-region')) {
      flag = true
    }
    return flag
  }
}
