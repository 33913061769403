













































































































































































import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator'
import { LocalModule } from '@/store/modules/local'
import { getMenu, getQuery } from '@/utils/common'
import _ from 'lodash'
import { Debounce } from 'lodash-decorators'
import { isMethodOrPropertyDecoratorArgs } from 'lodash-decorators/utils'
import { proceedAfterEditingDataSetCheck } from '@/utils/layout'
import logwire from '@/logwire'
import { getMaxZIndex } from '@/utils/dom'

@Component({ name: 'BoardMenu' })
export default class BoardMenu extends Vue {
  dragDom: HTMLElement | null = null
  dragIndex = -1
  showBoardMenu = false
  showSecondMenu = false
  showFavoriteMenus = true // 默认展开收藏菜单
  query = ''
  currentIndex = -1
  filterMenus: Array<Record<string, any>> = this.menus
  secondMenusList: Array<Record<string, any>> = []
  zIndex = 2001
  showFavorite = true // 显示 我的收藏
  currentTimer = 0

  get menus (): Array<Record<string, any>> {
    return LocalModule.menus
  }

  get favoriteMenus (): Array<Record<string, any>> {
    return LocalModule.favoriteMenus
  }

  get menuTheme (): any {
    return logwire.store.getConfig('menuTheme')
  }

  @Watch('showBoardMenu', { immediate: true })
  handleShowSecondMenu (status: boolean): void {
    const headerMenuIcon = document.getElementById('lw-header-menu-icon-show')
    if (!headerMenuIcon) return
    if (status) {
      headerMenuIcon.classList.remove('icon-zhankai')
      headerMenuIcon.classList.add('icon-shouqi')
    } else {
      headerMenuIcon.classList.remove('icon-shouqi')
      headerMenuIcon.classList.add('icon-zhankai')
    }
  }

  @Watch('showSecondMenu', { immediate: true })
  handleSecondMenuVisibleChange (status: boolean): void {
    if (!status) return
    this.$nextTick(() => {
      const { $refs } = this
      const input: any = $refs.searchInput
      input.focus()
    })
  }

  @Watch('currentIndex', { immediate: true })
  setSecondMenusList (index: number): void {
    this.secondMenusList = this.filterMenus.length > 0
      ? (this.filterMenus[index]?.items || [])
      : (this.menus[index]?.items || [])
    this.showSecondMenu = (index !== -1)
    this.$nextTick(function () {
      this.menuLayout()
    })
  }

  @Watch('menus') // menus 只在 getUserInfo 的时候变化一次，所以只会触发一次
  onMenusChange () {
    this.queryFilterMenus('')
  }

  // 初始化的时候所有菜单会进行遍历，将各级菜单的 isMatched 设为 true
  @Watch('query', { immediate: true })
  queryFilterMenus (queryStr: string) {
    queryStr = queryStr.trim().toLowerCase()
    // 筛选匹配菜单
    const menusFilter = function (menus: Array<Record<string, any>>, query: string, parentMenu?: Record<string, any>, flag = false) {
      menus.forEach((menu: Record<string, any>) => {
        // 菜单默认不匹配
        menu.isMatched = false
        if (menu.title.toLowerCase().indexOf(query) >= 0) {
          // 如果匹配，当前菜单和父级菜单都标记匹配
          menu.isMatched = true
        }
        // 有父级菜单且父级菜单匹配，则子菜单标记匹配显示
        if (parentMenu && flag) {
          menu.isMatched = true
        }
        if (menu.items?.length > 0) {
          // 如果还有子菜单，继续遍历
          // 在这里如果子元素匹配，自身也会被标记为匹配
          menusFilter(menu.items, query, menu, menu.isMatched)
        }
        // 如果自身具有父级菜单，并且自身被标记为匹配，则将父级菜单也标记为匹配
        if (parentMenu && menu.isMatched) {
          parentMenu.isMatched = true
        }
      })
    }
    menusFilter(this.menus, queryStr)
    const index = this.menus.findIndex(item => item.isMatched)
    if (queryStr && index > -1) this.currentIndex = index
    this.filterMenus = this.menus
    this.secondMenusList = this.menus[this.currentIndex]?.items || []
    this.showFavorite = this.showFavoriteMenus = !queryStr

    this.$nextTick(function () {
      this.menuLayout()
    })
  }

  handleMouseenter (index: number) {
    this.currentTimer = setTimeout(() => {
      this.currentIndex = index
    }, 100)
  }

  handleMouseleave () {
    this.currentTimer && clearTimeout(this.currentTimer)
  }

  isLeafNode (menu: Record<string, any>) {
    return !menu.items && menu.link
  }

  open (): void {
    this.zIndex = getMaxZIndex(1)
    this.showBoardMenu = true
    // 打开时候默认展开第一个菜单
    this.currentIndex = 0
  }

  close (): void {
    // 关闭后的重置选项
    this.showBoardMenu = false
    this.showSecondMenu = false
    this.currentIndex = -1
    this.query = ''
  }

  toggle (): void {
    if (this.showBoardMenu) {
      this.close()
    } else {
      this.open()
    }
  }

  toggleFavoriteMenus (): void {
    this.showFavoriteMenus = !this.showFavoriteMenus
  }

  collectOrCancelMenu (menu: Record<string, any>): void {
    if (menu.favoriteMenu) {
      this.cancelFavorite(menu)
    } else {
      this.collectMenu(menu)
    }
  }

  collectMenu (menu: Record<string, any>): void {
    if (this.filterMenus.length > 0) menu.favoriteMenu = false
    LocalModule.collectMenu(menu)
  }

  cancelFavorite (menu: Record<string, any>): void {
    if (this.filterMenus.length > 0) menu.favoriteMenu = true
    LocalModule.cancelFavoriteMenu(menu)
    // 在我的收藏中取消的时候，需要把 filterMenus 中的 menu 设置为未收藏
    const m = getMenu(this.filterMenus, menu.code)
    m && (m.favoriteMenu = false)
  }

  menuLayout (): void {
    const { secondMenuContain } = this.$refs
    if (!secondMenuContain) return
    const menuList = (secondMenuContain as HTMLElement).querySelectorAll('.second-menu__inner')
    if (menuList.length === 0) {
      (secondMenuContain as HTMLElement).style.height = '0px'
      return
    }
    // 每个菜单的宽度
    const menuItemWidth = menuList[0].clientWidth;
    // 菜单容器的宽度
    (secondMenuContain as HTMLElement).style.width = ''
    const containWidth = (secondMenuContain as HTMLElement).clientWidth
    // 计算的到列数, 最小 1 列
    let cols = 1
    if (menuItemWidth) cols = Math.max(Math.floor(containWidth / menuItemWidth), 1)
    // 定义间隔
    const gutter = 10
    // 获取全部未被隐藏的菜单
    const itemsNodeList :Array<HTMLElement> = []
    const itemsGutter: Array<number> = []
    const itemsPosX: Array<number> = []
    menuList.forEach((node) => {
      if ((node as HTMLElement).style.display !== 'none') itemsNodeList.push(node as HTMLElement)
    })
    if (itemsNodeList.length === 0) return
    // 重新计算容器宽度
    (secondMenuContain as HTMLElement).style.width = (menuItemWidth * cols + gutter) + 'px'
    for (let g = 0; g < cols; g++) {
      itemsPosX.push(g * menuItemWidth + g * gutter)
      itemsGutter.push(gutter)
    }

    itemsNodeList.forEach(item => {
      const itemIndex = itemsGutter.indexOf(Math.min(...itemsGutter))
      const posX = itemsPosX[itemIndex]
      const posY = itemsGutter[itemIndex]
      const transformProps = [
        'webkitTransform',
        'MozTransform',
        'msTransform',
        'OTransform',
        'transform'
      ]
      // x, y 坐标赋值
      transformProps.forEach(transform => {
        item.style[transform] = 'translate(' + posX + 'px,' + posY + 'px)'
      })

      itemsGutter[itemIndex] += item.getBoundingClientRect().height + gutter

      const containerHeight: number = Math.max(...itemsGutter);

      (secondMenuContain as HTMLElement).style.height = containerHeight + 'px'
    })
  }

  goLayout (menu: Record<string, any>): void {
    // 具有 link、 并且没有子菜单 才可以跳转
    // 默认 linkType = 'layout'
    if (menu.link && !menu.items) {
      const linkType = menu.linkType || 'layout'
      if (linkType === 'layout') {
        const [layoutName, queryString] = menu.link.split('?')
        const params = getQuery(queryString)
        logwire.ui.pushSwitch({ layoutName, params } as any)
      } else {
        window.open(menu.link)
      }
      this.close()
    }
  }

  goLayoutWithBlank (menu: Record<string, any>): void {
    if (!menu.link) return
    window.open(menu.link)
    this.close()
  }

  handleDragStart (e: DragEvent): void {
    this.dragDom = e.target as HTMLElement
    this.dragIndex = parseInt(this.dragDom.dataset.index || '0')
  }

  allowDrop (e: DragEvent): void {
    const dom = e.target as HTMLElement
    if (dom === this.dragDom || !dom.classList.contains('favorite-menus-item')) return
    dom.style.border = 'none'
    const { pageY } = e
    const domTop = (dom as HTMLElement).getBoundingClientRect().top
    const domHeight = dom.clientHeight
    const rawIndex = parseInt(dom.dataset.index || '0')
    const isDowwn = this.dragIndex < rawIndex
    let inertIndex
    if (pageY > (domTop + (domHeight / 2))) {
      inertIndex = isDowwn ? rawIndex : rawIndex + 1
    } else {
      inertIndex = isDowwn ? rawIndex - 1 : rawIndex
    }
    const coptFavoriteMenus = JSON.parse(JSON.stringify(this.favoriteMenus))
    const moveMenus = coptFavoriteMenus.splice(this.dragIndex, 1)
    coptFavoriteMenus.splice(inertIndex, 0, moveMenus[0])
    LocalModule.loadUserFavoriteMenus({ favoriteMenus: coptFavoriteMenus, update: true })
  }

  handleDragover (e: DragEvent): void {
    const dom = e.target as HTMLElement
    if (dom === this.dragDom || !dom.classList.contains('favorite-menus-item')) return
    const { pageY } = e
    const domTop = (dom as HTMLElement).getBoundingClientRect().top
    const domHeight = dom.clientHeight
    if (pageY > (domTop + (domHeight / 2))) {
      dom.classList.remove('top-border')
      dom.classList.add('bottom-border')
    } else {
      dom.classList.add('top-border')
      dom.classList.remove('bottom-border')
    }
  }

  handleDragleave (e: DragEvent): void {
    const dom = e.target as HTMLElement
    dom.classList.remove('top-border')
    dom.classList.remove('bottom-border')
  }

  mounted (): void {
    this.$nextTick(function () {
      this.menuLayout()
      window.addEventListener('resize', this.menuLayout)
    })
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', this.menuLayout)
  }
}
