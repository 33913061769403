



































import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attributeForce2Boolean, attributeForce2Number } from '@/utils/layout'
import TextBase from './TextBase'

@Component({ name: 'LwText' })
class LwText extends Mixins(FormControlComponent, TextBase) {
  readonly = attributeForce2Boolean('password', this.component, false)

  // 不支持脚本
  get maxLength (): number { return attributeForce2Number('maxLength', this.component, Number.MAX_SAFE_INTEGER) }

  get password (): boolean { return attributeForce2Boolean('password', this.component, false) }

  get type (): string {
    return this.password
      ? 'password'
      : 'text'
  }

  get autocomplete (): string {
    let result = 'off'
    if (this.password) {
      result = 'new-password'
    }
    return result
  }

  handleFocus (e: any): void {
    // 解决当密码输入框跟在 select 控件之后时，select 的下拉会被浏览器的自动填充下拉框所遮挡
    this.readonly = false
    this.component.onFocus && this.runRunnableContent('onFocus')
  }
}
export default LwText
