











import BaseComponent from '@/components/layout/BaseComponent'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'ExpandRowWrapper',
  props: {
    parentRow: Object
  }
})
export default class ExpandRowWrapper extends BaseComponent {}
