import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from 'element-ui/src/utils/date-util'
import logwire from '@/logwire'

const DATE_TYPES = ['year', 'month', 'date', 'daterange']

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get type (): string {
    const type = this.getFinalAttributeValue('type', { args: this.args })
    return DATE_TYPES.includes(type) ? type : 'date'
  }

  get displayFormat (): string {
    if (this.type === 'year') return 'yyyy'
    let format = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
    if (this.type === 'month') {
      format = logwire.store.getConfig('core.i18n.year-month-format') || 'yyyy-MM'
    }
    return format
  }

  get inputValue (): any {
    const { field } = this.component
    const dataRow = this.form.dataRow
    const value = dataRow[field]
    const isDateRange = this.type === 'daterange'
    return value
      ? isDateRange
        ? value.map((dateTime: string) => new Date(dateTime))
        : new Date(value)
      : isDateRange
        ? []
        : value
  }

  set inputValue (value: any) {
    this.form.dataRow[this.component.field] = value
  }

  // 根据首选项
  get displayValue (): string {
    return formatDate(this.inputValue, this.displayFormat)
  }
}

export default DateBase
