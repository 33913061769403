


















































import { Component, InjectReactive, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { LayoutModule } from '@/store/modules/layout'
import { filterGroupOperator, fieldOperations, fieldAttrs, filterGroupOperatorList, dateOperators } from '@/utils/const'
import { getUuid } from '@/utils/common'
import BaseComponent from '../../BaseComponent'
import { getI18nContent } from '@/utils/layout'

@Component({ name: 'FilterRow' })
class FilterRow extends BaseComponent {
  @Prop() filter: any
  @Prop({ default: 0 }) filterIndex!: number

  @ProvideReactive() evaluatingBase = 'advancedFilter'

  fieldOptions: any = []
  operatorOptions: any = []
  filterDataSet = {}

  @InjectReactive() filterLayoutName!: string
  @InjectReactive() filterDataSetName!: string
  @InjectReactive() evaluateScriptInTableControls!: boolean

  handleRowCommand (command: string) {
    command === 'delete' && this.$emit('deleteRow')
    command === 'toGroup' && this.$emit('changeRowToGroup')
  }

  created () {
    // 根据showFields是空，来判定dataset对应的是list，字段标题只能用 queryMetadata 提供的来源于 Model 的 title
    let options = null
    if (LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields) {
      options = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields.filter((item: { is: string }) => item.is !== 'lw-upload-file')
    } else {
      const queryName = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].queryName as string
      options = LayoutModule.resource[this.filterLayoutName].queryMeta[queryName]
      // 处理 list 的 options ,使其和table返回的数据一致
      options = options.map((item: any) => {
        item.field = item.name
        item.is = 'lw-text'
        return _.pick(item, ['is', 'field', 'title'])
      })
    }
    // 合并 field 相同的项 并将每一项的title国际化
    options = _.unionBy(options, 'field')
    options = options.map((item: any) => {
      if (item.title) {
        item.i18nTitle = getI18nContent(item.title)
      }
      return item
    })
    this.fieldOptions = options
    const firstOption = this.fieldOptions[0]
    if (!Object.keys(fieldOperations).includes(firstOption.is)) {
      firstOption.is = 'lw-text'
    }
    const layouts = this._initLayouts(firstOption)
    let initRow = {}
    if (!this.filter.field) {
      initRow = {
        layouts,
        field: firstOption.field,
        operator: filterGroupOperator.EQ,
        value: null,
        is: firstOption.is
      }
    }
    // 国际化操作符
    this.operatorOptions = filterGroupOperatorList.map((item: any) => {
      const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
      return data
    })
    this.filter = Object.assign(this.filter, initRow)
    // searchDs改名__advancedFilterDs，为了兼容在此之前创建的过滤组，取出来后修改dataset名称
    this.filter.layouts[0].dataSet = '__advancedFilterDs'
  }

  changeField (currentField: any) {
    this.filter.betweenClass = ''
    const component = this.fieldOptions.find((item: { field: any }) => item.field === currentField)
    const layouts = this._initLayouts(component)
    this.$set(this.filter, 'is', component.is)
    this.$set(this.filter, 'layouts', layouts)
    this.$set(this.filter, 'operator', filterGroupOperator.EQ)
    this.$forceUpdate()
  }

  changeOperator (currentOperator: any) {
    // lw-text 特殊情况： 操作符为可选,排除时，后面为lw-textarea
    this.$set(this.filter.layouts[0].components[0], 'enabled', 'true')
    if (this.filter.is === 'lw-text') {
      if ([filterGroupOperator.IN, filterGroupOperator.NOT_IN].includes(currentOperator)) {
        this.$set(this.filter.layouts[0].components[0], 'is', 'lw-textarea')
      } else {
        this.$set(this.filter.layouts[0].components[0], 'is', 'lw-text')
      }
      const id = this.filter.field + '_' + getUuid()
      const originalId = this.filter.layouts[0].id
      this.$set(this.filter.layouts[0].components[0], 'field', id)
      this.$set(this.filter.layouts[0], 'id', id)
      // 还需要改变dataset中的值
      const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
      const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
      searchRow.setData(this.filter.layouts[0].id, searchRow.getData(originalId))
    }
    this.filter.betweenClass = ''
    // 如果操作符是之间，则出现两个区间日期选择
    if (['lw-date', 'lw-date-time', 'lw-timestamp'].includes(this.filter.is)) {
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(currentOperator)) {
        const id = this.filter.field + '_' + getUuid()
        this.$set(this.filter.layouts[0], 'id', id)
        this.$set(this.filter.layouts[0], 'columns', '2,2,2,2,2')
        const component = this.filter.layouts[0].components[0]
        const copyComponent = _.cloneDeep(component)
        copyComponent.field = id
        this.filter.layouts[0].components = [component, copyComponent]
        this.filter.betweenClass = 'filter-row-between'
      } else {
        const id = this.filter.field + '_' + getUuid()
        this.$set(this.filter.layouts[0], 'id', id)
        this.$set(this.filter.layouts[0].components[0], 'field', id)
        this.filter.layouts[0].components[1] && this.filter.layouts[0].components.pop()
      }

      for (const component of this.filter.layouts[0].components) {
        this.$set(component, 'is', dateOperators.includes(currentOperator) ? 'lw-date' : 'lw-date-time')
      }
    }
    // 如果操作符是为空或者不为空，则value输入框置为不可编辑
    if ([filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY].includes(currentOperator)) {
      this.$set(this.filter.layouts[0].components[0], 'enabled', 'false')
      const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
      const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
      searchRow.setData(this.filter.layouts[0].id, null)
    }
    this.$forceUpdate()
  }

  getOperators (component: any) {
    if (component.is === 'lw-choice' && component.multiple) {
      this.operatorOptions = fieldOperations[component.is + '-multiple'].map((item: any) => {
        const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
        return data
      })
    }
    this.operatorOptions = fieldOperations[component.is].map((item: any) => {
      const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
      return data
    })
  }

  _initLayouts (component: any) {
    if (component.is === 'lw-timestamp') component.is = 'lw-date-time'
    const id = component.field + '_' + getUuid()
    const initComponent = _.pick(_.cloneDeep(component), fieldAttrs['lw-form-item'].concat(fieldAttrs[component.is]))
    // 过滤掉initComponent中有 {eval} {function} {common} 的属性
    if (!this.evaluateScriptInTableControls) {
      for (const key in initComponent) {
        if (initComponent[key].includes('{eval}') || initComponent[key].includes('{function}') || initComponent[key].includes('{common}')) {
          delete initComponent[key]
        }
        if (['displayTemplate', 'dropDownRowTemplate', 'dropDownHeaderTemplate'].includes(key) && initComponent[key].includes('getCurrentRow')) {
          delete initComponent[key]
        }
      }
    }
    const layouts = [{
      id,
      is: 'lw-input-form',
      dataSet: '__advancedFilterDs',
      components: [
        Object.assign(initComponent,
          {
            field: id,
            enabled: 'true'
          })
      ]
    }]
    // this.getOperators(component)
    return layouts
  }
}

export default FilterRow
