







import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import TimestampBase from '@/components/layout/form-item/timestamp/TimestampBase'

@Component({ name: 'LwTimestamp' })
class LwTimestamp extends Mixins(FormControlComponent, TimestampBase) {}
export default LwTimestamp
