<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellText from './TableCellText.vue'
import FormText from './Text.vue'

@Component({ name: 'LwText', components: { TableCell: TableCellText, FormItem: FormText } })
class LwText extends FormControlBase {}
export default LwText
</script>
