













import { Component, Mixins, Prop } from 'vue-property-decorator'
import BaseComponent from '../BaseComponent'
import { px2rem } from '@/utils/dom'
import Args from '@/models/Args'
import { DataRow } from '@/types/data'
import _ from 'lodash'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'
import { getCsrfToken } from '@/utils/data'
import { attributeType } from '@/utils/const'
import eventbus from '@/utils/event'
import { getArchiveStorageAppend } from '@/utils/common'

type ImageStyle = {
  width?: string
  height?: string
  transform?: string
  cursor?: string
}

@Component({ name: 'LwImage' })
export default class LwImage extends Mixins(BaseComponent, BaseComponentWithRow) {
  scaleStyle: ImageStyle = {}

  get width () {
    // 根据 tableRow 判断 image 组件是否在 table 下
    if (this.tableRow) {
      // 没有设置 width 默认是 150，否则取 width 值
      return this.component.imageRealWidth ? this.component.imageRealWidth + '' : '150'
    } else {
      return this.getFinalAttributeValue('width')
    }
  }

  get alt () { return this.getFinalAttributeValue('alt', { args: this.argsWithRow }) }

  get height () { return this.getFinalAttributeValue('height') }

  get scale () {
    // scale 可以是小数
    let result = 1
    if (this.component.scale) {
      result = this.getFinalAttributeValue('scale', { args: this.argsWithRow })
    }
    return result
  }

  get srcType (): string {
    let result = 'asset'
    if (this.component.srcType) {
      result = this.getFinalAttributeValue('srcType', { args: this.argsWithRow })
      if (!['asset', 'document', 'raw'].includes(result)) {
        result = 'asset'
      }
    }
    return result
  }

  get previewable (): boolean {
    let result = false
    if (this.component.previewable) {
      result = this.getFinalAttributeValue('previewable', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get src () {
    const src = this.getFinalAttributeValue('src', { args: this.argsWithRow })
    const namespace = this.context.getNamespace()
    let result = src
    if (this.srcType === 'asset') {
      result = `/api/open/${namespace}/core.get-asset?download=false&path=${namespace}/asset/${src}`
    } else if (this.srcType === 'document') {
      result = `/api/open/${namespace}/core.document-preview?code=${src}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
    }
    return result
  }

  get style () {
    const result: ImageStyle = this.scaleStyle
    if (this.width) {
      result.width = this.getWidthOrHeight(this.width)
    }
    if (this.height) {
      result.height = this.getWidthOrHeight(this.height)
    }
    if (this.component.onClick) {
      result.cursor = 'pointer'
    }
    return result
  }

  // 宽、高 的形式都只支持三种 500、500px 或者 50%
  getWidthOrHeight (value: string): string {
    if (value.endsWith('px')) {
      value = px2rem(parseInt(value.replace('px', '')))
    } else if (!value.endsWith('%')) {
      // 不以 % 或 px 处理，都当作纯数字处理
      value = px2rem(parseInt(value))
    }
    return value
  }

  handleClick (evt: MouseEvent) {
    if (this.previewable) {
      evt.stopPropagation()
      eventbus.$emit('preview-file', this.src)
    } else if (this.component.onClick) {
      evt.stopPropagation()
      this.runRunnableContent('onClick', { args: this.argsWithRow })
    }
  }

  handleLoad () {
    if (this.scale && !this.width && !this.height) {
      // 如果用 css 的 transform 来实现 scale 功能，会导致原先 image 所占据位置的边界不会发生变化，而图片本身被放大或者缩小，导致图片遮挡或者被遮挡界面其他元素，或者缩小后留白问题
      // 所以改用计算宽高的方式来实现缩放效果
      const image = this.$refs.img as HTMLElement
      let width = image.clientWidth
      let height = image.clientHeight
      width = width * this.scale
      height = height * this.scale
      this.$set(this.scaleStyle, 'width', this.getWidthOrHeight(width + ''))
      this.$set(this.scaleStyle, 'height', this.getWidthOrHeight(height + ''))
    }
  }
}
