import ComponentStructure from '../basic'

export default class I18nForLwRegion3 extends ComponentStructure {
  name = 'lw-region-3'
  properties = []

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    return [].concat(this.component.left || []).concat(this.component.right || []).concat(this.component.middle || [])
  }
}
