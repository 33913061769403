














import { Component, InjectReactive } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeForce2Boolean } from '@/utils/layout'

@Component({ name: 'LwRegion' })
export default class LwRegion extends BaseComponent {
  get inlineTitle (): boolean { return attributeForce2Boolean('inlineTitle', this.component, false) }
}
