import { IDataTreeNode } from '@/types/data'

export default class DataTreeNode {
  #node: IDataTreeNode

  constructor (node: IDataTreeNode) {
    this.#node = node
  }

  getChildren () {
    return this.#node.children
      ? this.#node.children.map(n => new DataTreeNode(n))
      : null
  }
}
