
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import TimeBase from './TimeBase'

@Component({
  name: 'TableCellTime'
})
class TableCellTime extends Mixins(TableCell, TimeBase) {}
export default TableCellTime
