import { LocalModule } from '@/store/modules/local'
import { getCsrfToken } from '@/utils/data'
import _ from 'lodash'

// TODO 兼容处理，之后版本中移除此模块
export default {
  /**
   * 获取一个配置项内容
   * @param {string} name 目标配置项名称
   * */
  get (name: string) {
    const configRes = LocalModule.config
    return _.get(configRes, name)
  },
  /**
   * 设置一个配置项内容
   * @param {string} name 目标配置项名称
   * @param {any} value 目标配置项值
   * */
  set (name: string, value: any): void {
    LocalModule.updateClientConfig({ name, value })
  },
  getCsrfToken () {
    return getCsrfToken()
  }
}
