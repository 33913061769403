

















import Args from '@/models/Args'
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator'
import BaseComponent from '../BaseComponent'
import FormControlComponent from '../FieldBoundComponent'

/**
 * 在表格 Table.vue 中， 有可能表格单元格被渲染为纯文本，此时 FormDisplayText 找不到 FormControl, 需要重新创建一个组件，此处使用
 * 参考 curCol.renderBodyCell 方法
 */
@Component
export default class TableDisplayText extends BaseComponent {
  @Prop() tableRow!: any
  @InjectReactive() evaluatingBase!: string

  $refs !: {
    text: HTMLElement
  }

  get args () : Args { return new Args(this.context, { row: this.tableRow, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') }) }

  get href () {
    return this.getFinalAttributeValue('href', { args: this.args })
  }

  get target () {
    return this.getFinalAttributeValue('target', { args: this.args }) || '_blank'
  }

  get hasOnClick () {
    return this.component.onClick
  }

  handleClick (evt: MouseEvent) {
    if (this.hasOnClick) {
      evt.preventDefault()
      this.runRunnableContent('onClick', { args: this.args })
    }
  }

  mounted () {
    if (this.$slots.default?.[0]?.text) {
      this.$refs.text.setAttribute('title', this.$slots.default?.[0]?.text)
    }
  }
}
