import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from 'element-ui/src/utils/date-util'
import logwire from '@/logwire'
const DATE_TYPES = ['datetime', 'datetimerange']

@Component
class DateTimeBase extends Vue {
  [propName: string]: any;

  get type (): string {
    const type = this.getFinalAttributeValue('type', { args: this.args })
    return DATE_TYPES.includes(type) ? type : 'datetime'
  }

  get displayFormat (): string {
    const formatDate = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
    const formatTime = logwire.store.getConfig('core.i18n.time-format') === '24-hour'
      ? 'HH:mm:ss'
      : 'hh:mm:ss A'
    return formatDate + ' ' + formatTime
  }

  get inputValue (): any {
    const { field } = this.component
    const dataRow = this.form.dataRow
    const value = dataRow[field]
    const isDateTimeRange = this.type === 'datetimerange'
    return value
      ? isDateTimeRange
        ? value.map((dateTime: string) => new Date(dateTime))
        : new Date(value)
      : isDateTimeRange
        ? []
        : value
  }

  set inputValue (value: any) {
    this.form.dataRow[this.component.field] = value
  }

  // 根据首选项
  get displayValue (): string {
    return formatDate(this.inputValue, this.displayFormat)
  }
}

export default DateTimeBase
