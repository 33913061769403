import { LayoutComponent, LayoutMeta } from '@/types/layout'
import Component from './Component'

class Layout {
  layout: LayoutMeta

  constructor (layout: LayoutMeta) {
    this.layout = layout
  }

  forEachComponent (callback: (component: Component, index: number) => void) {
    this.layout.components.forEach((item: LayoutComponent, index: number) => {
      callback(new Component(item.is, item), index)
    })
  }

  // 在当前 Layout-components 下的components 的末尾添加组件
  appendComponent (component: Component): void {
    if (!this.layout.components) return
    this.layout.components.push(component.component)
  }

  // 在指定位置添加组件
  insertComponent (index: number, component: Component): void {
    if (!this.layout.components) return
    this.layout.components.splice(index, 0, component.component)
  }

  // 移除指定位置的组件
  removeComponent (index: number): void {
    if (!this.layout.components) return
    this.layout.components.splice(index, 1)
  }

  // 根据索引获取 Component 对象
  getComponent (index: number): Component | null {
    const components = this.layout.components
    if (components) {
      return new Component(components[index].is, components[index])
    } else {
      return null
    }
  }

  getPanel (name: string): LayoutComponent | null {
    let panel = null
    const panels = this.layout.panels
    if (panels) {
      panels.forEach((item: LayoutComponent) => {
        if (item.name === name) {
          panel = new Component(item.is, item)
        }
      })
    }
    return panel
  }
}

export default Layout
