export default class LayoutArgs {
  #layoutName!: string
  #params = {} as Record<string, any>
  #width = '50%'
  #modal = true
  #onClose!: () => void
  #onOpen!: () => void

  getLayoutName (): string {
    return this.#layoutName
  }

  setLayoutName (layoutName: string): void {
    this.#layoutName = layoutName
  }

  getId (): number {
    return this.#params.id
  }

  setId (id: number): void {
    this.#params.id = id
  }

  getNew (): string {
    return this.#params.new
  }

  setNew (eventTarget: string) {
    this.#params.new = eventTarget
  }

  setAfterLoadEvent (event: string) {
    this.#params.afterLayoutLoadEvent = event
  }

  getParam (paramName: string): any {
    return this.#params[paramName]
  }

  getParams (): Record<string, any> {
    return this.#params
  }

  setParam (paramName: string, value: any) {
    this.#params[paramName] = value
  }

  getWidth (): string {
    return this.#width
  }

  setWidth (width: string) {
    this.#width = width
  }

  getOnClose (): () => void {
    return this.#onClose
  }

  setOnClose (onClose: () => void): void {
    this.#onClose = onClose
  }

  getOnOpen (): () => void {
    return this.#onOpen
  }

  setOnOpen (onOpen: () => void): void {
    this.#onOpen = onOpen
  }

  setModal (modal: boolean) {
    this.#modal = modal
  }

  getModal () {
    return this.#modal
  }
}
