

















































































































































import { Component, InjectReactive, Mixins, Vue, Watch } from 'vue-property-decorator'
import selectOperation from '@/components/layout/mixin/selectOperation'
import { attributeType, filterOperator } from '@/utils/const'
import {
  attribute2Boolean,
  attribute2Number,
  attribute2Strings,
  attributeForce2Number, checkTemplateSafety,
  warnAttributeMissing,
  warnAttributeTypeWrong,
  warnDeprecatedApi,
  warnDeprecatedAttribute
} from '@/utils/layout'
import _ from 'lodash'
import { Debounce, Bind } from 'lodash-decorators'
import logwire from '@/logwire'
import { countByFilter, queryByFilter } from '@/http/api'
import Args from '@/models/Args'
import { formatDataRow, formatMasterFilter } from '@/utils/data'
import { ElSelect } from 'element-ui/types/select'
import { ElOption } from 'element-ui/types/option'
import { DataRow, DataSet } from '@/types/data'
import SelectBase from '@/components/layout/form-item/select/SelectBase'

// 因为 element 的 Select 最终显示label是通过 el-option 上的 prop: label 属性，并且所有的传值都是对象的引用，所以将 label 显示为最终 getTemplate 后的结果，然后再让 el-select 重新渲染已选中的内容
interface Option {
  label: string; // 显示到el-select的输入框的文本
  value: string;
  content: string // 显示到下拉弹窗内的文本
  raw: Record<string, any> // 后端返回的源数据对象
}

/**
 * 2023-5-12 增加 Multiple 属性，但是一旦设置为 true，那么就不能作为引起数据保存的字段，因为后端不会支持。一般用于纯前端的操作，js
 */
@Component({ name: 'LwSelect' })
class LwSelect extends Mixins(selectOperation, SelectBase) {
  // cachedOptions 的用途是在 change 时提供给 args 一个原始数据对象
  cachedOptions !: Option[]

  options = [] as Array<Option>
  pageNo = 1
  total = 0
  queryString = ''
  searching = false
  visibleChange = false
  onChangeFields: Array<string> = []
  ignoreInputValueChange = false

  $refs !: {
    select: ElSelect & {
      // 一些 element 源码内 select.vue 组件上的属性或方法，可以通过实例访问
      setSelected: () => void
      selectedLabel: any
    }
  }

  // TODO 这是否是一个必填字段
  get valueFieldInDropDown (): string { return this.component.valueFieldInDropDown || 'value' }

  get pageCount (): number { return Math.ceil(this.total / this.pageSize) }

  // pageSize 不支持动态
  get pageSize (): number {
    return attributeForce2Number('dropDownPageSizes', this.component, 20)
  }

  get multiple () {
    const result = attribute2Boolean(this.component.multiple) === true
    if (this.tableRow) {
      console.warn('[Logwire Warning] Attribute "multiple" not work because current lw-select component is in table')
      return false
    }
    return result
  }

  get prevButtonDisabled (): boolean { return this.pageNo === 1 }

  get nextButtonDisabled (): boolean { return this.pageNo === this.pageCount }

  get dropDownHeaderTemplate (): string {
    checkTemplateSafety('dropDownHeaderTemplate', this.component)
    const { dropDownHeaderTemplate } = this.component
    const result = dropDownHeaderTemplate
      ? _.template(dropDownHeaderTemplate)({ args: this.args, logwire })
      : ''
    return result
  }

  get parentTable () {
    let parent = this.$parent
    const options: any = parent.$options
    let parentName = options.name
    while (parent && parentName !== 'lw-table') {
      parent = parent?.$parent
      const options: any = parent?.$options
      parentName = options?.name
    }
    return parent
  }

  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    if (this.multiple) {
      return this.inputValue instanceof Array && this.inputValue.length !== 0
    } else {
      return this.inputValue !== row.originalData[this.component.field]
    }
  }

  @Watch('inputValue', { immediate: true })
  handleValueChange (value: string | number | Array<string | number>, oldValue?: any) {
    if (this.ignoreInputValueChange) {
      this.ignoreInputValueChange = false
      return
    }
    /**
     * 发现一个神奇的现象，当对表格的第20行的 select 组件做修改时，提交保存，会发现保存后，表格内该 field 字段有值的每一行，都变为刚才选中的值
     * 原因在于，保存后，因为某些过程， vxe-table 重新渲染了一次，然后对每一行的 lw-select 组件
     * 会发现 inputValue 的 oldValue 值并不是原来的值，而是其他组件的值，而 value 却是原来自己的值
     * 这样就会触发 handleChange，将 cacheFieldOptions 直接作为自身的值。
     * 暂时不知道为什么 vxe-table 会这样渲染，所以将 handleChange 内做了判断，如果 option.value !== this.inputValue 就不会继续执行
     *
     * oldValue 可能等于空字符串，表示表格内无数据的 lw-select 组件
     *
     * 如果是处于表格内的 lw-select 组件，永远不会显示多选内容
     */
    if (this.tableRow && oldValue !== undefined && Object.prototype.hasOwnProperty.call((this.parentTable as any).cacheFieldOptions, this.field)) {
      this.handleChangeByTable(value as string)
    } else if (this.asFilterForm && (!value || (value instanceof Array && value.length === 0)) && this.$refs && this.$refs.select && this.$refs.select.selectedLabel) {
      // 如果用户点击 FiltersForTable 组件内的清空查询条件的按钮，则快速查询表单的字段都会被清空，但是需要走一次 onChange 事件
      // 不存在 value 但是存在 $refs.select.selectLabel，说明是非点击 select 图标的情况下清除了数据
      this.handleChangeByQuickFilter(value)
    }
    if (value === null || value === undefined) {
      return
    }
    this.$nextTick(() => {
      let label: string | string[]
      // 过滤组中的显示字段取过滤组对应field拼接_label获取
      if (this.evaluatingBase === 'advancedFilter') {
        const searchRow = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
        label = searchRow && searchRow.getData(this.field + '_label')
        const options: Option[] = [{
          label: label as string,
          content: label as string,
          value: value as string,
          raw: {}
        }]
        this.options = options
      } else {
        // fix: 2162.现在每次 inputValue 变更都要执行以下逻辑来改变 options，因为要考虑到改变 DataSet 引起的变化
          label = this.getTemplate()
          if (this.multiple && label instanceof Array) {
            const options: Option[] = label.map((o, index) => {
              return {
                label: o,
                content: o,
                value: value[index] as string,
                raw: {}
              }
            })
            this.options = options
          } else if (!this.multiple && !(label instanceof Array)) {
            // 如果 options 数量不为 0，说明是在点击下拉框的内容，此时让 option 的内容变更即可
            const options: Option[] = [{
              label: label,
              content: label,
              value: value as string,
              raw: {}
            }]
            this.options = options
          } else if (label !== undefined) {
            console.error('[Select.vue] 初始化时 multiple 属性为 ' + this.multiple + ', 但是显示文本值却为 ' + label)
          }
      }
      // 手动调用 select 的 setSelected，原因是即便选项 label 发生变化，只要 value 不变，那也不会更新选中后显示的内容
      // eslint-disable-next-line no-debugger
      this.$nextTick(() => this.$refs.select?.setSelected())
    })
  }

  setCachedOptions (opts: Option[]) {
    this.cachedOptions = opts
  }

  setCachedOption (opt: Option) {
    if (this.cachedOptions.some(o => o.value === opt.value)) {
      const index = this.cachedOptions.findIndex(o => o.value === opt.value)
      opt.label = this.cachedOptions[index].label
      this.cachedOptions.splice(index, 1, opt)
    } else {
      this.cachedOptions.push(opt)
    }
  }

  handleDataRowReset () {
    this.ignoreInputValueChange = true
    this.$emit('data-row-reset', { fields: this.onChangeFields })
  }

  resetValue (): void {
    const row = this.form.dataSet?.rows?.[0] || (this.parentTable as any)?.targetRow
    if (!row) return
    const { field } = this.component
    const value = row.originalData[field]
    this.$set(this.form.dataRow, field, value)
    this.onChangeFields.forEach(f => {
      const value = row.originalData[f]
      this.$set(this.form.dataRow, f, value)
    })
  }

  handleClear () {
    // 在表格的快速编辑时，缓存好选中的选项，方便后续做改动使用
    if (this.parentTable) {
      (this.parentTable as any).cacheFieldOption({ field: this.field, option: {} })
    }
  }

  // 在表格的快速编辑时，缓存好选中的选项
  handleOptionClick (option: Option) {
    if (this.parentTable) {
      (this.parentTable as any).cacheFieldOption({ field: this.field, option: option.raw })
    }
  }

  switchPage (command: string): void {
    switch (command) {
      case 'first':
        if (this.prevButtonDisabled) return
        this.pageNo = 1
        break
      case 'prev':
        if (this.prevButtonDisabled) return
        --this.pageNo
        break
      case 'next':
        if (this.nextButtonDisabled) return
        ++this.pageNo
        break
      case 'last':
        if (this.nextButtonDisabled) return
        this.pageNo = this.pageCount
        break
    }
    this.handleRemoteSearchBase(this.queryString)
  }

  handleVisibleChange (status: boolean): void {
    this.visibleChange = status
    if (status) {
      this.options = []
      this.handleRemoteSearchBase('')
    }
  }

  // 点击下拉框的值时，触发了 el-select 的 change 事件
  handleChangeByElement (value: string | string[]) {
    this.ignoreInputValueChange = true
    // options 和 value 的顺序要对应
    const options: undefined | Option | Option[] = value instanceof Array
      ? value.map(o => this.cachedOptions.find(c => o === c.value)).filter(o => !!o) as any
      : this.cachedOptions.find(o => o.value === value)
    const getSelectedOption = () => {
      return options instanceof Array
        ? options.map(o => o.raw || {})
        : (options?.raw || {})
    }
    let setDisplayLabel = null
    // 过滤组中的select不用 displayTemplate显示，而是在onChange中提供setDisplayLabel的方式设置显示内容
    if (this.evaluatingBase === 'advancedFilter') {
      setDisplayLabel = (label: string) => {
        // 将显示值设置到过滤组对应的dataset中
        const searchRow = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
        searchRow && searchRow.setData(this.field + '_label', label)
      }
    }
    const args = new Args(this.context, { row: formatDataRow(this.form.dataRow), getSelectedOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow'), setDisplayLabel })
    // 默认自动为 displayField 赋值 labelFieldInDropdown 的内容
    if (this.component.displayField) {
      const setData = (dataRow: DataRow['currentData'], key: string, value: unknown) => {
        if (this.evaluatingBase === 'advancedFilter') {
          key = this.field + '_label'
        }
        if (typeof dataRow[key] !== 'undefined') {
          dataRow[key] = value
        } else {
          Vue.set(dataRow, key, value)
        }
      }
      if (value instanceof Array) {
        const displayFields = value.length ? (options as Option[]).map(o => o.content) : []
        setData(this.form.dataRow, this.component.displayField, displayFields)
      } else {
        const displayFields = value ? (options as Option | undefined)?.content : ''
        setData(this.form.dataRow, this.component.displayField, displayFields)
      }
    }
    this.runRunnableContent('onChange', { args, noWarning: true })

    // 在完成 onChange 事件后，回写 Option 的 label 值
    const labels = this.getTemplate()
    if (this.multiple && labels instanceof Array) {
      this.options.forEach((item) => {
        if (value.includes(item.value)) {
          const index = value.indexOf(item.value)
          item.label = labels[index]
          const cacheOption = this.cachedOptions.find(o => o.value === item.value)
          if (cacheOption) cacheOption.label = item.label
        }
      })
    } else if (!this.multiple && !(labels instanceof Array)) {
      // 如果 options 数量不为 0，说明是在点击下拉框的内容，此时让 option 的内容变更即可
      this.options.forEach((item) => {
        if (value === item.value) {
          item.label = labels as string
          const cacheOption = this.cachedOptions.find(o => o.value === item.value)
          if (cacheOption) cacheOption.label = item.label
        }
      })
    } else {
      console.error('[Select.vue] change 事件时 multiple 属性为 ' + this.multiple + ', 但是显示文本值却为 ' + labels)
    }
    this.$nextTick(() => this.$refs.select?.setSelected())

    if (this.evaluatingBase === 'advancedFilter') return
    const originalCurrentData = args.getCurrentRow()?.row.originalData
    const currentCurrentData = args.getCurrentRow()?.row.currentData
    if (originalCurrentData && currentCurrentData) {
      this.onChangeFields = []
      for (const k in currentCurrentData) {
        if ((currentCurrentData[k] !== originalCurrentData[k])) {
          this.onChangeFields.push(k)
        }
      }
    }
  }

  // 在表格内，因为 InputValue 的改变，引起自身触发 change 事件, 此时 value 只可以是非数组类型
  handleChangeByTable (value: string | string[]) {
    if (value instanceof Array) return

    // 当清空时，option 为一个 null 对象
    // 当在表格中，由于用户选择选项的操作和值变动的操作是独立的（快速编辑框中是一个独立的行拷贝）
    // 所以从表格中取出缓存的选项，传给 onchange
    const option = value
      ? (this.parentTable as any).cacheFieldOptions[this.field] || null
      : null
    // 如果变化时，发现 option 内数据不等于当前值，则说明自己其实没有变化
    if (option && option[this.valueFieldInDropDown] !== value) {
      return
    }
    if (option && option[this.valueFieldInDropDown]) {
      // 如果发生了变化，将该 option 组装进入 cacheOption
      const opt: Option = this.generateDropdownOption(option)
      this.setCachedOption(opt)
    }

    // 完成 onChange 等事件
    this.handleChangeByElement(value)
  }

  // 只有在通过按钮清除 QuickFilter 的数据时，才会触发
  handleChangeByQuickFilter (val: any) {
    this.handleChangeByElement(val)
  }

  handleBlur (e: any): void {
    this.component.onBlur && this.runRunnableContent('onBlur')
  }

  generateDropdownOption (raw: Record<string, any>) {
    return {
      label: '',
      content: this.getRowTemplate(raw),
      value: raw[this.valueFieldInDropDown],
      raw: raw
    }
  }

  @Bind()
  @Debounce(500)
  handleRemoteSearch (queryString: string, callback?: (options?: any) => void): void {
    this.handleRemoteSearchBase(queryString, callback)
  }

  handleRemoteSearchBase (queryString: string, callback?: (options?: any) => void): void {
    this.queryString = queryString
    this.searching = true
    const { onDropDownRetrieve, dropDownQuery, keywordFieldsInDropDown } = this.component
    // 两者都没写则提醒需要设置 dropdownQuery
    if (!onDropDownRetrieve && !dropDownQuery) {
      warnAttributeMissing('dropDownQuery', this.component.is)
      return
    } else if (dropDownQuery && !keywordFieldsInDropDown) {
      // 写了 dropDownQuery 还需要 keywordFieldsInDropDown 才能正常使用
      warnAttributeMissing('keywordFieldsInDropDown', this.component.is)
      return
    }
    let masterFilter = this.getFinalAttributeValue('dropDownMasterFilter', { args: this.args, type: attributeType.OBJECT })
    masterFilter = formatMasterFilter(masterFilter)
    const data: any = {
      getTotalBy: 'count',
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      keywordFilter: {
        value: queryString,
        matchMode: filterOperator.LIKE,
        fields: this.getFinalAttributeValue('keywordFieldsInDropDown', { type: attributeType.STRING_ARRAY })
      }
    }
    if (masterFilter) {
      data.masterFilter = masterFilter
    }

    const queryParams = this.getFinalAttributeValue('dropDownQueryParams', { args: this.args, type: attributeType.OBJECT })

    queryParams && (data.queryParams = queryParams)

    // TODO setDropDownData 变更为 applyDropDownDat，为保证兼容性，保留几个版本后进行删除
    const setDropDownData = (data: Record<string, any>) => {
      applyDropDownData(data)
    }

    // applyDropDownData 为变更后的内容
    const applyDropDownData = (data: Record<string, any>) => {
      // 为了避免 先后发送两次请求，第一次请求条件是 a 查询时间较长，第二次请求条件是 b 查询时间较短，结果 b 请求先返回结果，a 请求后返回结果，导致界面上查询条件是 b, 查询结果却是 a 的结果
      if (queryString === this.queryString) {
        this.options = (data.rows as any[]).map(this.generateDropdownOption)
        this.options.forEach(this.setCachedOption)
        this.total = data.total
        this.searching = false
        this.$nextTick(() => {
          const select = this.$refs.select
          const selectDropdown = select.$children[select.$children.length - 1] as any
          selectDropdown && selectDropdown.updatePopper()
        })
      }
    }

    const getQueryParams = () => {
      return {
        queryString,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
    }
    const failCallback = () => {
      this.options = []
      this.total = 0
      this.searching = false
    }
    const layoutName = this.editLayoutName || this.layoutName
    if (onDropDownRetrieve) {
      this.runRunnableContent('onDropDownRetrieve', { args: new Args(this.context, { row: formatDataRow(this.form.dataRow), getQueryParams, setDropDownData, applyDropDownData, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') }) })
    } else {
      Promise.all([
        queryByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: data }, { silent: true }),
        countByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: data }, { loadingLevel: 'none' })
      ]).then(([resQuery, resCount]) => {
        const data = {
          ...resQuery.data.data,
          ...resCount.data.data
        }
        applyDropDownData(data)
        callback && callback(data)
      }).catch(_ => failCallback())
    }
  }

  getRowTemplate (option: Record<string, any>): string {
    const { labelFieldInDropDown, dropDownRowTemplate } = this.component
    const getCurrentOption = () => option
    const args = new Args(this.context, { row: formatDataRow(this.form.dataRow), getCurrentOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    const result = dropDownRowTemplate
      ? _.template(this.getFinalAttributeValue('dropDownRowTemplate'))({ args, logwire })
      : labelFieldInDropDown
        ? option[labelFieldInDropDown]
        : ''
    return result
  }

  created (): void {
    this.cachedOptions = []

    const { dropDownRowTemplate, displayTemplate, labelFieldInDropDown, displayContent, displayField } = this.component
    if (!dropDownRowTemplate && !labelFieldInDropDown) {
      warnAttributeMissing('dropDownRowTemplate or labelFieldInDropdown', this.component.is)
    }
    if (displayTemplate && !displayContent) {
      warnDeprecatedAttribute('displayTemplate', 'displayContent', this.component.is)
    } else if (!displayContent && !displayField) {
      warnAttributeMissing('displayContent or displayField', this.component.is)
    }
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.getTemplate()
  }

  getTableInputValue () {
    let label: string | string[] = this.getTemplate()
    if (label instanceof Array) {
      label = label.join(',')
    }
    return label
  }
}
export default LwSelect
