































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import DatasetBoundComponent from '@/components/layout/DatasetBoundComponent'
import DataRow from '@/models/DataRow'
import DataSet from '@/models/DataSet'
import { DataRow as IDataRow, DataSet as IDataSet } from '@/types/data'
import { LayoutModule } from '@/store/modules/layout'
import { LayoutComponent } from '@/types/layout'
import { layoutStatus, rowPersist, SCREEN_SIZE_BREAK_POINTS } from '@/utils/const'
import { addResizeListener, removeResizeListener } from '@/utils/dom'
import _ from 'lodash'
import { formatDataRow, mergeDataRow, mergeDataRowCompactly } from '@/utils/data'
import { attributeForce2Number, attributeForce2Boolean } from '@/utils/layout'
import eventbus from '@/utils/event'
import { ElForm } from 'element-ui/types/form'

@Component({ name: 'LwInputForm' })
export default class LwInputForm extends DatasetBoundComponent {
  @Inject() popupSetFormInstance ?: (vm: LwInputForm) => void

  @Prop() asFilterForm!: boolean

  status = layoutStatus.EXPRESS_EDIT // 输入表单只有一个编辑的状态 不应该受到页面状态影响
  currentColumn = this.asFilterForm ? 2 : 12
  rowGroup = [] as Array<any>
  formRow = {}
  $refs !: {
    formWrapper: any,
    form: ElForm
  }

  get labelWidth (): string { return this.getFinalAttributeValue('labelWidth') }

  get labelPosition (): string { return this.getFinalAttributeValue('labelPosition') || 'top' }

  get dataSet (): DataSet | any {
    const data = LayoutModule.data[this.encodedLayoutName]
    return this.asFilterForm
      ? data?.systemState[this.dataSetName]?.quickFilter
      : data?.dataSet?.[this.dataSetName]
  }

  get dataRow (): DataRow {
    return this.asFilterForm
      ? this.dataSet?.data
      : this.dataSet?.rows?.[0].currentData
  }

  get isDataRowEmpty (): boolean {
    return _.isEmpty(this.dataRow)
  }

  // 设置 form 的列数， 默认 [1, 2, 3, 4, 5]
  getLayoutSetting () {
    // 这里的 asFilterForm 是针对于 lw-form 组件使用， lw-input-form 中没有此属性
    if (this.asFilterForm) return
    let { columns: attrColumns } = this.component
    const { formWrapper } = this.$refs
    const { clientWidth } = formWrapper
    // 预设四个尺寸节点
    const breakPoints = SCREEN_SIZE_BREAK_POINTS
    let columns = [1, 2, 3, 4, 5]
    if (attrColumns && attrColumns.indexOf(',') > -1) {
      columns = attrColumns.split(',')
    } else if (attrColumns) {
      attrColumns = parseInt(attrColumns)
      for (let i = 1; i < 6; i++) {
        let col = 1
        if ((attrColumns - 4 + i) > 0) col = attrColumns - 4 + i
        columns[i - 1] = col
      }
    }
    // 根据 form 的 clientWidth 判断当前应使用的列数
    if (clientWidth < breakPoints[0]) {
      this.currentColumn = columns[0]
    } else if (clientWidth > breakPoints[3]) {
      this.currentColumn = columns[4]
    } else {
      for (let i = 0; i < breakPoints.length; i++) {
        if (breakPoints[i] <= clientWidth && clientWidth <= breakPoints[i + 1]) {
          this.currentColumn = columns[i + 1]
          break
        }
      }
    }
  }

  getStyle (item: LayoutComponent, index: number): Record<string, any> {
    let colspan = 1
    if (item.colSpan) {
      colspan = attributeForce2Number('colSpan', item, 1)
    }
    if (colspan > this.currentColumn) colspan = this.currentColumn
    const percent = (colspan / this.currentColumn) * 100
    const colCount = Math.round(this.currentColumn / colspan)
    const isFirst = index % colCount === 0
    const isLast = (index + 1) % colCount === 0
    // quickFilter 中的表单，内边距与表单中内边距不一样
    const paddingLeft = this.asFilterForm ? (isFirst ? 0 : '8px') : '24px'
    const paddingRight = this.asFilterForm ? (isLast ? 0 : '8px') : '24px'
    const paddingBottom = this.asFilterForm ? '15px' : null
    return {
      flex: `0 0 ${percent}%`,
      maxWidth: `${percent}%`,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      paddingBottom
    }
  }

  /**
   * 准备表单内容
   * 1. 确定 form 的编辑状态
   * 2. 准备 dataRow
   * 3. 根据 endOfLine 对元素进行分行
   * [ { is: 'lw-text' }, { is: 'lw-select', endOfLine: true }, { is: 'lw'-text } ]
   * =>
   * [ [ { is: 'lw-text' }, { is: 'lw-select', endOfLine: true } ], [ { is: 'lw-text' } ] ]
   * */
  prepareForm () {
    const result = this.component.components?.reduce((result, current) => {
    // 准备 form 的 dataRow 字段
      if (!this.asFilterForm) this.$set(this.formRow, current.field, null)
      const group = _.last(result as any)
      if (group) {
        const lastOneOfGroup = _.last(group as any)
        // endLine 之后的元素另起一行
        if (attributeForce2Boolean('endOfLine', lastOneOfGroup as LayoutComponent, false)) {
          const nextGroup = []
          nextGroup.push(current)
          result.push(nextGroup)
        } else {
          (group as any).push(current)
        }
      } else {
        result.push([current])
      }
      return result
    }, [])
    if (this.dataSetName && !this.asFilterForm) {
      // 没有 dataRow 则新增一个
      if (!this.dataRow) {
        const dataRow = formatDataRow(this.formRow)
        dataRow.rowSequence = 0
        if (!this.dataSet.rows) Vue.set(this.dataSet, 'rows', [])
        this.dataSet.rows.push(dataRow)
      } else {
        // 已经存在则说明已经初始化过同 dataSet 名的组件，采取 merge
        mergeDataRowCompactly(this.dataSet.rows[0], { currentData: this.formRow, originalData: _.cloneDeep(this.formRow) } as IDataRow)
      }
    }
    this.rowGroup = result as Array<any>
  }

  validateData (): Promise<boolean> {
    return this.$refs.form
      ? this.$refs.form.validate()
      : Promise.resolve(true)
  }

  getSameDataSetForm (componentList: Array<Vue>): void {
    componentList.push(this)
  }

  mounted (): void {
    // 没有 dataRow 对象时，说明当前 dataSet 很有可能 rows 为空数组，需要添加一行数据
    // this.dataSetName && !this.dataRow && !this.asFilterForm && this.context.getOrCreateDataSet(this.dataSetName).addRow(this.formRow)
    addResizeListener(this.$refs.formWrapper, this.getLayoutSetting)
  }

  created () {
    this.prepareForm()
    LayoutModule.initFormExtraData({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName })
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.get-same-dataSet-form`, this.getSameDataSetForm)
    if (this.popupSetFormInstance) {
      this.popupSetFormInstance(this)
    }
  }

  beforeDestroy () {
    removeResizeListener(this.$refs.formWrapper, this.getLayoutSetting)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.get-same-dataSet-form`, this.getSameDataSetForm)
  }
}
