








































import LwInputForm from '@/components/layout/containers/InputForm.vue'
import logwire from '@/logwire'
import Layout from '@/models/Layout'
import { LayoutModule } from '@/store/modules/layout'
import { LayoutComponent, PopupLayoutConfig } from '@/types/layout'
import { layoutStatus } from '@/utils/const'
import { Component, Provide, Vue, ProvideReactive, InjectReactive } from 'vue-property-decorator'

@Component({ name: 'PopupLayoutOfSelect' })
class PopupLayoutOfSelect extends Vue {
  isShow = false
  config = {} as PopupLayoutConfig
  validateInstances: LwInputForm[] = []

  get layoutTitle () {
    let code = 'client.common.edit'
    if (this.config.layoutStatus === layoutStatus.NEW) {
      code = 'client.common.new'
    }
    return logwire.ui.getI18nMessage(code, [], 'core')
  }

  @Provide() popupLayout = true

  @ProvideReactive() get editLayoutName (): string | null {
    return this.config.editLayout
  }

  @ProvideReactive() get popupLayoutStatus (): string {
    return this.config.layoutStatus
  }

  @ProvideReactive() evaluatingBase = 'editRow'

  @Provide() popupSetFormInstance = this.setFormInstance

  show (config: PopupLayoutConfig) {
    this.config = config
    this.isShow = true
    this.validateInstances = []
  }

  close () {
    this.isShow = false
  }

  setFormInstance (vm: LwInputForm) {
    this.validateInstances.push(vm)
  }

  handleBeforeClose () {
    LayoutModule.removeDataSet({ layoutName: this.config.layoutName, dataSetName: this.config.editDataSet })
  }

  doEditLayoutCancel () {
    this.handleBeforeClose()
    this.isShow = false
  }

  async doEditLayoutOp () {
    const { editDataSet, layoutName } = this.config
    const rows = LayoutModule.data[layoutName].dataSet[editDataSet].rows
    // 表单校验
    for (const form of this.validateInstances) {
      const result = await form.validateData()
      if (result === false) return
    }
    const closeEditLayout = () => {
      this.handleBeforeClose()
      this.isShow = false
    }
    this.$emit('save', { rows, closeEditLayout })
  }
}

export default PopupLayoutOfSelect

