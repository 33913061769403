import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from '@/utils/data'
import { warnAttributeMissing } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import { attributeType } from '@/utils/const'
import _ from 'lodash'

interface Option {
  title: string;
  value: string;
  [propName: string]: any;
}

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get multiple (): boolean {
    return this.getFinalAttributeValue('multiple', { args: this.args, type: attributeType.BOOLEAN })
  }

  /**
   * 先从当前 namespace 下的 choices 中取，没有再从 core 下的 choices 中取
   * */
  get options (): Array<Option> {
    const namespace = this.context.getNamespace()
    const { category } = this.component
    if (!category) {
      warnAttributeMissing('category', this.component.is)
    }
    let namespacedCategory = category
    if (!category.includes('.')) {
      namespacedCategory = `${namespace}.${category}`
    }
    let choices = LocalModule.choices[namespacedCategory] || []
    // 根据 dimension 属性显示下拉内容 LocalModule.dimensionChoices
    const dimension = this.component.dimension
    if (dimension) {
      const dimensionChoices = LocalModule.dimensionChoices[dimension]
      choices = choices.filter((choice: Option) => dimensionChoices?.includes(choice.value))
    }
    const filteredChoices = this.getFinalAttributeValue('filteredChoices', { args: this.args, type: attributeType.STRING_ARRAY })
    return filteredChoices
      ? choices.filter((choice: Option) => filteredChoices.includes(choice.value))
      : choices
  }

  get inputValue (): any {
    const dataRow = this.form.dataRow
    const value = dataRow[this.component.field]
    return this.multiple
      ? value
        ? value.split(',')
        : []
      : value
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = this.multiple ? value.join(',') : value
  }

  get displayValue (): string {
    if (!this.inputValue) return ''
    const values = _.isArray(this.inputValue) ? this.inputValue : this.inputValue.split(',')
    return _.compact(this.options.map(option => {
      return values.includes(option.value) ? option.title : undefined
    })).join(',')
  }

  mounted () {
    const dimension = this.component.dimension
    if (!dimension) return
    LocalModule.getOrCreateDimensionChoices(dimension)
  }
}

export default DateBase
