// 列宽度拖动
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import { bind, unbind, getViewportOffset, hasClass, addClass, removeClass } from '../../../utils/dom'

@Component
export default class DragColumnWidth extends Vue {
  declare columns: any
  declare scrollWidth: number
  declare recordColumnsWidth: () => any

  draggingColumn!: any // 当前拖动的列
  allColumns !: any[] // 所有列

  isDragging = false // 是否正在拖动
  draggingStartX = 0 // 拖动开始横坐标
  draggingEndX = 0 // 拖动结束横坐标
  minColumnWidth = 40 // 列允许拖动后的最小宽度,

  $refs !: {
    table: Vue
  }

  // 需要修改，可能是控制的是前一列
  findColumn (columns: any[], field: string, prev: boolean) {
    let currentCol: any
    columns.forEach((col, index) => {
      if (col.children) {
        if (col.field === field) {
          currentCol = col.children[col.children.length - 1]
          if (prev) {
            currentCol = columns[index - 1]
          }
        } else {
          col.children.forEach((item: any, i: number) => {
            if (item.field === field) {
              currentCol = item
              if (prev) {
                currentCol = col.children[i - 1]
                if (i === 0) currentCol = columns[index - 1]
              }
            }
          })
        }
      } else if (col.field === field) {
        currentCol = col
        if (prev) {
          currentCol = columns[index - 1]
        }
      }
    })
    if (currentCol && currentCol.children) {
      currentCol = currentCol.children[currentCol.children.length - 1]
    }
    return currentCol
  }

  handleTitleMouseMove (event: MouseEvent, column: any) {
    let target: HTMLElement
    this.allColumns = this.columns

    if (this.isDragging) {
      this.setDragLinePosition(event)
    }

    // 最后一列不允许拖动
    /* if (this.internalColumns[this.internalColumns.length - 1].field === column) {
      return false;
      } */

    target = event.target as HTMLElement

    while (target && ((target.className && !hasClass(target, 'lw-table-th')) || !target.className)) {
      target = target.parentNode as HTMLElement
    }
    const rect = target.getBoundingClientRect()

    const bodyStyle = document.body.style
    // rect.right - event.pageX 鼠标范围在当前列内离右边10px中触发拖动
    if (Math.floor((rect.width + 20)) >= this.minColumnWidth && (event.pageX - rect.left < 5 || rect.right - event.pageX < 5)) {
      if (!this.isDragging) { // 拖动中不设置
        let prev = false
        if (event.pageX - rect.left < 5) {
          prev = true
        }
        this.draggingColumn = this.findColumn(this.columns, column.field, prev)
      }
      // 鼠标状态变成横向拖动状态
      bodyStyle.cursor = 'col-resize'
    } else {
      if (!this.isDragging) { // 拖动中不设置
        this.draggingColumn = null
        bodyStyle.cursor = ''
      }
    }
  }

  handleTitleMouseOut () {
    if (!this.isDragging) {
      document.body.style.cursor = ''
    }
  }

  handleTitleMouseDown (event: MouseEvent, column: any) {
    if (!this.draggingColumn) {
      return false
    }

    this.isDragging = true

    this.draggingStartX = event.clientX

    this.setDragLinePosition(event)

    document.onselectstart = function () {
      return false
    }
    document.ondragstart = function () {
      return false
    }

    bind(document, 'mousemove', this.handleDragMouseMove)
    bind(document, 'mouseup', this.handleDragMouseUp)
  }

  handleDragMouseMove (e: MouseEvent) {
    if (!this.isDragging) {
      return
    }

    this.setDragLinePosition(e)
  }

  setDragLinePosition (e: MouseEvent) {
    const { left: tableLeft, right2: tableRight } = getViewportOffset(this.$el)
    const dragLine = this.$el.querySelector('.v-table-drag-line') as HTMLElement | null
    const clientX = e.clientX
    if (!dragLine) return
    if (clientX - tableRight > 0) return
    const offset = clientX - this.draggingStartX
    if (this.draggingColumn.realWidth + offset <= this.minColumnWidth) {
      return
    }

    dragLine.style.left = (clientX - tableLeft) + 'px'
  }

  // 拖动时mouseup
  handleDragMouseUp (e: MouseEvent) {
    if (!this.isDragging) {
      return false
    }

    this.draggingEndX = e.clientX

    const differ = this.draggingEndX - this.draggingStartX

    // 差值大于1才处理
    if (Math.abs(differ) > 1) {
      const { draggingColumn } = this

      if (draggingColumn.realWidth + differ < this.minColumnWidth) {
        draggingColumn.realWidth = this.minColumnWidth
      } else {
        draggingColumn.realWidth += differ
      }
      // 找到当前拖动和最后空白列的对应 dom 元素
      const cols = this.$refs.table.$el.getElementsByTagName('col')
      let num = 0
      this.allColumns.forEach(item => {
        if (item.children) {
          item.children.forEach((i: { key: any }) => {
            if (i.key === draggingColumn.key) {
              cols[num].style.width = draggingColumn.realWidth + 'px'
            }
            num++
          })
        } else {
          if (item.key === draggingColumn.key) {
            cols[num].style.width = draggingColumn.realWidth + 'px'
          }
          if (item.key === 'tail') {
            let totalWidth = 0
            this.allColumns.filter(item => item.key !== 'tail').forEach(item => {
              if (item.children) {
                item.children.forEach((i: { realWidth: number }) => {
                  totalWidth += i.realWidth
                })
              } else {
                totalWidth += item.realWidth
              }
            })
            const occupiedWidth = (this.$refs.table.$el as HTMLElement).offsetWidth - 35
            if (occupiedWidth > totalWidth) {
              item.realWidth = occupiedWidth - totalWidth
              this.scrollWidth = occupiedWidth
            } else {
              item.realWidth = 0
              this.scrollWidth = totalWidth
            }
            cols[num].style.width = item.realWidth + 'px'
          }
          num++
        }
      })
    }
    // 拖动之后记录所有列列宽
    this.recordColumnsWidth()
    this.draggingColumn = null
    document.body.style.cursor = ''
    this.isDragging = false

    document.onselectstart = function () {
      return true
    }
    document.ondragstart = function () {
      return true
    }

    unbind(document, 'mousemove', this.handleDragMouseMove)
    unbind(document, 'mouseup', this.handleDragMouseUp)
  }
}
