




import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ResizeObserver from 'resize-observer-polyfill'
import QueriableByFilterComponent from '../QueriableByFilterComponent'
import * as echarts from 'echarts'
import { DataRow, DataSet as IDataSet } from '@/types/data'
import Args from '@/models/Args'
import { MapEchartsInstance } from '@/logwire/ui'
import { getQueryFilter } from '@/utils/data'
import { queryByFilter } from '@/http/api'
import { LayoutModule } from '@/store/modules/layout'
import { px2rem } from '@/utils/dom'

/**
 * 1. [ ] 类别字段值先用单行处理，然后用多行
 * 2. [ ] 图例字段名先用单行处理，然后多行
 * FIXME: 多个类别的处理是不一样的，1个类别意味着单独的 xAxis, 2个类别意味着有多个 series, 会有3个类别吗？如果没有开发者写了要提示吗？
 * FIXME: 多个图例字段，同样怎么处理？多图例往往伴随着多类别，2个图例字段，意味着每个1级类别下，的2级类别，都是不同颜色标记的
 * 多类别字段时，将多个该类别的值组合为一个新的值
 * `
 * 当 categoryFields 为 'name,type',
 * 数据为 [{ name: '小王', type: '数学', grade: 123 }, { name: '小王', type: '语文', grade: 59 }] 时
 * 此时显示到页面上的类别轴为 '小王\n数学', '小王\n语文'
 * 他们会作为两组数据存在，而不是 '小王' 这组数据下的两个分组存在
 * `
 */
@Component({ name: 'LwEcharts' })
export default class LwEcharts extends QueriableByFilterComponent {
  echartInstance !: echarts.ECharts
  observer !: ResizeObserver

  options = {}

  $refs !: {
    echarts: HTMLElement
  }

  // 普通图表属性
  get type (): 'bar' | 'line' | 'pie' | 'custom' {
    return this.getFinalAttributeValue('type')
  }

  get categoryFields (): string {
    return this.getFinalAttributeValue('categoryFields')
  }

  get categoryTitle (): string {
    return this.getFinalAttributeValue('categoryTitle')
  }

  get valueField (): string {
    return this.getFinalAttributeValue('valueField')
  }

  get valueTitle (): string {
    return this.getFinalAttributeValue('valueTitle')
  }

  get valueAggregationFunction (): 'sum' | 'avg' | 'min' | 'max' {
    return this.getFinalAttributeValue('valueAggregationFunction') || 'sum'
  }

  get valueScale (): number {
    return +this.getFinalAttributeValue('valueScale') || 2
  }

  // 图例字段必须在选择完类别字段之后，从剩余的可以选择的字段中挑选
  // 然后再从 排除了 serieFields 之后的字段中，进行聚合函数的操作
  get serieFields () {
    return this.getFinalAttributeValue('serieFields') || ''
  }

  get categoryAxis (): 'x' | 'y' {
    return this.getFinalAttributeValue('categoryAxis') || 'x'
  }

  // 自定义图表属性
  get name () {
    return this.getFinalAttributeValue('name')
  }

  @Watch('dataSet.rows', { deep: true })
  onDataSetRowChange (newRows: DataRow[]) {
    if (!newRows) return
    // 首先编辑结构，形成 { category: '', serie: '', value: '' } 的结构
    const { categoryFields, serieFields, valueField } = this
    const fn = (row: DataRow, fields: string, space: string) => { // 根据传入的字段，返回按照 space 分隔的字符
      if (fields === '') return ''
      else if (fields === undefined) return ''
      return fields.split(',').reduce((p, n) => {
        return p + space + row.currentData[n]
      }, '').replace(space, '')
    }

    // 这个时候 datas 可能存在相同的 category,serie 的元素，这代表还需要对 value 进行聚合处理
    let datas = newRows.map(o => {
      return {
        category: fn(o, categoryFields, '\n'),
        serie: fn(o, serieFields, '-'),
        value: fn(o, valueField, ',').split(',').reduce((p, n) => p + +n, 0)
      }
    })
    if (this.type === 'bar' || this.type === 'line') {
      datas = this.getResultAfterAggregation(datas) // 完成聚合函数处理之后
      const categoryKey = this.categoryAxis === 'x' ? 'xAxis' : 'yAxis'
      const valueKey = this.categoryAxis === 'x' ? 'yAxis' : 'xAxis'
      const categories = [...new Set(datas.map(o => o.category)).values()]
      const series = [...new Set(datas.map(o => o.serie)).values()].map(s => {
        return {
          name: s,
          label: 'inherit',
          data: categories.map(c => datas.find(d => d.category === c && d.serie === s)?.value || 0).map(o => {
            return { value: o, label: { show: true, color: '#000' } }
          }),
          type: this.type
        }
      })
      const option = {
        tooltip: {
          trigger: 'item'
        },
        [categoryKey]: {
          type: 'category',
          data: categories.map(o => {
            return {
              value: o,
              textStyle: {
                width: 50,
                overflow: 'truncate'
              }
            }
          }),
          name: this.categoryTitle,
          axisLabel: {
            interval: 0
          }
        },
        [valueKey]: {
          type: 'value',
          name: this.valueTitle
        },
        series,
        legend: {
          data: series.map(o => o.name)
        }
      }
      this.runRunnableContent('beforeSetOption', { args: new Args(this.context, { getOption () { return option } }) })
      this.echartInstance.setOption(option)
    } else if (this.type === 'pie') {
      datas = this.getResultAfterAggregation(datas.map(o => {
        return {
          ...o,
          serie: ''
        }
      }))
      const option = {
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          radius: '50%',
          data: datas.map(o => {
            return {
              value: o.value,
              name: o.category
            }
          })
        }]
      }
      this.runRunnableContent('beforeSetOption', { args: new Args(this.context, { getOption () { return option } }) })
      this.echartInstance.setOption(option)
    } else {
      // 对于自定义图形，就不需要自动处理 dateset 数据
    }
  }

  mounted () {
    this.echartInstance = echarts.init(this.$refs.echarts)
    if (this.name) MapEchartsInstance.set(this.name, this.echartInstance)

    this.initRetrieve()

    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach(entry => {
        this.handleResizeListener(entry)
      })
    })
    this.observer.observe(this.$el)
  }

  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  handleResizeListener (entry: ResizeObserverEntry) {
    if (this.echartInstance) {
      this.echartInstance.resize({
        width: entry.contentRect.width,
        height: entry.contentRect.height
      })
    }
  }

  // 宽、高 的形式都只支持三种 500、500px 或者 50%
  getWidthOrHeight (value: string): string {
    if (value.endsWith('px')) {
      value = px2rem(parseInt(value.replace('px', '')))
    } else if (!value.endsWith('%')) {
      // 不以 % 或 px 处理，都当作纯数字处理
      value = px2rem(parseInt(value))
    }
    return value
  }

  getResultAfterAggregation (datas: { category: string, serie: string, value: number }[]) {
    const map: Map<string, { category: string, serie: string, value: number[] }> = new Map()
    datas.forEach(item => {
      const key = `category: ${item.category}, serie: ${item.serie}`
      if (map.has(key)) {
        const v = map.get(key) as { category: string, serie: string, value: number[] }
        v.value.push(item.value)
      } else {
        map.set(key, { category: item.category, serie: item.serie, value: [item.value] })
      }
    })
    const result: { category: string, serie: string, value: number }[] = []
    for (const item of map.values()) {
      const { category, serie, value } = item
      const v = { category, serie, value: 0 }
      if (this.valueAggregationFunction === 'sum') {
        v.value = value.reduce((p, n) => p + n)
      } else if (this.valueAggregationFunction === 'avg') {
        v.value = value.reduce((p, n) => p + n) / value.length
      } else if (this.valueAggregationFunction === 'min') {
        v.value = Math.min(...value)
      } else {
        v.value = Math.max(...value)
      }
      if (v.value !== Math.floor(v.value)) {
        v.value = Math.trunc(v.value * Math.pow(10, this.valueScale)) / Math.pow(10, this.valueScale)
      }
      result.push(v)
    }
    return result
  }

  handleDataSetRetrieve (): void {
    if (this.queryMasterFilter) {
      LayoutModule.addDataSetMasterFilter({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        masterFilter: this.queryMasterFilter
      })
    }
    if (this.queryParams) {
      LayoutModule.addDataSetQueryParams({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        queryParams: this.queryParams
      })
    }
    const applyDataToCurrentDataSet = ({ rows }: { rows: Array<Record<string, any>> }) => {
      const dataSetName = this.dataSetName
      const dataSet = {
        dataSetName,
        rows
      } as IDataSet
      LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: Object.assign(dataSet, { dataSetName }) })
    }
    const proceed = () => {
      const { layoutName, encodedLayoutName, dataSetName } = this
      // 拼凑查询条件，如果没有写lw-pagination，默认查询所有数据
      const ds = LayoutModule.data[encodedLayoutName].systemState[dataSetName]
      const filter = getQueryFilter(ds)

      queryByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: this.query, filter }).then(res => {
        const { rows } = res.data.data
        applyDataToCurrentDataSet({ rows })

        this.component.afterRetrieve && this.runRunnableContent('afterRetrieve', { args: new Args(this.context, { getCurrentDataSet: this.getCurrentDataSet }) })
      }, e => { console.error(e) })
    }
    if (this.component.onRetrieve) {
      // 写了 onRetrieve 则不在执行 beforeRetrieve 与 afterRetrieve
      // before 和 after 的逻辑由开发者在 onRetrieve 中自行完成
      this.runRunnableContent('onRetrieve', { args: new Args(this.context, { getCurrentDataSet: this.getCurrentDataSet, applyDataToCurrentDataSet }) })
    } else {
      this.beforeBehaviorCommon('beforeRetrieve', proceed, { getCurrentDataSet: this.getCurrentDataSet })
    }
  }
}
