



















import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { warnAttributeMissing } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import { attributeType } from '@/utils/const'

interface Option {
  title: string
  value: string
  [propName: string]: any
}

@Component({ name: 'LwRadio' })
export default class LwRadio extends FormControlComponent {
  get inputValue () {
    const value = this.form.dataRow[this.field]
    return value
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = value
  }

  get options (): Array<Option> {
    const namespace = this.context.getNamespace()
    const { category } = this.component
    if (!category) {
      warnAttributeMissing('category', this.component.is)
    }
    let namespacedCategory = category
    if (!category.includes('.')) {
      namespacedCategory = `${namespace}.${category}`
    }
    let choices = LocalModule.choices[namespacedCategory] || []
    // 根据 dimension 属性显示下拉内容 LocalModule.dimensionChoices
    const dimension = this.component.dimension
    if (dimension) {
      const dimensionChoices = LocalModule.dimensionChoices[dimension]
      choices = choices.filter((choice: Option) => dimensionChoices?.includes(choice.value))
    }
    const filteredChoices = this.getFinalAttributeValue('filteredChoices', { args: this.args, type: attributeType.STRING_ARRAY })
    return filteredChoices
      ? choices.filter((choice: Option) => filteredChoices.includes(choice.value))
      : choices
  }

  get displayValue (): string {
    if (!this.inputValue) return ''
    const values = _.isArray(this.inputValue) ? this.inputValue : this.inputValue.split(',')
    return _.compact(this.options.map(option => {
      return values.includes(option.value) ? option.title : undefined
    })).join(',')
  }

  get valueChanged (): boolean {
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const { field } = this.component
    return row.currentData[field] !== row.originalData[field]
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.displayValue
  }

  mounted () {
    const dimension = this.component.dimension
    if (!dimension) return
    LocalModule.getOrCreateDimensionChoices(dimension)
  }
}
