export interface partitionFilter {
  beginDate: string
  endDate: string
  name: string
}

export default class PartitionFilter {
  #filter !: partitionFilter

  constructor (filter: partitionFilter) {
    this.#filter = filter
  }

  getBeginDate () {
    return this.#filter.beginDate
  }

  setBeginDate (beginDate: string) {
    this.#filter.beginDate = beginDate
    return this
  }

  getEndDate () {
    return this.#filter.endDate
  }

  setEndDate (endDate: string) {
    this.#filter.endDate = endDate
    return this
  }

  getName () {
    return this.#filter.name
  }

  setName (name: string) {
    this.#filter.name = name
    return this
  }
}
