import { LayoutModule } from '@/store/modules/layout'
import { IConditionGroup } from '@/types/data'
import { totalByType } from '@/utils/const'
import { formatMasterFilter, getQueryFilter } from '@/utils/data'
import { warnDeprecatedApi } from '@/utils/layout'
import Vue from 'vue'
import Condition from './Condition'
import ConditionGroup from './ConditionGroup'
import KeywordFilter, { keywordFilter } from './KeyWordFilter'
import PartitionFilter, { partitionFilter } from './PartitionFilter'
import QuickFilter, { quickFilter } from './QuickFilter'

interface filter {
  keywordFilter?: keywordFilter
  pageSize: number
  pageNo: number
  getTotalBy: totalByType
  queryParams?: Record<string, any>
  masterFilter?: IConditionGroup
  advancedFilter?: IConditionGroup
  quickFilter?: quickFilter
  partitionFilter?: partitionFilter
  orderBy: Array<{ field: string, order: 'esc' | 'desc' }>
}

export default class Filter {
  #layoutName!: string
  #dataSetName!: string
  #filter!: filter

  constructor (layoutName: string, dataSetName: string, filter: filter) {
    this.#layoutName = layoutName
    this.#dataSetName = dataSetName
    this.#filter = filter
  }

  get filter (): filter {
    return getQueryFilter(this.#filter) as unknown as filter
  }

  /**
   * @deprecated
   */
  getKeyWordFilter (): KeywordFilter | null {
    warnDeprecatedApi('Filter.getKeyWordFilter', 'DataSet.getKeyWordFilter')
    return this.#filter.keywordFilter ? new KeywordFilter(this.#filter.keywordFilter) : null
  }

  getQueryParams (): Record<string, any> | null {
    warnDeprecatedApi('Filter.getQueryParams', 'DataSet.getQueryParams')
    return this.filter.queryParams || null
  }

  setQueryParams (params: Record<string, any>): Filter {
    warnDeprecatedApi('Filter.setQueryParams', 'DataSet.setQueryParams')
    this.#filter.queryParams = params
    return this
  }

  getMasterFilter (): ConditionGroup | null {
    warnDeprecatedApi('Filter.getMasterFilter', 'DataSet.getMasterFilter')
    return this.#filter.masterFilter ? (
      new ConditionGroup(this.#filter.masterFilter)
    ) : null
  }

  setMasterFilter (masterFilter: ConditionGroup | Condition): Filter {
    warnDeprecatedApi('Filter.setMasterFilter', 'DataSet.setMasterFilter')
    const result = formatMasterFilter(masterFilter)
    if (masterFilter) {
      this.#filter.masterFilter = result
    }
    return this
  }

  getQuickFilter (): QuickFilter | null {
    warnDeprecatedApi('Filter.getQuickFilter', 'DataSet.getQuickFilter')
    return this.#filter.quickFilter ? (
      new QuickFilter(this.#filter.quickFilter)
    ) : null
  }

  getAdvancedFilter (): ConditionGroup | null {
    warnDeprecatedApi('Filter.getAdvancedFilter', 'DataSet.getAdvancedFilter')
    return this.#filter.advancedFilter ? (
      new ConditionGroup(this.#filter.advancedFilter)
    ) : null
  }

  getPartitionFilter (): PartitionFilter | null {
    warnDeprecatedApi('Filter.getPartitionFilter', 'DataSet.getPartitionFilter')
    return this.filter.partitionFilter ? (
      new PartitionFilter(this.filter.partitionFilter)
    ) : null
  }
}
