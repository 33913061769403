import { attributeRunType } from './const'

const BaseComponent = {
  title: {
    type: attributeRunType.AUTO,
    require: false
  },
  resourceCode: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  visible: {
    type: attributeRunType.AUTO,
    require: false
  }
}

const DatasetBoundComponent = {
  ...BaseComponent,
  dataSet: {
    type: attributeRunType.ONLY_STATIC,
    require: true
  },
  subDataSet: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  headerDataSet: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  }
}

const QueriableComponent = {
  ...DatasetBoundComponent,
  query: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  retrieveOnLayoutLoad: {
    type: attributeRunType.AUTO,
    require: false
  },
  onRetrieve: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  beforeRetrieve: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  afterRetrieve: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  beforeNew: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  afterNew: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  beforeEdit: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  afterEdit: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  onDelete: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  beforeDelete: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  afterDelete: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  onSave: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  beforeSave: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  afterSave: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  editLayout: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  editPanel: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  queryMasterFilter: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  queryParams: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  }
}

const FormControlComponent = {
  ...BaseComponent,
  field: {
    type: attributeRunType.ONLY_STATIC,
    require: true
  },
  enabled: {
    type: attributeRunType.AUTO,
    require: false
  },
  required: {
    type: attributeRunType.AUTO,
    require: false
  },
  colSpan: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  endOfLine: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  quickFilterField: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  quickFilterOperator: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  quickFilterSubQuery: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  quickFilterSubQueryFilterField: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  quickFilterSubQueryFilterOperator: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  group: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  widthRatio: {
    type: attributeRunType.ONLY_STATIC,
    require: false
  },
  onFocus: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  onBlur: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  onEnter: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  onChange: {
    type: attributeRunType.ONLY_SCRIPT,
    require: false
  },
  autoFocus: {
    type: attributeRunType.AUTO,
    require: false
  },
  help: {
    type: attributeRunType.AUTO,
    require: false
  }
}

// 组件属性
export default {
  'lw-region': {
    ...BaseComponent,
    inlineTitle: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-region-2': {
    ...BaseComponent,
    ratio: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-region-3': {
    ...BaseComponent,
    ratio: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-tabs': {
    ...BaseComponent,
    activeTabName: {
      type: attributeRunType.AUTO,
      require: false
    },
    tabPosition: {
      type: attributeRunType.AUTO,
      require: false
    },
    onTabClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-tab-pane': {
    ...BaseComponent,
    name: {
      type: attributeRunType.AUTO,
      require: false
    },
    enabled: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-form': {
    ...QueriableComponent,
    enabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    columns: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-input-form': {
    ...DatasetBoundComponent,
    columns: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-card': {
    ...BaseComponent,
    shadow: {
      type: attributeRunType.AUTO,
      require: false
    },
    onClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-section': {
    ...BaseComponent
  },
  'lw-board': {
    ...BaseComponent,
    gutter: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    columns: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-wrapper': {
    ...BaseComponent,
    style: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-text': {
    ...FormControlComponent,
    placeholder: {
      type: attributeRunType.AUTO,
      require: false
    },
    maxLength: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    prepend: {
      type: attributeRunType.AUTO,
      require: false
    },
    append: {
      type: attributeRunType.AUTO,
      require: false
    },
    password: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-textarea': {
    ...FormControlComponent,
    placeholder: {
      type: attributeRunType.AUTO,
      require: false
    },
    maxLength: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    rows: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-select': {
    ...FormControlComponent,
    dropDownQuery: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    onDropDownRetrieve: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownPageSizes: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    keywordFieldsInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownMasterFilter: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    displayTemplate: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    valueFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRowTemplate: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownHeaderTemplate: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-auto-complete': {
    ...FormControlComponent,
    dropDownQuery: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    onDropDownRetrieve: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownPageSizes: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    keywordFieldsInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownMasterFilter: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    valueFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRowTemplate: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownHeaderTemplate: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-cascader': {
    ...FormControlComponent,
    levels: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownQuery1: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownQuery2: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownQuery3: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownQuery4: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownQuery5: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction1: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction2: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction3: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction4: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction5: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownMasterFilter1: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownMasterFilter2: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownMasterFilter3: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownMasterFilter4: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownMasterFilter5: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams1: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams2: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams3: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams4: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams5: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    valueFieldInDropDown1: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    valueFieldInDropDown2: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    valueFieldInDropDown3: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    valueFieldInDropDown4: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    valueFieldInDropDown5: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown1: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown2: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown3: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown4: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown5: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    parentIdFieldInDropDown2: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    parentIdFieldInDropDown3: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    parentIdFieldInDropDown4: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    parentIdFieldInDropDown5: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-recursive': {
    ...FormControlComponent,
    dropDownQuery: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownRetrieveAction: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    dropDownMasterFilter: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dropDownQueryParams: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    valueFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    labelFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    parentIdFieldInDropDown: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-choice': {
    ...FormControlComponent,
    category: {
      type: attributeRunType.ONLY_STATIC,
      require: true
    },
    multiple: {
      type: attributeRunType.AUTO,
      require: false
    },
    filteredChoices: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dimension: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-radio': {
    ...FormControlComponent,
    category: {
      type: attributeRunType.ONLY_STATIC,
      require: true
    },
    filteredChoices: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    dimension: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-checkbox': {
    ...FormControlComponent
  },
  'lw-switch': {
    ...FormControlComponent
  },
  'lw-date': {
    ...FormControlComponent,
    type: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-time': {
    ...FormControlComponent,
    type: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    selectStart: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectEnd: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectStep: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectMin: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectMax: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectableRange: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-date-time': {
    ...FormControlComponent,
    type: {
      type: {
        type: attributeRunType.ONLY_STATIC,
        require: false
      }
    },
    defaultTime: {
      type: {
        attributeRunType: attributeRunType.AUTO,
        require: false
      }
    }
  },
  'lw-timestamp': {
    ...FormControlComponent
  },
  'lw-upload-file': {
    ...FormControlComponent,
    imageOnly: {
      type: attributeRunType.AUTO,
      require: false
    },
    multiple: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    maxHeight: {
      type: attributeRunType.AUTO,
      require: false
    },
    accept: {
      type: attributeRunType.AUTO,
      require: false
    },
    beforeUpload: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    afterUpload: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    beforeRemove: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    afterRemove: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    limit: {
      type: attributeRunType.AUTO,
      require: false
    },
    singleLineDisplay: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    clipImageOnUpload: {
      type: attributeRunType.AUTO,
      require: false
    },
    compressImageOnUpload: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    compressImageSizeThreshold: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-button': {
    ...BaseComponent,
    onClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    enabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    colorScheme: {
      type: attributeRunType.AUTO,
      require: false
    },
    size: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-button-group': {
    ...BaseComponent,
    primary: {
      type: attributeRunType.AUTO,
      require: false
    },
    size: {
      type: attributeRunType.AUTO,
      require: false
    },
    groupClickable: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-toolbar': {
    ...BaseComponent,
    align: {
      type: attributeRunType.AUTO,
      require: false
    },
    stickyPosition: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-search-bar': {
    ...DatasetBoundComponent,
    onSearch: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    filterGroupEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    keywordEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    keywordFields: {
      type: attributeRunType.AUTO,
      require: false
    },
    keywordPlaceholder: {
      type: attributeRunType.AUTO,
      require: false
    },
    evaluateScriptInTableControls: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-pagination': {
    ...DatasetBoundComponent,
    pageSizes: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    defaultPageSize: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    content: {
      type: attributeRunType.AUTO,
      require: false
    },
    getTotalBy: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-table': {
    ...QueriableComponent,
    aggregationFields: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    newable: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    viewable: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    editable: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    deletable: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    onRowFirstTimeExpand: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    showIndex: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    selectionMode: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    height: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    rowColorScheme: {
      type: attributeRunType.AUTO,
      require: false
    },
    rowEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    rowSelectable: {
      type: attributeRunType.AUTO,
      require: false
    },
    rowSelected: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectAllEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    crossPageSelectAllEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    rowSpanFields: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    onRowSelectChange: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onRowClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onRowMouseOver: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onRowMouseLeave: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    enabled: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-html': {
    ...BaseComponent
  },
  'lw-dynamic': {
    ...BaseComponent,
    name: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
    // components: {
    //   type: attributeRunType.ONLY_SCRIPT,
    //   require: false
    // }
  },
  'lw-tags': {
    ...BaseComponent,
    content: {
      type: attributeRunType.AUTO,
      require: true
    },
    colorScheme: {
      type: attributeRunType.AUTO,
      require: false
    },
    size: {
      type: attributeRunType.AUTO,
      require: false
    }
  },
  'lw-breadcrumb': {
    ...BaseComponent,
    item: {
      type: attributeRunType.ONLY_SCRIPT,
      require: true
    },
    onItemClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-label': {
    ...BaseComponent,
    content: {
      type: attributeRunType.AUTO,
      require: true
    },
    colorScheme: {
      type: attributeRunType.AUTO,
      require: false
    },
    fontSize: {
      type: attributeRunType.AUTO,
      require: false
    },
    maxLines: {
      type: attributeRunType.AUTO,
      require: false
    },
    onClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-image': {
    ...BaseComponent,
    width: {
      type: attributeRunType.AUTO,
      require: false
    },
    height: {
      type: attributeRunType.AUTO,
      require: false
    },
    scale: {
      type: attributeRunType.AUTO,
      require: false
    },
    srcType: {
      type: attributeRunType.AUTO,
      require: false
    },
    previewable: {
      type: attributeRunType.AUTO,
      require: false
    },
    src: {
      type: attributeRunType.AUTO,
      require: true
    },
    onClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    }
  },
  'lw-list': {
    ...QueriableComponent,
    aggregationFields: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    selectionMode: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    height: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    itemColorScheme: {
      type: attributeRunType.AUTO,
      require: false
    },
    itemSelectable: {
      type: attributeRunType.AUTO,
      require: false
    },
    selectAllEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    crossPageSelectAllEnabled: {
      type: attributeRunType.AUTO,
      require: false
    },
    onItemSelectChange: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onItemClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onItemMouseOver: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onItemMouseLeave: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    gutterVertical: {
      type: attributeRunType.AUTO,
      require: false
    },
    gutterHorizontal: {
      type: attributeRunType.AUTO,
      require: false
    },
    columns: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    }
  },
  'lw-tree': {
    ...BaseComponent,
    dataTree: {
      type: attributeRunType.ONLY_STATIC,
      require: true
    },
    onRetrieve: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    showFilter: {
      type: attributeRunType.AUTO,
      require: false
    },
    filterLogic: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    selectionMode: {
      type: attributeRunType.AUTO,
      require: false
    },
    height: {
      type: attributeRunType.ONLY_STATIC,
      require: false
    },
    nodeSelectable: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onNodeSelectChange: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onNodeClick: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onNodeExpand: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    onNodeCollapse: {
      type: attributeRunType.ONLY_SCRIPT,
      require: false
    },
    expandOnRetrieve: {
      type: attributeRunType.AUTO,
      require: false
    },
    template: {
      type: attributeRunType.AUTO,
      require: false
    }
  }
}
