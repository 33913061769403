import { filterOperator } from '@/utils/const'

export interface keywordFilter {
  fields: Array<string>,
  matchMode: filterOperator,
  value: string | number
}

export default class KeywordFilter {
  #filter!: keywordFilter

  constructor (filter: keywordFilter) {
    this.#filter = filter
  }

  get filter (): keywordFilter {
    return JSON.parse(JSON.stringify(this.#filter))
  }

  getValue (): string | number {
    return this.filter.value
  }

  setValue (value: string): KeywordFilter {
    this.#filter.value = value
    return this
  }

  getFields (): Array<string> {
    return this.filter.fields
  }

  setFields (fields: string[]): KeywordFilter {
    this.#filter.fields = fields
    return this
  }
}
