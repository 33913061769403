





























import BaseComponent from '@/components/layout/BaseComponent'
import { Component } from 'vue-property-decorator'
import clickoutside from 'element-ui/src/utils/clickoutside'
import { layoutStatus } from '@/utils/const'

@Component({ name: 'MoreOpsForTable', directives: { clickoutside } })
export default class MoreOpsForTable extends BaseComponent {
  moreOpts: any = null
  currentRow: any = null
  showView = false
  showEdit = false
  showClone = false
  showDelete = false

  deleteRow () {
    this.$emit('delete-row', { row: this.currentRow })
    this.disappear()
  }

  editRow () {
    this.$emit('edit-row', layoutStatus.EDIT, this.currentRow)
    this.disappear()
  }

  viewRow () {
    this.$emit('view-row', this.currentRow)
    this.disappear()
  }

  cloneRow () {
    this.$emit('clone-row', this.currentRow)
    this.disappear()
  }

  disappear () {
    this.$emit('disappear')
  }
}
