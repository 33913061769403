


























import { Component } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import DataRow from '@/models/DataRow'

@Component({ name: 'LwSwitch' })
class LwSwitch extends FormControlComponent {
  get inputValue (): any {
    const dataRow = this.form.dataRow
    return !!dataRow[this.field] || false
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = value
  }

  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const originalValue = row.originalData[this.component.field]
    if (!originalValue) {
      return this.inputValue === true
    }
    return this.inputValue !== row.originalData[this.component.field]
  }

  mounted () {
    // 如果在 mounted 时，发现数据集中没有赋值过，那么默认赋值 false, 避免无法通过表单验证必填
    // 放在 Mounted 阶段的原因，是 InputForm 在 created 阶段才会执行 prepareForm 方法
    if (this.form.dataRow[this.field] === '' && !this.tableRow) {
      this.inputValue = false
    }
  }
}
export default LwSwitch
