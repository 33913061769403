import ComponentStructure from '../basic'

export default class I18nForLwRegion2 extends ComponentStructure {
  name = 'lw-region-2'
  properties = []

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    return [].concat(this.component.left || []).concat(this.component.right || [])
  }
}
