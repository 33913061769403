import _ from 'lodash'
import { completionFormat, isPositiveInteger } from '@/utils/data'

export default class LocalTime {
  #hour!: number
  #minute!: number
  #second!: number
  #millisecond!: number

  get hour (): number {
    return this.#hour
  }

  get minute ():number {
    return this.#minute
  }

  get second (): number {
    return this.#second
  }

  get millisecond (): number {
    return this.#millisecond
  }

  constructor (hour: number, minute: number, second: number, millisecond = 0) {
    if (!(_.isInteger(hour) && hour >= 0) || !(_.isInteger(minute) && minute >= 0) || !(_.isInteger(second) && second >= 0)) {
      throw new Error('Invalid time')
    }
    if ((hour < 0 || hour > 23) ||
      (minute < 0 || minute > 60) ||
      (second < 0 || second > 60)
    ) {
      throw new Error('Invalid time')
    }
    this.#hour = hour
    this.#minute = minute
    this.#second = second
    this.#millisecond = millisecond * 1
  }

  static of (hour: number, minute: number, second: number, millisecond = 0): LocalTime {
    return new LocalTime(hour, minute, second, millisecond)
  }

  static parse (timeStr: string): LocalTime {
    // timeString 支持 hover:minute:second 或者 hover:minute:second.millisecond 这种格式
    const reg = /^(\d{1,2}):(\d{1,2}):(\d{1,2})(\.\d{1,3})?$/
    if (reg.test(timeStr)) {
      const timeArr = timeStr.split(':')
      const hour = Number(timeArr[0].startsWith('0') ? timeArr[0].replace(/^0/, '') : timeArr[0])
      const minute = Number(timeArr[1].startsWith('0') ? timeArr[1].replace(/^0/, '') : timeArr[1])
      const second = Number(timeArr[2].startsWith('0') ? timeArr[2].replace(/^0/, '') : timeArr[2])
      const millisecond = Number(timeArr[3] ? timeArr[3]?.startsWith('0') ? timeArr[3].replace(/^0/, '') : timeArr[3] : 0)
      return LocalTime.of(hour, minute, second, millisecond)
    } else {
      throw new Error(`Invalid time ${timeStr}`)
    }
  }

  toString (): string {
    return `${this.#hour < 10 ? '0' + this.#hour : this.#hour}:${this.#minute < 10 ? '0' + this.#minute : this.#minute}:${this.#second < 10 ? '0' + this.#second : this.#second}.${completionFormat(this.#millisecond, 3)}`
  }

  plusHours (n: number): LocalTime {
    const newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      const sumHover = newLocalTime.#hour + n
      if (sumHover >= 24) {
        // 超过 24 小时
        newLocalTime.#hour = sumHover % 24
      } else {
        newLocalTime.#hour = sumHover
      }
    }
    return newLocalTime
  }

  plusMinutes (n:number): LocalTime {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      const sumMinute = newLocalTime.#minute + n
      if (sumMinute >= 60) {
        // 超过 60 分钟
        newLocalTime.#minute = sumMinute % 60
        newLocalTime = newLocalTime.plusHours(Math.floor(sumMinute / 60))
      } else {
        newLocalTime.#minute = sumMinute
      }
    }
    return newLocalTime
  }

  plusSeconds (n: number): LocalTime {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      const sumSecond = newLocalTime.#second + n
      if (sumSecond >= 60) {
        newLocalTime.#second = sumSecond % 60
        newLocalTime = newLocalTime.plusMinutes(Math.floor(sumSecond / 60))
      } else {
        newLocalTime.#second = sumSecond
      }
    }
    return newLocalTime
  }

  plusMillisecond (n: number) {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      const sumMillisecond = newLocalTime.#millisecond + n
      if (sumMillisecond >= 1000) {
        newLocalTime.#millisecond = sumMillisecond % 1000
        newLocalTime = newLocalTime.plusSeconds(Math.floor(sumMillisecond / 1000))
      } else {
        newLocalTime.#millisecond = sumMillisecond
      }
    }
    return newLocalTime
  }

  minusHours (n: number): LocalTime {
    const newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      let hover = newLocalTime.#hour - n
      if (hover < 0) {
        hover = Math.abs(hover)
        newLocalTime.#hour = 24 - (hover % 24)
        if (newLocalTime.#hour === 24) newLocalTime.#hour = 0
      } else {
        newLocalTime.#hour = hover
      }
    }
    return newLocalTime
  }

  minusMinutes (n: number): LocalTime {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      let minute = newLocalTime.#minute - n
      if (minute < 0) {
        minute = Math.abs(minute)
        newLocalTime.#minute = 60 - (minute % 60)
        if (newLocalTime.#minute === 60) newLocalTime.#minute = 0
        newLocalTime = newLocalTime.minusHours(Math.ceil(minute / 60))
      } else {
        newLocalTime.#minute = minute
      }
    }
    return newLocalTime
  }

  minusSeconds (n: number): LocalTime {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      let second = newLocalTime.#second - n
      if (second < 0) {
        second = Math.abs(second)
        newLocalTime.#second = 60 - (second % 60)
        if (newLocalTime.#second === 60) newLocalTime.#second = 0
        newLocalTime = newLocalTime.minusMinutes(Math.ceil(second / 60))
      } else {
        newLocalTime.#second = second
      }
    }
    return newLocalTime
  }

  minusMillisecond (n: number): LocalTime {
    let newLocalTime = LocalTime.of(this.#hour, this.#minute, this.#second, this.#millisecond)
    if (isPositiveInteger(n)) {
      let millisecond = newLocalTime.#millisecond - n
      if (millisecond < 0) {
        millisecond = Math.abs(millisecond)
        newLocalTime.#millisecond = 1000 - (millisecond % 1000)
        if (newLocalTime.#millisecond === 1000) newLocalTime.#millisecond = 0
        newLocalTime = newLocalTime.minusSeconds(Math.ceil(millisecond / 1000))
      } else {
        newLocalTime.#millisecond = millisecond
      }
    }
    return newLocalTime
  }

  compareTo (ldt: LocalTime): number {
    if (ldt instanceof LocalTime) {
      let result = this.#hour - ldt.#hour
      if (result === 0) {
        result = this.#minute - ldt.#minute
        if (result === 0) {
          result = this.#second - ldt.#second
        }
      }
      return result < 0 ? -1 : result > 0 ? 1 : 0
    } else {
      throw new Error('LocalTime can only be compared with LocalTime')
    }
  }
}
