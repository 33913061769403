
















































import { Component, Prop } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import _ from 'lodash'
import logwire from '@/logwire'
import { doAction, doSave } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import draggable from 'vuedraggable'
import { saveUserSettings } from '@/utils/common'

@Component({ name: 'fields-sort', components: { draggable } })
class FieldsSort extends BaseComponent {
  @Prop() dialogVisible!: boolean
  @Prop() settingDate: any
  @Prop() dataSetName: any

  sortChoices = ['asc', 'desc']

  handleClose () {
    this.$emit('closeSortDialog')
  }

  save () {
    // 保存到 user_table_settings 表中
    let sortsJson = ''
    if (this.settingDate.sort_enabled) {
      sortsJson = JSON.stringify(this.settingDate.sort.map((item: any) => _.pick(item, ['name', 'order'])))
    } else {
      this.settingDate.sort = []
      sortsJson = ''
    }
    saveUserSettings(this.layoutName, this.dataSetName,
      {
        sort_enabled: this.settingDate.sort_enabled,
        sort_json: sortsJson
      },
      (res) => {
        // 设置成功之后不刷新页面，retrieve数据后，将头部排序字段全部取消
        this.$emit('saveSortDialog', res)
      }
    )
  }

  addSort () {
    const firstName = this.settingDate.all_fields[0].name
    this.settingDate.sort.push({ name: firstName, order: 'asc' })
  }

  deleteSortField (index: number) {
    this.settingDate.sort.splice(index, 1)
  }

  mounted () {
    // 初始化的时候过滤掉以 __field 开头的非表单组件,不支持排序
    this.settingDate.all_fields = this.settingDate.all_fields.filter((item: {display: boolean, name: string, title: string}) => !item.name?.startsWith('__field'))
    if (!this.settingDate.sort_enabled) {
      this.settingDate.sort = []
    }
  }
}
export default FieldsSort
