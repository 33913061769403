















import Component from 'vue-class-component'
import Vue from 'vue'
import screenshot, { Canvas } from './i18n-utils/screenshot'
import { getLastestLayoutInQueue } from '@/utils/layout'
import { LayoutModule } from '@/store/modules/layout'
import ComponentStructure, { ModelCustomI18nItem } from './i18n-utils/basic'
import { LayoutComponent } from '@/types/layout'
import { getComponentStructure } from './i18n-utils'
import DialogModifyI18n from './i18n-utils/DialogModifyI18n.vue'
import { queryByFilter, getLayoutAllI18nOfAllLanguage, getMessageAllI18nOfAllLanguage } from '@/http/api'
import { AxiosResponse } from 'axios'
import { lang } from 'moment-timezone'
import { LocalModule } from '@/store/modules/local'

/**
 * 在线修改 i18n 多语言
 * 1. 点击按钮后，希望给页面增加一层蒙版，同时当前 Layout 层级的多语言翻译的元素，都高亮；如果不是当前 Layout 层级，则不应该显示
 * 2. 直接在高亮窗口内编辑多语言内容
 *
 * 如果哪天希望在线修改平台内置的多语言，那么就需要修改 property 的结构，放入一些内置属性
 */
@Component
export default class ModifyI18nOnline extends Vue {
  array !: ComponentStructure[]
  propertyElements !: (ComponentStructure['propertyElements'][number] & { modified: boolean })[]
  canvas !: Canvas
  customTranslation !: ModelCustomI18nItem[]
  translation !: {
    messages: Record<string, Record<string, string > | undefined>,
    layout: Record<string, Record<string, string> | undefined>
  }

  targetLayoutName = ''

  created () {
    this.translation = {} as ModifyI18nOnline['translation']
  }

  async getMessagesData () {
    if (this.translation && this.translation.messages) return
    const res: AxiosResponse<{ data: Record<string, Record<string, string>>}> = await getMessageAllI18nOfAllLanguage({
      loadingLevel: 'none'
    })
    this.translation.messages = res.data.data
  }

  async getLayoutData () {
    const res: AxiosResponse<{ data: Record<string, Record<string, string>>}> = await getLayoutAllI18nOfAllLanguage({ layoutName: this.targetLayoutName }, {
      loadingLevel: 'none'
    })
    this.translation.layout = res.data.data
  }

  async getCustomLayoutData () {
    const res: AxiosResponse<{ data: { rows: ModelCustomI18nItem[] }}> = await queryByFilter({
      layoutName: 'core.special_resources',
      queryName: 'layout_custom_translation',
      filter: {
        masterFilter: {
          conditions: [{ field: 'layout_name', operator: 'eq', value: this.targetLayoutName }],
          connector: 'and',
          not: false,
          type: 'group'
        },
        pageSize: -1
      }
    }, {
      loadingLevel: 'none'
    })
    this.customTranslation = res.data.data.rows
  }

  async handleClick () {
    this.targetLayoutName = getLastestLayoutInQueue()

    // 0. 准备数据
    await Promise.all([
      this.getCustomLayoutData(),
      this.getMessagesData(),
      this.getLayoutData()
    ])

    // 获取当前页面结构
    this.array = this.getComponentStructures()

    console.log(this.array)
    this.propertyElements = this.getPropertyElementsWhichUseI18n()
    console.log('elements is', this.propertyElements)

    // 绘制蒙版, 将绘制过程放在获取元素结构之后，因为蒙版会影响 document.elementFromPoint，判断组件上方是否有其他元素遮挡
    this.canvas = await screenshot()

    this.drawHighlightElements()

    this.drawExitElement()
  }

  getComponentStructures () {
    const layoutName = this.targetLayoutName
    const resource = LayoutModule.resource[layoutName]
    const components = ([] as LayoutComponent[]).concat(resource.metaData.components || []).concat(resource.metaData.panels || [])
    const structures: ComponentStructure[] = []
    const queues: LayoutComponent[] = [...components]
    while (queues.length) {
      const component = queues.shift()
      if (component) {
        const structure = getComponentStructure(component)
        if (structure) {
          structure.initialRenderDom()

          if (structure.getPropertiesWhichUseI18n().length > 0) {
            structures.push(structure)
          }
          queues.push(...structure.getChildComponents())
        }
      }
    }
    return structures
  }

  getPropertyElementsWhichUseI18n () {
    let queues: ModifyI18nOnline['propertyElements'] = []
    this.array.forEach(struc => {
      const result = struc.getPropertyElementsWhichUseI18n()
      result.forEach(item => {
        queues.push({
          ...item,
          modified: this.customTranslation.some(o => o.i18n_code === item.i18nKey)
        })
      })
    })

    // queues 内部可能有重复出现同一个 dom 对应多个编辑项的情况，比如 group 属性，每个表单属性都会做一遍，导致一个 group 下有多少个表单组件，就会出现多少次这个 group 元素, 根据 dom 筛选
    queues = queues.reduce((p, n) => {
      if (!p.some(o => o.element === n.element)) {
        p.push(n)
      }
      return p
    }, [] as ModifyI18nOnline['propertyElements'])
    return queues
  }

  drawHighlightElements () {
    // 在渲染高亮内容之前，清除所有已绘制的内容
    for (const dom of this.canvas._container.querySelectorAll('.modify-i18n-online__translation-item')) {
      dom.remove()
    }
    this.canvas.clearDashLine()

    this.propertyElements.forEach(propertyElement => {
      const rect = propertyElement.element.getBoundingClientRect()
      this.canvas.drawDashLine(rect.x, rect.y, rect.width, rect.height, [3], '#fff')

      var dom = document.createElement('div')
      dom.classList.add('modify-i18n-online__translation-item')
      dom.style.width = rect.width + 'px'
      dom.style.height = rect.height + 'px'
      dom.style.cursor = 'pointer'
      dom.style.position = 'absolute'
      dom.style.top = rect.top + 'px'
      dom.style.left = rect.left + 'px'
      dom.addEventListener('click', evt => {
        this.openDialogModifyI18n(propertyElement.i18nKey)
      })

      if (propertyElement.modified) {
        const icon = document.createElement('i')
        icon.style.position = 'absolute'
        icon.style.top = '-2px'
        icon.style.right = '-2px'
        icon.style.height = '0'
        icon.style.width = '0'
        icon.style.border = '6px solid #FECD2D'
        icon.style.borderLeftColor = 'transparent'
        icon.style.borderBottomColor = 'transparent'
        dom.appendChild(icon)
      }

      this.canvas._container.appendChild(dom)
    })
  }

  openDialogModifyI18n (i18nKey: string) {
    const layout_name = this.targetLayoutName
    // 打开弹窗修改词条内容
    const langs: { title: string, lang: string }[] = LocalModule.choices['core.language'].map((o: any) => ({ title: o.title, lang: o.value }))
    const DialogModifyI18nExtend = Vue.extend(DialogModifyI18n)
    const vm = new DialogModifyI18nExtend({
      el: document.createElement('div'),
      propsData: {
        layoutName: layout_name,
        langs: langs,
        i18nKey: i18nKey
      },
      parent: this
    }) as DialogModifyI18n
    this.canvas._container.appendChild(vm.$el)
    const translation: ModelCustomI18nItem[] = langs.map(o => {
      const custom = this.customTranslation.find(custom => custom.language === o.lang && custom.i18n_code === i18nKey)
      if (custom) {
        return {
          ...custom,
          origin_text: this.translation.layout[o.lang]?.[i18nKey] || this.translation.messages[o.lang]?.[i18nKey] || ''
        }
      } else {
        return {
          layout_name,
          i18n_code: i18nKey,
          language: o.lang,
          custom_text: this.translation.layout[o.lang]?.[i18nKey] || this.translation.messages[o.lang]?.[i18nKey] || ''
        }
      }
    })
    vm.show(translation, async () => {
      // 编辑完成后，重新获取 custom_translation, 也可以通过 save 接口的回调，但是这样直接发请求，写的简单点
      await this.getCustomLayoutData()
      this.propertyElements = this.getPropertyElementsWhichUseI18n()
      this.drawHighlightElements()
    })
  }

  drawExitElement () {
    const dom = document.createElement('div')
    dom.style.position = 'absolute'
    dom.style.top = '0'
    dom.style.right = '0'
    dom.style.minWidth = '56px'
    dom.style.height = '56px'
    dom.style.backgroundColor = '#262626'
    dom.style.color = '#BFBFBF'
    dom.style.textAlign = 'center'
    dom.style.lineHeight = '56px'
    dom.style.cursor = 'pointer'
    // dom.style.paddingLeft = '10px'
    dom.setAttribute('title', this.$i18n('core', 'modifyI18n.exit'))
    dom.addEventListener('click', evt => {
      document.body.removeChild(this.canvas._container)
    })

    // const span = document.createElement('span')
    // span.style.verticalAlign = 'super'
    // span.innerText = '退出在线编辑多语言模式'
    // dom.appendChild(span)

    const icon = document.createElement('i')
    icon.classList.add('iconfont', 'icon-guanbi')
    icon.style.fontSize = '32px'
    dom.appendChild(icon)

    this.canvas._container.appendChild(dom)
  }
}
