import ui from './ui'
import util from './util'
import common from './common'
import network from './network'
import store from './store'
import config from './config'
import IClass from './class'
import security from './security'
import device from './device'
import app from './app'

const logwire = {
  ui,
  store,
  network,
  common,
  util,
  config,
  class: IClass,
  security,
  device,
  app
}

// 冻结 logwire 对象以及其部分属性，不允许使用的人修改里面的内容
Object.freeze(logwire)
Object.freeze(logwire.ui)
Object.freeze(logwire.network)
Object.freeze(logwire.store)
Object.freeze(logwire.util)
Object.freeze(logwire.config)
Object.freeze(logwire.class)
Object.freeze(logwire.security)
Object.freeze(logwire.device)
Object.freeze(logwire.app)

export default logwire
