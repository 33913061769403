<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellDate from './TableCellDate.vue'
import FormDate from './Date.vue'

@Component({ name: 'LwDate', components: { TableCell: TableCellDate, FormItem: FormDate } })
class LwDate extends FormControlBase {}
export default LwDate
</script>
