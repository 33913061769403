import Component from '@/models/Component'
import TagContent from '@/models/TagContent'
import LayoutArgs from '@/models/LayoutArgs'
import BreadcrumbItem from '@/models/BreadcrumbItem'
import ConditionGroup from '@/models/ConditionGroup'
import Condition from '@/models/Condition'
import LocalDateTime from '@/models/LocalDateTime'
import LocalDate from '@/models/LocalDate'
import LocalTime from '@/models/LocalTime'

export default {
  Component,
  TagContent,
  LayoutArgs,
  BreadcrumbItem,
  ConditionGroup,
  Condition,
  LocalDateTime,
  LocalDate,
  LocalTime
}
