<script>
import { Component, Vue } from 'vue-property-decorator'
import { camelCase } from 'lodash'

@Component
class FormControlBase extends Vue {
  get props () {
    const result = {}
    for (const key in this.$attrs) {
      result[camelCase(key)] = this.$attrs[key]
    }
    return result
  }

  get componentOn () {
    return {
      'quick-edit': this.handleQuickEdit,
      'data-row-reset': this.handleDataRowReset
    }
  }

  handleQuickEdit () {
    this.$emit('quick-edit')
  }

  handleDataRowReset () {
    this.$emit('data-row-reset')
  }

  render () {
    const componentOn = {
      'quick-edit': this.handleQuickEdit,
      'data-row-reset': this.handleDataRowReset
    }

    return (
      this.props.tableRow
        // eslint-disable-next-line no-undef
        ? <TableCell { ...{ attrs: this.props, on: componentOn } } />
      // eslint-disable-next-line no-undef
        : <FormItem { ...{ attrs: this.props, on: componentOn } } />
    )
  }
}

export default FormControlBase
</script>
