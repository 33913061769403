import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { LoadingLevel } from '@/http'
import _ from 'lodash'
import { Vue } from 'vue-property-decorator'

export interface AppState {
  appLoading: boolean;
  currentLayout: string;
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements AppState {
  appLoading = false
  loadingLayoutNameArr:Array<string> = []
  loadingTime = 0
  currentLayout = '' // 当前 layout，存放以方便脚本代码能清楚的知道当前处于哪个 layout
  currentEventOwnerUId = '' // 当前事件的所属者组件的 uid
  processingEventOwnerUIds: Array<number> = [] //  当前正在处理任务中的组件 uid
  uiWebSocketListener: Record<string, Record<string, string>> = { open: {}, close: {}, customMessage: {} } // 全局注册的通知监听事件

  @Mutation
  private UPDATE_APP_LOADING_STATUS (payload: Record<string, any>): void {
    const { level, status, layoutName } = payload
    if (level === LoadingLevel.APP) {
      this.appLoading = status
    } else if (level === LoadingLevel.CONTENT) {
      if (status) {
        !this.loadingLayoutNameArr.includes(layoutName) && this.loadingLayoutNameArr.push(layoutName)
      } else {
        this.loadingLayoutNameArr.splice(this.loadingLayoutNameArr.indexOf(layoutName), 1)
      }
    }
  }

  @Action
  updateAppLoadingStatus (payload: Record<string, any>): void {
    this.UPDATE_APP_LOADING_STATUS(payload)
  }

  @Mutation
  private UPDATE_APP_LOADING_TIME (loadingTime: number): void {
    this.loadingTime = loadingTime
  }

  @Action
  updateAppLoadingTime (payload: number): void {
    this.UPDATE_APP_LOADING_TIME(payload)
  }

  @Mutation
  private UPDATE_APP_CURRENT_LAYOUT (layoutName: string): void {
    this.currentLayout = layoutName
  }

  @Action
  updateAppCurrentLayout (payload: string): void {
    this.UPDATE_APP_CURRENT_LAYOUT(payload)
  }

  @Mutation
  private UPDATE_APP_CURRENT_EVENT_OWNER (uid: string): void {
    this.currentEventOwnerUId = uid
  }

  @Action
  updateAppCurrentEventOwner (uid: string): void {
    this.UPDATE_APP_CURRENT_EVENT_OWNER(uid)
  }

  @Mutation
  private UPDATE_PROCESSING_EVENT_OWNER ({ uid = -1, type = 'add' }): void {
    type === 'add'
      ? this.processingEventOwnerUIds.push(uid)
      : this.processingEventOwnerUIds.splice(this.processingEventOwnerUIds.indexOf(uid), 1)
  }

  @Action
  updateProcessingEventOwner (payload: Record<string, any>): void {
    this.UPDATE_PROCESSING_EVENT_OWNER(payload)
  }

  @Action
  setAppWebSocketListener (payload: any) : void {
    this.SET_APP_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private SET_APP_WEBSOCKET_LISTENER ({ type, name, customType } : any) : void {
    this.uiWebSocketListener[type][name] = customType || name
  }

  @Action
  removeAppWebSocketListener (payload: any) : void {
    this.REMOVE_APP_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private REMOVE_APP_WEBSOCKET_LISTENER ({ type, name } : any) : void {
    const listeners = this.uiWebSocketListener?.[type]
    if (!listeners) return
    delete listeners[name]
  }
}

export const AppModule = getModule(App)
