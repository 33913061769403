import { LayoutComponent } from '@/types/layout'
import Component from './Component'

class Dynamic {
  components: Array<LayoutComponent> = []

  constructor (components: Array<LayoutComponent>) {
    this.components = components
  }

  getCurrentComponents (): Array<LayoutComponent> {
    return this.components
  }

  getComponent (index: number): Component|null {
    if (this.components) {
      return new Component(this.components[index].is, this.components[index])
    } else {
      return null
    }
  }

  appendComponent (component: LayoutComponent): void {
    this.components.push(component.component)
  }

  removeComponent (index: number): void {
    this.components.splice(index, 1)
  }

  clearComponents (): void {
    this.components.splice(0, this.components.length)
  }
}

export default Dynamic
