
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import NumberBase from './NumberBase'

@Component({
  name: 'TableCellNumber'
})
class TableCellNumber extends Mixins(TableCell, NumberBase) {}
export default TableCellNumber
