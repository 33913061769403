













































import { Component, Prop } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import _ from 'lodash'
import logwire from '@/logwire'
import { doAction, doSave } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import draggable from 'vuedraggable'
import { saveUserSettings } from '@/utils/common'

@Component({ name: 'fields-display', components: { draggable } })
class FieldsDisplay extends BaseComponent {
  @Prop() dialogVisible!: boolean
  @Prop() settingDate: any
  @Prop() dataSetName: any

  handleClose () {
    this.$emit('closeDisplayDialog')
  }

  save () {
    // 保存到 user_table_settings 表中
    const displayFieldsJson = JSON.stringify(this.settingDate.display_fields.map((item: any) => _.pick(item, ['name', 'display'])))
    saveUserSettings(this.layoutName, this.dataSetName,
      {
        display_fields_enabled: this.settingDate.display_fields_enabled,
        display_fields_json: displayFieldsJson
      },
      (res) => {
        // 设置成功之后不刷新页面
        this.$emit('saveDisplayDialog', res)
      }
    )
  }

  showAll () {
    for (const item of this.settingDate.display_fields) {
      item.display = true
    }
    this.$forceUpdate()
  }

  hideAll () {
    for (const item of this.settingDate.display_fields) {
      item.display = false
    }
    this.$forceUpdate()
  }

  changeDisplayFields () {
    this.$forceUpdate()
  }
}
export default FieldsDisplay
