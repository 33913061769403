<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div
      v-show="showPopper"
      class="el-autocomplete-suggestion el-popper"
      :class="{ 'is-loading': !parent.hideLoading && parent.loading }"
      :style="{ width: dropdownWidth }"
      role="region">
      <slot name="header">
      </slot>
      <el-scrollbar
        tag="ul"
        wrap-class="el-autocomplete-suggestion__wrap"
        view-class="el-autocomplete-suggestion__list">
        <li v-if="!parent.hideLoading && parent.loading"><i class="el-icon-loading"></i></li>
        <slot v-else>
        </slot>
      </el-scrollbar>
      <slot name="footer">
      </slot>
    </div>
  </transition>
</template>
<script>
  import ElAutocompleteSuggestions from 'element-ui/packages/autocomplete/src/autocomplete-suggestions.vue'

  export default {
    mixins: [ElAutocompleteSuggestions]
  };
</script>
