import { Message, NotificationTodo, Notification as INotification, Todo, TodoOperation } from '@/types/common'
import { Notification as notify } from 'element-ui'
import eventbus from '@/utils/event'
import { attributeSymbol, notificationType } from '@/utils/const'
import logwire from '@/logwire'
import {
  getI18nContent,
  getPlainAttributeValue,
  getResourceWithParams,
  isSymbol,
  runRunnableContent
} from '@/utils/layout'
import Args from '@/models/Args'
import { AssociatedContext, LayoutContext } from '@/types/layout'
import DataRow from './DataRow'
import { formatDataRow } from '@/utils/data'

class Notification {
  static notifications: any[] = []
  context!: LayoutContext
  associatedContext!: AssociatedContext

  constructor (notification: Message | Todo, context: LayoutContext, associatedContext: AssociatedContext) {
    this.context = context
    this.associatedContext = associatedContext
    const { data } = notification
    const { notifications } = Notification
    if (notifications.length === 3) {
      const notify = notifications[0] as any
      notify.close()
      notifications.splice(0, 1)
    }
    const notificationInstance = data.type === notificationType.MESSAGE
      ? this.createMessage(data)
      : this.createTodo(data as NotificationTodo)
    notifications.push(notificationInstance)
  }

  private getI18nContent (content: string, namespace: string) {
    if (isSymbol(content, attributeSymbol.I18N)) {
      return getI18nContent(content, namespace)
    }
    return content
  }

  private handleOperation (func: string, todoEntityId: string, namespace: string, type: string, e: MouseEvent) {
    e.stopPropagation()
    const paramStart = func.indexOf('(')
    const paramEnd = func.lastIndexOf(')')
    let funcName = '{commonWithNullArgs}' + namespace + '.'
    if (paramStart === -1 || paramEnd === -1) {
      funcName += func + '(' + todoEntityId + ')'
    } else {
      funcName += func.substring(0, paramStart) + '('
      const paramString = func.substring(paramStart + 1, paramEnd)
      const params = paramString.split(',')?.map(param => param.replace(/'/g, '').replace(/"/g, '').trim())
      params.forEach((param) => {
        funcName += param ? param + ',' : ''
      })
      funcName += todoEntityId + ')'
    }
    runRunnableContent(type, { is: 'notification', [type]: funcName }, new Args(this.context), this.associatedContext)
  }

  handleNotification (notification: INotification) {
    logwire.ui.openLayoutInDialog({
      layoutName: 'core.notification_detail',
      width: '70%',
      params: {
        notification: new DataRow(formatDataRow(notification))
      }
    })
  }

  private createMessage (message: INotification) {
    const { namespace } = message
    return notify({
      iconClass: 'el-icon-warning',
      customClass: 'lw-notification message ',
      title: this.getI18nContent(message.title, namespace),
      message: this.getI18nContent(message.content, namespace),
      offset: 48,
      onClick: () => {
        this.handleNotification(message)
      }
    })
  }

  private createTodo (todo: NotificationTodo) {
    const h = eventbus.$createElement
    // eslint-disable-next-line camelcase
    const {
      primary_operation: mOperation,
      primary_operation_title: mOperationTitle,
      secondary_operation: sOperation,
      secondary_operation_title: sOperationTitle,
      view_operation: vOperation,
      view_operation_title: vOperationTitle,
      todo_entity_id: todoEntityId,
      namespace
    } = todo
    return notify({
      iconClass: 'el-icon-warning',
      customClass: 'lw-notification',
      title: todo.title,
      offset: 48,
      message: h('div', {}, [
        h('p', { class: 'lw-notification-content' }, this.getI18nContent(todo.content, namespace)),
        h('div', { class: 'lw-notification-operations' }, [
          vOperationTitle
            ? h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: (e: MouseEvent) => this.handleOperation(vOperation, todoEntityId, namespace, 'viewOperation', e) } }, this.getI18nContent(vOperationTitle, namespace))
            : h('div'),
          h('div', {}, [
            sOperationTitle && h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: (e: MouseEvent) => this.handleOperation(sOperation, todoEntityId, namespace, 'secondaryOperation', e) } }, this.getI18nContent(sOperationTitle, namespace)),
            mOperationTitle && h('button', { class: 'el-button el-button--primary el-button--mini', on: { click: (e: MouseEvent) => this.handleOperation(mOperation, todoEntityId, namespace, 'primaryOperation', e) } }, this.getI18nContent(mOperationTitle, namespace))
          ])
        ])
      ]),
      onClick: () => {
        this.handleNotification(todo)
      }
    })
  }
}

export default Notification
