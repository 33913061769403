













import { Component, Prop } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import Args from '@/models/Args'

@Component({ name: 'lw-card' })
export default class LwCard extends BaseComponent {
  // always / hover / never，默认为 `never`
  get shadow () {
    return this.getFinalAttributeValue('shadow') || 'never'
  }

  // 提供  args.getCurrentRow 方法获取当前数据行
  handleCardClick (evt: MouseEvent) {
    if (this.component.onClick) {
      evt.stopPropagation()
      const args = new Args(this.context, { row: this.currentRowInParent })
      this.runRunnableContent('onClick', { args })
    }
  }
}
