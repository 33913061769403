<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellTimestamp from './TableCellTimestamp.vue'
import FormTimestamp from './Timestamp.vue'

@Component({ name: 'LwTimestamp', components: { TableCell: TableCellTimestamp, FormItem: FormTimestamp } })
class LwTimestamp extends FormControlBase {}
export default LwTimestamp
</script>
