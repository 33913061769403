import { LayoutComponent } from '@/types/layout'
import ComponentStructure from '../basic'

export default class I18nForLwSection extends ComponentStructure {
  name = 'lw-section'
  properties = ['title']

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.lw-section')) {
      // eslint-disable-next-line dot-notation
      if (dom['__vue__'] && dom['__vue__'].component === this.component) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      }
    }
    return null
  }

  getPropertyShownElement (property: string) {
    return this.dom
      ? this.dom.querySelector('.lw-section > .lw-section-header') as HTMLElement | null
      : null
  }

  getChildComponents () {
    return this.component.components || []
  }
}
