import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import { AppModule } from '@/store/modules/app'
import logwire from '@/logwire'
import { getGlobalI18nMessage, toLoginLayout } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import eventbus from '@/utils/event'
import { socketMessageType } from '@/utils/const'
import { getCheckedArchiveStorage, getCsrfToken, getTraceIds, updateTraceIdsCache } from '@/utils/data'
import { getUuid } from '@/utils/common'
import { cancelActionUrl } from '@/http/api'
// import { setServiceError } from '@/utils/feedback'

export enum LoadingLevel {
  APP = 'app',
  CONTENT = 'content',
  NONE = 'none'
}

interface LogwireRequestConfig extends AxiosRequestConfig {
  loadingLevel?: LoadingLevel;
  silent?: boolean;
  /**
   * undefined 表示非 doAction 请求
   * true 或 false 表示 doAction 请求内用户设置的 silent 值，用户没有设置按照 false 处理
   *
   * doAction 使用自身的 then/catch 显示提示，平台内设置 silent: true 来跳过 axios 的检查，提供 userSilent 记录 doAction 时用户传递的参数
   */
  userSilent?: boolean
  loadingTimer?: number;
  __traceId?: string
}

interface ICancelTarget {
  source: CancelTokenSource;
  config: LogwireRequestConfig;
}

interface LogwireResponse extends AxiosResponse{
  code: number;
  traceId: string;
  message: string;
  messageType: 'error' | 'success';
  data: any;
  stackTrace?: any;
}

const minLoadingTime = 300

export function handleRequestError (response: LogwireResponse): void {
  const { silent, layout } = response.config as any
  const fn = (response: LogwireResponse) => {
    // setServiceError(response)
    switch (response.status) {
      // 未登录
      case 401:
        toLoginLayout()
        break
      // 已登录但没有权限
      case 403:
        if (!silent) {
          // 通用提示 "无权限"
          let message = getGlobalI18nMessage('core', 'client.tips.no-permission')
          if (layout) {
            // 针对 layout 获取不到的提示 "您无权访问页面 {0}"
            message = getGlobalI18nMessage('core', 'client.tips.no-layout-permission', layout)
          }
          logwire.ui.message({
            message,
            stackTrace: response.data.stackTrace,
            traceId: response.data.traceId,
            request: response.config.url,
            type: 'error'
          })
        }
        break
      // 没有找到页面
      case 404:
      case 422:
      // 后台报错
      // eslint-disable-next-line
      case 500:
        !silent && response.data && logwire.ui.message({
          message: response.data.message,
          stackTrace: response.data.stackTrace,
          traceId: response.data.traceId,
          request: response.config.url,
          type: 'error'
        })
        break
      default:
        !silent &&
        logwire.ui.message({
          message: response.data.message,
          stackTrace: response.data.stackTrace,
          traceId: response.data.traceId,
          request: response.config.url,
          type: 'error'
        })
    }
  }
  if (response.data instanceof Blob) {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      response.data = JSON.parse(reader.result as string)
      fn(response)
    })
    reader.readAsText(response.data, 'utf-8')
  } else {
    fn(response)
  }
}

// 存储 还在 pending 状态的接口的定时器。
const loadingTimerArr: { level?: LoadingLevel, timeout: number }[] = []

function updateLoadingStatus (config: LogwireRequestConfig, status: boolean): void {
  // 关闭 loading 时将 el-loading-mask 透明度重置为 0
  if (!status) {
    const dom = document.querySelector('.action-processing')
    dom && dom.classList.remove('show')
  }
  // 默认都是 app 级别的 loading
  let { loadingLevel } = config
  loadingLevel = loadingLevel || LoadingLevel.APP
  AppModule.updateAppLoadingStatus({ level: loadingLevel, status })
}

function closeLoading (config: LogwireRequestConfig): void {
  const { loadingTimer } = config
  if (loadingTimer) {
    clearTimeout(loadingTimer)
    const index = loadingTimerArr.findIndex(o => o.timeout === loadingTimer)
    index !== -1 && loadingTimerArr.splice(index, 1)
  }
  const startTime = AppModule.loadingTime
  let time = 0
  if (startTime) {
    const diff = Date.now() - startTime
    if (diff < minLoadingTime) time = minLoadingTime - diff
  }
  // 放在异步中执行是为了避免在刚请求完又发起请求的情况下两次 loading 的状况
  setTimeout(() => {
    // 没有带 loading 的接口处于 pending 状态 则关闭 loading
    if (loadingTimerArr.filter(o => o.level === config.loadingLevel).length === 0) {
      // 结束计时
      AppModule.updateAppLoadingTime(0)
      updateLoadingStatus(config, false)
    }
  }, time)
}

const instance: AxiosInstance = axios.create({
  withCredentials: true
})

const { CancelToken } = axios

const cancelRequestMap: Map<string, ICancelTarget> = new Map()

const cancelRequest = (config: LogwireRequestConfig) => {
  const { url } = config
  if (url === cancelActionUrl) {
    const { data: { traceId } } = config
    const target = cancelRequestMap.get(traceId)
    // eslint-disable-next-line no-unused-expressions
    target?.source?.cancel(traceId)
  }
}

instance.interceptors.request.use((config: LogwireRequestConfig) => {
  // el-loading-mask 默认透明度为 0 ，
  // 300 ms 以后如果还未响应，透明度置为 1
  // 300 ms 内有响应，则清除定时操作，同时还原 el-loading-mask 透明度 为 0
  updateLoadingStatus(config, true)

  cancelRequest(config)
  if (config.headers) {
    const mergeHeaders: Record<string, any> = {}
    config.__traceId = config.__traceId || getUuid()
    const traceId = config.__traceId
    const csrfToken = getCsrfToken()
    const checkedArchiveStorage = getCheckedArchiveStorage()
    if (traceId) {
      mergeHeaders['X-TRACE-ID'] = traceId
      updateTraceIdsCache({ traceId, type: 'add' })

      const cancelTokenSource = CancelToken.source()
      cancelRequestMap.set(traceId, { source: cancelTokenSource, config })
      config.cancelToken = cancelTokenSource.token
    }
    if (csrfToken) {
      mergeHeaders['X-CSRF-TOKEN'] = csrfToken
    }
    if (checkedArchiveStorage) {
      mergeHeaders['X-ARCHIVE-DATASOURCE'] = checkedArchiveStorage
    }
    mergeHeaders['X-DATE'] = +new Date()
    config.headers = { ...config.headers, ...mergeHeaders }
  }

  // 显示进度条，以前由 silent 控制，现在不应该控
  const loadingTimer = setTimeout(() => {
    const dom = document.querySelector('.action-processing')
    dom && dom.classList.add('show')
    // 开始计时
    if (!AppModule.loadingTime) AppModule.updateAppLoadingTime(Date.now())
  }, 300)
  loadingTimerArr.push({ timeout: loadingTimer, level: config.loadingLevel })
  config.loadingTimer = loadingTimer

  return config
}, (error) => Promise.reject(error))

instance.interceptors.response.use((response) => {
  const { data } = response
  // 服务端通知重定向
  const { type, url } = data
  if (type && url && type === 'redirect') {
    location.href = url
  }
  const traceId = data?.traceId || (response.config as any).__traceId
  closeLoading(response.config)
  if (traceId) {
    eventbus.$emit('update-processing-action', { type: socketMessageType.FINISH_ACTION, data: { traceId } })
    setTimeout(() => {
      updateTraceIdsCache({ traceId, type: 'remove' })
    }, 0)
    // 删除 map 中此次请求对应 traceId 存储的内容
    cancelRequestMap.delete(traceId)
  }
  return response
}, error => {
  /**
   * 假如请求被取消，那么 error 是不包含 response 和 config 的
   * 为了方便统一处理，在请求开始时会将 config 存储在 map 中，一旦请求被取消，直接从 map 中取 config
   * */
  if (axios.isCancel(error)) {
    const { message: targetId } = error
    const target = cancelRequestMap.get(targetId) || { config: {} }
    const { config } = target
    error.config = config
    cancelRequestMap.delete(targetId)
  }

  const traceId = error.response?.data?.traceId || error.config?.__traceId

  const traceIdArr = getTraceIds()
  const ignoreUrl = [
    '/api/open/book/core.get-asset?isDownload=false&path=book/asset/favicon.ico',
    '/api/action/core/core.get-user-home-layout-name'
  ]
  if (!ignoreUrl.includes(error.config.url) && traceIdArr.includes(traceId)) {
    if (error.response) {
      handleRequestError(error.response)
    } else {
      // 当出现网络请求报错等非后端报错时，err.response 为 undefined 阻塞后续的运行, 此时根据 error.message 提示用户错误信息
      !axios.isCancel(error) && logwire.ui.message({
        message: logwire.ui.getI18nMessage('server-network-error', undefined, 'core'),
        stackTrace: error.stack,
        traceId: '', // 非后端问题报错时，没有traceId
        request: error.config.url,
        type: 'error'
      })
    }
  }
  closeLoading(error.config)
  if (traceId) {
    eventbus.$emit('update-processing-action', { type: socketMessageType.FINISH_ACTION, data: { traceId } })
    setTimeout(() => {
      updateTraceIdsCache({ traceId, type: 'remove' })
    }, 0)
  }
  return Promise.reject(error)
})

export default instance
