import _ from 'lodash'
import network from './network'

interface networkProps {
  successCallback?: (response: any) => void;
  failCallback?: (error: any) => void;
}

export default {
  getLocalPrintDeviceId ({ successCallback, failCallback }: networkProps) {
    network.get({
      url: 'http://localhost:1699/api/get-device-id',
      data: {},
      successCallback: (res) => {
        _.isFunction(successCallback) && successCallback(res.data.deviceId)
      },
      failCallback: (err) => {
        _.isFunction(failCallback) && failCallback(err)
      }
    })
  }
}
