

















































import { Component, Mixins, Watch } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attributeType, showPreviewType, STORAGE_RECORD_TIME } from '@/utils/const'
import { attribute2Number, attributeForce2Boolean, downloadFile } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import ImageView from '@/3rd-party-extension/element/packages/imageView/ImageView.vue'
import Args from '@/models/Args'
import { documentDirectUploadPrepare, documentDirectUploadedNotify, documentDirectDownloadPrepare, documentDirectPreviewPrepare, doOpenApi, doAction } from '@/http/api'
import axios, { AxiosResponse } from 'axios'
import logwire from '@/logwire'
import CropImage from '@/components/layout/inner/CropImage.vue'
import _ from 'lodash'
import { getCsrfToken } from '@/utils/data'
import { getArchiveStorageAppend, isTypeFile } from '@/utils/common'
import DownloadFile from '../../mixin/DownloadFile'

let prevOverflow = ''

@Component({ name: 'LwUploadFile', components: { ImageView, CropImage } })
export default class LwUploadFile extends Mixins(FormControlComponent, DownloadFile) {
  showFileList = true
  showViewer = false
  // 层级要盖过 drawer
  zIndex = 2100
  previewFile: File | null = null
  // 文件直传时的文件预览列表
  directPreviewList: Array<string> = []
  cropImage: null | File | Blob = null

  headers = {
    'X-CSRF-TOKEN': getCsrfToken()
  }

  get uploadInfo () {
    return {
      previewList: this.isDirectAccessStorageService
        ? this.directPreviewList
        : this.previewList,
      httpRequest: this.isDirectAccessStorageService
        ? this.handleHttpRequest
        : undefined
    }
  }

  get clipImageOnUpload () {
    let result = false
    if (this.component.clipImageOnUpload) {
      result = this.getFinalAttributeValue('clipImageOnUpload', { type: attributeType.BOOLEAN })
    }
    return result
  }

  // 初始化预览图片的索引
  get initialIndex () {
    let previewIndex = 0
    const srcIndex = (this.fileList as any).indexOf(this.previewFile)
    if (srcIndex >= 0) {
      previewIndex = srcIndex
    }
    return previewIndex
  }

  // 非直传时文件预览列表
  get previewList () {
    let result: Array<string> = []
    const namespace = this.context.getNamespace()
    result = this.fileList.map((f: any) => {
      let url = ''
      if (!this.isDirectAccessStorageService) {
        // 非直传的时候才直接传递整个列表
        // 直传时候需要调用接口获取,在切换时候再去获取
        url = `/api/open/${namespace}/core.document-preview?code=${f.code}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
      }
      return url
    })
    return result
  }

  // 上传地址
  get uploadUrl () {
    const namespace = this.context.getNamespace()
    return `/api/action/${namespace}/core.document-upload`
  }

  // 是否仅上传图片
  get imageOnly (): boolean {
    let result = false
    if (this.component.imageOnly) {
      result = this.getFinalAttributeValue('imageOnly', { type: attributeType.BOOLEAN })
    }
    return result
  }

  // 是否多选
  get multiple (): boolean { return attributeForce2Boolean('multiple', this.component, false) }

  // 最大高度
  get maxHeight (): string {
    let result = '300px'
    if (this.component.maxHeight) {
      result = this.getFinalAttributeValue('maxHeight')
    }
    return result
  }

  // 接收文件类型
  get accept (): string {
    return this.imageOnly
      ? 'image/*'
      : this.getFinalAttributeValue('accept') || null
  }

  // 上传文件数限制
  get limit (): number | string {
    let result: number | string = 100
    if (this.component.limit) {
      result = attribute2Number(this.getFinalAttributeValue('limit'))
    }
    return result
  }

  // 是否一行展示
  get singleLineDisplay (): boolean { return attributeForce2Boolean('singleLineDisplay', this.component, false) }

  // 是否开启上传前压缩 如果开启，则根据 compressImageSizeThreshold 进行压缩
  get compressImageOnUpload (): boolean { return attributeForce2Boolean('compressImageOnUpload', this.component, false) }

  get compressImageSizeThreshold (): string {
    let result = '1MB'
    if (this.component.compressImageSizeThreshold) {
      result = this.getFinalAttributeValue('compressImageSizeThreshold')
    }
    return result
  }

  // 是否启用直传服务器
  get isDirectAccessStorageService () {
    return (LocalModule.config as any).directAccessStorage
  }

  // 上传的文件列表
  get fileList () {
    let result = []
    if (this.inputValue) {
      result = this.inputValue.split('|')
        .filter((v: string) => v)
        .map((f: string) => {
          return {
            name: f.split('?')[0],
            code: f.split('?')[1].replace(/^code=/, '')
          }
        })
    } else {
      result = []
    }
    return result
  }

  // 在 table 中展示的字段，由 文件名 和 ; 拼接
  get tableCellValue () {
    let result = ''
    if (this.inputValue) {
      result = this.inputValue.split('|')
        .filter((v: string) => v)
        .map((f: string) => {
          return f.split('?')[0]
        }).join(';')
    }
    return result
  }

  get drag (): boolean {
    let result = false
    if (this.component.drag) {
      result = this.getFinalAttributeValue('drag', { type: attributeType.BOOLEAN })
    }
    return result && this.formEditing
  }

  // 上传文件列表发生变化时候，为直传的预览列表数组同步长度
  @Watch('fileList')
  setdirectPreviewListLength () {
    this.directPreviewList.length = this.fileList.length
  }

  // 直传服务器的上传逻辑
  async handleHttpRequest ({ file, onProgress, onSuccess, onError }: Record<string, any>): Promise<void> {
    // 直传文件服务器
    const namespace = this.context.getNamespace()
    const { name: filename, size: fileSize } = file
    // 调用准备接口
    try {
      const layoutName = this.editLayoutName || this.layoutName
      const { data: { data: { header, method, url, storageCode }, messageType } } = await documentDirectUploadPrepare({ layoutName, namespace, data: { filename } })
      if (messageType === 'success') {
        // 按返回值调用直传接口
        // 过滤掉接口返回的请求头中的 Host
        if (header.Host) delete header.Host
        const { status } = await axios[(method as string).toLowerCase()](url, file, { headers: header }).catch((e: Error) => {
          logwire.ui.message({
            message: e.message,
            type: 'error'
          })
        })
        if (status >= 200 && status < 300) {
          // 上传成功，调用通知接口
          const { data } = await documentDirectUploadedNotify({ layoutName, namespace, data: { storageCode, filename, fileSize } })
          if (data.messageType === 'success') {
            // 上传成功
            onSuccess(data)
          }
        }
      }
    } catch (error) {
      onError(error)
    }
  }

  // 上传前
  handleBeforeUpload (file: File | Blob) {
    return new Promise((resolve, reject) => {
      // 图片上传 开启压缩图片则根据阈值去压缩
      const sizeThreshold = this.unitConversion()

      // 抽离共有逻辑，压缩然后调用用户 beforeUpload 行为
      const compressImageAndBeforeUpload = (file: File | Blob | string, sizeThreshold: number, resolve: (value: any) => void, reject: () => any) => {
        this.compressImage(file, sizeThreshold)
          .then(res => {
            // 压缩成功 替换文件
            file = res as File
            // 如果设置了 beforeUpload 执行
            this.userBehavior('beforeUpload', { file, resolve, reject })
          }).catch(e => {
            // TODO 错误提醒
            console.log(e)
          })
      }

      if (this.imageOnly && this.clipImageOnUpload) {
        // 开启了图片裁剪 仅在 imageOnly 时候生效
        const { cropImageComponent } = this.$refs
        this.cropImage = file;
        (cropImageComponent as any)
          .init()
          .then((res: string) => {
            // dataUrl 与 Blob.size 比值约为0.75
            if (res.length * 0.75 > sizeThreshold) {
              // 压缩
              compressImageAndBeforeUpload(res, sizeThreshold, resolve, reject)
            } else {
              file = this.dataURLtoFile(res, 'image/jpeg')
              this.userBehavior('beforeUpload', { file, resolve, reject })
            }
          },
          (rej: any) => {
            reject(rej)
          })
      } else {
        if (/^image\//.test(file.type) && this.compressImageOnUpload && file.size > sizeThreshold) {
          // 超过阈值 需要压缩
          compressImageAndBeforeUpload(file, sizeThreshold, resolve, reject)
        } else {
          this.userBehavior('beforeUpload', { file, resolve, reject })
        }
      }
    })
  }

  // 上传成功 调用 afterUpload
  handleSuccess (response: any, file: File, fileList: Array<Record<string, any>>): void {
    this.userBehavior('afterUpload', { file, fileList })
    // ELEMENT 文件上传是一个一个上传, 所以等i一次上传的文件全部上传以后再更新 inputValue
    const readyFileList = fileList.filter((f: any) => {
      return f.status === 'ready' || f.status === 'uploading'
    })
    if (readyFileList.length === 0) {
      this.updateInputValue(fileList)
    }
  }

  handleError (err: ProgressEvent | Error) {
    if (err instanceof ProgressEvent) {
      // 因为离线导致上传过程失败，会传递 ProgressEvent 参数
    } else {
      const errorInfo = JSON.parse(err.message)
      logwire.ui.message({
        message: errorInfo?.message || '',
        type: 'error'
      })
    }
  }

  handleBeforeRemove (file: File, fileList: Array<Record<string, any>>) {
    return new Promise((resolve, reject) => {
      this.userBehavior('beforeRemove', { file, fileList, resolve, reject })
    })
  }

  handleAfterRemove (file: File, fileList: Array<Record<string, any>>): void {
    this.userBehavior('afterRemove', { file, fileList })
    this.updateInputValue(fileList)
  }

  // 用户行为 beforeUpload | afterUpload | beforeRemove | afterRemove
  // beforeUpload 中 args 提供了两个方法 getFile 获取文件(压缩后的) 和 proceed ()
  // afterUpload 中 args 提供了两个方法 getFile 获取文件 和 getFileList 获取文件列表
  // beforeRemove 中 args 提供了三个方法 getFile 获取文件 和 getFileList 获取文件列表 proceed ()
  // afterRemove 中 args 提供了两个方法 getFile 获取文件 和 getFileList 获取文件列表
  userBehavior (type: string, params?: any): void {
    const getFile = function () {
      return params.file
    }
    const getFileList = function () {
      return params.fileList
    }
    if (type === 'beforeUpload') {
      // TODO 文档需要注明，如果定义 beforeUpload 则 调用 proceed 时候必须带上 file 参数，否则上传的是源文件而不是压缩后的文件
      const proceed = function (file: File = params.file) {
        params.resolve(file)
      }
      if (this.component.beforeUpload) {
        // 如果有 beforeUpload 则由 beforeUpload 决定是否继续上传
        const args = new Args(this.context, { proceed, getFile })
        this.runRunnableContent('beforeUpload', { args })
        // 利用 Promise 状态只会改变一次
        // 如果用户没有 调用 proceed，在这里 reject 掉
        // 如果用户调用了 proceed， Promise 的状态已经变化，则这里的 reject 虽然执行 但是不会影响到 Promise 的状态
        // 这里 reject 的目的是为了让 fileList 里只有 成功上传的文件，而没有处于 ready 状态的等待上传文件
        params.reject()
      } else {
        // 如果没有 beforeUpload 行为，则继续上传
        proceed()
      }
    } else if (type === 'afterUpload' && this.component.afterUpload) {
      const args = new Args(this.context, { getFile, getFileList })
      this.runRunnableContent('afterUpload', { args })
    } else if (type === 'beforeRemove') {
      const proceed = function () {
        params.resolve()
      }
      if (this.component.beforeRemove) {
        const args = new Args(this.context, { proceed, getFile, getFileList })
        this.runRunnableContent('beforeRemove', { args })
        // 这里没有 reject 的原因在于，删除前有可能具有一个异步的确认过程，reject 会导致确认失效
      } else {
        proceed()
      }
    } else if (type === 'afterRemove') {
      const args = new Args(this.context, { getFile, getFileList })
      this.runRunnableContent('afterRemove', { args })
    }
  }

  /**
   * 压缩图片
   * @parmas {File | Base64} file 需要压缩的图片文件
   * @params {number} size 压缩阈值
   */
  compressImage (file: File | Blob | string, size: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // 根据 size 值计算一个 size 范围出来
      // 默认给一个 0.95 精度
      const config = {
        minSize: size * 0.95,
        maxSize: size * (2 - 0.95),
        size: size
      }
      let fileType = 'image/jpeg'
      const _this = this as any
      const image = new Image()

      if (!_.isString(file)) {
        // 文件对象
        fileType = file.type
        // FileReader 对 IE 的支持只到 10+
        const reader = new FileReader()
        reader.onload = function () {
          image.src = this.result as string
        }
        reader.readAsDataURL(file)
      } else {
        // 裁剪后传入的 base64 格式
        image.src = file
      }
      image.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        let width = image.width
        let height = image.height
        // 如果图片大于两百万像素，压缩至两百万以下
        let ratio
        if ((ratio = width * height / 2000000) > 1) {
          ratio = Math.sqrt(ratio)
          width /= ratio
          height /= ratio
        }
        canvas.width = width
        canvas.height = height
        // 铺底色
        ctx.drawImage(image, 0, 0, width, height)
        /**
         * 经过测试发现，blob.size与dataURL.length的比值约等于0.75
         * 这个比值可以同过dataURLtoFile这个方法来测试验证
         * 这里为了提高性能，直接通过这个比值来计算出blob.size
         */
        const proportion = 0.75
        let imageQuality = 0.5
        let compressDataURL
        const tempDataURLs: string[] = ['', '']
        /**
         * HTMLCanvasElement.toDataURL()压缩参数
         * 的最小细粒度为0.01，而2的7次方为128，即只要循环7次，则会覆盖所有可能性
         */
        for (let x = 1; x <= 7; x++) {
          compressDataURL = canvas.toDataURL('image/jpeg', imageQuality)
          const CalculationSize = compressDataURL.length * proportion
          // 如果到循环第七次还没有达到精确度的值，那说明该图片不能达到到此精确度要求
          // 这时候最后一次循环出来的dataURL可能不是最精确的，需要取其周边两个dataURL三者比较来选出最精确的；
          if (x === 7) {
            if (config.maxSize < CalculationSize || config.minSize > CalculationSize) {
              compressDataURL = [compressDataURL, ...tempDataURLs]
                .filter(i => i) // 去除null
                .sort((a, b) => Math.abs(a.length * proportion - config.size) - Math.abs(b.length * proportion - config.size))[0]
            }
            break
          }
          if (config.maxSize < CalculationSize) {
            tempDataURLs[1] = compressDataURL
            imageQuality -= Math.pow(0.5, x + 1)
          } else if (config.minSize > CalculationSize) {
            tempDataURLs[0] = compressDataURL
            imageQuality += Math.pow(0.5, x + 1)
          } else {
            break
          }
        }
        const compressFile = _this.dataURLtoFile(compressDataURL as string, fileType)
        // 如果压缩后体积大于原文件体积，则返回源文件；
        if (!_.isString(file) && compressFile.size > file.size) {
          resolve(file)
        } else if (_.isString(file) && compressFile.size > file.length * proportion) {
          resolve(_this.dataURLtoFile(file, fileType))
        }
        resolve(compressFile)
      }
    })
  }

  /**
   * 将一个dataURL字符串转变为一个File（Blob）对象
   * 转变时可以确定File对象的类型
   * @params {string} dataURL
   * @params {string} type
   */
  dataURLtoFile (dataURL: string, type: string): Blob {
    const arr = dataURL.split(',')
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {
      type
    })
  }

  // 在上传成功和删除成功后调用, 根据 fileList 更新 inputValue 字段
  // inputValue 由 name 和 code 拼接成 用 | 隔开
  // 例如 '详情.png?code=32485129814016.png|详情.png?code=32485165465600.png|向下.png?code=32485194825728.png'
  updateInputValue (fileList: Array<Record<string, any>>): void {
    fileList.forEach(f => {
      f.code = f.response?.data || f.code
    })
    if (fileList.length > 0) {
      this.inputValue = fileList
        .filter(file => file.status === 'success')
        .map(f => {
          return f.name + '?code=' + f.code
        })
        .join('|')
    } else {
      this.inputValue = ''
    }
  }

  // 关闭预览
  closeViewer () {
    document.body.style.overflow = prevOverflow
    this.showViewer = false
  }

  // 点击文件列表后的钩子
  async handlePreview (file: File): Promise<void> {
    // 记录当前预览文件 用于获取 initialIndex
    this.previewFile = file
    if (this.isDirectAccessStorageService) {
      // 采用文件直传
      // 获取第一张预览的缩略图
      const code = (this.fileList[this.initialIndex] as any).code
      this.getPreviewFile(code, this.initialIndex)
    }
    prevOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    this.showViewer = true
  }

  // 预览切换时的钩子
  // 用在直传时候,加载预览缩略图
  async handlePreviewSwitch (index: number): Promise<void> {
    const { code } = this.fileList[index]
    if (this.isDirectAccessStorageService) {
      this.getPreviewFile(code, index)
    }
  }

  // 下载文件 直传则调用接口获取下载地址
  async handleDownloadFile (file: Record<string, any>): Promise<void> {
    await this._download({ name: file.name, code: file.code }, this.context.getNamespace())
  }

  // 打包下载全部文件
  async handleDownloadAllFile () {
    await this._downloadAll(this.fileList.map((item: Record<string, any>) => item.code), this.field)
  }

  // 计算转换 压缩阈值为字节
  unitConversion (): number {
    let sizeThreshold: string | number = this.compressImageSizeThreshold
    // 计算压缩阈值为 字节
    // sizeThreshold 值的几种可能情况
    // 1. 纯数字 字节
    // 2. 1m 1mb 可能有大小写
    // 3. 1k 1kb 可能有大小写
    const kbArr = ['k', 'kb']
    const mbArr = ['m', 'mb']
    const unit = sizeThreshold.replace(/\d/g, '').toLowerCase()
    if (kbArr.indexOf(unit) !== -1) {
      // kb
      sizeThreshold = parseInt(sizeThreshold.replace(/\D/g, '')) * 1024
    } else if (mbArr.indexOf(unit) !== -1) {
      // mb
      sizeThreshold = parseInt(sizeThreshold.replace(/\D/g, '')) * 1024 * 1024
    } else {
      // 没写单位或者单位不是这几种都按 字节处理
      sizeThreshold = parseInt(sizeThreshold.replace(/\D/g, ''))
    }
    return sizeThreshold
  }

  // 将获取到的 文件url 根据 code 缓存到 sessionStorage 中
  // 用在 直传预览的时候,切换时候不用频繁调用接口
  saveStorage (code: string, url: string) {
    window.sessionStorage.setItem(code, url)
  }

  // 根据传入的 code 去判断 当前预览文件是取缓存还是接口
  // 用在 直传文件的时候
  // code 文件code
  // index 预览文件索引
  async getPreviewFile (code: string, index: number) {
    const namespace = this.context.getNamespace()
    const storage = this.getStorage(code)
    let useStorage = false
    if (storage) {
      const [recordUrl, recordTime] = storage.split(STORAGE_RECORD_TIME)
      const timeDiff = Date.now() - parseInt(recordTime)
      if (timeDiff < 300 * 1000) {
        // 有缓存且缓存时间小于300s取缓存
        this.$set(this.directPreviewList, index, recordUrl)
        useStorage = true
      }
    }
    if (!useStorage) {
      try {
        const layoutName = this.editLayoutName || this.layoutName
        const { data: { data: { url } } } = await documentDirectPreviewPrepare({ layoutName, namespace, data: { code } }, { silent: true })
        this.$set(this.directPreviewList, index, url)
        this.saveStorage(code, url + STORAGE_RECORD_TIME + Date.now())
      } catch (error) {
        this.$set(this.directPreviewList, index, 'Not available')
      }
    }
  }

  getStorage (code: string): string {
    return window.sessionStorage.getItem(code) || ''
  }

  created () {
    this.showFileList = !this.singleLineDisplay
    this.directPreviewList.length = this.fileList.length
  }
}

