import { LocalModule } from '@/store/modules/local'
import { getCheckedArchiveStorage, getCsrfToken } from '@/utils/data'
import _ from 'lodash'

export default {
  /**
   * 获取用户信息
   * @return {object} user 用户信息
   * */
  getUser (): Record<string, unknown> {
    const user = _.cloneDeep(LocalModule.user)
    delete user.csrfToken
    return user
  },

  getNotifications (): Record<string, number> {
    // eslint-disable-next-line camelcase
    const { todo_count = 0, message_count = 0 } = LocalModule.notifications
    return {
      messageCount: message_count,
      todoCount: todo_count
    }
  },

  getChoice (category: string): Array<{title: string, value: string}> {
    return LocalModule.choices[category]
  },

  /**
   * 获取一个配置项内容
   * @param {string} name 目标配置项名称
   * */
  getConfig (name: string) {
    const configRes = LocalModule.config
    return _.get(configRes, name)
  },

  /**
   * 设置一个配置项内容
   * @param {string} name 目标配置项名称
   * @param {any} value 目标配置项值
   * */
  setConfig (name: string, value: any): void {
    LocalModule.updateClientConfig({ name, value })
  },

  /**
   * 获取一个自定义项内容
   * @param {string} name 目标项名称
   * */
  getState (name: string) {
    const stateRes = LocalModule.customState
    return _.get(stateRes, name)
  },

  /**
   * 设置一个自定义项内容
   * @param {string} name 目标项名称
   * @param {any} value 目标项值
   * */
  setState (name: string, value: any): void {
    LocalModule.updateCustomState({ name, value })
  },

  getCsrfToken () {
    return getCsrfToken()
  },

  getCheckedArchiveStorage () {
    return getCheckedArchiveStorage()
  }
}
