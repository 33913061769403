
















import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'

// 带有头部背景色、边框
@Component({ name: 'lw-section' })
export default class LwSection extends BaseComponent {
}
