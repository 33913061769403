











import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeType, SemanticColorSchemes } from '@/utils/const'
import eventbus from '@/utils/event'

@Component({ name: 'LwTabPane' })
export default class LwTabPane extends BaseComponent {
  _uid!: number
  $parent!: any

  get name (): string { return this.getFinalAttributeValue('name') }

  get enabled (): boolean {
    const { enabled } = this.component
    return enabled
      ? this.getFinalAttributeValue('enabled', { type: attributeType.BOOLEAN })
      : true
  }

  get tagContent (): string | null {
    let result = null
    if (this.component.tagContent !== undefined) {
      result = this.getFinalAttributeValue('tagContent')
    }
    return result
  }

  get tagColorScheme () {
    let tagColorScheme: any = SemanticColorSchemes.PRIMARY
    if (this.component.tagColorScheme) {
      tagColorScheme = this.getFinalAttributeValue('tagColorScheme')
    }
    return tagColorScheme
  }

  @Watch('title')
  handleTitleChange () {
    this.$emit('title-change')
  }

  updateActiveBarStyle () {
    // 用在 tabs 嵌套时，手动触发 内层 lw-tabs 组件计算avtiveBar 的样式
    eventbus.$emit('lw-tabs.update-active-bar-style', this)
  }

  created () {
    this.$parent.childrenTabPanesComponents.push(this)
  }

  beforeDestroy () {
    this.$parent.childrenTabPanesComponents.splice(this.$parent.childrenTabPanesComponents.findIndex((c: any) => c._uid === this._uid), 1)
  }
}
