import ComponentStructure from '../basic'

export default class I18nForWrapper extends ComponentStructure {
  name = 'lw-wrapper'
  properties = []

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    return this.component.components || []
  }
}
