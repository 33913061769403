import { DataRow as IDataRow, DataSet } from '@/types/data'
import DataRow from '@/models/DataRow'
import { LayoutContext } from '@/types/layout'
import { rowPersist, SCRIPT_ENVIRONMENT } from '@/utils/const'
import { LayoutModule } from '@/store/modules/layout'
import logwire from '@/logwire'
import { getLayoutNameOutsideComponent } from '@/utils/layout'

export default class Args {
  readonly #row!: IDataRow | void
  readonly #context!: LayoutContext
  readonly #evaluatingBase!: string | void
  readonly event!: any

  constructor (context: LayoutContext, param?: { row?: IDataRow, evaluatingBase?: string, event?: any, [propName: string]: any }) {
    this.#context = context
    this.#row = param?.row
    this.#evaluatingBase = param?.evaluatingBase
    if (param) {
      for (const key in param) {
        if (key === 'row') continue
        this[`${key}`] = param[key]
      }
    }
  }

  getContext () {
    return this.#context
  }

  getCurrentRow () {
    if (!this.#row) return null
    // 返回一个 DataRow 实例
    if (this.#evaluatingBase === 'advancedFilter') {
      logwire.ui.message({ message: 'args.getCurrentRow() NOT applicable in this context.', type: 'error' })
      return null
    }
    return new DataRow(this.#row)
  }

  getCurrentIndex () {
    if (!this.#row) return undefined
    if (this.#evaluatingBase === 'advancedFilter') {
      logwire.ui.message({ message: 'args.getCurrentRow() NOT applicable in this context.', type: 'error' })
      return null
    }
    const layoutName = getLayoutNameOutsideComponent()
    const dataSets = LayoutModule.data?.[layoutName]?.dataSet as Record<string, DataSet>
    let currentIndex = -1
    // 遍历当前 layout 下的全部 dataSet 去寻找对应关系
    for (const ds of Object.values(dataSets)) {
      if (ds.rows) {
        let index = -1
        index = ds.rows.indexOf(this.#row)
        if (index !== -1) {
          currentIndex = ds.rows.filter(r => r.rowPersist !== rowPersist.DELETE).indexOf(this.#row)
          break
        }
      }
    }
    return currentIndex
  }

  getEvaluatingBase () {
    // tableRow advancedFilter editRow
    return this.#evaluatingBase
  }
}
