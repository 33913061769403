




















































import { Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import BaseComponent from '../../BaseComponent'
import logwire from '@/logwire'
import { getMaxZIndex } from '@/utils/dom'
import { getPartition } from '@/utils/data'
import { commonSemanticPartitions } from '@/utils/const'

@Component({ name: 'FilterRow' })
class PartitionFilter extends BaseComponent {
  @Prop({ default: commonSemanticPartitions.LAST_MONTH }) defaultCommonPartition!: string
  @Prop({ default: 366 }) maxPartitionRangeDays!: number

  $refs!: { partitionFilterDrop: any }

  startValue = ''
  endValue = ''
  commonSemanticPartitions = commonSemanticPartitions
  currentPartition = commonSemanticPartitions.LAST_MONTH
  commonPartitions = { [commonSemanticPartitions.TODAY]: 'today', [commonSemanticPartitions.LAST_WEEK]: 'last-week', [commonSemanticPartitions.LAST_MONTH]: 'last-month', [commonSemanticPartitions.LAST_3_MONTH]: 'last-3-month', [commonSemanticPartitions.LAST_6_MONTH]: 'last-6-month', [commonSemanticPartitions.LAST_YEAR]: 'last-year' }
  dialogVisible = false
  pickerOptions = {}

  mounted () {
    this.currentPartition = this.commonPartitions[this.defaultCommonPartition]
  }

  changeStartTime (startDate: string) {
    const maxPartitionRangeDays = Math.max(Math.min(this.maxPartitionRangeDays, 366), 1)
    const startTime = new Date(startDate).getTime()
    const onDay = 3600 * 1000 * 24 * 1
    const maxDay = 3600 * 1000 * 24 * (maxPartitionRangeDays - 1)
    if (this.endValue) {
      const endValueTime = new Date(this.endValue).getTime()
      if (endValueTime < startTime || endValueTime > startTime + maxDay) this.endValue = ''
    }
    this.pickerOptions = {
      disabledDate (endDate: Date) {
        const endTime = endDate.getTime()
        if (startTime) {
          return endTime < startTime - onDay || endTime > startTime + maxDay
        }
      }
    }
  }

  handleCommand (command: string) {
    if (command !== 'custom') {
      this.currentPartition = this.commonPartitions[command]
      let partitionFilter = getPartition(command)
      partitionFilter = Object.assign(partitionFilter, { name: command })
      this.$emit('queryByPartition', partitionFilter)
    }
    this.$refs.partitionFilterDrop.hide()
  }

  setCustomizePartition () {
    this.currentPartition = 'customize'
    // 获取自定义的开始和结束时间
    if (!this.startValue || !this.endValue) return
    const partitionFilter = { beginDate: this.startValue, endDate: this.endValue, name: 'custom' }
    this.$emit('queryByPartition', partitionFilter)
    this.dialogVisible = false
  }

  handleFocus (self: any): void {
    setTimeout(() => {
      self.picker.$el.style.zIndex = getMaxZIndex() + ''
    })
  }

  get displayFormat (): string {
    return logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  }
}

export default PartitionFilter
