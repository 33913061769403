<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellDate from './TableCellDateTime.vue'
import FormDateTime from './DateTime.vue'

@Component({ name: 'LwDateTime', components: { TableCell: TableCellDate, FormItem: FormDateTime } })
class LwDateTime extends FormControlBase {}
export default LwDateTime
</script>
