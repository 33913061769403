import { Component, Vue } from 'vue-property-decorator'

@Component
class TextBase extends Vue {
  [propName: string]: any;
  get prepend (): string { return this.getFinalAttributeValue('prepend', { args: this.args }) }

  get append (): string { return this.getFinalAttributeValue('append', { args: this.args }) }

  get displayValue (): string {
    let result = this.inputValue
    if (result) {
      if (this.prepend) {
        result = this.prepend + result
      }
      if (this.append) {
        result += this.append
      }
    }
    return result
  }
}

export default TextBase
