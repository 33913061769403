/* eslint-disable dot-notation */
import logwire from '@/logwire'
import ComponentStructure from '../basic'

export default class I18nForLwDynamic extends ComponentStructure {
  properties = ['title']
  name = 'lw-dynamic'

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    const dynamic = logwire.ui.getDynamic(this.component.name)
    if (dynamic) {
      return dynamic.components || []
    } else {
      return []
    }
  }
}
