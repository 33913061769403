import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import logwire from '@/logwire'
import { attributeType } from '@/utils/const'

@Component
class SelectBase extends Vue {
  [propName: string]: any;

  get displayValue () {
    return this.getTemplate()
  }

  getTemplate (): string | string[] {
    const { displayTemplate, displayContent, displayField } = this.component
    const row = this.form?.dataRow || this.tableRow?.currentData
    if (this.evaluatingBase === 'advancedFilter') {
      return row[this.field + '_label']
    } else {
      let label: string | string[] = ''
      if (displayTemplate) {
        label = _.template(displayTemplate)({ args: this.args, logwire })
        return _.unescape(label)
      } else if (displayContent) {
        label = this.getFinalAttributeValue('displayContent', { args: this.args, types: [attributeType.STRING, attributeType.STRING_ARRAY] })
      } else if (displayField) {
        label = row[displayField]
      }
      return label
    }
  }
}

export default SelectBase
