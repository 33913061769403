import _ from 'lodash'
import { ceil, numberSplitByWan } from './layout'

export function numeralFormat (val: number | string, decimalScaleParam: number, thousandsGroupStyle: string, percentage?: boolean) {
  const decimalScale = decimalScaleParam || 0
  const thousandStyle = thousandsGroupStyle || 'thousand'
  const splitArray = val.toString().split('.')
  let integer: number | string = parseInt(splitArray[0])
  let decimal = splitArray.length > 1 ? splitArray[1] : ''
  // 四舍五入处理
  if (decimal.length > decimalScale) {
    /* eslint-disable */
    val = ceil(val, decimal, decimalScale) ? _.ceil(Number(val), decimalScale)
      : _.floor(Number(val), decimalScale)
    integer = parseInt(val.toString().split('.')[0])
    if (val.toString().split('.').length > 1) {
      decimal = '.' + val.toString().split('.')[1]
    } else {
      decimal = ''
    }
  }
  switch (thousandStyle) {
    case 'thousand':
      integer = integer.toLocaleString('en')
      break
    case 'wan':
      integer = numberSplitByWan(integer)
      break
    case 'lakh':
      integer = integer.toLocaleString('en-IN')
      break
  }
  
  if (decimalScale !== 0 && decimal.replace('.', '').length <= decimalScale) {
    const decimalCopy = decimal.replace('.', '')
    const suffixLength = decimalScale - decimalCopy.length
    const suffixZero = new Array(suffixLength).fill(0).join('')
    suffixLength >= 0 && (decimal = `.${decimalCopy}${suffixZero}`)
  }
  return percentage ? `${integer}${decimal}%` : `${integer}${decimal}`
}