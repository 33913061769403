/* eslint-disable standard/no-callback-literal */
import { getLogwireAuthorizeUrl, getLogwireFeedbackConfig, getLogwireToken, getLogwireTokenByCode, getUserInfoAction, getVersion, refreshLogwireToken } from '@/http/api'
import logwire from '@/logwire'
import { LogwireC } from '@/vue'
import { AxiosResponse } from 'axios'

interface LogwireResponse<T> {
  code: number;
  traceId: string;
  message: string;
  messageType: 'error' | 'success';
  data: T;
  stackTrace?: any;
}

interface FeedbackResponse {
  feedbackEnabled: boolean
  clientId: string
}

let promise: Promise<LogwireC.FeedbackInstance>
let promiseConfig: Promise<AxiosResponse<FeedbackResponse>>
// eslint-disable-next-line prefer-promise-reject-errors
let promiseReady: Promise<LogwireC.FeedbackInstance>
const clientError: LogwireC.ClientError = []
const serverError: LogwireC.ServerError = []

/**
 * 获取当前用户反馈配置
 */
export async function getFeedbackConfig (): typeof promiseConfig {
  if (promiseConfig) return promiseConfig
  promiseConfig = getLogwireFeedbackConfig()
  return promiseConfig
}

/**
 * 获取协同云反馈对象实例，在 FeedbackMonitor 中 created 生命周期会被加载一次，为了获取 todo 数量，其他情况都是手动调用
 */
export async function getFeedbackInstance (): Promise<LogwireC.FeedbackInstance> {
  if (promise) return promise

  if (!document.querySelector('#coopwire-feedback-js')) {
    const coopwireFeedbackJs = logwire.store.getConfig('coopwireBaseUrl') + '/open/index.js?v=1.0&callback=onLoad'
    const coopwireFeedbackCss = logwire.store.getConfig('coopwireBaseUrl') + '/open/index.css'
    const script = document.createElement('script')
    script.setAttribute('src', coopwireFeedbackJs)
    script.setAttribute('nonce', 'logwire')
    script.setAttribute('id', 'coopwire-feedback-js')
    const link = document.createElement('link')
    link.setAttribute('href', coopwireFeedbackCss)
    link.setAttribute('rel', 'stylesheet')

    return new Promise((resolve, reject) => {
      script.onload = function () {
        resolve(getFeedbackInstance())
      }

      document.head.appendChild(script)
      document.head.appendChild(link)
    })
  } else if (!window.LC?.Feedback) {
    return new Promise((resolve, reject) => {
      const script = document.querySelector('#coopwire-feedback-js') as HTMLScriptElement
      script.onload = function () {
        resolve(getFeedbackInstance())
      }
    })
  }

  const resVersion = await getVersion()
  const { data: { data: backendVersionData } } = resVersion

  const resUser = await getUserInfoAction()

  const feedback = new window.LC.Feedback({
    baseUrl: logwire.store.getConfig('coopwireBaseUrl'),
    systemInfo: {
      version: backendVersionData
    },
    systemType: '05',
    userInfo: {
      uid: resUser.data.data.id,
      email: resUser.data.data.email,
      username: resUser.data.data.username
    },
    type: 'feedback',
    themeColor: '#2e75e6',
    clientError: clientError,
    serverException: serverError,
    getClientId () {
      getFeedbackConfig().then(res => {
        feedback.setClientId(res.data.clientId)
      })
    },
    getApplicationToken () {
      getLogwireToken().then(res => {
        feedback.setApplicationToken(res.data)
      })
    },
    getRefreshToken (accessToken, refreshToken) {
      refreshLogwireToken({ data: { refresh_token: refreshToken } }).then(res => {
        feedback.setLCRefreshToken(res.data)
      })
    },
    handleClickLoginButton () {
      getLogwireAuthorizeUrl({ data: { currentUrl: window.location.href } }).then(res => {
        // 计算居中
        const width = screen.availWidth
        const left = (width - 660) / 2
        const newTabWindow = window.open(res.data.url, '_blank', 'popup=true,width=660,height=791,left=' + left)
        if (newTabWindow) {
          window.addEventListener('message', evt => {
            if (evt.origin === window.location.origin) {
              let data = evt.data
              if (typeof data === 'string' && data.length) {
                data = JSON.parse(data)
                getLogwireTokenByCode({ data: { code: data.code, state: data.state } }).then(res => {
                  feedback.setLCAccountToken(res.data)
                })
              }
            }
          })
        }
      })
    }
  })

  promise = new Promise((resolve, reject) => {
    resolve(feedback)
  })
  promiseReady = new Promise((resolve, reject) => {
    feedback.on('complete', () => {
      if (window.location.search && window.location.search.match(/fid=(.*?)/)) {
        const fid = window.location.search.match(/fid=(.*?)&/)
          ? /fid=(.*?)&/.exec(window.location.search)?.[1]
          : /fid=(.*)/.exec(window.location.search)?.[1]
        if (fid) {
          feedback.showDetailPage(fid)
        }
      }
      resolve(feedback)
    })
  })

  return promise
}

export function getFeedbackInstanceReady () {
  return promiseReady
}
