<script>
import DateTable from '../basic/dateTable.vue'
import DatePanel from 'element-ui/packages/date-picker/src/panel/date.vue'
import logwire from '@/logwire';

export default {
  mixins: [DatePanel],

  components: {
    DateTable
  },

  methods: {
    changeToNow() {
      // NOTE: not a permanent solution
      //       consider disable "now" button in the future
      if ((!this.disabledDate || !this.disabledDate(new Date(logwire.util.now().toString()))) && this.checkDateWithinRange(new Date(logwire.util.now().toString()))) {
        this.date = new Date(logwire.util.now().toString());
        this.emit(this.date);
      }
    },
  }
}
</script>
