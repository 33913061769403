



















import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeType } from '@/utils/const'
import { LayoutComponent } from '@/types/layout'
import { getScrollParent, addResizeListener, removeResizeListener } from '@/utils/dom'

@Component({ name: 'LwToolbar' })
export default class LwToolbar extends BaseComponent {
  scrollableParent: HTMLElement | null = null
  offsetTopToScrollableParent = 0
  toolbarFixed = false
  fixedToolbarStyle = {}
  $el!: HTMLElement

  get align (): string { return this.getFinalAttributeValue('align') || 'right' }

  get stickyPosition (): string { return this.getFinalAttributeValue('stickyPosition', { type: attributeType.BOOLEAN }) || false }

  get childGroup (): Array<LayoutComponent> {
    return this.component.components?.filter(c => c.is === 'lw-button' || c.is === 'lw-button-group') || []
  }

  handleParentScroll (e: any): void {
    if (e.target.scrollTop > this.offsetTopToScrollableParent) {
      if (this.toolbarFixed) return
      this.toolbarFixed = true
      this.getFixToolbarStyle()
      let placeholder: HTMLElement | null = document.createElement('div')
      placeholder.classList.add('toolbar-place-holder')
      placeholder.style.paddingTop = this.$el.clientHeight + 'px';
      (this.$el.parentNode as HTMLElement).insertBefore(placeholder, this.$el)
      placeholder = null
      removeResizeListener(this.$el, this.getOffsetTopToScrollableParent)
    } else {
      let placeholder = document.querySelector('.toolbar-place-holder')
      placeholder && placeholder.parentNode && placeholder.parentNode.removeChild(placeholder)
      placeholder = null
      this.toolbarFixed = false
      this.fixedToolbarStyle = {}
      addResizeListener(this.$el, this.getOffsetTopToScrollableParent)
    }
  }

  getFixToolbarStyle (): void {
    if (!this.toolbarFixed || !this.scrollableParent) return
    const { clientWidth } = this.scrollableParent
    let offsetLeft = 0
    let offsetTop = 0
    let node = this.scrollableParent
    while (node) {
      offsetLeft += node.offsetLeft
      offsetTop += node.offsetTop
      node = node.offsetParent as HTMLElement
    }
    this.fixedToolbarStyle = {
      left: offsetLeft + 'px',
      top: offsetTop + 'px',
      width: clientWidth + 'px'
    }
  }

  getOffsetTopToScrollableParent () {
    this.offsetTopToScrollableParent = this.$el.getBoundingClientRect().top - this.scrollableParent!.getBoundingClientRect().top
  }

  mounted (): void {
    if (this.stickyPosition) {
      let parentNode = this.$el.parentNode as HTMLElement
      while (parentNode) {
        // dialog 中滚动的是 el-dialog__body 而不是 lw-content
        if (parentNode?.classList?.contains('el-dialog__body')) {
          this.scrollableParent = parentNode
          break
        }
        parentNode = parentNode.parentNode as HTMLElement
      }
      if (!this.scrollableParent) this.scrollableParent = getScrollParent(this.$el)
      addResizeListener(this.$el, this.getOffsetTopToScrollableParent)
      this.scrollableParent.addEventListener('scroll', this.handleParentScroll)
      // 在父级元素大小被改变时，需要重新设置样式
      addResizeListener(this.scrollableParent, this.getFixToolbarStyle)
    }
  }

  beforeDestroy () {
    removeResizeListener(this.$el, this.getOffsetTopToScrollableParent)
    removeResizeListener(this.scrollableParent, this.getFixToolbarStyle)
    this.scrollableParent && this.scrollableParent.removeEventListener('scroll', this.handleParentScroll)
  }
}
