




















import BaseComponent from '@/components/layout/BaseComponent'
import { Component, Prop, Watch } from 'vue-property-decorator'
import FormDisplayText from './FormDisplayText.vue'
import eventbus from '@/utils/event'
import { getCsrfToken } from '@/utils/data'
import { getArchiveStorageAppend } from '@/utils/common'
import logwire from '@/logwire'
import axios from 'axios'

@Component({ name: 'TableCell', components: { FormDisplayText } })
export default class TableCell extends BaseComponent {
  @Prop() inputValue: any
  @Prop() disabled!: boolean
  @Prop() preWrap!: boolean
  @Prop() dataRow: any
  @Prop() field!: string
  @Prop() componentName!: string

  handleQuickEdit () {
    this.$emit('quick-edit')
  }

  handleDataRowReset () {
    this.$emit('data-row-reset')
  }

  changed () {
    return this.dataRow.currentData[this.field] !== this.dataRow.originalData[this.field]
  }

  getFileName (value: string) {
    return value.split('|').map(o => {
      return o.replace(/(.*)\?.*?$/, '$1')
    }).join(',')
  }

  handleClickPreviewFile () {
    const namespace = this.context.getNamespace()
    const urls = (this.inputValue as string).split('|').filter(url => url !== '').map(item => {
      const [name, src] = item.split('?code=')
      const url = `/api/open/${namespace}/core.document-preview?name=${name}&code=${src}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
      return url
    })
    eventbus.$emit('preview-file', urls)
  }
}
