import { render, staticRenderFns } from "./FeedbackCreateBar.vue?vue&type=template&id=11a24f17&scoped=true&"
import script from "./FeedbackCreateBar.vue?vue&type=script&lang=ts&"
export * from "./FeedbackCreateBar.vue?vue&type=script&lang=ts&"
import style0 from "./FeedbackCreateBar.vue?vue&type=style&index=0&id=11a24f17&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a24f17",
  null
  
)

export default component.exports