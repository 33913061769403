import { IDataTree, IDataTreeNode } from '@/types/data'
import DataTreeNode from './DataTreeNode'
import eventbus from '@/utils/event'
import Vue from 'vue'
import { LayoutModule } from '@/store/modules/layout'

export default class DataTree {
  #dataTree: IDataTree
  #layoutName: string
  constructor (datatree: IDataTree, layoutName: string) {
    this.#dataTree = datatree
    this.#layoutName = layoutName
  }

  get dataTree (): IDataTree {
    return JSON.parse(JSON.stringify(this.#dataTree))
  }

  set dataSet (params: IDataTree) {
    console.warn('The property "dataSet" is read-only')
  }

  getDataTreeName () {
    return this.#dataTree.dataTreeName
  }

  setTree (nodes: Array<IDataTreeNode>) {
    this.#dataTree.nodes = nodes
  }

  getChildren () {
    const nodes = this.#dataTree.nodes
    return nodes.map(node => new DataTreeNode(node))
  }

  getCheckedNodes () {
    const payload = { nodes: [] }
    eventbus.$emit(`${this.#layoutName}.${this.getDataTreeName()}.getDataTreeSelectedNodes`, payload)
    return payload.nodes
  }

  // 通过 dataTree 上的 id 对 tree 节点进行勾选
  // params --- id 数组
  setCheckedNodes (keys: Array<string | number>) {
    eventbus.$emit(`${this.#layoutName}.${this.getDataTreeName()}.setDataTreeCheckedNodes`, keys)
  }
}
