
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import TimestampBase from './TimestampBase'

@Component({
  name: 'TableCellTimestamp'
})
class TableCellTimestamp extends Mixins(TableCell, TimestampBase) {}
export default TableCellTimestamp
