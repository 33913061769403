import _ from 'lodash'
import { _addAppWebSocketListener, _removeAppWebSocketListener } from '@/utils/common'

// Web Socket 相关 API
function addWebSocketOpenListener (listenerName: string, callback: () => void) {
  _addAppWebSocketListener('open', listenerName, callback)
}
function addWebSocketCloseListener (listenerName: string, callback: () => void) {
  _addAppWebSocketListener('close', listenerName, callback)
}
function addWebSocketCustomMessageListener (listenerName: string, customType: string, callback: () => void) {
  _addAppWebSocketListener('customMessage', listenerName, callback, customType)
}
function removeWebSocketOpenListener (listenerName: string) {
  _removeAppWebSocketListener('open', listenerName)
}
function removeWebSocketCloseListener (listenerName: string) {
  _removeAppWebSocketListener('close', listenerName)
}
function removeWebSocketCustomMessageListener (listenerName: string) {
  _removeAppWebSocketListener('customMessage', listenerName)
}
export default {
  addWebSocketOpenListener,
  addWebSocketCloseListener,
  addWebSocketCustomMessageListener,
  removeWebSocketOpenListener,
  removeWebSocketCloseListener,
  removeWebSocketCustomMessageListener
}
