
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import ChoiceBase from './ChoiceBase'

@Component({
  name: 'TableCellChoice'
})
class TableCellChoice extends Mixins(TableCell, ChoiceBase) {}
export default TableCellChoice
