import { LayoutFunction } from '@/types/layout'

export default class TagContent {
  // 如过构造函数时候提供 content，color, size 参数还需要 setContent 和 setColor 、 setSize ？
  #content!: string
  #colorScheme!: string | null
  #size!: string | null
  #onClick!: LayoutFunction | null

  constructor (options?: { content?: string, colorScheme?: string, size?: string, onClick?: LayoutFunction }) {
    this.#content = options?.content || ''
    this.#colorScheme = options?.colorScheme || null
    this.#size = options?.size || null
    this.#onClick = options?.onClick || null
  }

  getContent (): string {
    return this.#content
  }

  setContent (content: string): void {
    this.#content = content
  }

  getColorScheme (): string | null {
    return this.#colorScheme
  }

  setColorScheme (color: string): void {
    this.#colorScheme = color
  }

  // 文档没有注明 size 的相关方法，先提供，不合适再去除
  getSize (): string | null {
    return this.#size
  }

  setSize (size: string): void {
    this.#size = size
  }

  getOnClick () {
    return this.#onClick
  }

  setOnClick (callback: LayoutFunction): void {
    this.#onClick = callback
  }
}
