import axios, { LoadingLevel } from './index'
import { AxiosRequestConfig } from 'axios'

// 统一管理请求 api

export const loginCheck = () => axios.post('/api/action/core/core.login-check')

export const getLoginEntry = (data: Record<string, any>) => axios.post('/api/open/core/core.get-login-entry', data)

// 获取 backend 版本接口
export const getVersion = () => axios.get('/api/action/core/core.get-version')

// 获取 gateway 版本接口
export const getGatewayVersion = () => axios.get('/api/gateway/get-version')

export const getUserDashboard = () => axios.post('/api/action/core/core.get-user-home-layout-name', null, { silent: true } as AxiosRequestConfig)

// export const getLayout = ({ namespace = 'core', layout = '' }) => axios.post(`/api/action/${namespace}/core.get-layout:${layout}`, {}, { loadingLevel: LoadingLevel.CONTENT } as AxiosRequestConfig)
export const getLayout = (params: Record<string, any>) => axios.post(`/api/action/core/core.get-layout:${params.layout}`, {}, { loadingLevel: LoadingLevel.CONTENT, layout: params.layout, silent: params.silent } as AxiosRequestConfig)

export const getClientResourceList = () => axios.post('/api/action/core/core.get-client-resource-list', { resourceType: 'resource-browser' }, { silent: true } as AxiosRequestConfig)

export const apiClientResource = '/api/action/core/core.get-client-resource'

export const getClientResource = ({ path = '', expectJson = false }) => axios.post(apiClientResource, { resourceType: 'resource-browser', path, expectJson }, { silent: true } as AxiosRequestConfig)

export const getClientConfig = () => axios.post('/api/action/core/core.get-client-properties')

export const getUserInfoAction = () => axios.post('/api/action/core/core.get-user-info')

export const cancelActionUrl = '/api/action/core/core.cancel-context'

export const cancelAction = (traceId: string) => axios.post(`${cancelActionUrl}`, { traceId }, { silent: true } as AxiosRequestConfig)

export const getAsset = (namespace = '', path = '') => axios.get(`/api/open/${namespace}/core.get-asset?isDownload=false&path=${path}`)

export const getI18nMessages = () => axios.post('/api/action/core/core.get-i18n', { all: true })

export const getTheme = (themeName = '') => axios.post('/api/action/core/core.get-theme', { themeName })

export const getChoice = () => axios.post('/api/action/core/core.get-choice')

export const getUserMenu = () => axios.post('/api/action/core/core.get-menu')

export const getNotifications = () => axios.post('/api/action/core/core.get-latest-notifications', {}, { silent: true } as AxiosRequestConfig)

export const updateFavoriteMenu = ({ data }: Record<string, any>) => axios.post('/api/action/core/core.update-favorite-menu', data)

export const doOpenApi = ({ name, namespace = '', layoutName = '', data }: Record<string, any>, config?: Record<string, any>) => axios.post(`/api/open/${layoutName === '' ? namespace : layoutName}/${name}`, data, config)

export const doAction = ({ layoutName = '', namespace = '', actionName = '', actionSubName = '', data }: Record<string, any>, config?: Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/${actionName}${actionSubName ? ':' + actionSubName : ''}`, data, config)

export const queryByFilter = ({ layoutName = '', namespace = '', queryName = '', filter }: Record<string, any>, config?: Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.query-by-filter:${queryName}`, filter, config)

export const countByFilter = ({ layoutName = '', namespace = '', queryName = '', filter }: Record<string, any>, config?: Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.count-by-filter:${queryName}`, filter, config)

export const queryById = ({ layoutName = '', namespace = 'core', queryName = '', id = 0 }) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.query-by-id:${queryName}`, { id })

export const doSave = ({ layoutName = '', namespace = '', queryName = '', data } : Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.save:${queryName}`, data)

export const getQueryMeta = ({ layoutName = '', namespace = '', queryName = '' } : Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.get-query-metadata:${queryName}`)

export const doDelete = ({ layoutName = '', namespace = '', queryName = '', data } : Record<string, any>) => axios.post(`/api/action/${layoutName === '' ? namespace : layoutName}/core.delete:${queryName}`, data)

// 直传文件服务器 准备接口
export const documentDirectUploadPrepare = ({ namespace, data } : Record<string, any>) => axios.post(`/api/action/${namespace}/core.document-direct-upload-prepare`, data)

// 直传文件服务器 上传成功通知接口
export const documentDirectUploadedNotify = ({ namespace, data } : Record<string, any>) => axios.post(`/api/action/${namespace}/core.document-direct-uploaded-notify`, data)

// 直传文件服务器 文件下载准备接口
export const documentDirectDownloadPrepare = ({ namespace, data } : Record<string, any>) => axios.post(`/api/action/${namespace}/core.document-direct-download-prepare`, data)

// 直传文件服务器 文件预览准备接口
export const documentDirectPreviewPrepare = ({ namespace, data } : Record<string, any>, config?: Record<string, any>) => axios.post(`/api/action/${namespace}/core.document-direct-preview-prepare`, data, config)

export const getIdps = () => axios.get('/api/open/core/core.get-idps')

// 获取归档数据库
export const getArchiveStorages = () => axios.post('/api/action/core/core.get-archive-storages')

export const getChoiceDimension = (dimension = '') => axios.post('/api/action/core/core.get-dimension-choice', { dimension })

export const getLogwireToken = () => axios.post('/api/open/core/core.logwirecloud-get-token')

export const getLogwireAuthorizeUrl = ({ data }: { data: Record<string, string> }) => axios.post('/api/open/core/core.logwirecloud-get-authorize-url', data)

export const getLogwireTokenByCode = ({ data }: { data: Record<string, string> }) => axios.post('/api/open/core/core.logwirecloud-get-token-by-code', data)

export const refreshLogwireToken = ({ data }: { data: Record<string, string> }) => axios.post('/api/open/core/core.logwirecloud-refresh-token', data)

export const getLogwireFeedbackConfig = () => axios.post('/api/open/core/core.logwirecloud-get-config')

// 在线修改多语言
export const getLayoutAllI18nOfAllLanguage = (data: { layoutName: string }, config?: any) => axios.post('/api/action/core.special_resources/core.get-layout-i18n-of-all-languages', data, config)

export const getMessageAllI18nOfAllLanguage = (config?: any) => axios.post('/api/action/core.special_resources/core.get-messages-i18n-of-all-languages', null, config)
