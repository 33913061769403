





































































































import { on, off } from 'element-ui/src/utils/dom'
import { rafThrottle, isFirefox } from 'element-ui/src/utils/util'
import axios, { CancelTokenSource } from 'axios'
import { getMaxZIndex } from '@/utils/dom'
import { getCsrfToken } from '@/utils/data'
import { getArchiveStorageAppend, getFileType, isTypeFile } from '@/utils/common'
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { audioType, imgType, showPreviewType, videoType } from '@/utils/const'
import { LocalModule } from '@/store/modules/local'

const Mode = {
  CONTAIN: {
    name: 'contain',
    icon: 'el-icon-full-screen'
  },
  ORIGINAL: {
    name: 'original',
    icon: 'el-icon-c-scale-to-original'
  }
}

const mousewheelEventName = isFirefox() ? 'DOMMouseScroll' : 'mousewheel'

const maskPaddingBottom = 144

/**
 * fileList 和 urlList 共同作用，作为数据源，fileList 控制了多个文件的切换，urlList 控制了文件的预览
 */

@Component
export default class ImageView extends Vue {
  @Prop({ default: 'core' }) namespace !: string
  @Prop({ default: () => [] }) urlList!: string[]
  @Prop({ default: () => [] }) fileList !: {
    code: string
    name: string
    thumbnailUrl: string
  }[]

  @Prop({ default: 2000 }) zIndex !: number
  @Prop({ default: () => () => ({}) }) onSwitch !: (index: number) => void
  @Prop({ default: () => () => ({}) }) onClose !: () => void
  @Prop({ default: 0 }) initialIndex !: number
  @Prop({ default: true }) appendToBody !: boolean
  @Prop({ default: false }) maskClosable !: boolean

  _keyDownHandler !: ((e: any) => void) | null
  _mouseWheelHandler !: ((e: any) => void) | null
  _dragHandler !: ((e: any) => void) | null

  index = this.initialIndex
  isShow = false
  infinite = true
  loading = false
  canPreview = true
  isVideo = false
  isAudio = false
  mode = Mode.CONTAIN
  blobUrl: string | null = null
  fileItemWidth = 104
  transform = {
    scale: 1,
    deg: 0,
    offsetX: 0,
    offsetY: 0,
    enableTransition: false
  }

  source: CancelTokenSource | null = null

  $refs !: {
    scrollContent : HTMLElement
    fileListWrap : HTMLElement
  }

  isTypeFile = isTypeFile

  get isSingle () {
    return this.urlList.length <= 1
  }

  get isFirst () {
    return this.index === 0
  }

  get isLast () {
    return this.index === this.urlList.length - 1
  }

  get currentFileName () {
    if (this.fileList.length) {
      return this.fileList[this.index].name
    } else if (this.urlList.length) {
      const url = this.urlList[this.index]
      // 如果是 api 格式，认为是在表格中点击 lw-upload-file 内容传进来的，如果是 doPreviewFileAction 则应该是 blob 格式
      if (/api\/open\/(.*?)\/core.document-preview/.test(url)) {
        const name = /\?name=(.*?)&/.exec(url)?.[1]
        if (name) return name
      } else { // blob 格式直接返回
        return url
      }
    }
  }

  get currentImg () {
    return this.urlList[this.index]
  }

  get isCurrentFileIsImg () {
    if (this.fileList.length) {
      return isTypeFile(this.fileList[this.index])
    } else if (this.urlList.length && this.currentFileName) {
      return imgType.some(suffix => this.currentFileName!.endsWith(suffix))
    }
  }

  get isCurrentFileDownloadable () {
    return this.fileList.length || this.urlList.every(url => /api\/open\/(.*?)\/core.document-preview/.test(url))
  }

  get imgStyle () {
    const { scale, deg, offsetX, offsetY, enableTransition } = this.transform
    const style: Record<string, string> = {
      transform: `scale(${scale}) rotate(${deg}deg)`,
      transition: enableTransition ? 'transform .3s' : '',
      'margin-left': `${offsetX}px`,
      'margin-top': `${offsetY}px`
    }
    if (this.mode === Mode.CONTAIN) {
      style.maxWidth = style.maxHeight = '100%'
    }
    return style
  }

  get viewerZIndex () {
    const nextZIndex = getMaxZIndex()
    return this.zIndex > nextZIndex ? this.zIndex : nextZIndex
  }

  @Watch('index')
  onIndexChange (val: number) {
    // 切换文件的时候先将blobUrl置空，这样就不会再显示前一个文件了
    this.blobUrl = null
    // 切换文件的时候先将canPreview置为true，这样就不会warning信息了
    this.canPreview = true
    this.reset()
    this.onSwitch(val)
    this.checkActiveFileInView(val)
  }

  @Watch('currentImg')
  onCurrentImgChange (val: string) {
    this.generateBlobUrl()
  }

  // 监听到blobUrl发生变化后，释放之前通过调用 URL.createObjectURL() 创建的 URL 对象
  @Watch('blobUrl')
  onBlobUrlChange (val: string, oldValue: string) {
    window.URL.revokeObjectURL(oldValue)
  }

  handleIframeLoad () {
    this.loading = false
  }

  hide () {
    this.deviceSupportUninstall()
    this.onClose()
  }

  deviceSupportInstall () {
    this._keyDownHandler = e => {
      e.stopPropagation()
      const keyCode = e.keyCode
      switch (keyCode) {
        // ESC
        case 27:
          this.hide()
          break
        // SPACE
        case 32:
          this.toggleMode()
          break
        // LEFT_ARROW
        case 37:
          this.prev()
          break
        // UP_ARROW
        case 38:
          this.handleActions('zoomIn')
          break
        // RIGHT_ARROW
        case 39:
          this.next()
          break
        // DOWN_ARROW
        case 40:
          this.handleActions('zoomOut')
          break
      }
    }
    this._mouseWheelHandler = rafThrottle(e => {
      const delta = e.wheelDelta ? e.wheelDelta : -e.detail
      if (delta > 0) {
        this.handleActions('zoomIn', {
          zoomRate: 0.015,
          enableTransition: false
        })
      } else {
        this.handleActions('zoomOut', {
          zoomRate: 0.015,
          enableTransition: false
        })
      }
    })
    on(document, 'keydown', this._keyDownHandler)
    on(document, mousewheelEventName, this._mouseWheelHandler)
  }

  deviceSupportUninstall () {
    if (this._keyDownHandler) off(document, 'keydown', this._keyDownHandler)
    if (this._mouseWheelHandler) off(document, mousewheelEventName, this._mouseWheelHandler)
    this._keyDownHandler = null
    this._mouseWheelHandler = null
  }

  handleImgLoad () {
    this.backToCenter()
    this.loading = false
  }

  handleImgError (e: Event) {
    this.canPreview = false
    if (e.target instanceof HTMLImageElement) {
      e.target.alt = this.$i18n('core', 'client.tips.image-unavaliable')
    }
  }

  beforeDrag (e: Event) {
    const { offsetX, offsetY, scale, deg } = this.transform
    const reverse = Boolean(deg % 180)
    // 获取图片显示区域的宽高
    const canvas = document.querySelector('.el-image-viewer__canvas') as HTMLElement
    const img = document.querySelector('.el-image-viewer__img')
    const canvasHeight = canvas.offsetHeight - maskPaddingBottom
    const canvasWidth = canvas.offsetWidth
    // 根据缩放和旋转计算图片的宽高
    const imgInitHeight = (e.target as HTMLImageElement).height
    const imgInitWidth = (e.target as HTMLImageElement).width
    const imgCurHeight = Math.ceil((reverse ? imgInitWidth : imgInitHeight) * scale)
    const imgCurWidth = Math.ceil((reverse ? imgInitHeight : imgInitWidth) * scale)
    // 计算图片最大和最小的移动范围
    const minMarginLeft = (imgCurWidth - imgInitWidth) / 2
    const maxMarginLeft = (canvasWidth - imgInitWidth) - minMarginLeft
    const minMarginTop = (imgCurHeight - imgInitHeight) / 2
    const maxMarginTop = (canvasHeight - imgInitHeight) - minMarginTop

    const drag = { isDrag: false, img, minMarginLeft, maxMarginLeft, minMarginTop, maxMarginTop }
    // 图片显示未超出显示区域的时候不可以拖动
    if (!(offsetX >= minMarginLeft && offsetX <= maxMarginLeft && offsetY >= minMarginTop && offsetY <= maxMarginTop)) {
      drag.isDrag = true
    }
    return drag
  }

  handleMouseOver (e: Event) {
    const { isDrag, img } = this.beforeDrag(e)
    if (isDrag) {
      // 拖动图片时鼠标状态为 grab
      img && img.classList.add('img-grab')
    } else {
      img && img.classList.remove('img-grab')
    }
  }

  handleMouseDown (e: MouseEvent) {
    if (this.loading || e.button !== 0) return
    // 阻止默认行为，防止出现拖动阴影
    e.preventDefault()

    const { isDrag, img, minMarginLeft, maxMarginLeft, minMarginTop, maxMarginTop } = this.beforeDrag(e)
    if (!isDrag) return

    const { offsetX, offsetY } = this.transform
    const startX = e.pageX
    const startY = e.pageY

    // mousemove 事件进行拖动
    this._dragHandler = rafThrottle(ev => {
      img && img.classList.add('img-grabbing')
      // 横向拖动
      const horizontalMove = ev.pageX - startX
      let marginLeft = offsetX + horizontalMove
      if (horizontalMove < 0) {
        // 往左边拖动
        if (marginLeft < maxMarginLeft) marginLeft = maxMarginLeft
      } else {
        // 往右边拖动
        if (marginLeft > minMarginLeft) marginLeft = minMarginLeft
      }
      // 横向上超出才可以横向拖动
      if (!(offsetX >= minMarginLeft && offsetX <= maxMarginLeft)) {
        this.transform.offsetX = marginLeft
      }
      // 竖向拖动
      const verticalMove = ev.pageY - startY
      let marginTop = offsetY + verticalMove
      if (verticalMove < 0) {
        // 往上边拖动
        if (marginTop < maxMarginTop) marginTop = maxMarginTop
      } else {
        // 往下边拖动
        if (marginTop > minMarginTop) marginTop = minMarginTop
      }
      // 竖向上超出才可以竖向拖动
      if (!(offsetY >= minMarginTop && offsetY <= maxMarginTop)) {
        this.transform.offsetY = marginTop
      }
    })

    // 注册和销毁 mousemove mouseup 事件
    const handleMouseUp = (ev: MouseEvent) => {
      img && img.classList.remove('img-grabbing')
      if (this._dragHandler) off(document, 'mousemove', this._dragHandler)
      off(document, 'mouseup', handleMouseUp)
    }
    on(document, 'mousemove', this._dragHandler)
    on(document, 'mouseup', handleMouseUp)
  }

  handleMaskClick () {
    if (this.maskClosable) {
      this.hide()
    }
  }

  reset () {
    this.transform = {
      scale: 1,
      deg: 0,
      offsetX: 0,
      offsetY: 0,
      enableTransition: false
    }
    this.backToCenter()
  }

  generateBlobUrl () {
    if (this.fileList.length) {
      this.generateBlobUrlByFileList()
    } else if (this.urlList.length) {
      this.generateBlobUrlByUrlList()
    }
  }

  generateBlobUrlByUrlList () {
    this.blobUrl = this.urlList[this.index]
  }
  generateBlobUrlByFileList () {
    this.loading = true
    if (this.source) {
      this.source.cancel()
    }
    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()
    // 发送请求前，判断文件的类型 （pdf/image/video/audio）
    let url = this.currentImg
    if (!url) return
    if (url === 'Not available') { // 直传链接在 UploadFile.vue 里可能被设置为 Not available
      this.loading = false
      this.canPreview = false
      return
    }
    const file =  this.fileList[this.index]
    this.isVideo = isTypeFile(file, showPreviewType.VIDEO)
    this.isAudio = isTypeFile(file, showPreviewType.AUDIO)
    const isImg = this.isTypeFile(file)
    if (this.isVideo || this.isAudio || isImg) {
      // 图片、视频、音频直接显示文件
      this.loading = false
      this.source = null
      this.canPreview = true
      this.blobUrl = url
    } else {
      // 其他文件类型使用object标签显示，需要先转成blob
      url = this.currentImg + '&isDownload=false'
      axios.get(url, { responseType: 'blob', cancelToken: this.source.token })
      .then(res => {
        this.loading = false
        this.source = null
        this.canPreview = true
        this.blobUrl = window.URL.createObjectURL(res.data)
      })
      .catch(e => {
        console.log(e)
        this.loading = false
        this.canPreview = false
      })
    }
  }

  toggleMode () {
    if (this.loading) return
    const modeNames = Object.keys(Mode)
    const modeValues = Object.values(Mode)
    const index = modeValues.indexOf(this.mode)
    const nextIndex = (index + 1) % modeNames.length
    this.mode = Mode[modeNames[nextIndex]]
    // 在图片改变大小之后再重置图片
    setTimeout(() => this.reset())
  }

  prev () {
    if (this.isFirst && !this.infinite) return
    const len = this.urlList.length
    this.index = (this.index - 1 + len) % len
    this.canPreview = true
  }

  next () {
    if (this.isLast && !this.infinite) return
    const len = this.urlList.length
    this.index = (this.index + 1) % len
    this.canPreview = true
  }

  // 当左右切换预览图片时候，要保证下方 active 的图片要在视窗内
  checkActiveFileInView (index: number) {
    const fileListWrap = this.$refs.fileListWrap
    const scrollContent = this.$refs.scrollContent
    if (!fileListWrap || !scrollContent) return
    const padding = +getComputedStyle(fileListWrap).paddingLeft.replace('px', '')
    const fileItem = scrollContent.getElementsByClassName('file-item')[index]
    // 先判断当前 active 文件是否在视窗内
    const leftDistance = fileItem.getBoundingClientRect().right - padding
    let transform = ''
    if (leftDistance < 0) {
      // 左侧视窗外
      transform = `translateX(-${index * this.fileItemWidth}px)`
    } else if (leftDistance > scrollContent.clientWidth) {
      // 右侧视窗外
      const scrollContentTransform = scrollContent.style.transform
      const scrollContentTransformValue = scrollContentTransform.match(/translateX\((.+?)\)/)
      let nowPosition = 0
      if (scrollContentTransform && scrollContentTransformValue) {
        nowPosition = +scrollContentTransformValue[1].replace('px', '')
      }
      transform = `translateX(${scrollContent.clientWidth - leftDistance + (nowPosition * 1)}px)`
    }
    (transform !== '') && (scrollContent.style.transform = transform)
  }

  switchFile (i: number) {
    this.index = i
    this.canPreview = true
  }

  // 使图片居中展示
  backToCenter () {
    const { transform } = this
    const canvas = document.querySelector('.el-image-viewer__canvas') as HTMLElement | null
    const img = document.querySelector('.el-image-viewer__img') as HTMLImageElement | null
    if (!canvas || !img) return
    const height = (canvas.offsetHeight - maskPaddingBottom - img.height) / 2
    const width = (canvas.offsetWidth - img.width) / 2
    transform.offsetX = width
    transform.offsetY = height
  }

  handleActions (action: 'zoomOut' | 'zoomIn' | 'clocelise' | 'anticlocelise', options = {}) {
    if (this.loading) return
    const { zoomRate, rotateDeg, enableTransition } = {
      zoomRate: 0.2,
      rotateDeg: 90,
      enableTransition: true,
      ...options
    }
    const { transform } = this

    // 除了放大的情况，不论时缩小还是旋转图片，都希望能够重新居中
    if (action !== 'zoomIn') this.backToCenter()

    switch (action) {
      case 'zoomOut':
        if (transform.scale > 0.2) {
          transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3))
        }
        break
      case 'zoomIn':
        transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3))
        break
      case 'clocelise':
        transform.deg += rotateDeg
        break
      case 'anticlocelise':
        transform.deg -= rotateDeg
        break
    }
    transform.enableTransition = enableTransition
  }

  initPosition () {
    if (!this.$refs.scrollContent) return
    const fileItem = this.$refs.scrollContent.getElementsByClassName('file-item')[0]
    if (fileItem instanceof HTMLElement) {
      const marginLeft = parseInt(getComputedStyle(fileItem).marginLeft.replace('px', ''))
      this.fileItemWidth = (fileItem.offsetWidth + 2 * marginLeft)
    }
    const scrollContent = this.$refs.scrollContent
    if (scrollContent.scrollWidth >= scrollContent.clientWidth) {
      scrollContent.style.justifyContent = 'flex-start'
    }
  }

  handleDownload () {
    if (this.fileList.length) {
      this.$emit('download', this.fileList[this.index])
    } else if (this.urlList.length) {
      this.$emit('download', this.urlList[this.index])
    }
  }

  handleDownloadAll () {
    this.$emit('download-all')
  }

  getIconClass (file: any) {
    const fileType = getFileType(file)
    let defaultCls = 'icon-other'
    if (imgType.includes(fileType)) {
      defaultCls = 'icon-tuxiang-face'
    } else if (['doc', 'docx'].includes(fileType)) {
      defaultCls = 'icon-doc'
    } else if (['xls', 'xlsx'].includes(fileType)) {
      defaultCls = 'icon-xls'
    } else if (['ppt', 'pptx'].includes(fileType)) {
      defaultCls = 'icon-ppt'
    } else if (['txt', 'csv'].includes(fileType)) {
      defaultCls = 'icon-txt'
    } else if (fileType === 'pdf') {
      defaultCls = 'icon-pdf'
    } else if (videoType.includes(fileType)) {
      defaultCls = 'icon-shipin'
    } else if (audioType.includes(fileType)) {
      defaultCls = 'icon-yinpin'
    }
    return defaultCls
  }

  handleThumbnailImgLoad (e: Event) {
    if (e.target instanceof HTMLElement) {
      e.target.className = 'file-content__thumbnail'
    }
  }

  mounted () {
    this.deviceSupportInstall()
    if (this.appendToBody) {
      document.body.appendChild(this.$el)
    }
    // add tabindex then wrapper can be focusable via Javascript
    // focus wrapper so arrow key can't cause inner scroll behavior underneath
    this.$refs['el-image-viewer__wrapper'].focus()
    this.generateBlobUrl()
    this.initPosition()
    // 开始加载缩略图
    this.fileList.forEach(f => {
      f.thumbnailUrl = `/api/open/${this.namespace}/core.document-thumbnail?code=${f.code}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
    })
  }

  destroyed () {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
