










import { LogwireC } from '@/vue'
import { getFeedbackInstance, getFeedbackInstanceReady } from '@/utils/feedback'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class FeedbackCreateBar extends Vue {
  handleCreateFeedback () {
    getFeedbackInstanceReady().then(feedback => {
      feedback.showCreatePage(document.body)
    })
  }
}
