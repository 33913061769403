import { isLeapYear, isPositiveInteger } from '@/utils/data'
import _ from 'lodash'

export default class LocalDate {
  #year!: number
  #month!: number
  #dayOfMonth!: number

  get year (): number {
    return this.#year
  }

  get month (): number {
    return this.#month
  }

  get dayOfMonth (): number {
    return this.#dayOfMonth
  }

  constructor (year: number, month: number, dayOfMonth: number) {
    if (!isPositiveInteger(year) || !isPositiveInteger(month) || !isPositiveInteger(dayOfMonth)) {
      throw new Error('Invalid date')
    }
    if (dayOfMonth > 28) {
      let dom = 31
      switch (month) {
        case 2:
          dom = isLeapYear(year) ? 29 : 28
          break
        case 4:
        case 6:
        case 9:
        case 11:
          dom = 30
          break
      }
      if (dayOfMonth > dom) {
        if (dayOfMonth === 29) {
          throw new Error(`Invalid date 'February 29' as ${year} is not a leap year`)
        } else {
          throw new Error(`Invalid date '${month} ${dayOfMonth}`)
        }
      }
    }
    this.#year = year
    this.#month = month
    this.#dayOfMonth = dayOfMonth
  }

  static of (year: number, month: number, dayOfMonth: number): LocalDate {
    return new LocalDate(year, month, dayOfMonth)
  }

  static parse (timeStr: string): LocalDate {
    // timeString 仅支持 year-month-dayOfMonth 这种格式
    const reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/
    if (reg.test(timeStr)) {
      const timeArr = timeStr.split('-')
      const year = Number(timeArr[0])
      const month = Number(timeArr[1].startsWith('0') ? timeArr[1].replace(/^0/, '') : timeArr[1])
      const dayOfMonth = Number(timeArr[2].startsWith('0') ? timeArr[2].replace(/^0/, '') : timeArr[2])
      return LocalDate.of(year, month, dayOfMonth)
    } else {
      throw new Error(`Invalid date ${timeStr}`)
    }
  }

  toString (): string {
    return `${this.#year}-${this.#month < 10 ? '0' + this.#month : this.#month}-${this.#dayOfMonth < 10 ? '0' + this.#dayOfMonth : this.#dayOfMonth}`
  }

  plusYears (n:number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n)) {
      newLocalDate.#year = newLocalDate.#year + n
    }
    return newLocalDate
  }

  plusMonths (n: number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n)) {
      // 利用 Date 对象计算
      const newDate = new Date(new Date(newLocalDate.#year, newLocalDate.#month - 1, newLocalDate.#dayOfMonth).setMonth((newLocalDate.#month - 1) + n))
      newLocalDate.#year = newDate.getFullYear()
      newLocalDate.#month = newDate.getMonth() + 1
    }
    return newLocalDate
  }

  plusDays (n: number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n)) {
      // 利用 Date 对象计算
      const newDate = new Date(new Date(newLocalDate.#year, newLocalDate.#month - 1, newLocalDate.#dayOfMonth).setDate(newLocalDate.#dayOfMonth + n))
      newLocalDate.#year = newDate.getFullYear()
      newLocalDate.#month = newDate.getMonth() + 1
      newLocalDate.#dayOfMonth = newDate.getDate()
    }
    return newLocalDate
  }

  minusYears (n: number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n) && isPositiveInteger(newLocalDate.#year - n)) {
      newLocalDate.#year -= n
    }
    return newLocalDate
  }

  minusMonths (n: number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n)) {
      const newDate = new Date(new Date(newLocalDate.#year, newLocalDate.#month - 1, newLocalDate.#dayOfMonth).setMonth((newLocalDate.#month - 1) - n))
      newLocalDate.#year = newDate.getFullYear()
      newLocalDate.#month = newDate.getMonth() + 1
    }
    return newLocalDate
  }

  minusDays (n: number): LocalDate {
    const newLocalDate = LocalDate.of(this.#year, this.#month, this.#dayOfMonth)
    if (isPositiveInteger(n)) {
      const newDate = new Date(new Date(newLocalDate.#year, newLocalDate.#month - 1, newLocalDate.#dayOfMonth).setDate(newLocalDate.#dayOfMonth - n))
      newLocalDate.#year = newDate.getFullYear()
      newLocalDate.#month = newDate.getMonth() + 1
      newLocalDate.#dayOfMonth = newDate.getDate()
    }
    return newLocalDate
  }

  // 返回 -1，0，1
  compareTo (ldt: LocalDate): number {
    if (ldt instanceof LocalDate) {
      let result = this.#year - ldt.#year
      if (result === 0) {
        result = this.#month - ldt.#month
        if (result === 0) {
          result = this.#dayOfMonth - ldt.#dayOfMonth
        }
      }
      return result < 0 ? -1 : result > 0 ? 1 : 0
    } else {
      throw new Error('Localdate can only be compared with Localdate')
    }
  }
}
