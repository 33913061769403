














import { addResizeListener, removeResizeListener } from '@/utils/dom'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'virtual-list' })
class VirtualList extends Vue {
  @Prop() listData!: Array<any>
  @Prop() itemSize!: number

  $refs!: {
    list: Element
  }

  screenHeight = 0
  startOffset = 0
  start = 0
  end = 0

  get visibleCount (): number {
    return Math.ceil(this.screenHeight / this.itemSize)
  }

  get listHeight (): number {
    return this.listData.length * this.itemSize
  }

  get transform (): string {
    return `translate3d(0,${this.startOffset}px,0)`
  }

  get visibleData (): Array<any> {
    return this.listData.slice(this.start, Math.min(this.end, this.listData.length))
  }

  rerender () {
    this.screenHeight = this.$el.clientHeight
    this.end = this.start + this.visibleCount
  }

  scrollEvent () {
    // 当前滚动位置
    const scrollTop = this.$refs.list.scrollTop
    // 此时的开始索引
    this.start = Math.floor(scrollTop / this.itemSize)
    // 此时的结束索引
    this.end = this.start + this.visibleCount
    // 此时的偏移量
    this.startOffset = scrollTop - (scrollTop % this.itemSize)
  }

  mounted () {
    this.screenHeight = this.$el.clientHeight
    this.start = 0
    this.end = this.start + this.visibleCount
    addResizeListener(this.$el, this.rerender)
  }

  beforeDestroy () {
    removeResizeListener(this.$el, this.rerender)
  }
}
export default VirtualList
