<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellDate from './TableCellChoice.vue'
import FormDate from './Choice.vue'

@Component({ name: 'LwChoice', components: { TableCell: TableCellDate, FormItem: FormDate } })
class LwChoice extends FormControlBase {}
export default LwChoice
</script>
