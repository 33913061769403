import { AppModule } from '@/store/modules/app'
import { LayoutModule } from '@/store/modules/layout'
import { socketMessageType } from '@/utils/const'
import eventbus from '@/utils/event'
import { getLayoutNameOutsideComponent } from '@/utils/layout'
import _ from 'lodash'

interface MessageHandler {
  (message: MessageEvent): void;
}

class Socket {
  #socket!: WebSocket
  #messageHandlers = [] as MessageHandler[]
  #onOpen!: () => void
  #onClose!: () => void
  #messages: Array<string> = []
  #alive = false
  #reconnected = false
  #autoReconnect = true
  #reconnectTimer!: number | undefined
  #heartbeatTimer!: number | undefined

  closeInstance () {
    if (this.#socket) {
      this.#socket.onclose = (e) => {
        this.#alive = false
        this.#socket = { } as WebSocket
        Socket.instance = null as any
      }
      this.#socket.close()
    }
    clearInterval(this.#heartbeatTimer)
    clearInterval(this.#reconnectTimer)
  }

  static instance: Socket
  static heartbeatPing = '@ping'
  static heartbeatPong = '@pong'
  static heartbeatDuration = 30000
  static reconnectInterval = 30000

  constructor () {
    if (!this.#socket) {
      this.initSocket()
    }
  }

  static getInstance () {
    if (!Socket.instance) {
      Socket.instance = new Socket()
    }
    return Socket.instance
  }

  private initSocket () {
    clearInterval(this.#reconnectTimer)
    clearInterval(this.#heartbeatTimer)
    if (this.#socket) {
      _.isFunction(this.#socket.close) && this.#socket.close()
      this.#socket = { } as WebSocket
    }
    const { location } = window
    const { host, protocol } = location
    const url = host.endsWith('/') ? host : host + '/'
    const address = process.env.NODE_ENV === 'development'
      ? `ws://${window.location.hostname}:8081/web-socket`
      : `${protocol.includes('https') ? 'wss' : 'ws'}://${url}web-socket` // contextPath
    this.#socket = new WebSocket(address)
    this.#socket.onopen = () => {
      this.#alive = true
      this.startHeartbeat()
      // 运行使用的地方设置的 onOpen 函数
      _.isFunction(this.#onOpen) && this.#onOpen()
      // 调用 addWebSocketOpenListener 注册的事件
      const currentLayout = getLayoutNameOutsideComponent()
      const uiOpenListeners = LayoutModule.data[currentLayout].uiWebSocketListener?.open
      const appOpenListeners = AppModule.uiWebSocketListener?.open
      if (appOpenListeners) {
        for (const listenerName of Object.keys(appOpenListeners)) {
          eventbus.$emit(`webSocket-open-${listenerName}`)
        }
      }
      if (uiOpenListeners) {
        for (const listenerName of Object.keys(uiOpenListeners)) {
          eventbus.$emit(`${currentLayout}.webSocket-open-${listenerName}`)
        }
      }
      // messages 中存在内容说明调用 .send 时 socket 还没连上
      if (!this.#messages.length) return
      for (const message of this.#messages) {
        this.#socket.send(message)
      }
    }
    this.#socket.onmessage = (message) => {
      this.#messageHandlers.forEach(handler => handler(message))
      const { CUSTOM } = socketMessageType
      let data = message.data
      if (!data.startsWith('@')) data = JSON.parse(data)
      if (CUSTOM === data.type) {
        // 调用 addWebSocketCustomMessageListener 注册的事件
        const currentLayout = getLayoutNameOutsideComponent()
        const namespace = currentLayout.split('.')[0]
        const uiCustomListeners: Record<string, string> | undefined = LayoutModule.data[currentLayout].uiWebSocketListener?.customMessage
        const appCustomListeners: Record<string, string> = AppModule.uiWebSocketListener?.customMessage
        const { namespace: messageNamespace = namespace, custom_type: messageCustomType } = data.data
        const namespacedCustomType = messageNamespace + '.' + messageCustomType
        if (appCustomListeners) {
          for (let [listenerName, customType] of Object.entries(appCustomListeners)) {
            if (!customType.includes('.')) customType = namespace + '.' + customType
            if (customType === namespacedCustomType) eventbus.$emit(`webSocket-customMessage-${listenerName}`)
          }
        }
        if (uiCustomListeners) {
          for (let [listenerName, customType] of Object.entries(uiCustomListeners)) {
            if (!customType.includes('.')) customType = namespace + '.' + customType
            if (customType === namespacedCustomType) eventbus.$emit(`${currentLayout}.webSocket-customMessage-${listenerName}`)
          }
        }
      }
    }
    this.#socket.onclose = (e) => {
      this.#alive = false
      this.#socket = { } as WebSocket
      clearInterval(this.#heartbeatTimer)
      // 调用 addWebSocketCloseListener 注册的事件
      const currentLayout = getLayoutNameOutsideComponent()
      const uiCloseListeners = LayoutModule.data[currentLayout].uiWebSocketListener?.close
      const appCloseListeners = AppModule.uiWebSocketListener?.close
      if (appCloseListeners) {
        for (const listenerName of Object.keys(appCloseListeners)) {
          eventbus.$emit(`webSocket-close-${listenerName}`)
        }
      }
      if (uiCloseListeners) {
        for (const listenerName of Object.keys(uiCloseListeners)) {
          eventbus.$emit(`${currentLayout}.webSocket-close-${listenerName}`)
        }
      }
      this.#autoReconnect && this.reconnect()
    }
    this.#socket.onerror = () => {
      // TODO 调用 logwire 的 error 提示
    }
  }

  private reconnect () {
    this.#reconnectTimer = setInterval(() => {
      // 没连上网的情况下不再尝试重连
      if (!navigator.onLine) return
      this.initSocket()
    }, Socket.reconnectInterval)
  }

  private startHeartbeat () {
    if (!this.#alive) return
    this.#heartbeatTimer = setInterval(() => {
      this.send(Socket.heartbeatPing)
    }, Socket.heartbeatDuration)
  }

  onOpen (func: () => void) {
    if (!_.isFunction(func)) return
    if (this.#alive) {
      func()
    } else {
      this.#onOpen = func
    }
  }

  onClose (func: () => void) {
    if (!_.isFunction(func)) return
    this.#onClose = func
  }

  onMessage (func: MessageHandler): void {
    this.#messageHandlers.push(func)
  }

  removeMessageHandle (func: MessageHandler): void {
    const index = this.#messageHandlers.indexOf(func)
    if (index > -1) {
      this.#messageHandlers.splice(index, 1)
    }
  }

  send (message: string): void;
  send (message: Record<string, any>): void;
  send (message: string | Record<string, any>): void {
    if (!_.isString(message)) { message = JSON.stringify(message) }
    // socket 已经连上则直接发送信息，否则推送到列表中，等连上之后会统一发送
    this.#alive
      ? this.#socket.send(message)
      : this.#messages.push(message)
  }
}

export default Socket
