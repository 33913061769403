<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellTextArea from '../TableCell.vue'
import FormTextarea from './Textarea.vue'

@Component({ name: 'LwTextarea', components: { TableCell: TableCellTextArea, FormItem: FormTextarea } })
class LwTextarea extends FormControlBase {}
export default LwTextarea
</script>
