













import { Component, Mixins, Vue } from 'vue-property-decorator'
import ImageView from '@/3rd-party-extension/element/packages/imageView/ImageView.vue'
import eventbus from '@/utils/event'
import axios from 'axios'
import { getMaxZIndex } from '@/utils/dom'
import _ from 'lodash'
import DownloadFile from './layout/mixin/DownloadFile'

@Component({ name: 'PreviewFile', components: { ImageView } })
export default class PreviewFile extends Mixins(DownloadFile) {
  zIndex = 2100
  initialIndex = 0 // 预览的第一张图的索引
  showViewer = false
  previewList: Array<any> = []
  regUrlIsFormatted = /\/api\/open\/(.*?)\/core\.document-preview\?name=(.*?)&code=(.*?)&/
  fileList: {
    code: string
    name: string
    thumbnailUrl?: string
  }[] = []

  closeViewer () {
    this.previewList.forEach(url => {
      window.URL.revokeObjectURL(url)
    })
    this.previewList = []
    this.fileList = []
    this.showViewer = false
  }

  handlePreviewSwitch () {
    // console.log('handlePreviewSwitch')
  }

  handlePreviewFile (payload: Blob | string | Blob[] | string[]) {
    const handlePayloadItem = (obj: Blob | string) => {
      let url = obj
      if (typeof url === 'string' && this.regUrlIsFormatted.test(url)) {
        const result = this.regUrlIsFormatted.exec(url)
        if (result) {
          this.fileList.push({
            code: result[3],
            name: result[2],
            thumbnailUrl: ''
          })
        }
      }
      if (!_.isString(url)) {
        url = window.URL.createObjectURL(url)
      }
      this.previewList.push(url)
    }
    if (payload instanceof Array) {
      payload.forEach((obj: string | Blob) => {
        handlePayloadItem(obj)
      })
    } else {
      handlePayloadItem(payload)
    }
    this.zIndex = getMaxZIndex() + 10
    this.showViewer = true
  }

  /**
   * 满足下载请求的话，则 Url 必须是 /api/open 的格式，而且目前只能由 lw-table 内的 table-cell(componentName='lw-upload-file') 发起
   * @param url
   */
  handleDownloadFile (file: any) {
    const index = this.fileList.indexOf(file)
    const url = this.previewList[index]
    const result = this.regUrlIsFormatted.exec(url)
    if (result) {
      const namespace = result[1]
      const name = result[2]
      const code = result[3]
      this._download({ name, code }, namespace)
    } else {
      console.warn('[PreviewFile] url 格式不允许下载: ' + url)
    }
  }

  handleDownloadAllFile () {
    const codes = this.previewList.map(url => {
      const result = this.regUrlIsFormatted.exec(url)
      return result?.[3]
    }).filter(o => o !== undefined) as string[]
    this._downloadAll(codes, '批量下载')
  }

  created () {
    eventbus.$on('preview-file', this.handlePreviewFile)
  }

  beforeDestroy () {
    eventbus.$off('preview-file', this.handlePreviewFile)
  }
}
