




































import { Component, InjectReactive, Mixins, Prop, Watch } from 'vue-property-decorator'
import QueriableComponent from '@/components/layout/QueriableComponent'
import { LayoutModule } from '@/store/modules/layout'
import {
  attributeType,
  layoutStatus,
  rowPersist,
  TABLE_RELATED_DATASET_NAME
} from '@/utils/const'
import { formatDataRow, getEventName } from '@/utils/data'
import eventbus from '@/utils/event'
import InputForm from '@/components/layout/containers/InputForm.vue'
import DataRow from '@/models/DataRow'
import DataSet from '@/models/DataSet'
import Args from '@/models/Args'
import { DataForSave } from '@/types/data'
import logwire from '@/logwire'

@Component({ name: 'LwForm' })
class LwForm extends Mixins(InputForm, QueriableComponent) {
  @Prop() asQuickEditForm!: boolean

  currentColumn = 12
  status = layoutStatus.VIEW
  editedFields: string[] = []

  get dataRow (): DataRow {
    // todo subDataSet静态值
    const subDataSetName = this.subDataSetName
    return this.parentRow
      ? subDataSetName !== TABLE_RELATED_DATASET_NAME
        ? this.parentRow.subDataSets?.[subDataSetName]?.rows?.[0].currentData
        : this.parentRow.currentData
      : this.dataSet?.rows?.[0]?.currentData
  }

  get showItemSubline (): boolean {
    return this.status === layoutStatus.VIEW || this.disabled
  }

  get showFormOperations (): boolean {
    return !this.disabled &&
      this.status === layoutStatus.EXPRESS_EDIT &&
      !this.parentRow &&
      !this.asQuickEditForm &&
      ![layoutStatus.NEW, layoutStatus.EDIT].includes(this.popupLayoutStatus as layoutStatus)
      // 在 popupLayoutStatus 为上述值的时候，则是表格或者表单的编辑、新增事件发起的，此时不显示按钮
  }

  get disabled (): boolean {
    return this.component.enabled
      ? !this.getFinalAttributeValue('enabled', { args: new Args(this.context, { row: formatDataRow(this.dataRow) }), type: attributeType.BOOLEAN })
      : false
  }

  // 寻找父级组件的title属性作为当前表单区域的标题，在自动生成的editLayout中用到
  get editLayoutTitle (): any {
    let parent = this.$parent
    const options: any = parent.$options
    let parentName = options.name
    // tabpane的标题是由name决定
    while (parent && parentName !== 'LwTabPane' && !(parent as any).title) {
      parent = parent.$parent
      parentName = parent?.$options?.name
    }
    return (parent as any)?.title || (parent as any)?.name
  }

  /**
   * 当改动的字段发生变化时，更新 store 中的 editingDataSet
   * */
  @Watch('editedFields')
  handleEditedFieldsChange (fields: string[]): void {
    const editingDataSet = LayoutModule.data[this.encodedLayoutName]?.editingDataSet
    const currentDataSet = `${this.encodedLayoutName}.${this.dataSetName}`
    // 当不是当前 dataSet 并且存在改动的字段时，将 store 中的 editingDataSet 改动为当前 dataSet
    if (editingDataSet !== currentDataSet && fields.length > 0) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: this.dataSetName })
    } else if (editingDataSet === currentDataSet && fields.length === 0) {
      // 当是当前 dataSet 并且不存在改动的字段时，将 store 中的 editingDataSet 由当前 dataSet 改为空
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
    }
  }

  // FIXME 这里的 title 不太清楚是用来做什么的，有可能是因为经历过 UI 设计上面的变更之后已经被废弃了，感觉可以删掉
  captureFormLayout (allSameDataSetLayout: any) {
    const editLayoutTitleComponent = this.editLayoutTitle
      ? [{
        is: 'lw-html',
        endOfLine: 'true',
        template: `<div class="edit-layout--form-title">${this.editLayoutTitle || ''}</div>`,
        colSpan: '12'
      }]
      : []
    allSameDataSetLayout.push(...editLayoutTitleComponent.concat((this.component as any).components))
  }

  /**
   * 保存并退出编辑模式，使用默认的保存逻辑
   * */
  saveAndQuitEditing (): void {
    // this.handleDataSetSave()
    logwire.ui.emit(`${this.dataSetName}.save`)
  }

  /**
   * 退出编辑模式
   * 这里使用提供的 api 进行重置改动，不会引起改动的响应
   * */
  quitEditing (): void {
    if (this.status === layoutStatus.HEADER_DETAIL_NEW) {
      // 当处于头明细新增的时候去回退到新增前的状态，因为内存中已经将新增前的 dataSet 清空，所以这里需要重新去 retireve 一下
      // 几种进入头明细新增的场景
      // 1. 打开页面后直接进入头明细新增状态（在一个列表页面点击新增按钮，打开详情页并进入头明细新增状态）
      // 2. 通过新增按钮点击进入头明细新增状态（在一个 id=001 的页面，通过点击按钮，进入头明细新增状态）
      // 是否还有其他场景
      // 两种场景的区分是 当前页面参数中是否带有 id
      // 如果带有 id 值，那么重新 retireve 一下拿到之前的数据
      // 如果不带 id，那么认为此时的取消其实是返回列表页面或者关闭 popuplayout
      const id = this.context.getLayoutParam('id')
      // id 值是否可能是 0
      if (id !== undefined) {
        this.handleDataSetRetrieve()
      } else {
        logwire.ui.closeCurrentLayout()
      }
    } else {
      const dataRow = this.dataSet.rows[0]
      dataRow.currentData = Object.assign(dataRow.currentData, dataRow.originalData)
    }
    // 重置表单的时候同时将 rowPersist 状态置空
    this.dataSet.rows[0].rowPersist = ''
    this.$nextTick(() => this.resetStatus())
  }

  // 重置表单状态、重置 layout 状态
  resetStatus (): void {
    this.status = layoutStatus.VIEW
    LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.VIEW })
    LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
  }

  // 更改状态为 view  例如，点击按钮进入查看状态时，即使 dataSet 是 editingRow, 也更改状态为 view
  changeStatusToView () : void {
    this.status = layoutStatus.VIEW
  }

  handleDbClick (): void {
    if (this.disabled ||
      this.status !== layoutStatus.VIEW ||
      this.popupLayoutStatus === layoutStatus.VIEW ||
      this.subDataSetName
    ) return
    this.proceedAfterStatusCheck(this.handleDatasetExpressEdit)
  }

  // 字段改动，并且 rowPersist  为 I 说明是由 row.setData 引起的，表单进入编辑状态
  handleFieldChange (field:string, value: any): void {
    const row = this.dataSet?.rows[0]
    if (row?.rowPersist === rowPersist.UPDATE) {
      // 假如当前 layout 已经处于编辑状态，将设置的值进行重置
      if (this.layoutEditing) {
        const dataRow = this.context.getOrCreateDataSet(this.dataSetName).getRow(0)
        dataRow.setData(field, dataRow.getOriginalData(field), false)
      }
    }
  }

  // 记录哪些自动存在改动
  updateEditedFields (field: string, changed: boolean): void {
    if (changed && !this.editedFields.includes(field)) {
      this.editedFields.push(field)
    } else if (!changed && this.editedFields.includes(field)) {
      this.editedFields.splice(this.editedFields.indexOf(field), 1)
    }
  }

  generateUpdatedRows (payload: DataForSave) {
    if (this.query) {
      payload.queryName = this.query
    }
    payload.headerDetailSave = this.detailDataSets?.length > 0 && [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)
    const { recordList } = payload
    if (!this.dataSet?.rows?.[0].rowPersist) {
      const rowPersistMode = [layoutStatus.NEW, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) ? rowPersist.INSERT : rowPersist.UPDATE
      this.dataSet.rows[0].rowPersist = rowPersistMode
    }
    recordList.push(this.dataSet?.rows?.[0])
  }

  created () {
    if (this.asQuickEditForm || this.asFilterForm) {
      this.status = layoutStatus.EXPRESS_EDIT
    } else if ([layoutStatus.EDIT, layoutStatus.NEW].includes(this.popupLayoutStatus as layoutStatus)) {
      // 如果当前是在弹窗 Layout 内并且是 new / edit 状态则应该显示为快速编辑状态
      this.status = layoutStatus.EXPRESS_EDIT
    }
    eventbus.$on(getEventName(this.encodedLayoutName, this.dataSetName, 'captureFormLayout'), this.captureFormLayout)
    eventbus.$on(getEventName(this.encodedLayoutName, this.dataSetName, 'changeStatusToView'), this.changeStatusToView)
    this.initRetrieve()
  }

  beforeDestroy () {
    eventbus.$off(getEventName(this.encodedLayoutName, this.dataSetName, 'captureFormLayout'))
    eventbus.$off(getEventName(this.encodedLayoutName, this.dataSetName, 'changeStatusToView'))
  }
}
export default LwForm
