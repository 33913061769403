import Vue from 'vue'
import { getTransform } from '@/utils/dom'

// v-dialogScale 指令
Vue.directive('dialogScale', {
  bind (el) {
    setDialogScale(el)
  },
  update (el) {
    setDialogScale(el)
  }
})

function setDialogScale (el) {
  // 弹框可拉伸最小宽高
  const minWidth = 400
  const minHeight = 100
  const dragDom = el.querySelector('.el-dialog')
  // 现在将 top, bottom ,right 三条定位线固定在 Dialog 外部， overflow: auto 会影响弹窗出现滚动条

  dragDom.querySelectorAll('.dialog-scale').forEach(element => {
    element.remove()
  })
  setResizeBorder('left')
  setResizeBorder('right')
  setResizeBorder('top')
  setResizeBorder('bottom')

  setResizeBorder('left-top')
  setResizeBorder('left-bottom')
  setResizeBorder('right-top')
  setResizeBorder('right-bottom')

  function setResizeBorder (pos) {
    const el = document.createElement('div')
    dragDom.appendChild(el)
    el.style.position = 'absolute'
    el.style.bottom = '0'
    el.classList.add('dialog-scale')
    if (pos === 'bottom' || pos === 'top') {
      el.style.cursor = 'n-resize'
      el.style.height = '10px'
      el.style.width = '100%'
      el.style.right = '0'
      pos === 'bottom' ? el.style.bottom = '-10px' : el.style.top = '-10px'
    } else if (pos === 'left' || pos === 'right') {
      el.style.cursor = 'e-resize'
      el.style.height = '100%'
      el.style.width = '10px'
      pos === 'left' ? el.style.left = '-10px' : el.style.right = '-10px'
    } else {
      el.style.height = '20px'
      el.style.width = '20px'
      if (pos === 'left-top') {
        el.style.cursor = 'nw-resize'
        el.style.left = '-10px'
        el.style.top = '-10px'
      }
      if (pos === 'left-bottom') {
        el.style.cursor = 'sw-resize'
        el.style.left = '-10px'
        el.style.bottom = '-10px'
      }
      if (pos === 'right-top') {
        el.style.cursor = 'ne-resize'
        el.style.right = '-10px'
        el.style.top = '-10px'
      }
      if (pos === 'right-bottom') {
        el.style.cursor = 'se-resize'
        el.style.right = '-10px'
        el.style.bottom = '-10px'
      }
    }
    el.addEventListener('mousedown', (e) => {
      initScale(e, pos)
    }, false)
  }

  function initScale (e, pos) {
    // 鼠标元素在屏幕内位置
    const initX = e.clientX
    const initY = e.clientY

    // 获取视口高度，弹框的最大高度为视口的 50%
    const vh = window.innerHeight
    const maxHeight = vh / 100 * 50

    // 弹框的初始宽高
    const initWidth = parseInt(window.getComputedStyle(dragDom).width, 10)
    const dragDomBody = el.querySelector('.el-dialog__body')
    const initHeightBody = parseInt(window.getComputedStyle(dragDomBody).height, 10)

    const initMarginTop = window.getComputedStyle(dragDom).marginTop
    let marginTop = initMarginTop
    const initMarginBottom = window.getComputedStyle(dragDom).marginBottom

    const initMarginLeft = window.getComputedStyle(dragDom).marginLeft
    let marginLeft = initMarginLeft
    const initMarginRight = window.getComputedStyle(dragDom).marginRight
    let marginRight = initMarginRight

    // 监听移动事件,改变尺寸
    document.documentElement.addEventListener('mousemove', doScale, false)
    // 监听鼠标松开事件,移除鼠标事件
    document.documentElement.addEventListener('mouseup', stopScale, false)

    function doScale (e) {
      e.preventDefault()
      // 弹框的宽高 = 初始大小 + （鼠标当前位置 - 鼠标初始位置
      const moveX = e.clientX - initX
      const moveY = e.clientY - initY
      if (pos.includes('top')) {
        const curHeight = initHeightBody - moveY
        if (curHeight < minHeight || curHeight > maxHeight) return
        // 比较 marginTop 和 transformY 的值，不要放大超出屏幕
        const { y: transformY } = getTransform(dragDom)
        console.log(transformY, Number(initMarginTop.replace('px', '')) + moveY)
        if (transformY + Number(initMarginTop.replace('px', '')) + moveY < 0) return
        dragDomBody.style.height = curHeight + 'px'
        dragDom.style.marginBottom = initMarginBottom
        marginTop = Number(initMarginTop.replace('px', '')) + moveY + 'px'
        dragDom.style.marginTop = marginTop
      }
      if (pos.includes('bottom')) {
        const curHeight = initHeightBody + moveY
        if (curHeight < minHeight || curHeight > maxHeight) return
        dragDomBody.style.height = curHeight + 'px'
      }
      if (pos.includes('right')) {
        if (initWidth + moveX < minWidth) return
        dragDom.style.width = initWidth + moveX + 'px'
        dragDom.style.marginLeft = marginLeft
        marginRight = Number(initMarginRight.replace('px', '')) - moveX + 'px'
        dragDom.style.marginRight = marginRight
      }
      if (pos.includes('left')) {
        if (initWidth - moveX < minWidth) return
        dragDom.style.marginRight = marginRight
        dragDom.style.width = initWidth - moveX + 'px'
        marginLeft = Number(initMarginLeft.replace('px', '')) + moveX + 'px'
        dragDom.style.marginLeft = marginLeft
      }
    }
    function stopScale () {
      document.documentElement.removeEventListener('mousemove', doScale, false)
      document.documentElement.removeEventListener('mouseup', stopScale, false)
    }
  }
}
