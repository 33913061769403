







import { Component, Mixins, Prop } from 'vue-property-decorator'
import BaseComponent from '../BaseComponent'
import Args from '@/models/Args'
import { attribute2Number, attributeForce2Boolean, attributeForce2Number, getI18nContent } from '@/utils/layout'
import { px2rem } from '@/utils/dom'
import { IntuitiveColorAllList, IntuitiveColorMainList, SemanticColorMainList, SemanticColorSchemes, attributeType } from '@/utils/const'
import { formatDataRow } from '@/utils/data'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'
import FormControlComponent from '@/components/layout/FormControlComponent'
declare const window: Window & { $clamp: (el: any, config: any) => void }

@Component({ name: 'LwLabel' })
export default class LwLabel extends FormControlComponent {
  get href () {
    return this.getFinalAttributeValue('href', { args: this.argsWithRow })
  }

  get target () {
    return this.getFinalAttributeValue('target', { args: this.argsWithRow }) || '_blank'
  }

  get hasOnClick () {
    return this.component.onClick
  }

  get content () {
    return this.getFinalAttributeValue('content', { args: this.argsWithRow, types: [attributeType.STRING, attributeType.NUMBER] })
  }

  get maxLines (): number {
    // return attributeForce2Number('maxLines', this.component, 1)
    return this.getFinalAttributeValue('maxLines', { args: this.argsWithRow }) || 'auto'
  }

  get colorScheme () {
    let color = 'var(--gray-10)'
    const colorScheme = this.getFinalAttributeValue('colorScheme')
    if (SemanticColorMainList.includes(colorScheme) && colorScheme !== SemanticColorSchemes.SECONDARY) color = 'var(--' + colorScheme + '-6)'
    if (colorScheme === SemanticColorSchemes.PRIMARY) color = 'var(--theme-6)'
    if (IntuitiveColorMainList.includes(colorScheme)) color = 'var(--' + colorScheme + '-c)'
    if (IntuitiveColorAllList.includes(colorScheme)) color = 'var(--' + colorScheme + ')'
    return color
  }

  // px转换为rem
  get fontSize () {
    return px2rem(attribute2Number(this.getFinalAttributeValue('fontSize', { args: this.argsWithRow })) as number)
  }

  handleLabelClick (evt: MouseEvent) {
    if (this.component.onClick) {
      evt.stopPropagation()
      this.runRunnableContent('onClick', { args: this.argsWithRow })
    }
  }

  mounted () {
    window.$clamp(this.$refs.core, { clamp: this.maxLines })
  }
}
