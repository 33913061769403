














import { Component } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attributeType } from '@/utils/const'

@Component({ name: 'LwCheckbox' })
class LwCheckbox extends FormControlComponent {
  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const originalValue = row.originalData[this.component.field]
    if (!originalValue) {
      return this.inputValue === true
    }
    return this.inputValue !== row.originalData[this.component.field]
  }
}
export default LwCheckbox
