
















import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attribute2Number, attributeForce2Number } from '@/utils/layout'
import { SCREEN_SIZE_BREAK_POINTS } from '@/utils/const'
import { px2rem, addResizeListener, removeResizeListener } from '@/utils/dom'

@Component({ name: 'LwBoard' })
export default class LwBoard extends BaseComponent {
  currentColumn = 12
  boardHeihgt: string | number = ''

  get gutter () { return attribute2Number(this.component.gutter) || 0 }

  // 设置列数， 默认 [1, 2, 3, 4, 5]
  getLayoutSetting () {
    if (!this.$refs.content) return
    let { columns: attrColumns } = this.component
    const { clientWidth } = this.$refs.content as HTMLElement
    // 预设四个尺寸节点
    const breakPoints = SCREEN_SIZE_BREAK_POINTS
    let columns = [1, 2, 3, 4, 5]
    if (attrColumns && attrColumns.indexOf(',') > -1) {
      columns = attrColumns.split(',').map(Number)
    } else if (attrColumns) {
      attrColumns = parseInt(attrColumns)
      for (let i = 1; i < 6; i++) {
        let col = 1
        if ((attrColumns - 4 + i) > 0) col = attrColumns - 4 + i
        columns[i - 1] = col
      }
    }
    // 根据 clientWidth 判断当前应使用的列数
    if (clientWidth < breakPoints[0]) {
      this.currentColumn = columns[0]
    } else if (clientWidth > breakPoints[3]) {
      this.currentColumn = columns[4]
    } else {
      for (let i = 0; i < breakPoints.length; i++) {
        if (breakPoints[i] <= clientWidth && clientWidth <= breakPoints[i + 1]) {
          this.currentColumn = columns[i + 1]
          break
        }
      }
    }
  }

  getStyle (item: any, index: number): Record<string, any> {
    let colSpan = 1
    if (item.colSpan) {
      colSpan = attributeForce2Number('colSpan', item, 1)
    }
    if (colSpan > this.currentColumn) colSpan = this.currentColumn
    const percent = (colSpan / this.currentColumn) * 100
    // rowColSpan 记录当前行已经放置的列数
    let rowColSpan = 0
    const componentsArr = this.component.components || []
    componentsArr.slice(0, index).forEach(c => {
      const currentColSpan = attributeForce2Number('colSpan', c, 1)
      if (rowColSpan + currentColSpan > this.currentColumn) {
        // 如果当前行已放置列数 + 当前元素列数 > board 所设置列数，说明当前元素需要新起一行，所以 rowColSpan 在新的一行值应为 当前元素列数
        rowColSpan = currentColSpan
      } else if (rowColSpan + currentColSpan === this.currentColumn) {
        rowColSpan = 0
      } else {
        rowColSpan += currentColSpan
      }
    }, this)
    // 剩余组件列数的和(包含当前组件)
    const remainColSpanSum = this.component.components?.slice(index).reduce((total: number, current: any) => {
      return total + attributeForce2Number('colSpan', current, 1)
    }, 0) as number
    const isFirst = (rowColSpan === 0 || rowColSpan === this.currentColumn || rowColSpan + colSpan > this.currentColumn)
    const isLast = (rowColSpan + colSpan) === this.currentColumn
    const isLastLine = isFirst
      ? remainColSpanSum <= this.currentColumn
      : isLast
        ? remainColSpanSum === colSpan
        : remainColSpanSum <= this.currentColumn - rowColSpan
    const paddingLeft = isFirst ? 0 : px2rem((this.gutter as number / 2))
    const paddingRight = isLast ? 0 : px2rem((this.gutter as number / 2))
    const paddingBottom = isLastLine ? 0 : px2rem(this.gutter as number)
    return {
      flexGrow: 0,
      flexShrink: 0,
      width: percent + '%',
      paddingLeft,
      paddingRight,
      paddingBottom
    }
  }

  mounted () {
    this.getLayoutSetting()
    addResizeListener(this.$refs.content, this.getLayoutSetting)
  }

  beforeDestroy () {
    removeResizeListener(this.$refs.content, this.getLayoutSetting)
  }
}
