/* eslint-disable camelcase */
/**
 * 创建一个结构，描述 "组件如何判断某个属性是否是多语言" 以及
 * “组件如何搜索多语言属性所对应的 html 元素” 以及
 * “组件如何与某个元素匹配”
 *
 * getLayout 接口拿到页面结构 -> 从页面结构中分出各种组件 -> 组件判断自身属性是否多语言 -> 找出显示了多语言内容的元素
 */

import { LayoutComponent } from '@/types/layout'

export type ModelCustomI18nItem = {
  layout_name: string,
  language: string,
  custom_text: string,
  i18n_code: string,
  origin_text?: string
  update_username?: string
  id?: number,
  version?: number
  update_timestamp?: string
  update_user_id?: number
  insert_timestamp?: string
  insert_user_id?: number
}

export default abstract class ComponentStructure {
  /** 当前组件的名称，例：lw-button */
  abstract name: string
  protected component: LayoutComponent
  protected dom: HTMLElement | null
  /** 当前组件内所有涉及到多语言内容显示到页面的属性 */
  abstract properties: string[]
  protected propertyElements: { property: string, element: HTMLElement, value: string, i18nKey: string }[]

  constructor (component: LayoutComponent) {
    this.component = component
    this.propertyElements = []
    this.dom = null
  }

  /** 如何获取到当前结构在页面内的显示 */
  abstract getHtmlElement (): HTMLElement | null

  /** 获取该属性显示的 HTML 元素 */
  abstract getPropertyShownElement (property: string): HTMLElement | null

  /** 获取当前结构内，具有的所有子组件结构 */
  abstract getChildComponents (): LayoutComponent[]

  initialRenderDom () {
    this.dom = this.getHtmlElement()
  }

  getPropertiesWhichUseI18n () {
    return this.properties.filter(o => {
      const value = this.component[o]
      return typeof value === 'string' && value.startsWith('{i18n}')
    })
  }

  getPropertyElementsWhichUseI18n (): ComponentStructure['propertyElements'] {
    const queues: ComponentStructure['propertyElements'] = []
    const properties = this.getPropertiesWhichUseI18n()
    properties.forEach(property => {
      const dom = this.getPropertyShownElement(property)
      if (dom) {
        queues.push({
          property,
          element: dom,
          value: this.component[property],
          i18nKey: this.component[property].replace('{i18n}', '')
        })
      }
    })
    this.propertyElements = queues
    return queues
  }

  judgeDomExistInViewport (dom: HTMLElement, matchUnvisbleElement?: (dom: HTMLElement) => boolean): HTMLElement | null {
    const rect = dom.getBoundingClientRect()
    if (rect.x < document.body.offsetWidth &&
      rect.y < document.body.offsetHeight &&
      rect.x >= 0 &&
      rect.y >= 0 &&
      rect.width > 0 &&
      rect.height > 0
    ) {
      const currentPositionDom = document.elementFromPoint(rect.x, rect.y)
      if (currentPositionDom === dom || (currentPositionDom?.compareDocumentPosition(dom) as number) & Node.DOCUMENT_POSITION_CONTAINS) {
        return dom as HTMLElement
      } else {
        if (matchUnvisbleElement) {
          const isUnvisibleElement = matchUnvisbleElement(currentPositionDom as HTMLElement)
          if (isUnvisibleElement) {
            return dom as HTMLElement
          } else {
            return null
          }
        }
        // console.warn('[Modify I18n Online] dom 上方存在其他元素遮挡', dom, document.elementFromPoint(rect.x, rect.y))
        return null
      }
    } else {
      return null
    }
  }
}
