import { render, staticRenderFns } from "./ModifyI18nOnline.vue?vue&type=template&id=30381127&scoped=true&"
import script from "./ModifyI18nOnline.vue?vue&type=script&lang=ts&"
export * from "./ModifyI18nOnline.vue?vue&type=script&lang=ts&"
import style0 from "./ModifyI18nOnline.vue?vue&type=style&index=0&id=30381127&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30381127",
  null
  
)

export default component.exports