import { LayoutComponent } from '@/types/layout'
import ComponentStructure from '../basic'

export default class I18nForLwSearchBar extends ComponentStructure {
  name = 'lw-search-bar'
  properties = []

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return null
  }

  getChildComponents () {
    return [].concat(this.component.operations).concat(this.component.quickFilter) || []
  }
}
