































































import { doSave } from '@/http/api'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { ModelCustomI18nItem } from './basic'
import { LocalModule } from '@/store/modules/local'
import { formatDate } from '@/utils/data'
import { LayoutModule } from '@/store/modules/layout'
@Component
export default class DialogModifyI18n extends Vue {
  @Prop() langs !: { lang: string, title: string }[]
  @Prop() layoutName !: string
  @Prop() i18nKey !: string

  backupData !: DialogModifyI18n['data']
  callback !: () => void

  visible = false
  data: ModelCustomI18nItem[] = []

  get choiceLanguages () {
    return LocalModule.choices['core.language'] || []
  }

  getLanguageTitle (lang: string) {
    return this.choiceLanguages.find((o: any) => o.value === lang)?.title
  }

  show (data: DialogModifyI18n['data'], callback: () => void) {
    this.visible = true
    this.data = data
    this.backupData = JSON.parse(JSON.stringify(data))
    this.callback = callback
  }

  hide () {
    this.visible = false
  }

  formatDate (date?: string) {
    if (!date) return ''
    return formatDate(date, 'dateTime')
  }

  async handleSubmit () {
    const dataList = [{
      dataSetName: 'test',
      queryName: 'layout_custom_translation',
      recordList: this.langs.filter(({ lang }) => {
        return this.data.find(o => o.i18n_code === this.i18nKey && o.language === lang)?.custom_text !== this.backupData.find(o => o.i18n_code === this.i18nKey && o.language === lang)?.custom_text
      }).map(({ lang }) => {
        const obj = {
          currentData: this.data.find(o => o.i18n_code === this.i18nKey && o.language === lang),
          originalData: this.backupData.find(o => o.i18n_code === this.i18nKey && o.language === lang),
          rowPersist: ''
        }
        obj.currentData!.update_username = LocalModule.user.username
        obj.rowPersist = obj.originalData?.insert_user_id ? 'U' : 'I'
        if (obj.rowPersist === 'U' && obj.currentData?.custom_text === '') {
          obj.rowPersist = 'D'
        }
        return obj
      })
    }]
    await doSave({
      layoutName: 'core.special_resources',
      queryName: 'layout_custom_translation',
      data: { dataList }
    })
    this.visible = false
    // 保存成功后，还要根据 layout_name, 去修改 meta 下的 i18n 词条，或者通用词条，这样页面上可以看到新的效果
    dataList[0].recordList.forEach(item => {
      if (item.currentData?.language !== LocalModule.config['core.i18n.language']) return
      // 找到当前词条对应哪个 vuex 的内容
      const namespace = item.currentData?.i18n_code.includes('.')
        ? item.currentData.i18n_code.replace(/\..*/, '')
        : 'core'
      let target: Record<string, string>
      if (typeof LayoutModule.resource[item.currentData?.layout_name as string].i18n[this.i18nKey] === 'string') {
        target = LayoutModule.resource[item.currentData?.layout_name as string].i18n
      } else if (typeof LocalModule.i18n[namespace][this.i18nKey] === 'string') {
        target = LocalModule.i18n[namespace]
      } else if (typeof LocalModule.i18n.core[this.i18nKey] === 'string') {
        target = LocalModule.i18n.core
      } else {
        return
      }
      if (item.rowPersist === 'D') {
        // 将 vuex store 内的内容，替换为 origin_text 的内容
        target[this.i18nKey] = item.currentData?.origin_text as string
      } else {
        // 将 vuex store 内的内容，替换为 currentData.custom_text 的内容
        target[this.i18nKey] = item.currentData?.custom_text as string
      }
    })

    this.callback()

    this.$nextTick(() => {
      this.$destroy()
    })
  }

  handleClose () {
    this.visible = false
    this.$nextTick(() => {
      this.$destroy()
    })
  }
}
