
import { Component, Mixins } from 'vue-property-decorator'
import TableCell from '../TableCell.vue'
import DateTimeBase from './DateTimeBase'

@Component({
  name: 'TableCellDateTime'
})
class TableCellDateTime extends Mixins(TableCell, DateTimeBase) {}
export default TableCellDateTime
