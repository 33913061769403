






import { Component } from 'vue-property-decorator'
import TableCellComponent from '@/components/layout/mixin/TableCellComponent'

@Component({
  name: 'TableCell'
})
class TableCell extends TableCellComponent {
  get displayValue (): string {
    return this.inputValue
  }
}
export default TableCell
