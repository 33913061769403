import { LayoutComponent } from '@/types/layout'
import ComponentStructure from './basic'
import I18nForLwAutoComplete from './form-item/LwAutoComplete'
import I18nForLwButton from './basic/LwButton'
import I18nForLwDynamic from './basic/LwDynamic'
import I18nForLwChoice from './form-item/LwChoice'
import I18nForLwDate from './form-item/LwDate'
import I18nForLwRegion from './container/LwRegion'
import I18nForLwSearchBar from './basic/LwSearchBar'
import I18nForLwSelect from './form-item/LwSelect'
import I18nForLwSwitch from './form-item/LwSwitch'
import I18nForLwTable from './basic/LwTable'
import I18nForLwText from './form-item/LwText'
import I18nForLwCheckbox from './form-item/LwCheckbox'
import I18nForLwCascader from './form-item/LwCascader'
import I18nForLwDateTime from './form-item/LwDateTime'
import I18nForLwNumber from './form-item/LwNumber'
import I18nForLwTextarea from './form-item/LwTextarea'
import I18nForLwTime from './form-item/LwTime'
import I18nForLwTimestamp from './form-item/LwTimestamp'
import I18nForLwUploadFile from './form-item/LwUploadFile'
import I18nForLwLabel from './basic/LwLabel'
import I18nForLwCard from './container/LwCard'
import I18nForLwForm from './container/LwForm'
import I18nForLwInputForm from './container/LwInputForm'
import I18nForLwRegion2 from './container/LwRegion2'
import I18nForLwBoard from './container/LwBoard'
import I18nForLwSection from './container/LwSection'
import I18nForLwRegion3 from './container/LwRegion3'
import I18nForLwTabs from './container/LwTabs'
import I18nForLwTabPane from './container/LwTabPane'
import I18nForWrapper from './container/LwWrapper'
import I18nForLwButtonGroup from './basic/LwButtonGroup'
import I18nForLwToolbar from './basic/LwToolbar'

export function getComponentStructure (component: LayoutComponent): ComponentStructure | undefined {
  if (component?.is === 'lw-button') return new I18nForLwButton(component)
  else if (component?.is === 'lw-table') return new I18nForLwTable(component)
  else if (component?.is === 'lw-search-bar') return new I18nForLwSearchBar(component)
  else if (component?.is === 'lw-label') return new I18nForLwLabel(component)
  else if (component?.is === 'lw-button-group') return new I18nForLwButtonGroup(component)
  else if (component?.is === 'lw-toolbar') return new I18nForLwToolbar(component)
  else if (component?.is === 'lw-dynamic') return new I18nForLwDynamic(component)

  else if (component?.is === 'lw-board') return new I18nForLwBoard(component)
  else if (component?.is === 'lw-region') return new I18nForLwRegion(component)
  else if (component?.is === 'lw-card') return new I18nForLwCard(component)
  else if (component?.is === 'lw-form') return new I18nForLwForm(component)
  else if (component?.is === 'lw-input-form') return new I18nForLwInputForm(component)
  else if (component?.is === 'lw-region-2') return new I18nForLwRegion2(component)
  else if (component?.is === 'lw-region-3') return new I18nForLwRegion3(component)
  else if (component?.is === 'lw-section') return new I18nForLwSection(component)
  else if (component?.is === 'lw-tabs') return new I18nForLwTabs(component)
  else if (component?.is === 'lw-tab-pane') return new I18nForLwTabPane(component)
  else if (component?.is === 'lw-wrapper') return new I18nForWrapper(component)

  else if (component?.is === 'lw-auto-complete') return new I18nForLwAutoComplete(component)
  else if (component?.is === 'lw-cascader') return new I18nForLwCascader(component)
  else if (component?.is === 'lw-checkbox') return new I18nForLwCheckbox(component)
  else if (component?.is === 'lw-choice') return new I18nForLwChoice(component)
  else if (component?.is === 'lw-date') return new I18nForLwDate(component)
  else if (component?.is === 'lw-date-time') return new I18nForLwDateTime(component)
  else if (component?.is === 'lw-number') return new I18nForLwNumber(component)
  else if (component?.is === 'lw-select') return new I18nForLwSelect(component)
  else if (component?.is === 'lw-switch') return new I18nForLwSwitch(component)
  else if (component?.is === 'lw-text') return new I18nForLwText(component)
  else if (component?.is === 'lw-textarea') return new I18nForLwTextarea(component)
  else if (component?.is === 'lw-time') return new I18nForLwTime(component)
  else if (component?.is === 'lw-timestamp') return new I18nForLwTimestamp(component)
  else if (component?.is === 'lw-upload-file') return new I18nForLwUploadFile(component)
}
