import { Pagination } from "element-ui";
import { CreateElement } from "vue/types/umd";

/**
 * 修改原 ElementUI 的 Pagination 组件
 * 主要是让 internalCurrentPage 属性不再作为组件的 v-model 值，而是根据传入的 currentPage 来显示
 * 这样在切换页码时，传出新页码，同时样式上停留在旧页码，等到查询结束后，currentPage 发生变化，样式上同时完成更新
 */

if (Pagination['render'] instanceof Function) {
  Pagination['render'] = function (this: any, h: CreateElement) {
    const layout = this.layout;
    if (!layout) return null;
    if (this.hideOnSinglePage && (!this.internalPageCount || this.internalPageCount === 1)) return null;
    let template = h('div', {
      class: ['el-pagination', {
        'is-background': this.background,
        'el-pagination--small': this.small
      }]
    })
    const TEMPLATE_MAP = {
      prev: h('prev'),
      jumper: h('jumper'),
      pager: h('pager', {
        props: {
          currentPage: this.currentPage,
          pageCount: this.internalPageCount,
          pagerCount: this.pagerCount,
          disabled: this.disabled
        },
        on: {
          change: this.handleCurrentChange
        }
      }),
      next: h('next'),
      sizes: h('sizes', { props: { pageSizes: this.pageSizes } }),
      slot: h('slot', [this.$slots.default ? this.$slots.default : '']),
      total: h('total')
    };
    const components = layout.split(',').map((item: string) => item.trim());
    const rightWrapper = h('div', { class: "el-pagination__rightwrapper" })
    let haveRightWrapper = false;

    template.children = template.children || [];
    rightWrapper.children = rightWrapper.children || [];
    components.forEach((compo: string) => {
      if (compo === '->') {
        haveRightWrapper = true;
        return;
      }

      if (!haveRightWrapper) {
        template.children?.push(TEMPLATE_MAP[compo]);
      } else {
        rightWrapper.children?.push(TEMPLATE_MAP[compo]);
      }
    });

    if (haveRightWrapper) {
      template.children.unshift(rightWrapper);
    }

    return template;
  }
}
if (Pagination['components']?.Sizes?.render instanceof Function) {
  Pagination['components'].Sizes.render = function (this: any, h: CreateElement) {
    return h('span', {
      class: "el-pagination__sizes"
    }, [
      h('el-select', {
        props: {
          value: this.$parent.pageSize,
          popperClass: this.$parent.popperClass || '',
          size: 'mini',
          disabled: this.$parent.disabled
        },
        on: {
          input: this.handleChange
        }
      }, this.pageSizes.map((item: any) => {
        return h('el-option', {
          props: {
            value: item,
            label: item + this.t('el.pagination.pagesize')
          }
        })
      }))
    ])
  }
}
if (Pagination['components']?.Sizes?.methods instanceof Object) {
  Pagination['components'].Sizes.methods.handleChange = function (val: any) {
    if (val !== this.$parent.pageSize) {
      this.$parent.userChangePageSize = true;
      this.$parent.$emit('size-change', val);
    }
  }

}
if (Pagination['components']?.Prev?.render instanceof Function) {
  Pagination['components'].Prev.render = function (h: CreateElement) {
    return h('button', {
      class: 'btn-prev',
      attrs: {
        type: 'button',
        disabled: this.$parent.disabled || this.$parent.currentPage <= 1 
      },
      on: {
        click: this.$parent.prev
      }
    }, [
      this.$parent.prevText
        ? h('span', this.$parent.prevText)
        : h('i', { class: 'el-icon el-icon-arrow-left' })
    ])
  }
}
if (Pagination['components']?.Next?.render instanceof Function) {
  Pagination['components'].Next.render = function (h: CreateElement) {
    return h('button', {
      class: 'btn-next',
      attrs: {
        type: 'button',
        disabled: this.$parent.disabled || this.$parent.currentPage === this.$parent.internalPageCount || this.$parent.internalPageCount === 0
      },
      on: {
        click: this.$parent.next
      }
    }, [
      this.$parent.nextText
        ? h('span', this.$parent.nextText)
        : h('i', { class: 'el-icon el-icon-arrow-right' })
    ])
  }
}

export default {
  install: function(Vue: any) {
    Vue.component(Pagination.name, Pagination);
  }
}
