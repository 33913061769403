import { LayoutComponent } from '@/types/layout'
import ComponentStructure from '../basic'

export default class I18nForLwRegion extends ComponentStructure {
  name = 'lw-region'
  properties = ['title']

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.lw-region')) {
      // eslint-disable-next-line dot-notation
      if (dom['__vue__'] && dom['__vue__'].component === this.component) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      }
    }
    return null
  }

  getPropertyShownElement (property: string) {
    return this.dom
      ? this.dom.querySelector('.lw-region > .title.__container__title > .title__inner') as HTMLElement | null
      : null
  }

  getChildComponents () {
    return this.component.components || []
  }
}
