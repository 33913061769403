import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from '@/utils/data'

const TYPES = ['select', 'picker']

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get type (): string {
    return TYPES.includes(this.component.type)
      ? this.component.type
      : 'picker'
  }

  // 复写inputValue逻辑，当type为select的时候，显示格式为hh:mm，传输格式为hh:mm:ss
  get inputValue (): any {
    const dataRow = this.form.dataRow
    let value = dataRow[this.component.field]
    if (this.type === 'select' && value && value.includes(':')) {
      const times = dataRow[this.component.field].split(':')
      value = times[0] + ':' + times[1]
    }
    return value
  }

  set inputValue (value) {
    if (this.type === 'select' && value.length > 0) {
      value = value + ':00'
    }
    this.form.dataRow[this.component.field] = value
  }

  get displayValue (): string {
    let display = formatDate(this.inputValue, 'time')
    if (this.type === 'select' && this.inputValue && this.inputValue.includes(':')) {
      const displays = display.split(' ')
      const times = displays[0].split(':')
      display = times[0] + ':' + times[1] + (displays.length > 1 ? ' ' + displays[1] : '')
    }
    return display
  }
}

export default DateBase
