import LocalDate from './LocalDate'
import LocalTime from './LocalTime'

export default class LocalDateTime {
  #date!: LocalDate
  #time!: LocalTime

  constructor (year: number, month: number, dayOfMonth: number, hour: number, minute: number, second: number, millisecond = 0) {
    this.#date = LocalDate.of(year, month, dayOfMonth)
    this.#time = LocalTime.of(hour, minute, second, millisecond)
  }

  get year () {
    return this.#date.year
  }

  get month () {
    return this.#date.month
  }

  get dayOfMonth () {
    return this.#date.dayOfMonth
  }

  get hour () {
    return this.#time.hour
  }

  get minute () {
    return this.#time.minute
  }

  get second () {
    return this.#time.second
  }

  static of (date: LocalDate, time: LocalTime): LocalDateTime;
  static of (year: number, month: number, dayOfMonth: number, hour: number, minute: number, second: number, millisecond?: number): LocalDateTime;
  static of (...params: any): LocalDateTime {
    if (params[0] instanceof LocalDate && params[1] instanceof LocalTime) {
      return new LocalDateTime(params[0].year, params[0].month, params[0].dayOfMonth, params[1].hour, params[1].minute, params[1].second, params[1].millisecond)
    } else {
      return new LocalDateTime(params[0], params[1], params[2], params[3], params[4], params[5], params[6] || 0)
    }
  }

  // timeString 格式 2020-05-09T03:04:05、2020-05-09T03:04:05.000、2020-05-09 03:04:05
  static parse (timeStr: string): LocalDateTime {
    const dateReg = /^(\d{4})-(\d{1,2})-(\d{1,2})/g
    const timeReg = /(\d{1,2}):(\d{1,2}):(\d{1,2})(\.\d{1,3})?$/g
    const dStr = timeStr.match(dateReg)?.[0]
    const tStr = timeStr.match(timeReg)?.[0]
    if (dStr && tStr) {
      const dateArr = dStr.split('-').map(n => Number(n))
      const timeArr = tStr.split(/[:|.]/g).map(n => Number(n))
      return new LocalDateTime(dateArr[0], dateArr[1], dateArr[2], timeArr[0], timeArr[1], timeArr[2], timeArr[3] || 0)
    } else {
      throw new Error(`Invalid date ${timeStr}`)
    }
  }

  toString (): string {
    return `${this.#date.toString()}T${this.#time.toString()}`
  }

  getLocalDate (): LocalDate {
    return this.#date
  }

  getLocalTime (): LocalTime {
    return this.#time
  }

  plusYears (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.plusYears(n)
    return newLocalDateTime
  }

  plusMonths (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.plusMonths(n)
    return newLocalDateTime
  }

  plusDays (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.plusDays(n)
    return newLocalDateTime
  }

  plusHours (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const hover = this.#time.hour + n
    if (hover >= 24) {
      newLocalDateTime.#date = newLocalDateTime.#date.plusDays(Math.floor(hover / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.plusHours(n)
    return newLocalDateTime
  }

  plusMinutes (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const minute = newLocalDateTime.#time.minute + n
    const sumHover = newLocalDateTime.#time.hour + Math.floor(minute / 60)
    if (sumHover >= 24) {
      newLocalDateTime.#date = newLocalDateTime.#date.plusDays(Math.floor(sumHover / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.plusMinutes(n)
    return newLocalDateTime
  }

  plusSeconds (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const second = this.#time.second + n
    const sumHover = this.#time.hour + Math.floor((this.#time.minute + Math.floor(second / 60)) / 60)
    if (sumHover >= 24) {
      newLocalDateTime.#date = newLocalDateTime.#date.plusDays(Math.floor(sumHover / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.plusSeconds(n)
    return newLocalDateTime
  }

  plusMillisecond (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const millisecond = this.#time.millisecond + n
    const sumHover = this.#time.hour + Math.floor((this.#time.second + Math.floor(millisecond / 1000)) / 60 / 60)
    if (sumHover >= 24) {
      newLocalDateTime.#date = newLocalDateTime.#date.plusDays(Math.floor(sumHover / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.plusMillisecond(n)
    return newLocalDateTime
  }

  minusYears (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.minusYears(n)
    return newLocalDateTime
  }

  minusMonths (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.minusMonths(n)
    return newLocalDateTime
  }

  minusDays (n: number): LocalDateTime {
    const newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    newLocalDateTime.#date = newLocalDateTime.#date.minusDays(n)
    return newLocalDateTime
  }

  minusHours (n: number): LocalDateTime {
    let newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const hover = newLocalDateTime.#time.hour - n
    if (hover < 0) {
      newLocalDateTime = newLocalDateTime.minusDays(Math.ceil(Math.abs(hover) / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.minusHours(n)
    return newLocalDateTime
  }

  minusMinutes (n: number): LocalDateTime {
    let newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const minute = newLocalDateTime.#time.minute - n
    const sumHover = newLocalDateTime.#time.hour - Math.ceil(Math.abs(minute) / 60)
    if (sumHover < 0) {
      newLocalDateTime = newLocalDateTime.minusDays(Math.ceil(Math.abs(sumHover) / 24))
    }
    newLocalDateTime.#time = newLocalDateTime.#time.minusMinutes(n)
    return newLocalDateTime
  }

  minusSeconds (n: number): LocalDateTime {
    let newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const second = newLocalDateTime.#time.second - n
    const sumMinute = newLocalDateTime.#time.minute - (Math.ceil(Math.abs(second) / 60))
    if (sumMinute < 0) {
      const sumHover = newLocalDateTime.#time.hour - Math.ceil(Math.abs(sumMinute) / 60)
      if (sumHover < 0) {
        newLocalDateTime = newLocalDateTime.minusDays(Math.ceil(Math.abs(sumHover) / 24))
      }
    }
    newLocalDateTime.#time = newLocalDateTime.#time.minusSeconds(n)
    return newLocalDateTime
  }

  minusMillisecond (n: number): LocalDateTime {
    let newLocalDateTime = LocalDateTime.of(this.#date, this.#time)
    const millisecond = newLocalDateTime.#time.millisecond - n
    const sumSecond = newLocalDateTime.#time.second - (Math.ceil(Math.abs(millisecond) / 1000))
    if (sumSecond < 0) {
      const sumMinute = newLocalDateTime.#time.minute - Math.ceil(Math.abs(sumSecond) / 60)
      if (sumMinute < 0) {
        const sumHover = newLocalDateTime.#time.hour - Math.ceil(Math.abs(sumMinute) / 60)
        if (sumHover < 0) {
          newLocalDateTime = newLocalDateTime.minusDays(Math.ceil(Math.abs(sumHover) / 24))
        }
      }
    }
    newLocalDateTime.#time = newLocalDateTime.#time.minusMillisecond(n)
    return newLocalDateTime
  }

  compareTo (ldt: LocalDateTime): number {
    if (ldt instanceof LocalDateTime) {
      let result = this.#date.compareTo(ldt.#date)
      if (result === 0) {
        result = this.#time.compareTo(ldt.#time)
      }
      return result
    } else {
      throw new Error('LocalDateTime can only be compared with LocalDateTime')
    }
  }
}
