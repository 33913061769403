<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellNumber from './TableCellNumber.vue'
import FormNumber from './Number.vue'

@Component({ name: 'LwNumber', components: { TableCell: TableCellNumber, FormItem: FormNumber } })
class LwNumber extends FormControlBase {}
export default LwNumber
</script>
