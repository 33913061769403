<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellTime from './TableCellTime.vue'
import FormTime from './Time.vue'

@Component({ name: 'LwTime', components: { TableCell: TableCellTime, FormItem: FormTime } })
class LwTime extends FormControlBase {}
export default LwTime
</script>
