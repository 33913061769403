<template>
  <div class="lw-404">
    The resource you head for doesn't exist
  </div>
</template>

<script>
export default {
  name: 'Page404'
}
</script>

<style scoped>
  .lw-404{
    height: 100%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
