import { ICondition } from '@/types/data'
import { conditionType, filterOperator } from '@/utils/const'
import { checkValueIsEmpty } from '@/utils/data'

export default class Condition {
  #type = conditionType.CONDITION
  #field!: string
  #operator!: filterOperator
  #subQueryFilterField!: string
  #subQueryFilterOperator!: filterOperator
  #subQueryName!: string
  #value!: string

  constructor (field: string, operator: filterOperator, value?: string) {
    const c = this.setField(field).setOperator(operator)
    if (value !== undefined) c.setValue(value)
  }

  setField (param: string): Condition {
    this.#field = param
    return this
  }

  setOperator (param: filterOperator): Condition {
    this.#operator = param
    return this
  }

  setSubQueryFilterField (field: string): Condition {
    this.#subQueryFilterField = field
    return this
  }

  setSubQueryFilterOperator (param: filterOperator): Condition {
    this.#subQueryFilterOperator = param
    return this
  }

  setSubQueryName (query: string): Condition {
    this.#subQueryName = query
    return this
  }

  setValue (param: string): Condition {
    this.#value = param
    return this
  }

  build (): ICondition {
    return {
      type: this.#type,
      field: this.#field,
      operator: this.#operator,
      subQueryFilterField: this.#subQueryFilterField,
      subQueryFilterOperator: this.#subQueryFilterOperator,
      subQueryName: this.#subQueryName,
      value: this.#value
    }
  }
}
