













import { Component, Vue } from 'vue-property-decorator'
import FeedbackCreateBar from './FeedbackCreateBar.vue'
import ModifyI18nOnline from './ModifyI18nOnline.vue'
import { getLayout } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import { getFeedbackConfig } from '@/utils/feedback'
@Component({
  components: {
    FeedbackCreateBar,
    ModifyI18nOnline
  }
})
export default class FixedPanel extends Vue {
  isFeedbackVisible = false
  isModifyI18nVisible = false

  get isLogined () {
    return !!LocalModule.user?.id
  }

  get isUserAdmin () {
    return !!LocalModule.user?.admin
  }

  created () {
    getFeedbackConfig().then(res => {
      this.isFeedbackVisible = !!res.data.feedbackEnabled
    })
    this.getI18nModifyPriority()
  }

  getI18nModifyPriority () {
    getLayout({ layout: 'special_resources', silent: true }).then(res => {
      if (res.data.data.metaData.accessibleResources) {
        if (res.data.data.metaData.accessibleResources.includes('core.special_resources:custom-translations')) {
          this.isModifyI18nVisible = true
        }
      }
    })
  }
}
