import { LayoutComponent } from '@/types/layout'
import ComponentNode from '@/models/ComponentNode'
import _ from 'lodash'
import { getUuid } from '@/utils/common'

class Component {
  component: LayoutComponent = {}

  constructor (componentIs: string, component : LayoutComponent | null = null) {
    if (component) {
      this.component = component
    } else {
      this.component.is = componentIs
      if (componentIs !== 'lw-dynamic') {
        this.component.components = []
      }
      this.component.id = getUuid()
    }
  }

  // 遍历当前 Component 下的 components, 只遍历一层
  forEachComponent (callback: (component: Component, index: number) => void) {
    if (this.component.components && this.component.components.length > 0) {
      this.component.components.forEach((item: LayoutComponent, index: number) => {
        callback(new Component(item.is, item), index)
      })
    }
  }

  // 根据索引获取 Component 对象
  getComponent (index: number): Component|null {
    const components = this.component.components
    if (components) {
      return new Component(components[index].is, components[index])
    } else {
      return null
    }
  }

  // 在当前 Component 下的components 的末尾添加组件
  appendComponent (component: Component): void {
    if (!this.component.components) return
    this.component.components.push(component.component)
  }

  // 在指定位置添加组件
  insertComponent (index: number, component: Component): void {
    if (!this.component.components) return
    this.component.components.splice(index, 0, component.component)
  }

  // 移除指定位置的组件
  removeComponent (index: number): void {
    if (!this.component.components) return
    this.component.components.splice(index, 1)
  }

  // 根据子节点名称获取固定子节点
  getNode (nodeName: string): ComponentNode | null {
    return new ComponentNode(this.component[nodeName])
  }

  // 根据子节点名称创建固定子节点
  createNode (nodeName: string) : ComponentNode {
    const componentNode = new ComponentNode()
    this.component[nodeName] = componentNode.components
    return componentNode
  }

  // 根据子节点名称移除固定子节点
  removeNode (nodeName: string): void {
    delete this.component[nodeName]
  }

  // 获取属性值
  getAttribute (attributeName: string): string {
    return this.component[attributeName]
  }

  // 设置属性值
  setAttribute (attributeName: string, attributeValue: string): void {
    /**
     * 想要修改table和form的子组件，可以通过dataset改变组件内的数据
     * 但是如果想改变子组件的属性，只能重新创建一次table和form
     */
    this.component.id = getUuid()
    // 因为所有组件内部都将属性当成字符串处理
    this.component[attributeName] = attributeValue + ''
  }

  // 移除属性值
  removeAttribute (attributeName: string): void {
    delete this.component[attributeName]
  }

  // 获取当前组件类型 （'lw-button'...）
  getType (): string {
    return this.component.is
  }
}

export default Component
