


















































































































































































































































































import ExpandRowWrapper from '@/components/layout/inner/ExpandRowWrapper.vue'
import TableSelectAllTip from '@/components/layout/inner/TableSelectAllTip.vue'
import QuickEditForTable from '@/components/layout/inner/QuickEditForTable.vue'
import MoreOpsForTable from '@/components/layout/inner/MoreOpsForTable.vue'
import FiltersForTable from '@/components/layout/inner/FiltersForTable.vue'
import { createPopper, Instance } from '@popperjs/core'
import { checkValueIsEmpty, formatDataRow, getDataListForSave, getEventName, handleDataSetRetrieveForQueryByFilterComponent } from '@/utils/data'
import { LayoutModule } from '@/store/modules/layout'
import {
  attributeSymbol,
  attributeType,
  EDIT_LAYOUT_DATASET_NAME, EDIT_LAYOUT_POPUP_NAME_SUFFIX, EDIT_LAYOUT_SAVE_TRIGGER_MAP,
  layoutStatus, operationType,
  rowPersist, SYSTEM_DB_RESERVED_FIELDS,
  TABLE_RELATED_DATASET_NAME,
  totalByType
} from '@/utils/const'
import Args from '@/models/Args'
import logwire from '@/logwire'
import { doDelete, doSave, getLayout, queryByFilter, doAction } from '@/http/api'
import eventbus from '@/utils/event'
import _, { debounce, isFunction, pick } from 'lodash'
import { addResizeListener, calculateHeight, closeAllErrorMessages, isHidden, removeResizeListener } from '@/utils/dom'
import {
  attribute2Boolean,
  attribute2Number,
  attribute2Strings, findMasterFields, getPlainAttributeValue,
  percent2decimal,
  getI18nContent,
  warnComponentUsedWrong, getResourceWithParams, isSymbol,
  runRunnableContent, getFinalAttributeValue, setLayoutForOutsideComponent
} from '@/utils/layout'
import DataRow from '@/models/DataRow'
import dragColumnWidth from './drag-column-width'
import FieldsDisplay from '../basic/advanceFilter/fieldsDisplay.vue'
import FieldsSort from '../basic/advanceFilter/fieldsSort.vue'
import { LocalModule } from '@/store/modules/local'
import { numeralFormat } from '@/utils/number'
import QueriableByFilterComponent from '../QueriableByFilterComponent'
import TableDisplayText from '../inner/TableDisplayText.vue'
import { saveUserSettings, getUserSettings } from '@/utils/common'
import Component from 'vue-class-component'
import { Mixins, Vue, Watch } from 'vue-property-decorator'
import VxeTable from '@/3rd-party-extension/Table/src/VxeTable.vue'
import { ColumnInfo } from 'vxe-table'
import { LayoutComponent, PopupLayoutConfig } from '@/types/layout'
import { DataSet, DataRow as IDataRow } from '@/types/data'
import { AxiosResponse } from 'axios'
import TagContent from '@/models/TagContent'
import Displayer from '../form-item/TableCell.vue'

const colorSchemeClassMap = {
  primary: 'table-tr-style--primary',
  secondary: 'table-tr-style--secondary',
  success: 'table-tr-style--success',
  warn: 'table-tr-style--warn',
  error: 'table-tr-style--error'
}

type ColumnGroup = { type: 'groupColumn', title: string, children: any[] }
type ColumnItem = { type: 'column', column: ColumnInfo }

@Component({
  name: 'lw-table',
  components: {
    quickEditForTable: QuickEditForTable,
    moreOpsForTable: MoreOpsForTable,
    filtersForTable: FiltersForTable,
    tableSelectAllTip: TableSelectAllTip,
    ExpandRowWrapper,
    FieldsDisplay,
    FieldsSort,
    // eslint-disable-next-line vue/no-unused-components
    TableDisplayText,
    Displayer
  }
})
export default class LwTable extends Mixins(QueriableByFilterComponent, dragColumnWidth) {
  declare component: LayoutComponent & {
    fields: Record<string, any>[] // fields 节点内的各个组件结构
  }

  popperInstance !: Instance // 更多操作popper实例
  editColumn !: any
  targetCellDom !: HTMLElement
  batch !: boolean
  radioCheckedRow!: IDataRow
  _renderColumnOnce!: boolean
  quickEditingRow!: IDataRow
  editField!: string // 当前快速编辑的字段名
  backupDataSetRows !: any[]

  horizontalScrolling = true

  cacheFieldOptions = {}
  defaultColumnWidth = 180
  opsColumnWidth = 60
  tailColumnWidth = 0
  scrollWidth = 0 // 滚动区域的宽度,当外层容器宽度小于scroll-width值时，将会出现横向滚动条；当外层容器宽度大于scroll-width值时，将会跟随容器自适应
  tableHeight = 200
  editVal = null // 当前快速编辑的值
  /** 用来存储变更的行记录，如果把某个 row 添加进来要注意，重复的数据不要加进来 */
  editRows: IDataRow[] = []
  rowSpanFields: any[] = []
  rowSpan: any[] = []
  targetRow = {} as IDataRow
  tableData: (IDataRow & { _hasExpanded?: boolean, _rowEnabled?: boolean, _rowSelectable?: boolean })[] = []
  columns: any[] = []
  editable = false
  deletable = false
  viewable = false
  newable = false
  cloneable = false
  rowConfig = {
    isHover: true,
    keyField: 'rowKey'
  }

  virtualScrollConfig = { gt: 0, oSize: 5 }

  // 记录在单选模式下被选中的行
  // 目的是为了在 通过 rowSelected 属性，配置都选行时，保证只有一行的 isSelected 是 true
  radioConfig = {}
  checkboxConfig = {
    // 这里绑定 isSelected 字段的原因是为了提升否选的性能,否则 默认勾选 全部数据的时候,性能很差
    checkField: 'isSelected'
  }

  expandColumn = {
    field: '',
    key: 'expand',
    type: 'expand',
    title: '',
    width: 60,
    align: 'center'
  }

  editPopperShow = false // 快速编辑的弹出框是否显示
  iterateRowsMap = {} // 遍历过的行 map { rowKey1: true, rowKey2: true,,,,}
  disabledRowsKeys: any[] = [] // 禁止选择的行 [rowKey1, rowKey2,,,,]
  hasExpandRowKeys: any[] = [] // 已经展开过的行的 rowkey
  customClass = ''
  dialogDisplayVisible = false
  dialogSortVisible = false
  settingDateCopy = {}
  settingDate: {
    user_id: string
    table_control: string
    display_fields_enabled: boolean
    all_fields: any[],
    display_fields: any[],
    display_fields_json: string | null
    sort_enabled: boolean
    sort: any[],
    sort_json: string | null
    fields_width_json: string | null,
    changed_fields_width: any[],
    page_size: number | null,
    default_advanced_filter_id: string | null
  } = {
    user_id: LocalModule.user.id,
    table_control: this.layoutName + '.' + this.dataSetName,
    display_fields_enabled: false,
    all_fields: [],
    display_fields: [],
    display_fields_json: null,
    sort_enabled: false,
    sort: [],
    sort_json: null,
    fields_width_json: null,
    changed_fields_width: [],
    page_size: null,
    default_advanced_filter_id: null
  }

  handleResize: null | (() => void) = null
  renderColumns: (ColumnGroup | ColumnItem)[] = []
  // 8(左右 padding) + 16(两个行内图标) * 2 + 图标和内容间距 6 （46）
  cellWhiteSpace = 46

  $refs !: {
    table: VxeTable
    tableOperations: MoreOpsForTable
    saveForQuickEdit: HTMLElement
    quickEdit: QuickEditForTable
    quickEditWrapper: HTMLElement
  }

  get operationsDisabled () {
    let result = false
    const status = this.context.getLayoutStatus()
    // 在抽屉或者弹出层处于 view 状态或者，或者页面状态不是 view 或 eidt 或 HEADER_DETAIL_EDIT 或 HEADER_DETAIL_NEW 的时候，table 的新增按钮置为 diasbled
    if (this.popupLayoutStatus === layoutStatus.VIEW || (status && ![layoutStatus.VIEW, layoutStatus.EDIT, layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(status))) {
      result = true
    }
    if (this.component.headerDataSet && ![layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status)) {
      return true
    }
    return result
  }

  get selectAllEnabled () {
    return this.component.selectAllEnabled
      ? this.getFinalAttributeValue('selectAllEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  get crossPageSelectAllEnabled () {
    return this.component.crossPageSelectAllEnabled
      ? this.getFinalAttributeValue('crossPageSelectAllEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  get maxUnfoldedOperations () {
    return this.component.maxUnfoldedOperations
      ? this.getFinalAttributeValue('maxUnfoldedOperations', { type: attributeType.NUMBER })
      : 2
  }

  get widthCacheable () {
    return attribute2Boolean(this.component.widthCacheable) !== false
  }

  get minHeight () {
    let result = 150
    if (this.component.minHeight) {
      result = parseInt(this.component.minHeight.replace('px', ''))
    }
    return result
  }

  get width () {
    return attribute2Number(this.component.width) || 0
  }

  // 判断是否使用小蓝点标识
  get useTableSetting () {
    return this.settingDate.display_fields_enabled || this.settingDate.sort_enabled
  }

  get maxHeight () {
    let result = 2000
    if (this.component.maxHeight) {
      result = parseInt(this.component.maxHeight.replace('px', ''))
    }
    return result
  }

  // 是否可以进入快速编辑
  get enabled () {
    let result = true
    if (this.component.enabled) {
      result = this.getFinalAttributeValue('enabled', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get showIndex () {
    return checkValueIsEmpty(this.component.showIndex) ? true : attribute2Boolean(this.component.showIndex)
  }

  get selectionMode () {
    let result = 'multiple'
    if (this.component.selectionMode) {
      result = this.getFinalAttributeValue('selectionMode')
    }
    return result
  }

  // 是否显示操作列
  get showOpColumns () {
    const usedOpCount = this.defaultOpColumns.filter(o => o.visible === true).length
    const operations = this.component.operations || []
    if (usedOpCount || operations.length) return true
    return false
  }

  /**
     * 将表格的按钮转换为 { type, visible }[] 的形式
     */
  get defaultOpColumns () {
    const columns = []
    columns.push({ type: 'viewable', visible: this.viewable })
    // 如果处于弹窗内，并且页面状态是 VIEW，则不显示 编辑、克隆、删除 按钮
    if (this.popupLayoutStatus !== layoutStatus.VIEW) {
      columns.push({ type: 'editable', visible: this.editable })
      columns.push({ type: 'cloneable', visible: this.cloneable })
      columns.push({ type: 'deletable', visible: this.deletable })
    }
    return columns
  }

  get visibleOpColumns () {
    return this.defaultOpColumns.filter(o => o.visible === true).slice(0, this.maxUnfoldedOperations)
  }

  get moreOpColumns () {
    return this.defaultOpColumns.filter(o => o.visible === true).slice(this.maxUnfoldedOperations)
  }

  get tableScrollOptY () {
    return this.getFinalAttributeValue('verticalVirtualScrollEnabled', { type: attributeType.BOOLEAN }) === false
      ? { enabled: false }
      : this.virtualScrollConfig
  }

  created () {
    // this.bodyCellClass = `lw-table--${this.dataSetName}-cell`
    // editable、deletable、newable 受 资源权限控制，当不具备 __eidt 权限时，这三个值都应该设置为 false
    this.editable = this.accessibleResourcesWithEdit
      ? checkValueIsEmpty(this.component.editable)
        ? false
        : attribute2Boolean(this.component.editable) as boolean
      : false
    this.deletable = this.accessibleResourcesWithEdit
      ? checkValueIsEmpty(this.component.deletable)
        ? false
        : attribute2Boolean(this.component.deletable) as boolean
      : false
    this.newable = this.accessibleResourcesWithEdit
      ? checkValueIsEmpty(this.component.newable)
        ? false
        : attribute2Boolean(this.component.newable) as boolean
      : false
    this.cloneable = this.accessibleResourcesWithEdit
      ? checkValueIsEmpty(this.component.cloneable)
        ? false
        : attribute2Boolean(this.component.cloneable) as boolean
      : false
    this.viewable = checkValueIsEmpty(this.component.viewable)
      ? false
      : attribute2Boolean(this.component.viewable) as boolean
    // 如果是展开行中的表格，高度默认为 200
    if (this.component.subDataSet) this.tableHeight = 200
    const aggregationFields = this.getFinalAttributeValue('aggregationFields', { type: attributeType.STRING_ARRAY })
    // 初始时,给非表单组件添加 filed 属性，便于对比查询，命名规则如：__field_lw-label_1
    this.component.fields.forEach((item: any, index: number) => {
      if (!item.field) {
        item.field = `__field_${item.is}_${index}`
      }
    })
    // 初始化列表所需的附加信息，比方 pageNo pageSize等
    !this.parentRow && LayoutModule.initTableExtraData({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      // pageSize: -1,
      queryName: this.query,
      aggregationFields,
      showFields: this.component.fields
    })
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.init-retrieve`, this.initRetrieve)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.dataSet-row-changed`, this.handleDataRowChange)
    eventbus.$on('dataSet-row-field-changed', this.handleDataRowFieldChange)
    eventbus.$on('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.getSimpleExportExcelFields`, this.getSimpleExportExcelFields)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.clearEditRows`, this.clearEditRows)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.paging`, this.handlePaging)
  }

  mounted () {
    // 以前更多操作按钮在 mounted 阶段设置，但是没有考虑到组件 visible 为 false 的按钮
    if (this.component.height && !(this.component.height.endsWith('%') || this.component.height === 'auto')) {
      this.tableHeight = this.component.height.replace('px', '') * 1
      if (this.tableHeight > this.maxHeight) this.tableHeight = this.maxHeight
      if (this.tableHeight < this.minHeight) this.tableHeight = this.minHeight
    } else {
      /**
       * 以前
       * 多个table 同时显示的时候，每个 table 的计算经常会导致其它 table 的重新计算，导致界面不断抖动
       *
       * 2023-2-22
       * 为了表格在页面渲染时不会产生“过一段时间突然变高的情况”，去掉防抖, 以及添加一个 afterSearch, 表示经历了查询
       * 目前测试两个表格同时显示，没有发现不断抖动
       *
       * 2023-03-31
       * 以前 resize 方法内用了 window.requestAnimationFrame ，本意是为了解决 Tab 下的表格计算问题，但是会影响到全选时的提示信息，所以去除。同时去除了提示信息的动画效果，避免计算出现意外
       *
       * 2023-04-03
       * 以前 resize 回调内，具有异步重新绑定事件的做法，考虑到如果其他组件在异步代码执行之前改变了自身高度，导致表格计算没有触发，删除这部分代码看看效果
       * 也许是为了避免重复计算？但是简单测试下来没有发现会有这种情况
       */
      this.handleResize = () => {
        this.calculateTableHeight()
      }
      addResizeListener(this.$el, this.handleResize)
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    }
    // 根据 user_table_setting_search 获取我的显示字段, 先进行校验，看能不能用
    this.getUserTableSettings()
  }

  beforeDestroy () {
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.dataSet-row-changed`, this.handleDataRowChange)
    eventbus.$off('dataSet-row-field-changed', this.handleDataRowFieldChange)
    eventbus.$off('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
    if (this.handleResize) {
      removeResizeListener(this.$el, this.handleResize)
      window.removeEventListener('resize', this.handleResize)
    }
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.getSimpleExportExcelFields`, this.getSimpleExportExcelFields)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.clearEditRows`, this.clearEditRows)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.paging`, this.handlePaging)
  }

  @Watch('editRows')
  onEditRowsChange (rows: any[]) {
    const editingDataSet = LayoutModule.data[this.encodedLayoutName]?.editingDataSet
    const currentDataSet = `${this.encodedLayoutName}.${this.dataSetName}`
    if (editingDataSet !== currentDataSet && rows.length > 0) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: this.dataSetName })
    } else if (editingDataSet === currentDataSet && rows.length === 0) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
    }
    this.changeOpsColumnWidth()
  }

  @Watch('tableData')
  onTableDataChange () {
    this.changeOpsColumnWidth()
  }

  /**
   * 注意这里的问题，因为监听的是 LayoutModule 里的数据，如果页面上增加了一个 DataSet ,会使用 Vue.set 语法
   * 紧接着会导致 DataSet 下的对象的监听都触发一次，然后就触发了其他表格的这个监听，引起一次重新渲染，但这次渲染是没意义的
   * 所以通过记住上一次的 rows 对象，判断对象是否发生了变化
   * */
  @Watch('dataSet.rows', { immediate: true })
  onDataSetRowsChange (val: any[], oldVal: any[]) {
    if (val) {
      if (this.backupDataSetRows) {
        // 如果行数不同，或者某行的对象不匹配，则认为发生了 rows 上的变化
        const isAnyOneRowChange = val.length > 0
          ? (val.some((o, index) => o !== this.backupDataSetRows[index]) || this.backupDataSetRows.length !== val.length)
          : true
        if (isAnyOneRowChange) {
          this.backupDataSetRows = val.slice()
          this.loadTableData()
        }
      } else {
        this.backupDataSetRows = val.slice()
        this.loadTableData()
      }
      // TODO 数据变更后，强制重新渲染；否则从大数据量到小数据量，页面极有可能空白
      // this.$forceUpdate()
    }
  }

  @Watch('parentRow.subDataSets', { deep: true })
  onParentRowsSubDataSetsChange (val?: DataSet) {
    if (val) {
      const _rows = val?.[this.component.subDataSet]?.rows
      if (_rows) {
        // 对于虚拟滚动来说，row中必须要有一个 rowKey字段
        _rows.forEach((row: IDataRow, index: number) => { row.rowKey = `${this.component.subDataSet}${index}` })
      }
      const data = _rows || []
      this.tableData = data
      this.$refs.table.loadData(data)
    }
  }

  /*
  * 通过addRow api添加的行、单元格弹出小窗口的编辑 状态都变更为 expressEdit
  * */
  @Watch('status')
  onStatusChange (val: string) {
    // if (!this.enabled) return
    const saveForQuickEdit = this.$refs.saveForQuickEdit
    if (val === layoutStatus.VIEW) {
      saveForQuickEdit.style.display = 'none'
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.VIEW })
      eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'visible'), true)
    } else if (val === layoutStatus.EXPRESS_EDIT) {
      saveForQuickEdit.style.display = 'flex'
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EXPRESS_EDIT })
      eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'visible'), false)
    }
  }

  getTitleI18nContent (key: string) {
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // getI18nContent 内部会拿环境变量来识别 layout，如果不自己手动设置的话，可能因为环境变量指向其他页面而无法查出内容
    return getI18nContent(key)
  }

  // 实现单列自适应列宽, 注意这个函数参数会经过 vxe-patch 文件的处理
  makeCurColumnWidthFitContent ({ column, dblCallback }: { column: ColumnInfo, dblCallback: (resizeWidth?: number) => void }) {
    const dragField = column.params.field
    const columns = this.renderColumns
    let curColumn: Partial<ColumnInfo> | undefined
    columns.forEach(item => {
      if (item.type === 'column' && item.column.field === dragField) {
        curColumn = item.column
      } else if (item.type === 'groupColumn') {
        item.children.forEach(child => {
          if (child.field === dragField) {
            curColumn = child
          }
        })
      }
    })
    const _rows = this.dataSet.rows
    const rows = _rows.filter(row => {
      return row.rowPersist !== rowPersist.DELETE
    })
    if (!rows || !rows.length || !curColumn) return
    // 这里删除在逻辑上是需要这么操作，这样才可以在 calculate 的时候计算新的值，但是从类型上不应该这样做
    delete curColumn.width
    const columnArr = [curColumn] as ColumnInfo[]
    rows.map((row) => {
      this.calculateColumnsWidth(row, columnArr)
    })
    this.calculateColumnWidth(columnArr)
    // 还要改善 renderColumn中的计算内容
    dblCallback(curColumn.width)
    this.saveColumnsWidth(columns)
  }

  handleOperation ($event: MouseEvent, type: string, row: any) {
    if (this.operationsDisabled) return

    const handlerMap = new Map([
      ['edit', () => this._handleDataSetEdit(layoutStatus.EDIT, row)],
      ['clone', () => this.handleDataSetClone(row)],
      ['delete', () => this._handleDataSetDelete({ row })],
      ['more', () => this.showTableOpts($event, row)]
    ])

    const handler = handlerMap.get(type)

    if (isFunction(handler)) handler()
  }

  // 数据变更后，根据每行的数据改变操作列宽度
  changeOpsColumnWidth () {
    const rows = this.dataSet.rows
    if (!rows.length) return
    const defaultOps = ['viewable', 'editable', 'cloneable', 'deletable'] as const
    const initWidth = defaultOps.reduce((acc, cur) => acc + this.calculateDefaultOpWidth(cur), 0)
    const opsWidth: number[] = []
    rows.forEach((row) => {
      const more = this.isShowMoreOperationBtn({ row })
      let opWidth = more ? (this.getTextWidth('...', '400 14px') + 32) : 0
      const generateOpColumns = this.generateOpColumnsNotHidden(row)
      generateOpColumns.forEach((op) => {
        const text = getFinalAttributeValue('title', op, new Args(this.context, { row }), this.associatedContext)
        opWidth += this.getTextWidth(text, '400 14px') + 32 + 1 // borderRight: 1, padding: 32
      })
      if (more || generateOpColumns.length) opsWidth.push(opWidth)
    })
    const maxOpsWidth = opsWidth.length ? Math.max(...opsWidth, 60) : 0
    this.opsColumnWidth = Math.max(maxOpsWidth + initWidth, 60) - 12 // 第一项操作按钮的左边框为 4，而不是 16，减去多计算的 12
  }

  calculateDefaultOpWidth (type: 'viewable' | 'editable' | 'cloneable' | 'deletable') {
    const opText = {
      viewable: 'client.common.view',
      editable: 'client.common.edit',
      cloneable: 'client.common.clone',
      deletable: 'client.common.delete'
    }
    let width = 0
    if (this.checkDefaultButtonExistOutside(type)) {
      width = this.getTextWidth(logwire.ui.getI18nMessage(opText[type], 'core'), '400 14px') + 32 + 1 // borderRight: 1, padding: 32
    }
    return width
  }

  handleComponentFields () {
    const columns = this.component.fields
    // 根据显示列过滤columns
    this._handleComponentDisplayFields(columns)
    // 根据列宽显示表格
    this._handleComponentWidthFields(columns)
  }

  _handleComponentDisplayFields (columns: LwTable['component']['fields']) {
    const displayFields = this.settingDate.display_fields
    // 根据显示列显示表格
    if (this.settingDate.display_fields_enabled) {
      displayFields.forEach((item, index) => {
        item.index = index
      })
      const displayMap = _.keyBy(displayFields, (f) => f.name)
      columns.forEach(item => {
        item.display = displayMap[item.field]?.display
        item.index = displayMap[item.field]?.index
      })
    }
  }

  _handleComponentWidthFields (columns: LwTable['component']['fields']) {
    const fieldsWidthJson = this.settingDate.fields_width_json
    if (fieldsWidthJson) {
      const fieldsWidth = JSON.parse(fieldsWidthJson)
      const widthMap = _.keyBy(fieldsWidth, (f) => f.name)
      columns.forEach(item => {
        if (item.is === 'lw-image' && item.width) {
          item.imageRealWidth = item.width.toString().replace('px', '')
        }
        item.width = widthMap[item.field]?.width
      })
    }
  }

  // 获取table 最终所渲染的列，不包含序号列，选择列，操作列
  // FIXME: 在 render 时将 fields 的属性，一股脑的转换为了 ColumnInfo 对象。这是不合理的，比如 ColumnInfo 就不需要 is 属性
  getRenderColumns () {
    let columns = _.cloneDeep(this.component.fields) as ColumnInfo[]
    const groupList: LwTable['renderColumns'] = []
    if (this.settingDate.display_fields_enabled) {
      columns = columns.filter(item => item.display).sort((a, b) => a.index - b.index)
    }
    this.calculateColumnWidth(columns)
    for (const c of columns) {
      if (!this.enabled) {
        c.enabled = 'false'
      }
      if (c.group) {
        const group = groupList.find(g => g.type === 'groupColumn' && g.title === c.group) as ColumnGroup
        if (group) {
          group.children.push(c)
        } else {
          groupList.push({
            type: 'groupColumn',
            title: c.group,
            children: [c]
          })
        }
      } else {
        groupList.push({
          type: 'column',
          column: c
        })
      }
    }
    // 20230621 即使只有group内只有一个字段，也要用多级表头来显示
    // 修改列宽时，由于嵌套层级太深，及时使用forceUpdate也不能刷新table，所以采用先置空再赋值的方式，如果不这样，会发现表格没有渲染正确的宽度
    this.renderColumns = []
    this.$nextTick(() => {
      this.renderColumns = groupList
    })
    return groupList
  }

  // FIXME: 这个方法即会接受 ColumnInfo 作为参数，也会接受 Component.field 节点作为参数
  calculateColumnWidth (columns: ColumnInfo[]) {
    for (const c of columns) {
      const i18nKey = c.title
      c.title = getI18nContent(c.title)
      c.sortable = getFinalAttributeValue('sortable', c, new Args(this.context), this.associatedContext)
      c.sort = 'none'
      // 如果某列不具备 width 属性，才通过自适应计算列宽
      if (!c.width) {
        // 没有列表数据且未设置width时，按 头部title + 内边距24 + 排序icon + 误差1 显示宽度
        const sortWidth = c.sortable ? 26 : 0
        const headerWidth = this.getTextWidth(c.title, '600 14px') + 24 + sortWidth + 1
        // 计算列宽 = 内容宽度  + this.cellWhiteSpace
        // 固定内容宽度的组件：lw-switch lw-checkbox
        // tableColumnWidth: 是组件基类层面上的属性，用来表示用户定义的组件在表格中的初始列宽，而一旦表格保存宽度数据后，则使用存储的
        let textWidth = c.tableColumnWidth || c.textWidth || 60
        if (c.is === 'lw-switch') {
          // switch 内容宽度 40
          textWidth = 40 + this.cellWhiteSpace
        } else if (c.is === 'lw-checkbox') {
          // checkbox 内容宽度 16
          textWidth = 16 + this.cellWhiteSpace
        } else if (c.is === 'lw-image') {
          // 由于 image 组件本身就有 width 属性，会和 table 列的 width 冲突，所以添加 imageRealWidth 属性记录下 image 的 width 属性
          // 图片自身的默认值在 LwImage.vue 中设置
          textWidth = 16 + this.cellWhiteSpace
          if (c.tableColumnWidth) {
            textWidth = c.tableColumnWidth
          }
        }
        // 限制最大宽度 400
        c.width = Math.min(Math.max(headerWidth, textWidth, 60), 400)
      } else {
        if (c.is === 'lw-image') {
          // 如果已经存在 c.imageRealWidth, 说明在获取了已存储的表格设置后，对字段进行过赋值 width 操作
          if (!c.imageRealWidth) {
            const columnWidth = Number(c.width.toString().replace('px', ''))
            c.imageRealWidth = columnWidth
            c.width = columnWidth + 10
            if (c.tableColumnWidth) {
              c.width = c.tableColumnWidth
            }
          }
        }
      }

      // 将 title 属性还原回去，然后在 template 渲染中使用方式渲染，这样可以在 vuex 改变时引起页面的实时变化
      if (i18nKey.startsWith('{i18n}')) {
        c.title = i18nKey
      }
    }
  }

  /**
     * 对 row 的计算最好放在一个地方处理，比如计算这一行的 rowColorScheme，计算这一行有几个操作按钮，等等
     * 计算以后直接挂载到 row 上
     * 目的是希望只在一处去计算各种属性，减小性能开销
     */
  // 根据 rowColorScheme 设置行的样式
  handleRowClassName ({ row, rowIndex, $rowIndex }: { row: LwTable['tableData'][number], rowIndex: number, $rowIndex: number }) {
    let rowClassName = 'lw-table-tr'
    // 获取行的样式
    const colorScheme = this.component.rowColorScheme && this.getFinalAttributeValue('rowColorScheme', { args: new Args(this.context, { row }) })
    if (colorScheme && colorSchemeClassMap[colorScheme]) {
      rowClassName = `${rowClassName} ${colorSchemeClassMap[colorScheme]}`
    }
    // 获取 rowEnabled
    row._rowEnabled = this.component.rowEnabled
      ? this.getFinalAttributeValue('rowEnabled', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
      : true
      // 获取 rowSelectable
    row._rowSelectable = this.component.rowSelectable
      ? this.getFinalAttributeValue('rowSelectable', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
      : true
    return rowClassName
  }

  checkDefaultButtonExistOutside (type: 'viewable' | 'editable' | 'cloneable' | 'deletable') {
    return this.visibleOpColumns.find(o => o.type === type) !== undefined
  }

  checkDefaultButtonExistInMore (type: 'viewable' | 'editable' | 'cloneable' | 'deletable') {
    return this.moreOpColumns.find(o => o.type === type) !== undefined
  }

  // 是否在操作列展示 ‘...’ 更多操作按钮
  isShowMoreOperationBtn ({ row }: { row: LwTable['tableData'][number] }) {
    // 操作列按钮的数量
    let buttonCount = this.visibleOpColumns.length
    const moreButtonCount = this.moreOpColumns.length
    if (moreButtonCount > 0) {
      return true
    } else {
      const ops: LayoutComponent[] = this.component.operations || []
      ops.forEach(op => {
        if (op.visible) {
          const visible = getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
          if (visible) {
            buttonCount++
          }
        } else {
          buttonCount++
        }
      })
      if (buttonCount > this.maxUnfoldedOperations) return true
    }
  }

  // 使用 cellClick 来达到 onRowClick 的目的
  handleRowClick ({ event, row }: { event: any, row: LwTable['tableData'][number] }) {
    this.component.onRowClick && this.runRunnableContent('onRowClick', { args: new Args(this.context, { row }) })
  }

  handleToggleRowExpand ({ expanded, row }: { expanded: boolean, row: LwTable['tableData'][number] }) {
    if (expanded && !row._hasExpanded) {
      row._hasExpanded = true
      this.component.onRowFirstTimeExpand && this.runRunnableContent('onRowFirstTimeExpand', { args: new Args(this.context, { row }) })
    }
  }

  cellClassName ({ row, column }: { row: LwTable['tableData'][number], column: ColumnInfo }) {
    return row.currentData[column.field] !== row.originalData[column.field] && this.enabled
      ? 'lw-table--td-changed'
      : ''
  }

  handleRowEnabled (row: LwTable['tableData'][number]) {
    return this.component.rowEnabled
      ? this.getFinalAttributeValue('rowEnabled', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
      : true
  }

  handleRadioChange ({ row }: { row: LwTable['tableData'][number] }) {
    const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
    rows.forEach(r => this.$set(r, 'isSelected', false))
    this.$set(row, 'isSelected', true)
    // 执行脚本
    this.doRowSelectChange({ row })
    // 更新 store 中勾选行的数据
    this.updateSelection([row])
  }

  handleCheckboxChange ({ checked, row }: { row: LwTable['tableData'][number], checked: boolean }) {
    // 拿到当前是否是跨页全选
    // 如果是跨页全选，那么 当前如果是 取消勾选状态，应该取消跨页全选标志
    const isSelectAll = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selectAll
    if (isSelectAll && !checked) {
      LayoutModule.setTableSelectAll({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        selectAll: false
      })
    }
    // 筛选出当前全部的勾选行，更新 store 中的数据
    this.updateSelection()
    // 执行脚本
    this.doRowSelectChange({ row })
    // 更新快速编辑框中数据
    this.notifyQuickEdit()
  }

  // 全选的时候依次执行 行勾选脚本
  handleCheckAll ({ checked }: { checked: boolean }) {
    const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
    // 全不选
    if (!checked) {
      LayoutModule.setTableSelectAll({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        selectAll: false
      })
    }
    // 更新 stroe 勾选行数据,同时逐行执行 onRowSelectChange 脚本
    const selection = rows.filter(row => {
      this.doRowSelectChange({ row })
      return row.isSelected
    })
    this.updateSelection(selection)
    // 更新快速编辑框中数据
    this.notifyQuickEdit()
  }

  handleCellMouseenter ({ row, column, $event }: { row: LwTable['tableData'][number], column: ColumnInfo, $event: MouseEvent }) {
    this.editColumn = column.params
    this.targetRow = row
    this.targetCellDom = $event.target as HTMLElement
  }

  handleRowMouseenter ({ row }: { row: LwTable['tableData'][number] }) {
    this.component.onRowMouseOver && this.runRunnableContent('onRowMouseOver', { args: new Args(this.context, { row }) })
  }

  handleRowMouseleave ({ row }: { row: LwTable['tableData'][number] }) {
    if (this.component.onRowMouseOut) {
      this.runRunnableContent('onRowMouseOut', { args: new Args(this.context, { row }) })
    } else if (this.component.onRowMouseLeave) {
      this.runRunnableContent('onRowMouseLeave', { args: new Args(this.context, { row }) })
    }
  }

  // 执行项目开发者所定义的 doRowSelectChange 脚本
  doRowSelectChange ({ row }: { row: LwTable['tableData'][number] }) {
    this.component.onRowSelectChange && this.runRunnableContent('onRowSelectChange', { args: new Args(this.context, { row }) })
  }

  // 更新 store 中的选中行数据
  updateSelection (selection?: any[]) {
    if (!selection) {
      const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
      selection = rows.filter(row => row.isSelected)
    }
    LayoutModule.setTableSelection({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      selection
    })
  }

  clearEditRows () {
    this.editRows = []
    const editingDataSet = LayoutModule.data[this.encodedLayoutName]?.editingDataSet
    const currentDataSet = `${this.encodedLayoutName}.${this.dataSetName}`
    if (editingDataSet === currentDataSet) {
      LayoutModule.data[this.encodedLayoutName].editingDataSet = ''
    }
  }

  handleColumSort (column: ColumnInfo) {
    if (column.sort === 'asc') {
      column.sort = 'desc'
      this.sortColumn(column, 'desc')
    } else if (column.sort === 'desc') {
      column.sort = 'none'
      this.sortColumn(column, '')
    } else {
      column.sort = 'asc'
      this.sortColumn(column, 'asc')
    }
  }

  sortColumn (column: ColumnInfo, order: string) {
    let orderBy = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].orderBy || []
    orderBy = orderBy.filter(item => item.source === 'header')
    // 从当前排序中找到即将要加入的排序字段，先删除，r如果order不为空，再添加到最后
    const index = orderBy.findIndex(item => item.field === column.field)
    index !== -1 && orderBy.splice(index, 1)
    order && orderBy.unshift({ field: column.field, order, source: 'header' })
    LayoutModule.loadDataSetOrderBy({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      orderBy
    })
    this.handleDataSetRetrieve()
  }

  handleTableSetting (command: 'display' | 'sort' | 'width') {
    if (command === 'display') {
      this.settingDateCopy = _.cloneDeep(this.settingDate)
      this.dialogDisplayVisible = true
    }
    if (command === 'sort') {
      this.settingDateCopy = _.cloneDeep(this.settingDate)
      this.dialogSortVisible = true
    }
    if (command === 'width') this.makeColumnWidthFitContent()
  }

  // 自适应列宽
  makeColumnWidthFitContent () {
    this.component.fields.forEach((item) => delete item.width)
    const _rows = this.dataSet.rows
    const columns = this.component.fields
    columns.forEach((column) => {
      column.textWidth = 60
    })
    const rows = _rows.filter(row => {
      return row.rowPersist !== rowPersist.DELETE
    })
    if (!rows || !rows.length) return
    rows.map((row) => {
      this.calculateColumnsWidth(row, columns)
    })
    const groupList = this.getRenderColumns()
    this.saveColumnsWidth(groupList)
  }

  // 根据文字内容和font获取文字长度
  getTextWidth (text: string, font: any) {
    const fontFamily = getComputedStyle(document.documentElement).fontFamily
    font += ' ' + fontFamily
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d') as CanvasRenderingContext2D
    context.font = font
    let width = 0
    // 是否包含 /n 判断是否换行，换行的宽度计算取最长的一行
    if (text && text.includes('\n')) {
      const textArr = text.split('\n')
      textArr.forEach((item) => {
        width = Math.max(width, context.measureText(item).width)
      })
    } else {
      width = context.measureText(text).width
    }
    return Math.ceil(width)
  }

  saveDisplayDialog (res: AxiosResponse<{ data: { display_fields_json: string, display_fields_enabled: boolean } }>) {
    this.dialogDisplayVisible = false
    const { display_fields_json: displayFieldsJson, display_fields_enabled: displayFieldsEnabled } = res.data.data
    if (displayFieldsJson) {
      this.settingDate.display_fields_json = displayFieldsJson
    }
    this.settingDate.display_fields_enabled = displayFieldsEnabled
    this.setCurrentDisplay()
    // 修改表格中列的隐藏显示和列的显示顺序
    const columns = this.component.fields
    this._handleComponentDisplayFields(columns)
    this.getRenderColumns()
  }

  closeDisplayDialog () {
    this.dialogDisplayVisible = false
  }

  closeSortDialog () {
    this.dialogSortVisible = false
  }

  saveSortDialog (res: AxiosResponse<{ data: { sort_json: string, sort_enabled: boolean } }>) {
    // 使用Retrieve而不是页面刷新
    const { sort_json: sortJson, sort_enabled: sortEnabled } = res.data.data
    if (sortJson) {
      this.settingDate.sort_json = sortJson
    }
    this.settingDate.sort_enabled = sortEnabled
    this.setCurrentSort()
    // 修改表头的排序图标状态，全部置为排序图标，取消表头的排序
    this.renderColumns.forEach((item) => {
      if (item.type === 'column') {
        item.column.sort = 'none'
      }
    })
    this.handleDataSetRetrieve()
    this.dialogSortVisible = false
  }

  getUserTableSettings () {
    const allFields = this.settingDate.all_fields
    this.component.fields.forEach((item, index) => {
      allFields.push({
        name: item.field,
        title: getI18nContent(item.title),
        display: true
      })
    })
    this.settingDate.display_fields = _.cloneDeep(allFields)
    const successCallback = (res: any) => {
      const rows = res.rows
      if (!rows || !rows.length) {
        this.afterGetUserTableSettings()
        return
      }
      const row = rows[0]
      // 根据this.component.fields和显示排序字段做无效校验，如果两个enabled已经是false了，则不需要无效校验了
      let allFieldsName: string[] = []
      const sortFieldsName: string[] = []
      const displayFieldsName: string[] = []
      let sortFlag = true
      let displayFlag = true
      let widthFlag = true
      const allFields = this.component.fields
      allFieldsName = allFields.map(item => item.field)
      let recordWidthColumn: string[] = []
      if (row.sort_json) {
        const sortFields = JSON.parse(row.sort_json)
        for (const item of sortFields) {
          sortFieldsName.push(item.name)
        }
        // sortFlag=true 代表排序字段都存在，不需要重置
        sortFlag = sortFieldsName.every((item) => allFieldsName.includes(item))
      }
      if (row.display_fields_json) {
        const displayFields = JSON.parse(row.display_fields_json)
        for (const item of displayFields) {
          displayFieldsName.push(item.name)
        }
        // displayFlag=true 代表显示字段没有改变，不需要重置
        displayFlag = displayFieldsName.every((item) => allFieldsName.includes(item)) && allFieldsName.every((item) => displayFieldsName.includes(item))
      }
      // 列宽记录只需要和allFields进行对比，多余的字段删掉，没有的字段不带width补上
      if (row.fields_width_json) {
        recordWidthColumn = JSON.parse(row.fields_width_json).map((item: any) => item.name)
        widthFlag = allFieldsName.every((item) => recordWidthColumn.includes(item)) && recordWidthColumn.every((item) => allFieldsName.includes(item))
      }
      if ((!sortFlag && row.sort_enabled) || (!displayFlag && row.display_fields_enabled) || !widthFlag) {
        if (!sortFlag && row.sort_enabled) {
          // 将保存的setting全改了
          row.sort_enabled = false
          row.sort_json = null
          logwire.ui.message({
            type: 'info',
            message: this.$i18n('core', 'client.table-setting.check-sort-valid')
          })
        }
        if (!displayFlag && row.display_fields_enabled) {
          row.display_fields_enabled = false
          row.display_fields_json = null
          setTimeout(() => {
            logwire.ui.message({
              type: 'info',
              message: this.$i18n('core', 'client.table-setting.check-display-valid')
            })
          })
        }
        if (!widthFlag) {
          const widths = JSON.parse(row.fields_width_json).filter((item: any) => allFieldsName.includes(item.name))
          allFields.filter((item) => !recordWidthColumn.includes(item.field)).forEach(item => {
            widths.push({ name: item.field, width: item.width })
          })
          const widthsJson = JSON.stringify(widths)
          this.settingDate.fields_width_json = widthsJson
          row.fields_width_json = widthsJson
        }

        if (this.widthCacheable === false) {
          this._handleSettingDate(row)
          return
        }
        saveUserSettings(this.layoutName, this.dataSetName, row,
          () => {
            this._handleSettingDate(row)
          }, () => 0, this.anonymousAccessible)
      } else {
        this._handleSettingDate(row)
      }
    }
    getUserSettings(this.layoutName, this.dataSetName, successCallback, e => { this.afterGetUserTableSettings() }, this.anonymousAccessible)
  }

  _handleSettingDate (row: LwTable['tableData'][number]) {
    this.settingDate = Object.assign(this.settingDate, row)
    this.setCurrentDisplay()
    this.setCurrentSort()
    this.afterGetUserTableSettings()
  }

  setCurrentDisplay () {
    const titleMap = _.keyBy(this.settingDate.all_fields, (f) => f.name)
    // 解析显示列
    if (!this.settingDate.display_fields_json) return
    if (!this.settingDate.display_fields_enabled) {
      this.settingDate.display_fields = this.settingDate.all_fields
      return
    }
    this.settingDate.display_fields = JSON.parse(this.settingDate.display_fields_json)
    this.settingDate.display_fields.forEach(item => {
      if (titleMap[item.name]) item.title = getI18nContent(titleMap[item.name].title)
    })
  }

  setCurrentSort () {
    const titleMap = _.keyBy(this.settingDate.all_fields, (f) => f.name)
    // 解析排序列
    if (!this.settingDate.sort_json) return
    this.settingDate.sort = JSON.parse(this.settingDate.sort_json)
    // 将排序字段设置到查询条件中
    let sort: { field: string, order: string }[] = []
    this.settingDate.sort.forEach((item) => {
      if (titleMap[item.name]) item.title = getI18nContent(titleMap[item.name].title)
      sort.push({ field: item.name, order: item.order })
    })
    if (!this.settingDate.sort_enabled) {
      sort = []
      this.settingDate.sort = []
    }
    LayoutModule.loadDataSetOrderBy({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      orderBy: sort
    })
  }

  // table组件获取到UserTableSettings之后的操作
  afterGetUserTableSettings () {
    // 1. 看有没有page_size，有的话触发页面条数更新
    const settingPageSize = this.settingDate.page_size
    settingPageSize && eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.change-pageSize`, settingPageSize)
    // 2. 看有没有default_advanced_filter_id，有的话触发页面搜索条件的更新
    const settingDefaultAdvancedFilterId = this.settingDate.default_advanced_filter_id
    if (settingDefaultAdvancedFilterId) {
      // 3. 如果有默认的advanced_filter,那initRetrieve操作应该在完成搜索条件后在searchBar里操作
      settingDefaultAdvancedFilterId && eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.set_default_advancedFilter`, settingDefaultAdvancedFilterId)
    } else {
      // 4. 当table/pagination/searchBar都准备完成后，再根据条件获取table数据
      this.initRetrieve()
    }
    // 获取用户设置后根据保存的显示列，排序，列宽渲染列表
    this.handleComponentFields()
    this.getRenderColumns()
  }

  cacheFieldOption ({ field, option }: { field: string, option: any }) {
    this.cacheFieldOptions = this.cacheFieldOptions || {}
    this.cacheFieldOptions[field] = option
  }

  getSimpleExportExcelFields (payload: any) {
    const fields: { title: string, field: string, type: string, isMerge: boolean, displayContent?: string, displayField?: string, decimalScale?: number, category?: string }[] = []
    this.component.fields.forEach(f => {
      let title = f.title
      if (title.startsWith(attributeSymbol.I18N)) {
        title = logwire.ui.getI18nMessage(getPlainAttributeValue(title, attributeSymbol.I18N))
      }
      var fieldObj: typeof fields[number] = {
        title,
        field: f.field,
        type: 'text',
        isMerge: false
      }
      // 被合并的字段
      if (this.rowSpanFields.includes(f.field)) {
        fieldObj.isMerge = true
      }
      if (f.is === 'lw-select') {
        fieldObj.type = 'select'
        if (f.displayContent) {
            fieldObj.displayContent = f.displayContent
        } else if (f.displayField) {
            fieldObj.displayField = f.displayField
        }
      } else if (f.is === 'lw-number') {
        fieldObj.type = 'number'
        if (attribute2Boolean(f.formatEnabled)) {
          fieldObj.decimalScale = checkValueIsEmpty(f.decimalScale) ? 0 : attribute2Number(f.decimalScale) as number
        }
      } else if (f.is === 'lw-choice' || f.is === 'lw-radio') {
        fieldObj.type = attribute2Boolean(f.multiple) ? 'multipleChoice' : 'choice'
        fieldObj.category = f.category
      }
      // 根据显示列筛选 fields
      const isDisplay = _.findKey(this.settingDate?.display_fields, { name: f.field, display: true })
      if (!['lw-upload-file', 'lw-image', 'lw-label'].includes(f.is) && isDisplay) {
        fields.push(fieldObj)
      }
    })
    if (this.settingDate?.display_fields) {
      fields.sort((a, b) => {
        const aIndex = this.settingDate.display_fields.findIndex(o => o.name === a.field && o.display === true)
        const bIndex = this.settingDate.display_fields.findIndex(o => o.name === b.field && o.display === true)
        return aIndex - bIndex
      })
    }
    payload.fields = fields
  }

  resetStatus ({ isForceReset } = { isForceReset: false }) {
    if (isForceReset || ![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      this.status = layoutStatus.VIEW
    }
  }

  generateUpdatedRows (payload: any) {
    if (this.query) {
      payload.queryName = this.query
    }
    payload.headerDetailSave = this.detailDataSets?.length > 0 && [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)
    const rows = this.filterRows(this.editRows)
    payload.recordList.push(...rows)
    // 响应头明细保存 事件以后，对 editRows 和 selection 包含的 editRows 进行删除
    const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
    this.editRows.forEach(r => {
      selection.splice(selection.indexOf(r), 1)
    })
    // 这里不直接清空，是因为 save 方法有可能失败,要在 save 成功以后再去清空
    // this.editRows = []
  }

  filterRows (_rows: IDataRow[]) {
    // 只返回包含 currentData originalData rowPersist的数据
    // 如果不存在 id 同时 rowPersist 等于 Delete， 说明是通过 API 对新增的明细数据进行了删除，此时不应该提交该数据
    return _rows.filter(o => o.currentData.id || o.rowPersist !== rowPersist.DELETE).map(r => {
      return {
        currentData: r.currentData,
        originalData: r.originalDataForHeaderDetailEdit || r.originalData,
        rowPersist: r.currentData.id ? r.rowPersist ? r.rowPersist : rowPersist.UPDATE : rowPersist.INSERT
      }
    })
  }

  saveRows () {
    const updatedRows: IDataRow[] = this.batch ? LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[] : this.editRows
    const getUpdatedDataRows = () => updatedRows.map(row => new DataRow(row))
    if (this.component.onSave) {
      this.runRunnableContent('onSave', { args: new Args(this.context, { getUpdatedDataRows, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    const cb = () => {
      this.resetStatus()
      // 新增 删除 修改数据，保存后重新拉取table数据
      this.handleDataSetRetrieve()
      this.component.afterSave && this.runRunnableContent('afterSave')
    }
    const proceed = () => {
      const rows = this.filterRows(updatedRows) as IDataRow[]
      const dataListForSave = getDataListForSave(this.dataSetName, this.query, rows)
      const layoutName = this.editLayoutName || this.layoutName
      rows.length && doSave({ layoutName, namespace: this.context.getNamespace(), dataSetName: this.dataSetName, data: dataListForSave, queryName: this.query }).then(res => {
        logwire.ui.emit(`${this.dataSetName}.clearEditRows`)
        cb()
      }, e => { console.error(e) })
    }
    // todo 校验逻辑可能复杂，比方，每一行的每个字段校验规则可能都不同
    // 暂时只对静态的required的简单处理？？
    if (this.component.beforeSave) {
      this.runRunnableContent('beforeSave', { args: new Args(this.context, { getUpdatedDataRows, proceed }), noWarning: false })
    } else {
      proceed()
    }
  }

  /**
     * rows: 有值时，默认只有一个元素，是来自editLayout里的保存； 没有值，则调用 saveRows 处理 editRows中的内容
     * mode
     * op,
     * popupLayoutFlag
     * */
  handleDataSetSave (payload: Partial<{ rows: IDataRow[], mode: string, op: string, cbFromPopup: () => void, defaultAfterSave: () => void }> = {}) {
    // getTrigger 返回三种状态： insert  insert-and-new update
    const { rows, mode = 'edit', op = 'save', cbFromPopup } = payload
    const getTrigger = () => {
      if (op === operationType.SAVE_AND_NEW) {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP.saveAndNew
      }
      if (op === operationType.UPDATE_AND_NEXT) {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP.updateAndNext
      }
      return EDIT_LAYOUT_SAVE_TRIGGER_MAP[mode]
    }
    if (!rows) {
      this.saveRows()
      return
    }
    closeAllErrorMessages()
    if (this.component.onSave) {
      this.runRunnableContent('onSave', { args: new Args(this.context, { row: rows?.[0], getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    const updateRowIndex = this.dataSet.rows.indexOf(this.targetRow)
    const lastIndex = this.dataSet.rows.length - 1
    const hasNextRow = updateRowIndex < lastIndex
    const proceed = () => {
      const filterRows = this.filterRows(rows) as IDataRow[]
      const dataListForSave = getDataListForSave(this.dataSetName, this.query, filterRows)
      // 头明细编辑的情况下，只改数据不保存
      if ([layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW, layoutStatus.EXPRESS_EDIT].includes(this.status)) {
        const currentData = filterRows[0].currentData
        if (mode === layoutStatus.NEW) {
          const newDataRow = {
            currentData: _.cloneDeep(currentData),
            originalData: _.cloneDeep(currentData),
            rowPersist: rowPersist.INSERT,
            ___index: this.dataSet.rows.length + 1,
            rowKey: new Date().getTime().toString(),
            isSelected: false
          } as IDataRow
          this.editRows.push(newDataRow)
          // 列表添加一行
          LayoutModule.addNewItem({
            layoutName: this.encodedLayoutName,
            dataSetName: this.dataSetName,
            row: newDataRow
          })
        } else {
          // 更新targetRow数据
          // 根据 id 查找 editRows 中是否有已插入的 行
          if (mode === layoutStatus.EDIT) {
            const editRowIndex = this.getRowIndexExistInEditRows(rows[0])
            const editRow = this.editRows[editRowIndex]
            if (editRow) {
              editRow.currentData = _.cloneDeep(currentData)
              // 保存的时候 会将当前行的 currentData 和 originalData 都设置成当前的 数据
              // 但是头明细状态数据的的实际保存是在头表保存的时候，所以将 originalData 记录下来，在保存的时候替换掉 originalData
              editRow.originalDataForHeaderDetailEdit = _.cloneDeep(editRow.originalDataForHeaderDetailEdit || this.targetRow.originalData || currentData)
            } else {
              this.editRows.push({
                currentData: _.cloneDeep(currentData),
                originalData: _.cloneDeep(this.targetRow.originalData),
                rowPersist: rowPersist.UPDATE,
                rowKey: rows[0].rowKey,
                isSelected: false
              } as IDataRow)
            }
          }
          this.targetRow.currentData = _.cloneDeep(currentData)
          this.targetRow.originalData = _.cloneDeep(currentData)
        }
        this.handleSaveAndUpdate(op, hasNextRow, cbFromPopup, updateRowIndex)
      } else {
        // 非头明细编辑的情况
        doSave({ layoutName: this.editLayoutName || this.layoutName, namespace: this.context.getNamespace(), queryName: this.query, data: dataListForSave }).then(res => {
          const updatedCurrentData = filterRows[0].currentData
          const currentData = { ...updatedCurrentData, ...res.data.data[this.dataSetName].records[0] }
          if (mode === layoutStatus.NEW) {
            const newDataRow = { currentData, originalData: _.cloneDeep(currentData), rowPersist: '' } as IDataRow
            // 列表添加一行
            LayoutModule.addNewItem({
              layoutName: this.encodedLayoutName,
              dataSetName: this.dataSetName,
              row: newDataRow
            })
            this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: newDataRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })

            const pageNo = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].pageNo as number
            this.handlePaging({ pageNo, forceCount: true }) // 在新增时，按照既有的方式查询
          } else {
            // 清除editRows中快速编辑的一行，同时根据 editRows 是否为空来调整status
            this.editRows.splice(this.editRows.indexOf(rows[0]), 1)
            if (this.editRows.length === 0) {
              this.status = layoutStatus.VIEW
            }
            // 更新targetRow数据
            this.targetRow.currentData = currentData
            this.targetRow.originalData = _.cloneDeep(currentData)
            this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: this.targetRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
          }
          this.handleSaveAndUpdate(op, hasNextRow, cbFromPopup, updateRowIndex)
        }, e => { console.error(e) })
      }
    }
    if (this.component.beforeSave) {
      this.runRunnableContent('beforeSave', { args: new Args(this.context, { row: rows[0], proceed, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
    } else {
      proceed()
    }
  }

  handleSaveAndUpdate (op: string, hasNextRow: boolean, cbFromPopup: undefined | (() => void), updateRowIndex: number) {
    const getEditingRow = () => this.context.getOrCreateDataSet(this.editDataSet).getRow(0)
    const { editLayout } = this.component
    const { editDataSet } = this
    const newPopupOrPanelLayoutName = editLayout
      ? editLayout.includes('.')
        ? editLayout
        : `${this.context.getNamespace()}.${editLayout}`
      : this.layoutName
    const setRowIntoPopupDataSet = (row: IDataRow, layoutParams: any) => {
      // 将经过事件处理的 row 作为 DataRow 保存起来
      LayoutModule.loadLayoutDataSet({
        layoutName: newPopupOrPanelLayoutName,
        data: {
          dataSetName: editDataSet,
          rows: [row]
        }
      })
    }
    if (op === operationType.SAVE_AND_NEW) {
      // 不需要单独做 resetData, 因为 setRowIntoPopupDataSet 时会覆盖掉原来的数据
      this.throughDataSetNewEvents(this.getDataSetNewFields(), setRowIntoPopupDataSet)
    } else if (op === operationType.UPDATE_AND_NEXT) {
      if (hasNextRow) {
        const nextRow = this.dataSet.rows[updateRowIndex + 1]
        this.throughDataSetEditEvents(nextRow, (row, layoutParams) => {
          this.targetRow = nextRow
          setRowIntoPopupDataSet(row, layoutParams)
        })
      } else {
        if (logwire.ui.message.warning) {
          logwire.ui.message.warning(this.$i18n('core', 'data-saved-but-next-data-is-not-found'))
        }
      }
    } else {
      // 其他情况下，都认为应该关闭弹窗，但是发现有开发者会在 beforeSave 里写 setData，导致弹窗的编辑状态被更新了
      // 这里加一个兜底处理
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
      if (cbFromPopup) {
        cbFromPopup()
      }
    }
  }

  handleDataSetView (row: IDataRow) {
    const dataRow = _.cloneDeep(row).currentData
    const dr = formatDataRow(dataRow)
    this.openEditLayout(layoutStatus.VIEW, dr)
  }

  handleDataSetClone (row: IDataRow) {
    const dataRow = _.cloneDeep(row).currentData
    delete dataRow.id
    delete dataRow.version
    const dr = formatDataRow(dataRow)
    this.targetRow = row
    this.openEditLayout(layoutStatus.NEW, dr)
  }

  getDataSetNewFields () {
    return this.component.fields.filter(f => !SYSTEM_DB_RESERVED_FIELDS.includes(f.field)) as { field: string }[]
  }

  handleDataSetNew () {
    // 没有 edit 权限不应该响应 new 事件
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    closeAllErrorMessages()
    this.throughDataSetNewEvents(this.getDataSetNewFields(), (row, layoutParams) => {
      this.handleAfterQueryMeta(() => {
        this.openEditLayout(layoutStatus.NEW, row, layoutParams)
      })
    })

    // 如果是在头明细编辑或者头明细新增的状态下,不改变状态,头明细状态目前重置的方式应该只是在保存 主 DataSet 以后才重置状态为 view
    if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      this.status = layoutStatus.NEW
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.NEW })
    }
  }

  _handleDataSetEdit (mode: layoutStatus, row: IDataRow) {
    // 没有 edit 权限不应该响应 edit 事件
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    this.throughDataSetEditEvents(row, (editingIRow, layoutParams) => {
      this.targetRow = row
      this.openEditLayout(mode, editingIRow, layoutParams)
    })
    // 如果是在头明细编辑或者头明细新增的状态下,不改变状态,头明细状态目前重置的方式应该只是在保存 主 DataSet 以后才重置状态为 view
    if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      this.status = layoutStatus.EDIT
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EDIT })
    }
  }

  /**
     * 执行 ds.delete 事件时，触发此方法，params 参数为 { row?, logicDelete? } | number
     * row: 行中的删除传过来的参数
     * logicDelete: 是否是逻辑删除，默认为 false; 列表的数据保存如果自定义处理，一般会设置为true
     * */
  handleDataSetDelete (params: number | { row?: any, logicDelete?: boolean }) {
    if (typeof params === 'number') {
      params = { row: this.tableData[params] }
    }
    this._handleDataSetDelete(params)
  }

  _handleDataSetDelete (payload: { row?: IDataRow, logicDelete?: boolean } = {}) {
    // 没有 edit 权限不应该响应 edit 事件
    const { row, logicDelete } = payload
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    closeAllErrorMessages()
    // 如果自定义了删除，则执行自定义删除事件
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    if (self.component.onDelete) {
      self.runRunnableContent('onDelete', { args: new Args(self.context, { row, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    // 删除逻辑
    const proceed = function () {
      const afterDelete = () => {
        self.component.afterDelete && self.runRunnableContent('afterDelete', { args: new Args(self.context, { getCurrentDataSet: self.getCurrentDataSet }) })
      }
      const selection = LayoutModule.data[self.encodedLayoutName].systemState[self.dataSetName].selection as IDataRow[]
      const rows = row ? [row] : selection
      // persistRows才发送请求删除；客户端临时添加的行直接删除即可
      const deleteCallback = (row: IDataRow, logic?: boolean) => {
        !logic && self.dataSet.rows.splice(self.dataSet.rows.indexOf(row), 1)
        selection.length && selection.splice(selection.indexOf(row), 1)
        self.editRows.splice(self.editRows.indexOf(row), 1)
      }
      if (logicDelete || self.component.headerDataSet) {
        let hasLogic = true
        // 如果有id，则更改rowPersist为D；手动调用loadTableData重新渲染
        // 如果没有id（认为是新增的数据），直接从rows中清除
        rows.map(r => r).forEach(r => {
          if (r.currentData.id) {
            // 头明细保存的时候，这些持久化要被删除的行应该发到后端
            // 先判断是否已经被修改过了
            r.rowPersist = rowPersist.DELETE
            if (self.component.headerDataSet) {
              if (self.getRowIndexExistInEditRows(r) === -1) {
                self.editRows.push(r)
              } else {
                self.editRows.splice(self.getRowIndexExistInEditRows(r), 1, r)
              }
            }
            // 删除取消行勾选
            selection.length && selection.splice(selection.indexOf(r), 1)
          } else {
            hasLogic = false
            self.dataSet.rows.splice(self.dataSet.rows.indexOf(r), 1)
            deleteCallback(r, true)
          }
        })
        hasLogic && self.loadTableData()
        afterDelete()
        return
      }
      const persistRows: { id: string, version: number }[] = []
      const _persistRows: IDataRow[] = []
      rows.forEach(r => {
        if (r.currentData.id) {
          persistRows.push({
            id: r.currentData.id,
            version: r.currentData.version
          })
          _persistRows.push(r)
        } else {
          deleteCallback(r)
        }
      })
      // 如果都不是发请求进行删除，则表明都是删除的 rowPersist=I 的行
      if (rows.length && _persistRows.length === 0) {
        afterDelete()
      }
      if (persistRows.length) {
        const param = {
          message: self.$i18n('core', 'client.tips.is-delete') as string,
          callback: (action: string) => {
            if (action === 'confirm') {
              // 点击确定之后进行删除操作
              const layoutName = self.editLayoutName || self.layoutName
              doDelete({ layoutName, namespace: self.context.getNamespace(), queryName: self.query, data: { query: self.query, selectedRows: persistRows } }).then(res => {
                _persistRows.forEach(r => {
                  deleteCallback(r)
                })
                afterDelete()
                const pageNo = LayoutModule.data[self.encodedLayoutName].systemState[self.dataSetName].pageNo as number
                if (self.tableData.length === 1) {
                  self.handlePaging({ pageNo: pageNo === 1 ? 1 : (pageNo - 1), forceCount: true })
                } else {
                  self.handlePaging({ pageNo, forceCount: true })
                }
              }, e => { console.error(e) })
            }
          },
          distinguishCancelAndClose: true
        }
        logwire.ui.confirm(param)
      }
    }
    if (this.component.beforeDelete) {
      this.runRunnableContent('beforeDelete', { args: new Args(this.context, { row, proceed, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
    } else {
      proceed()
    }
  }

  // 获取列表数据
  handleDataSetRetrieve (params?: Record<string, any>) {
    // 判断是否是searchBar中首次触发，如果是要根据retrieveOnLayoutLoad判断是否立即显示数据
    if (params && params.searchBarInit && !this.retrieveOnLayoutLoad) {
      return
    }

    LayoutModule.setTempTablePageNo({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, tempPageNo: 1 })

    closeAllErrorMessages()
    if (params && params.scrollToTop) {
      this.$refs.table.scrollTo(0, 0)
    }
    handleDataSetRetrieveForQueryByFilterComponent.call(this, 'table')
    this.clearEditRows()
    // 重新拉取数据后就清空 editRows，不然 editRows 中的数据和 store 中的数据不一致，导致界面没有表现出修改状态，但是保存时候却有修改值
    // 每次重新获取数据,不论是翻页还是保存，或是加载，都重置已展开行的记录 hasExpandRowKeys
    this.hasExpandRowKeys = []
  }

  handleDataSetEdit (rowIndex?: number) {
    if (rowIndex === undefined || rowIndex === -1) return
    const row = this.tableData[rowIndex]
    if (row) {
      this._handleDataSetEdit(layoutStatus.EDIT, row)
    }
  }

  handleDatasetExpressEdit () { /* do nothing */ }

  // rowChange 一般是整行的变化，比如新增、删除操作。有可能在删除前执行了编辑操作，导致 editRows 内存储的 row 对象和表格的 row 对象不一致了
  handleDataRowChange ({ row, index }: { row: IDataRow, index: number }) {
    // 由于rows变化，必然引起 loadTableData 重新执行
    setTimeout(() => {
      if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) && this.enabled) {
        this.status = layoutStatus.EXPRESS_EDIT
      }
    }, 0)
    if (this.getRowIndexExistInEditRows(row) === -1) {
      this.editRows.push(row)
    } else {
      this.editRows.splice(this.getRowIndexExistInEditRows(row), 1, row)
    }
    if (row.rowPersist === rowPersist.DELETE) {
      this.dataSet.rows.splice(index, 1)
    }
  }

  handleDataRowFieldChange (row: IDataRow) {
    // 判断是不是自己的 row,并且此条 row 有没有被纪录过
    if (this.dataSet.rows.indexOf(row) !== -1 && this.getRowIndexExistInEditRows(row) === -1) {
      setTimeout(() => {
        if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) && this.enabled) {
          this.status = layoutStatus.EXPRESS_EDIT
        }
      }, 0)
      this.editRows.push(row)
    }
  }

  handleDataRowSelectedChange ({ row, select }: { row: IDataRow, select: boolean }) {
    // 相应 DataRow 的 select 方法
    // 判断是不是自己的 row
    if (this.dataSet.rows.indexOf(row) !== -1) {
      if (this.selectionMode === 'radio') {
        const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
        rows.forEach(row => this.$set(row, 'isSelected', false))
        if (select) {
          this.$set(row, 'isSelected', true)
          this.radioCheckedRow = row
          this.$refs.table.setRadioRow(this.radioCheckedRow)
          this.updateSelection([row])
        }
      } else {
        this.updateSelection()
      }
    }
  }

  // 直接在列表上展示的操作
  generateOpColumnsNotHidden (row: IDataRow) {
    // 自定义的操作按钮在 popupLayoutStatus 为 view 的状态下不进行渲染
    if (this.popupLayoutStatus === layoutStatus.VIEW) return []
    const usedOpCount = this.visibleOpColumns.length
    const ops = this.component.operations || []
    const opColumns = []
    for (let i = 0; opColumns.length < this.maxUnfoldedOperations - usedOpCount && i < ops.length; i++) {
      const op = ops[i]
      if (op) {
        if (op.visible) {
          const visible = getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
          if (visible) {
            opColumns.push(op)
          }
        } else {
          opColumns.push(op)
        }
      }
    }
    return opColumns
  }

  // 在列表更多按钮中展示的自定义操作
  generateOpColumnsHidden (row: IDataRow) {
    // 自定义的操作按钮在 popupLayoutStatus 为 view 的状态下不进行渲染
    if (this.popupLayoutStatus === layoutStatus.VIEW) return []
    const usedOpCount = this.visibleOpColumns.length
    const ops = this.component.operations || []
    let opColumns = []
    for (let i = 0; i < ops.length; i++) {
      const op = ops[i]
      let visible = true
      if (op.visible) {
        visible = getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
      }
      if (visible) {
        opColumns.push(op)
      }
    }
    opColumns = opColumns.slice(this.maxUnfoldedOperations - usedOpCount)
    return opColumns
  }

  isDynamicOps (text: string) {
    return isSymbol(text, attributeSymbol.FUNCTION) || isSymbol(text, attributeSymbol.EVAL) || isSymbol(text, attributeSymbol.COMMON) || isSymbol(text, attributeSymbol.COMMONWITHNULLARGS)
  }

  getEditFormLayoutComponent (mode?: string) {
    const { onRetrieve } = this.component
    // 平台预留的字段，新增 或者 编辑状态下去掉组件字段；查看状态下不处理。
    // 有的时候页面只展示了少量字段，而在editLayout中会展示多个字段，此时先点击编辑再点击新增，这些多出来的字段没有被重置
    let formFields = _.cloneDeep(this.component.fields)
    if (mode !== layoutStatus.VIEW) {
      formFields = formFields.filter(f => !SYSTEM_DB_RESERVED_FIELDS.includes(f.field))
    }
    if (!onRetrieve) {
      // 不是主数据字段，保存接口并不会处理；因此这些非主数据字段，enabled默认应该是false
      const masterFields = findMasterFields(this.layoutName, this.query)
      formFields.forEach(f => {
        if (!masterFields.includes(f.field)) {
          f.enabled = 'false'
        }
      })
    }
    return formFields
  }

  async openEditLayout (mode: layoutStatus, row: IDataRow, layoutParams = {}) {
    // if (this.component.headerDataSet) this.handleHeaderEdit()
    const { editLayout, editPanel } = this.component
    const editDataSet = this.editDataSet
    const drawerParams = {
      layoutName: editLayout
        ? editLayout.includes('.')
          ? editLayout
          : `${this.context.getNamespace()}.${editLayout}`
        : this.layoutName + EDIT_LAYOUT_POPUP_NAME_SUFFIX,
      editLayout: editLayout
        ? editLayout.includes('.')
          ? editLayout
          : `${this.context.getNamespace()}.${editLayout}`
        : null,
      layouts: [],
      layoutStatus: mode,
      isEditLayout: true,
      editDataSet,
      params: layoutParams,
      dataRow: row,
      sourceDataSetName: this.dataSetName,
      sourceLayoutName: this.encodedLayoutName,
      sourceLayoutStatus: this.status,
      fromTable: true,
      onClose: (args) => {
        this.resetStatus()
      }
    } as Partial<PopupLayoutConfig>

    this.openedPopupLayout = drawerParams as any
    const openLayoutFunction = (dp: any) => {
      logwire.ui.openLayoutInDialog(dp)
    }
    if (editLayout) {
      const layouts = await this.getEditLayoutComponents(drawerParams.editLayout as string)
      drawerParams.layouts = layouts
      openLayoutFunction(drawerParams)
    } else if (editPanel) {
      // QueriableComponent 中实现
      this.openEditPanel({ mode, row, fromTable: true, editDataSet })
    } else {
      drawerParams.layouts = [{
        is: 'lw-form',
        dataSet: editDataSet,
        components: this.getEditFormLayoutComponent()
      }]
      openLayoutFunction(drawerParams)
    }
    // #1383 议题中，afterEdit, afterNew 方法，都不再作为标准方法提供，现在只作为兼容项目上旧代码处理，放到了 beforeXXX - openLayout 过程中间
  }

  // 弹出快速编辑框
  handleQuickEdit () {
    const proceed = () => {
      // TODO 这里先调用返回原位隐藏的方式，可以尝试进行优化
      this.disapperQuickEditPopper()
      this.$nextTick(function () {
        this.quickEditingRow = this.targetRow
        const target = this.targetCellDom
        this.editField = this.editColumn.field
        // 如果不是多选，快速编辑框内不显示 `批量修改其他选中项` 复选框
        const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
        let isBatch = false
        if (selection.length > 1 || !selection.includes(this.quickEditingRow)) {
          isBatch = true
        }
        const editInstance = this.$refs.quickEdit
        editInstance.parentRow = JSON.parse(JSON.stringify(this.targetRow))
        editInstance.editField = this.editColumn.field
        editInstance.updateRowsLength = isBatch ? selection.length : 0
        const copyColumn = JSON.parse(JSON.stringify(this.editColumn))
        editInstance.parentComponent = {
          is: 'lw-form',
          subDataSet: TABLE_RELATED_DATASET_NAME,
          components: [copyColumn]
        }
        this.editPopperShow = true
        // eslint-disable-next-line
          const _this = this
        target.appendChild(editInstance.$el)
        addResizeListener(target, function hideQuickEdit () {
          if (!target.parentNode?.parentNode) {
            _this.disapperQuickEditPopper()
            // eslint-disable-next-line dot-notation
            if (target && target['__resizeListeners__'] && target['__resizeListeners__'].indexOf(hideQuickEdit) !== -1) {
              removeResizeListener(target, hideQuickEdit)
            }
          }
        })
        this.component.afterEdit && this.runRunnableContent('afterEdit', { args: new Args(this.context, { row: this.targetRow, proceed }), noWarning: false })
      })
    }

    if (this.component.beforeEdit) {
      this.runRunnableContent('beforeEdit', { args: new Args(this.context, { row: this.targetRow, proceed }), noWarning: false })
    } else {
      proceed()
    }
  }

  // 内容回撤
  handleDataRowReset (params?: { fields: any[] }) {
    const editField = this.editColumn.field
    this.targetRow.currentData[editField] = this.targetRow.originalData[editField]
    if (params) {
      const { fields } = params
      fields.forEach(f => {
        this.targetRow.currentData[f] = this.targetRow.originalData[f]
      })
    }
  }

  resetRows ({ isForceReset } = { isForceReset: false }) {
    this.handleDataSetRetrieve()
    this.resetStatus({ isForceReset })
  }

  disapperQuickEditPopper (data?: any) {
    // 回归原位置隐藏
    if (this.editPopperShow) {
      this.$refs.quickEditWrapper.appendChild(this.$refs.quickEdit.$el)

      if (data?.type === 'confirm') {
        this.applyChangedField(this.editField, data.val, data.batch, data.row)
      }
      this.$refs.quickEdit.parentRow = null
      this.editPopperShow = false
      this.component.afterExpressEdit && this.runRunnableContent('afterExpressEdit')
    }
  }

  applyChangedField (fieldName: string, value: any, batch: boolean, row: Record<string, any>) {
    const editVal = this.editVal = value
    const oldValue = this.targetRow.currentData[fieldName]
    // 收集改动中，一起发生变化的其他字段
    const updatedFieldsMap: Record<string, any[]> = {}
    updatedFieldsMap[fieldName] = []
    for (const key in row) {
      if (row[key] === this.targetRow.currentData[key]) continue
      updatedFieldsMap[fieldName].push(key)
    }
    const rowData = pick(row, updatedFieldsMap[fieldName])
    if (oldValue !== editVal) {
      this.targetRow.currentData = Object.assign({}, this.targetRow.currentData, rowData)
      // 对于addRow方式添加的行，如果 id 和 rowPersist为空，则表示是逻辑添加，不应该出现 取消 保存
      if (!this.targetRow.currentData.id && this.targetRow.rowPersist === '') {
        // do nothing
      } else {
        if (![layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status) && this.enabled) this.status = layoutStatus.EXPRESS_EDIT
      }
    }
    this.batch = batch
    // 难以区分 `批量修改其他项` 先勾选还是后勾选
    if (this.batch) {
      const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
      // 批量修改时候 记录其他行是否发生了变动，如果变动，应该显示取消和保存按钮
      let changeFlag = false

      selection.forEach(row => {
        if (row !== this.targetRow) {
          row.currentData = Object.assign({}, row.currentData, rowData)
          if (row.currentData[fieldName] !== row.originalData[fieldName]) {
            changeFlag = true
          }
        }
      })
      this.editRows.push(...selection)

      this.editRows = [...new Set(this.editRows)]
      changeFlag && !this.component.headerDataSet && this.enabled && (this.status = layoutStatus.EXPRESS_EDIT)
    } else {
      this.editRows.indexOf(this.targetRow) < 0 && this.editRows.push(this.targetRow)
    }
  }

  /**
     * 如果已经弹出了快速编辑框，行的勾选项发生变化，通知快速编辑框重新渲染，使修改的数量实时变化
     **/
  notifyQuickEdit () {
    if (this.editPopperShow) {
      // 如果不是多选，快速编辑框内不显示 `批量修改其他选中项` 复选框
      const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
      let isBatch = false
      if (selection.length && (selection[0] !== this.quickEditingRow || selection.length > 1)) {
        isBatch = true
      }
      const editInstance = this.$refs.quickEdit
      if (isBatch) {
        editInstance.updateRowsLength = selection.length
      } else {
        editInstance.updateRowsLength = 0
      }
    }
  }

  loadTableData () {
    /**
       * 2023-3-14
       * 屏蔽 this.resetStatus 的原因是，现在编辑时的 DataSet 可以是用户通过 editDataSet 修改的，所以不能像以前直接为当前 DataSet 创建一个 editingRow
       * 所以现在默认新增操作时，会使用 LayoutModule.loadLayoutDataSet 创建一个 DataSet
       * 但是这个方法会使用 Vue.$set 方法，影响到表格的 'dataSet.rows' 的监听，使得每次新增时会触发监听，导致 status 被还原, 关闭弹窗后发现按钮的禁用状态没有被还原
       */
    // this.resetStatus()
    const _rows = this.dataSet.rows
    const columns = this.component.fields
    const rows = _rows.filter(row => {
      return row.rowPersist !== rowPersist.DELETE
    })
    let tableData
    const handleRowSelected = (row: IDataRow) => {
      // 根据 rowSelected 属性,判断出来该行数据是否勾选
      if (this.component.rowSelected && this.selectionMode !== 'none') {
        const isSelected = this.getFinalAttributeValue('rowSelected', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
        if (isSelected) {
          if (this.selectionMode === 'radio') {
            // 单选模式下，确保只有一行的 isSelected 被设置为了 true
            // 原则，后一行覆盖前一行
            this.radioCheckedRow && this.$set(this.radioCheckedRow, 'isSelected', false)
            this.$set(row, 'isSelected', true)
            this.radioCheckedRow = row
          } else {
            this.$set(row, 'isSelected', true)
          }
        }
      }
    }
    const timestamp = new Date().getTime()
    if (![layoutStatus.EXPRESS_EDIT, layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status) && !this.component.headerDataSet) {
      this.iterateRowsMap = {}
      this.disabledRowsKeys = []
      tableData = rows.map((row, idx) => {
        row.rowKey = `${timestamp}${idx}` // rowKey每行都要不一样，即使是翻页了，也不要和之前的相同
        row.___index = idx + 1 // 用于行中展示序号的字段
        this.$set(row, 'isSelected', row.isSelected || false) // 当前行是否勾选
        // 在别的 dataSet 创建或者变动的时候会对table 的 dataSet 造成影响，被 watch 监听到，所以这里恢复一下原有的勾选状态
        handleRowSelected(row)
        this.calculateColumnsWidth(row, columns)
        return row
      })
    } else {
      // 头明细编辑状态下，初始状态不清空
      // 点击按钮触发事件进入头明细状态，一般不需要增加下面三个字段；但如果进入页面就进入头明细状态，则需要先处理下。
      tableData = rows.map((r, idx) => {
        if (!r.rowKey) {
          r.rowKey = `${timestamp}${idx}` // rowKey每行都要不一样，即使是翻页了，也不要和之前的相同
          r.___index = idx + 1 // 用于行中展示序号的字段
          this.$set(r, 'isSelected', r.isSelected || false) // 当前行是否勾选
        }
        handleRowSelected(r)
        this.calculateColumnsWidth(r, columns)
        return r
      })
    }
    this.tableData = tableData
    this.$refs.table.loadData(tableData)
    const noWidthColumns = columns.filter(item => !item.width)
    if (rows && rows.length && noWidthColumns.length && !this._renderColumnOnce) {
      // 计算列宽后重新渲染table列
      const groupList = this.getRenderColumns()
      this._renderColumnOnce = true // 在加载过程中，可能两次触发 loadTableData 的间隔很近，导致多次触发计算列宽
      // 以前这里控制下次不进入条件语句，是通过 getRenderColumns 中赋值给 columns width 属性，但是现在认为赋值属性给他们是不合理的，会改变表格组件中 field 原本的值，进而导致传递给其他组件的时候，不再是原值
      // 首次加载 table 的时候如果用户没有保存过列宽，则根据 width 属性显示列宽，如果字段未设置 width 属性，则根据自适应列宽计算，保存到 user_table_setting 表中, 这样下次进入页面就不会再触发计算
      this.saveColumnsWidth(groupList)
    }
    this.updateSelection()
    if (this.selectionMode === 'radio' && this.radioCheckedRow) {
      // 单选模式下通过方法主动勾选被勾选行
      // 因为 单选模式下不支持勾选框绑定字段
      this.$refs.table.setRadioRow(this.radioCheckedRow)
    }
  }

  // 计算列宽
  calculateColumnsWidth (row: IDataRow, columns: any[]) {
    if (!row) return
    columns.forEach((column) => {
      if (column.width) return
      let textWidth = 60
      let text = this._handleContent(column, row)
      if (['lw-switch', 'lw-checkbox', 'lw-image'].includes(column.is)) {
      } else if (column.is === 'lw-tags') {
        text && text.forEach((tag: string) => {
          // tags 自带左右margin：10px,左右padding：10px，一共 40px
          textWidth += this.getTextWidth(tag, '400 12px') + 40
        })
      } else if (column.is === 'lw-label') {
        textWidth = this.getTextWidth(text, '400 ' + column.fontSize + 'px') + this.cellWhiteSpace
      } else {
        // lw-text 和 lw-number 组件会显示 append/prepend 属性
        if (column.is === 'lw-text' || column.is === 'lw-number') text += (column.append || '') + (column.prepend || '')
        // 内容宽度根据显示内容决定
        textWidth = this.getTextWidth(text, '400 14px') + this.cellWhiteSpace
      }
      column.textWidth = Math.max(column.textWidth || 0, textWidth)
    })
  }

  // 返回单元格中需要特殊处理显示的内容
  _handleContent (column: ColumnInfo, row: IDataRow) {
    let val = row.currentData[column.field]
    if (column.is === 'lw-number') {
      if (val === 0) return 0
      if (checkValueIsEmpty(val)) return ''
      if (column.formatEnabled && val && Number(val)) {
        if (column.positiveOnly) val = Number(val.toString().replace('-', ''))
        return numeralFormat(val, column.decimalScale, column.thousandsGroupStyle)
      }
    } else if (column.is === 'lw-choice' || column.is === 'lw-radio') {
      if (checkValueIsEmpty(val)) return ''
      // 对每一行中的lw-choice, 获取category的返回值
      let namespacedCategory = column.category
      if (!column.category.includes('.')) {
        namespacedCategory = `${this.context.getNamespace()}.${column.category}`
      }
      const choices = LocalModule.choices[namespacedCategory] || []
      const choicesMap = _.keyBy(choices, 'value')
      let options: string[] = val.split(',')
      options = options.map(item => choicesMap[item]?.title || '')
      return options.join(',')
    } else if (column.is === 'lw-select') {
      const { displayTemplate, displayContent, displayField } = column
      const args = new Args(this.context, { row })
      // 对每一行中的lw-select, 获取displayTemplate的返回值
      let label = ''
      if (displayTemplate) {
        label = _.template(displayTemplate)({ args, logwire })
      } else if (displayContent) {
        label = getFinalAttributeValue('displayContent', column, args, this.associatedContext, [attributeType.STRING, attributeType.STRING_ARRAY])
      } else if (displayField) {
        label = row.currentData[displayField]
      }
      return label
    } else if (column.is === 'lw-auto-complete') {
      // 对每一行中的lw-auto-complete, 获取dropDownHeaderTemplate的返回值
      const dropDownHeaderTemplate = column.dropDownHeaderTemplate
      const label = dropDownHeaderTemplate ? _.template(dropDownHeaderTemplate)({ args: new Args(this.context, { row }), logwire }) : ''
      return label
    } else if (column.is === 'lw-label') {
      // 对每一行中的lw-label, 获取content的值
      const label = column.content ? getFinalAttributeValue('content', column, new Args(this.context, { row }), this.associatedContext, [attributeType.STRING, attributeType.NUMBER]) : ''
      return label
    } else if (column.is === 'lw-tags') {
      // 对每一行中的lw-tags, 获取content的值
      const label: string | any[] = column.content ? getFinalAttributeValue('content', column, new Args(this.context, { row }), this.associatedContext, [attributeType.STRING, attributeType.ARRAY]) : ''
      // 统一tags的值为字符串数组
      if (label) {
        if (_.isString(label)) return label.split(',')
        else return label.map((c: string | TagContent) => _.isString(c) ? c : c.getContent())
      }
      return label
    }
    return val
  }

  // 拖动列宽后获取拖动后的列宽
  afterDragColumn (data: any) {
    const dragField = data.column.params.field
    const resizeWidth = data.resizeWidth
    const columns = this.renderColumns
    let curColumn: ColumnInfo | undefined
    columns.forEach(item => {
      if (item.type === 'column' && item.column.field === dragField) {
        curColumn = item.column
      } else if (item.type === 'groupColumn') {
        item.children.forEach(child => {
          if (child.field === dragField) {
            curColumn = child
          }
        })
      }
    })
    if (!curColumn || curColumn.width === resizeWidth) return
    curColumn.width = resizeWidth
    this.saveColumnsWidth(columns)
  }

  // 格式化列宽记录
  formateColumnsWidth (renderColumns: LwTable['renderColumns']) {
    const recordColumns: { name: string, width: number }[] = []
    renderColumns.forEach(item => {
      if (item.type === 'groupColumn') {
        item.children.forEach(i => {
          recordColumns.push({
            name: i.field,
            width: i.width
          })
        })
      } else {
        recordColumns.push({
          name: item.column.field,
          width: item.column.width as number
        })
      }
    })
    return recordColumns
  }

  // 拖动之后记录列宽,保存到user_table_setting表中
  saveColumnsWidth (renderColumns: LwTable['renderColumns']) {
    if (this.widthCacheable === false) return
    const recordColumns = this.formateColumnsWidth(renderColumns)
    const widthJson = JSON.stringify(recordColumns)
    this.settingDate.fields_width_json = widthJson
    saveUserSettings(this.layoutName, this.dataSetName, { fields_width_json: widthJson }, undefined, undefined, this.anonymousAccessible)
  }

  /**
     * 计算table高度
     */
  calculateTableHeight () {
    if (!this.component.subDataSet && !isHidden(this.$el as HTMLElement)) {
      const tr = document.querySelector('.vxe-body--row lw-table-tr') as HTMLElement | undefined
      const header = document.querySelector('.vxe-header--row') as HTMLElement | undefined
      const rowsObj = {
        rowsLength: this.dataSet.rows.length || 0,
        rowHeight: tr ? tr.offsetHeight + 2 : 43,
        headerHeight: header ? header.offsetHeight + 1 : 41
      }
      this.tableHeight = calculateHeight(
        this.component.height,
          this.$el as HTMLElement,
          this.minHeight,
          this.maxHeight,
          rowsObj
      ) as number
      return this.tableHeight
    }
  }

  findIndexInRenderColumns (field: string) {
    let findIndex = -1
    let columnIndex = 0
    this.renderColumns.forEach((f, i) => {
      // 二级表头
      if (f.type === 'groupColumn') {
        const findIndexGroupChild = f.children.findIndex(ff => ff.field === field)
        if (findIndexGroupChild !== -1) {
          findIndex = columnIndex + findIndexGroupChild
        }
        columnIndex += f.children.length
      } else {
        if (f.column.field === field) findIndex = columnIndex
        columnIndex++
      }
    })
    return findIndex
  }

  handleScroll (e: any) {
    // const { table } = this.$refs
    // const oldMergeCells = table.getMergeCells()
    // if (oldMergeCells && oldMergeCells.length) {
    //   table.removeMergeCells(oldMergeCells)
    // }
    // console.log(e)
    // this.horizontalScrolling = e.isX
  }

  // table 中渲染的行数据发生变化以后
  handleRenderTableDataChange ({ rows }: { rows: any[] }) {
    const { table } = this.$refs
    // this.getSpanArray(rows)
    const oldMergeCells = table.getMergeCells()
    if (oldMergeCells && oldMergeCells.length) {
      table.removeMergeCells(oldMergeCells)
        .then(() => {
          this.getSpanArray(rows)
        })
    } else {
      this.getSpanArray(rows)
    }
  }

  getSpanArray (rows: any[]) {
    let rowSpanFields = checkValueIsEmpty(this.component.rowSpanFields) ? [] : attribute2Strings(this.component.rowSpanFields)
    if (rowSpanFields.length === 0) return
    // 虚拟滚动此时第一行数据的实际索引
    const startRowIndex = rows[0]?.___index ? rows[0]?.___index - 1 : 0
    // 根据 xml 中配置的 fields 顺序进行排序
    rowSpanFields.sort((a, b) => {
      const aIndex = this.findIndexInRenderColumns(a)
      const bIndex = this.findIndexInRenderColumns(b)
      return aIndex - bIndex
    })
    // 过滤掉不在页面渲染的列中的字段
    rowSpanFields = rowSpanFields.filter(f => this.findIndexInRenderColumns(f) !== -1)
    this.rowSpanFields = rowSpanFields
    const mergeCells: {
        [k in string]: ({
          row: any
          col: number
          rowspan: number,
          colspan: number
        } | null)[]
      } = {}
    let startIndex = 0
    if (this.showIndex) startIndex++
    if (this.selectionMode !== 'none') startIndex++
    if (this.component.rowExpansion && this.component.rowExpansion.length) startIndex++
    rowSpanFields.forEach((field, fIdx) => {
      // 计算出当前字段在表格的第几列
      const findIndex = this.findIndexInRenderColumns(field)
      //  .findIndex(f => f.field === field)
      if (findIndex !== -1) {
        const colIndex = findIndex + startIndex
        mergeCells[field] = []
        let pos = 0
        for (let i = 0; i < rows.length; i++) {
          if (i === 0) {
            mergeCells[field].push({
              row: i + startRowIndex,
              col: colIndex,
              rowspan: 1,
              colspan: 1
            })
          } else {
            const curColEquals = rows[i].currentData[field] === rows[i - 1].currentData[field] // 与当前列比较
            const preColEquals = fIdx && (!mergeCells[this.rowSpanFields[fIdx - 1]][i] || mergeCells[this.rowSpanFields[fIdx - 1]][i]?.rowspan === 1) // 与前一个列比较
            if ((fIdx === 0 && curColEquals) || (fIdx !== 0 && curColEquals && preColEquals)) {
              const obj = mergeCells[field][pos]
              obj && obj.rowspan++
              mergeCells[field].push(null)
            } else {
              pos = i
              mergeCells[field].push({
                row: i + startRowIndex,
                col: colIndex,
                rowspan: 1,
                colspan: 1
              })
            }
          }
        }
      }
    })
    let result: any[] = []
    for (const f in mergeCells) {
      const arr = mergeCells[f].filter(m => m && m.rowspan > 1)
      result = [...result, ...arr]
    }

    this.$refs.table.setMergeCells(result)
  }

  showTableOpts ($event: MouseEvent, row: any) {
    this.targetRow = row
    const target = $event.target as HTMLElement
    this.$refs.tableOperations.currentRow = row

    // 判断更多按钮中的表格默认按钮
    if (this.checkDefaultButtonExistInMore('viewable')) {
      this.$refs.tableOperations.showView = true
    }
    if (this.checkDefaultButtonExistInMore('editable')) {
      this.$refs.tableOperations.showEdit = true
    }
    if (this.checkDefaultButtonExistInMore('cloneable')) {
      this.$refs.tableOperations.showClone = true
    }
    if (this.checkDefaultButtonExistInMore('deletable')) {
      this.$refs.tableOperations.showDelete = true
    }

    const moreOpts = this.generateOpColumnsHidden(row)
    this.$refs.tableOperations.moreOpts = moreOpts

    this.$nextTick(() => {
      this.popperInstance = createPopper(target, this.$refs.tableOperations.$el as HTMLElement, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ]
      })
    })
  }

  hideMoreOps () {
    this.popperInstance && this.popperInstance.destroy()
  }

  /**
     * 跳转到第多少页
     * 只有因为改变页码数时的查询，才认为可以不需要 count
     * 而像删除、新增后的查询，都需要 count 来获得总数量
     * @param {{ pageNo: number, pageSize?: number }} params
     */
  handlePaging (param: { pageNo: number, pageSize?: number, forceCount?: boolean }) {
    LayoutModule.setTempTablePageNo({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, tempPageNo: param.pageNo })
    LayoutModule.setTempTablePageSize({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, tempPageSize: param.pageSize })
    // 让表格滚动到头部
    this.$refs.table.scrollTo(0, 0)
    handleDataSetRetrieveForQueryByFilterComponent.call(this, 'table', param.forceCount ? 'retrieve' : 'paging')
    this.clearEditRows()
    // 重新拉取数据后就清空 editRows，不然 editRows 中的数据和 store 中的数据不一致，导致界面没有表现出修改状态，但是保存时候却有修改值
    // 每次重新获取数据,不论是翻页还是保存，或是加载，都重置已展开行的记录 hasExpandRowKeys
    this.hasExpandRowKeys = []
  }

  /**
   * 当发生了行的变化时，需要判断当前行是否存在于 editRows 内，避免重复添加数据
   * 但是判断不可以直接使用 indexOf, 因为编辑时的 row 和 tableData 内的 row 并不是同一个对象，所以需要从 currentData.id, rowKey 判断
   * @param row
   */
  getRowIndexExistInEditRows (row: IDataRow): number {
    if (row.currentData.id) {
      return this.editRows.findIndex(o => o.currentData.id === row.currentData.id)
    } else if (row.rowKey) {
      // 这里的使用 rowKey 的场景是,头明细状态,新增了一条数据保存,这时候没有和后端进行交互,所以也就不存在 id
      // 然后对新增的这条数据进行编辑, 因为此时没有 id,无法判断出到底编辑的是哪一条数据,所以只能根据 rowKey 来进行判断
      // rowKey 是在 handleDataSetEdit 时设置的
      return this.editRows.findIndex(o => o.rowKey === row.rowKey)
    } else {
      return this.editRows.indexOf(row)
    }
  }
}
