import { operationType } from '@/utils/const'

export interface quickFilter {
  data: Record<string, string | number>
  fields: Array<Record<string, any>>
}

export default class QuickFilter {
  #filter!: quickFilter

  constructor (quickFilter: quickFilter) {
    this.#filter = quickFilter
  }

  get filter (): quickFilter {
    return JSON.parse(JSON.stringify(this.#filter))
  }

  getFieldValue (field: string): string | number {
    let result: string | number = ''
    if (this.filter.data[field]) {
      result = this.filter.data[field]
    }
    return result
  }

  setFieldValue (field: string, value: string | number): QuickFilter {
    if (this.#filter.data[field] !== undefined) {
      this.#filter.data[field] = value
    }
    return this
  }

  getFieldOperator (field: string): operationType | null {
    const resultField = this.#filter.fields.find(f => f.field === field)
    let result = null
    if (resultField) {
      result = resultField.quickFilterOperator
    }
    return result
  }
}
