import { Component } from 'vue-property-decorator'
import QueriableComponent from '@/components/layout/QueriableComponent'
import { LayoutModule } from '@/store/modules/layout'
import _ from 'lodash'
import { IConditionGroup } from '@/types/data'
import { attributeType } from '@/utils/const'
import { formatMasterFilter } from '@/utils/data'

@Component
class QueriableByFilterComponent extends QueriableComponent {
  get queryMasterFilter (): IConditionGroup | undefined {
    const result = this.getFinalAttributeValue('queryMasterFilter', { types: [attributeType.OBJECT_ARRAY, attributeType.OBJECT] })
    return formatMasterFilter(result)
  }

  get queryParams (): Array<Record<string, any>> | void { return this.getFinalAttributeValue('queryParams', { type: attributeType.OBJECT }) }
  // created (): void {
  //   const { query } = this.$route
  //   const { id } = query
  //   if (!_.isFinite(id) || !this.query) return
  //   const layoutName = this.editLayoutName || this.layoutName
  //   // 当 url 中含有 id 字段并且设置了 query 属性时，按照 ID 进行 query
  //   LayoutModule.loadDataById({
  //     layoutName,
  //     encodedLayoutName: this.encodedLayoutName,
  //     namespace: this.context.getNamespace,
  //     query: this.component.query,
  //     dataSetName: this.dataSetName,
  //     id
  //   })
  // }
}

export default QueriableByFilterComponent
