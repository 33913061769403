














import BaseComponent from '@/components/layout/BaseComponent'
import { Component, Prop } from 'vue-property-decorator'
import { LayoutModule } from '@/store/modules/layout'

@Component
export default class TableSelectAllTip extends BaseComponent {
  @Prop() dataSet!: string

  @Prop() selectAllEnabled!: boolean

  @Prop() crossPageSelectAllEnabled!: boolean

  @Prop() isInTable!: boolean

  get total () {
    return LayoutModule.data[this.encodedLayoutName].systemState[this.dataSet].total || 0
  }

  get count () {
    return LayoutModule.data[this.encodedLayoutName].systemState[this.dataSet].selection?.length || 0
  }

  get showTip () {
    if (!this.selectAllEnabled) return false
    var selectionLength = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSet].selection?.length
    var dataSetLength = LayoutModule.data[this.encodedLayoutName].dataSet[this.dataSet].rows.length
    let result = false
    if (this.selectAll || (selectionLength === dataSetLength && selectionLength !== 0)) {
      result = true
    }
    return result
  }

  // 跨页面全选
  get selectAll (): boolean {
    return LayoutModule.data[this.encodedLayoutName].systemState[this.dataSet].selectAll
  }

  get getTotalBy () {
    return LayoutModule.data[this.encodedLayoutName].systemState[this.dataSet].getTotalBy
  }

  operate (type: string) : void {
    let selectAll = false
    if (type === 'confirm') {
      selectAll = true
    }
    LayoutModule.setTableSelectAll({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSet,
      selectAll: selectAll
    })
  }
}
