import { LayoutComponent } from '@/types/layout'
import ComponentStructure from '../basic'

export default class I18nForLwTabPane extends ComponentStructure {
  name = 'lw-tab-pane'
  properties = ['title']

  getHtmlElement () {
    return null
  }

  getPropertyShownElement (property: string) {
    return this.dom
      ? this.dom.querySelector('.lw-section > .lw-section-header') as HTMLElement | null
      : null
  }

  getChildComponents () {
    return this.component.components || []
  }
}
