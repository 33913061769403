





































import { Component } from 'vue-property-decorator'
import LwRegion2 from './Region2.vue'
import { Bind, Debounce } from 'lodash-decorators'
import { SCREEN_SIZE_BREAK_POINTS } from '@/utils/const'

@Component({ name: 'LwRegion3' })
export default class LwRegion3 extends LwRegion2 {
  colInRow = 3

  @Bind()
  @Debounce(500)
  handleWindowResize () {
    const { innerWidth } = window
    if (innerWidth < SCREEN_SIZE_BREAK_POINTS[0]) {
      this.colInRow = 1
    } else {
      if (this.colInRow !== 3) {
        this.colInRow = 3
      }
    }
  }
}
