








import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import BreadcrumbItem from '@/models/BreadcrumbItem'
import { attributeType } from '@/utils/const'
import Args from '@/models/Args'

@Component({ name: 'LwBreadcrumb' })
class LwBreadcrumb extends BaseComponent {
  get items (): Array<BreadcrumbItem> {
    return this.getFinalAttributeValue('items', { type: attributeType.OBJECT_ARRAY })
  }

  handleItemClick (item: BreadcrumbItem): void {
    const getCurrentItem = () => item
    this.runRunnableContent('onItemClick', { args: new Args(this.context, { getCurrentItem }) })
  }
}
export default LwBreadcrumb
