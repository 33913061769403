/* eslint-disable dot-notation */
import ComponentStructure from '../basic'

export default class I18nForLwButton extends ComponentStructure {
  properties = ['title']
  name = 'lw-button'

  getHtmlElement () {
    for (const dom of document.querySelectorAll('.lw-badge')) {
      if (dom['__vue__'] &&
        dom['__vue__'].$vnode.componentOptions.tag === 'lw-button' &&
        dom['__vue__'].component === this.component
      ) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      } else if (dom['__vue__'] &&
        dom['__vue__'].$vnode.componentOptions.tag === 'el-badge' &&
        dom['__vue__'].$parent.$parent.component === this.component
      ) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      } else if (dom['__vue__'] &&
        dom['__vue__'].$vnode.componentOptions.tag === 'lw-badge' &&
        dom['__vue__'].$parent.component === this.component
      ) {
        return this.judgeDomExistInViewport(dom as HTMLElement)
      }
    }
    return null
  }

  getPropertyShownElement (property: string) {
    return this.dom
      ? this.dom.querySelector('.lw-button.el-button span') as HTMLElement | null
      : null
  }

  getChildComponents () {
    return []
  }
}
