<script>
import ElDatePicker from 'element-ui/packages/date-picker/src/picker/date-picker'
import DatePanel from '../panel/date.vue';
import DateRangePanel from '../panel/date-range.vue';
import MonthRangePanel from 'element-ui/packages/date-picker/src/panel/month-range.vue';

const getPanel = function(type) {
  if (type === 'daterange' || type === 'datetimerange') {
    return DateRangePanel;
  } else if (type === 'monthrange') {
    return MonthRangePanel;
  }
  return DatePanel;
};

export default {
  name: 'ElDatePicker',

  mixins: [ElDatePicker],

  watch: {
    type(type) {
      if (this.picker) {
        this.unmountPicker();
        this.panel = getPanel(type);
        this.mountPicker();
      } else {
        this.panel = getPanel(type);
      }
    }
  },

  created() {
    this.panel = getPanel(this.type);
  }
} 
</script>
