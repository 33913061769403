import { ICondition, IConditionGroup } from '@/types/data'
import { conditionType, connector } from '@/utils/const'
import Condition from './Condition'

export default class ConditionGroup {
  #type = conditionType.GROUP
  #not = false
  #connector = connector.AND
  #conditions: Array<IConditionGroup | ICondition> = []

  constructor (params?: IConditionGroup) {
    if (params) {
      this.#type = params.type
      this.#not = params.not
      this.#connector = params.connector
      this.#conditions = params.conditions
    }
  }

  setNot (): ConditionGroup {
    this.#not = true
    return this
  }

  setOr (): ConditionGroup {
    this.#connector = connector.OR
    return this
  }

  add (...params: Array<ConditionGroup | Condition | IConditionGroup | ICondition>): ConditionGroup {
    params.forEach(p => {
      if (p instanceof ConditionGroup || p instanceof Condition) {
        this.#conditions.push(p.build())
      } else if (p) {
        this.#conditions.push(p)
      }
    })
    return this
  }

  build (): IConditionGroup {
    return {
      type: this.#type,
      not: this.#not,
      connector: this.#connector,
      conditions: this.#conditions
    }
  }
}
