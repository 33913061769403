import { PopupManager } from 'element-ui/lib/utils/popup'

export class Canvas {
  _canvas: HTMLCanvasElement
  _ctx: CanvasRenderingContext2D
  _container: HTMLElement
  constructor (dom: HTMLElement) {
    this._canvas = this.initDom(dom)
    this._container = this.createContainer()
    const context = this._canvas.getContext('2d')
    if (context) {
      this._ctx = context
      this.fill(0, 0, this._canvas.width, this._canvas.height, 'rgba(0, 0, 0, .2)')
    } else {
      throw new Error('[Canvas]： 无法获取 context 对象')
    }
  }

  initDom (dom: HTMLElement) {
    const canvas = document.createElement('canvas')
    const rect = dom.getBoundingClientRect()
    canvas.setAttribute('width', rect.width + 'px')
    canvas.setAttribute('height', rect.height + 'px')
    return canvas
  }

  createContainer () {
    const dom = document.createElement('div')
    dom.style.cssText = `
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
    `
    // dom.style.zIndex = PopupManager.nextZIndex().toString()
    dom.style.zIndex = '9999'
    dom.appendChild(this._canvas)
    return dom
  }

  /**
   * 绘制某个区域的图形
   */
  fill (x: number, y: number, width: number, height: number, bgColor: string) {
    this._ctx.save()
    this._ctx.fillStyle = bgColor
    this._ctx.fillRect(x, y, width, height)
    this._ctx.restore()
  }

  // 清除所有虚线边框
  clearDashLine () {
    this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)
    this.fill(0, 0, this._canvas.width, this._canvas.height, 'rgba(0, 0, 0, .2)')
  }

  /**
   * 描绘虚线
   * @param { Array<number> } pattern 虚线的长度效果，[15,3,3] 表示循环渲染虚线时，先渲染 15 长度的实线，然后是空白间隔，然后再渲染 3 长度的实现，空白间隔，然后再渲染3长度的实现；然后循环此操作
   */
  drawDashLine (x: number, y: number, width: number, height: number, pattern: number[], strokeStyle: string) {
    this._ctx.save()
    this._ctx.beginPath()
    this._ctx.setLineDash(pattern)
    this._ctx.strokeStyle = strokeStyle
    this._ctx.lineWidth = 2
    this._ctx.moveTo(x, y)
    this._ctx.lineTo(x + width, y)
    this._ctx.lineTo(x + width, y + height)
    this._ctx.lineTo(x, y + height)
    this._ctx.lineTo(x, y)
    this._ctx.stroke()
    this._ctx.restore()
  }
}

/**
 * 创建一个 Canvas 蒙版，然后覆盖在当前页面上，后续再为固定位置的元素创建高亮效果
 */
export default async function screenshot () {
  const dom = document.body.querySelector('div#app') as HTMLElement
  const canvas = new Canvas(dom)
  document.body.appendChild(canvas._container)
  return canvas
}
