import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { LayoutComponent, LayoutResource } from '@/types/layout'
import { DataRow as IDataRow, DataSet, DetailDataSets, IConditionGroup, IDataTree, LayoutData, RowField, TableExtraInfo } from '@/types/data'
import { Vue } from 'vue-property-decorator'
import {
  formatDataset,
  getQueryFilter,
  resetDataRow,
  mergeDataRow
} from '@/utils/data'
import { countByFilter, queryByFilter, queryById } from '@/http/api'
import _, { isFunction } from 'lodash'
import {
  filterOperator,
  layoutStatus,
  RequestError,
  SYSTEM_DB_RESERVED_FIELDS,
  totalByType
} from '@/utils/const'
import { isNumber } from 'xe-utils'
import { getUuid } from '@/utils/common'

type QueryByFilterResponse = {
  rows: Record<string, any>[],
  pageSize: number,
  pageNo: number
} & {
  layoutName: string,
  dataSetName: string,
  layoutFields?: any,
  retrieveCallback?: () => any
}

type CountByFilterResponse = {
  total: number,
  aggregation: Record<string, any>,
  countState: string
} & {
  layoutName: string,
  dataSetName: string,
  layoutFields?: any,
  retrieveCallback?: () => any
}

type QueryByIdResponse = {
  layoutName: string,
  encodedLayoutName: string,
  dataSetName: string,
  namespace: string,
  query: string,
  id: number,
  callback?: () => void,
  failCallback?: (message: any) => void
}

export type SystemState = Partial<{
  queryName: string
  keywordFilter: any
  orderBy: any[]
  quickFilter: any
  displayFields: any
  partitionFilter: any
  advancedFilter: any
  // 临时页码数，查询时优先查询 tempPageNo，没有则取 pageNo，查询结束后删除 tempPageNo 属性，并将 tempPageNo 赋予 pageNo
  tempPageNo: number
  tempPageSize: number
  pageNo: number
  pageSize: number
  getTotalBy: totalByType
  total: number
  aggregationRow: any
  selection: IDataRow[]
  selectAll: any
  masterFilter: any
  queryParams: any
  userTableSetting: any
  showFields: any
  aggregationFields: string[]
  counting: boolean // 是否正在查询计数，计数查询过程中，Pagination 组件显示 loading 内容
  countState: string
}>

export interface LayoutState {
  [dataSetName: string]: any;
  data: {
    [k in string]: {
      dataSet: {
        [p in string]: DataSet
      }
      editingDataSet: string
      params?: Record<string, any>
      state: Record<string, any>
      status: layoutStatus | ''
      systemState: Record<string, SystemState>
      uiWebSocketListener?: {
        open: Record<string, any>,
        close: Record<string, any>,
        customMessage: Record<string, any>
      }
      afterLoadEvents?: string[]
    }
  };
  resource: {
    [k in string]: LayoutResource
  };
}

@Module({ dynamic: true, store, name: 'layout' })
class Layout extends VuexModule implements LayoutState {
  data = {} as LayoutState['data']
  resource = {} as LayoutState['resource']

  @Mutation
  private LOAD_LAYOUT_DATA_ROOT (layoutName: string): void {
    this.data[layoutName] = Vue.observable({ dataSet: {}, systemState: {}, state: {}, editingDataSet: '', status: '' })
  }

  @Action
  loadLayoutDataRoot (layoutName: string) {
    this.LOAD_LAYOUT_DATA_ROOT(layoutName)
  }

  @Mutation
  private REMOVE_AFTER_LOAD_EVENT (payload: { layoutName: string, eventName: string }): void {
    const { layoutName, eventName } = payload
    const events = this.data[layoutName].afterLoadEvents
    if (events) {
      events.splice(events.indexOf(eventName), 1)
    }
  }

  @Action
  removeAfterLoadEvent (payload: { layoutName: string, eventName: string }): void {
    this.REMOVE_AFTER_LOAD_EVENT(payload)
  }

  @Mutation
  private LOAD_LAYOUT_PARAMS (payload: { layoutName:string, params: Record<string, any> }): void {
    const { layoutName, params } = payload
    const { new: newDs, headerDetailNew: headerDetailNewDs, afterLayoutLoadEvent } = params
    const newEvent = newDs
      ? `${newDs}.new`
      : headerDetailNewDs
        ? `${headerDetailNewDs}.headerDetailNew`
        : undefined
    const afterLoadEvents = _.compact([newEvent, afterLayoutLoadEvent]) as string[]
    // 保持 params 的响应式功能
    if (!this.data[layoutName].params) {
      Vue.set(this.data[layoutName], 'params', { ...params })
    }
    this.data[layoutName].params = { ...params }
    if (afterLoadEvents.length) {
      this.data[layoutName].afterLoadEvents = afterLoadEvents
    }
  }

  @Action
  loadLayoutParams (payload: { layoutName:string, params: Record<string, any> }) {
    this.LOAD_LAYOUT_PARAMS(payload)
  }

  @Mutation
  private LOAD_DATASET_QUERY_INFO (payload: { layoutName: string; dataSetName: string; queryName: string }): void {
    const { layoutName, dataSetName, queryName } = payload
    const root = this.data[layoutName]
    const systemState = root.systemState
    const dataSetState = systemState[dataSetName]
    if (!dataSetState) {
      systemState[dataSetName] = Vue.observable({ queryName })
    }
  }

  @Action
  layoutDataSetQueryInfo (payload: { layoutName: string; dataSetName: string; queryName: string }) {
    this.LOAD_DATASET_QUERY_INFO(payload)
  }

  @Mutation
  private REMOVE_LAYOUT_DATA_ROOT (layoutName: string): void {
    delete this.data[layoutName]
  }

  @Action
  removeLayoutDataRoot (payload: string): void {
    this.REMOVE_LAYOUT_DATA_ROOT(payload)
  }

  @Mutation
  private LOAD_LAYOUT_RESOURCE (payload: LayoutResource): void {
    const { layoutName } = payload
    Vue.set(this.resource, layoutName, payload)
  }

  @Action
  loadLayoutResource (payload: LayoutResource): void {
    this.LOAD_LAYOUT_RESOURCE(payload)
  }

  @Mutation
  private LOAD_LAYOUT_DATA_SET (payload: LayoutData): void {
    const { layoutName, data } = payload
    formatDataset(data)
    Vue.set(this.data[layoutName].dataSet, data.dataSetName, data)
  }

  @Action({ rawError: true })
  loadLayoutDataSet (payload: LayoutData):void {
    this.LOAD_LAYOUT_DATA_SET(payload)
  }

  /**
   * 合并datarow中的内容
   * @param payload
   */
  @Mutation
  private MERGE_DATA_ROW (payload: LayoutData): void {
    const { layoutName, data } = payload
    // 如果已经存在dataset，则执行merge操作，后者覆盖前者
    const originDataRow = this.data[layoutName]?.dataSet?.[data.dataSetName]?.rows?.[0]
    const currentDataRow = data?.rows?.[0]
    if (originDataRow && currentDataRow) {
      mergeDataRow(originDataRow, currentDataRow)
    }
  }

  @Action
  mergeDataRow (payload: LayoutData) : void {
    this.MERGE_DATA_ROW(payload)
  }

  /**
   * 点击新增触发editLayout
   * 如果源数据中存在 id insert_timestamp等字段，删除
   * @param payload
   */
  @Mutation
  private NEW_DATA_ROW (payload: Record<string, any>): void {
    const { layoutName, row, dataSetName } = payload
    // 如果已经存在dataset，则执行merge操作，后者覆盖前者
    const originDataRow = this.data[layoutName]?.dataSet?.[dataSetName]?.rows?.[0]
    if (originDataRow && row) {
      mergeDataRow(originDataRow, row, true)
    }
    for (const reservedFieldName of SYSTEM_DB_RESERVED_FIELDS) {
      delete originDataRow?.currentData[reservedFieldName]
      delete originDataRow?.originalData[reservedFieldName]
    }
  }

  @Action
  newDataRow (payload: Record<string, any>) : void {
    this.NEW_DATA_ROW(payload)
  }

  @Mutation
  private LOAD_DATA_SET_FILTERS (
    {
      layoutName = '',
      dataSetName = '',
      quickFilterFields = [],
      keywordFields = [],
      allowedKeywordOperators = [filterOperator.LIKE, filterOperator.EQ, filterOperator.LEFT_LIKE, filterOperator.REGEXP_LIKE]
    }
  ): void {
    // 已经初始化后就不再初始化
    if (this.data[layoutName].systemState[dataSetName]?.keywordFilter) return
    const keywordFilter = { value: '', matchMode: allowedKeywordOperators[0], fields: keywordFields }
    const quickFilter = {} as Record<string, any>
    quickFilter.data = {}
    quickFilter.fields = []
    for (const field of quickFilterFields) {
      quickFilter.data[(field as LayoutComponent).field] = ''
      const newField = _.cloneDeep(field)
      quickFilter.fields.push(newField)
    }
    // TODO v2 将orderBy 和displayFields 放在了table中
    const orderBy = [] as Array<Record<string, any>>
    const displayFields = [] as Array<Record<string, any>>
    const newState = Object.assign({},
      this.data[layoutName].systemState[dataSetName],
      { keywordFilter, quickFilter, orderBy, displayFields })
    Vue.set(this.data[layoutName].systemState, dataSetName, newState)
  }

  @Action
  loadDataSetFilters (
    {
      layoutName = '',
      dataSetName = '',
      quickFilterFields = [],
      keywordFields = [],
      force = false,
      allowedKeywordOperators = [filterOperator.LIKE, filterOperator.EQ, filterOperator.LEFT_LIKE, filterOperator.REGEXP_LIKE]
    }
  ): void {
    this.LOAD_DATA_SET_FILTERS({ layoutName, dataSetName, quickFilterFields, keywordFields, allowedKeywordOperators })
  }

  @Action
  loadQuickFilters ({ layoutName = '', dataSetName = '', quickFilter = {} }): void {
    this.LOAD_QUICK_FILTERS({ layoutName, dataSetName, quickFilter })
  }

  @Mutation
  LOAD_QUICK_FILTERS ({ layoutName = '', dataSetName = '', quickFilter = {} }) {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'quickFilter', quickFilter)
  }

  @Action
  loadKeywordFilters ({ layoutName = '', dataSetName = '', keywordFilter = {} }): void {
    this.LOAD_KEY_WORD_FILTERS({ layoutName, dataSetName, keywordFilter })
  }

  @Mutation
  LOAD_KEY_WORD_FILTERS ({ layoutName = '', dataSetName = '', keywordFilter = {} }) {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'keywordFilter', keywordFilter)
  }

  @Action
  loadPartitionFilter ({ layoutName = '', dataSetName = '', partitionFilter = {} }): void {
    this.LOAD_PARTITION_FILTERS({ layoutName, dataSetName, partitionFilter })
  }

  @Mutation
  LOAD_PARTITION_FILTERS ({ layoutName = '', dataSetName = '', partitionFilter = {} }) {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'partitionFilter', partitionFilter)
  }

  @Action
  loadAdvancedFilter ({ layoutName = '', dataSetName = '', advancedFilter = {} }): void {
    this.LOAD_ADVANCED_FILTERS({ layoutName, dataSetName, advancedFilter })
  }

  @Mutation
  LOAD_ADVANCED_FILTERS ({ layoutName = '', dataSetName = '', advancedFilter = {} }) {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'advancedFilter', advancedFilter)
  }

  @Mutation
  private LOAD_DATA_SET_ORDERBY ({ layoutName = '', dataSetName = '', orderBy = [] }: { layoutName: string, dataSetName: string, orderBy: any[] }): void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'orderBy', orderBy)
  }

  @Action
  loadDataSetOrderBy ({ layoutName = '', dataSetName = '', orderBy = [] }: { layoutName: string, dataSetName: string, orderBy: any[] }): void {
    this.LOAD_DATA_SET_ORDERBY({ layoutName, dataSetName, orderBy })
  }

  @Mutation
  CLEAR_FILTER ({ layoutName = '', dataSetName = '' }) {
    delete this.data[layoutName].systemState[dataSetName]
  }

  @Action
  clearFilter ({ layoutName = '', dataSetName = '' }) {
    this.CLEAR_FILTER({ layoutName, dataSetName })
  }

  @Mutation
  private UPDATE_ROW_FIELD ({ layoutName, dataSetName, rowIndex, field, value }: RowField): void {
    if (!this.data[layoutName]?.dataSet?.[dataSetName]?.rows) return
    Vue.set(this.data[layoutName].dataSet[dataSetName].rows[rowIndex].currentData, field, value)
  }

  @Action
  updateRowField (payload: RowField): void {
    this.UPDATE_ROW_FIELD(payload)
  }

  @Mutation
  private INIT_TABLE_EXTRA_DATA (payload: any) : void {
    const { layoutName, dataSetName, pageSize, queryName, showFields, aggregationFields: originalAggregationFields } = payload
    // 已经初始化后就不再初始化
    if (this.data[layoutName].systemState[dataSetName]?.queryName) return
    // 如果沒有初始化dataSet下的數據，也初始化
    if (!this.data[layoutName].dataSet[dataSetName]) {
      Vue.set(this.data[layoutName].dataSet, dataSetName, { dataSetName, rows: [] })
    }
    if (!this.data[layoutName].dataSet[dataSetName].rows) {
      Vue.set(this.data[layoutName].dataSet[dataSetName], 'rows', [])
    }

    // 以前这里有设置 EDIT_LAYOUT_DATASET_NAME 的 DataSet，但是现在这个编辑的内容可能是由 Query 基类中 editDataSet 属性设置的，所以
    // 现在把创建对应 editDataSet 的步骤放到 PopupLayout 或者 Panel 里面了

    // 整理 aggregationFields 格式 为 { method: "", field: "" }
    const aggregationFields: Array<Record<string, any>> = []
    originalAggregationFields && originalAggregationFields.forEach((f: string) => {
      // 去掉收尾空格
      f = f.trim()
      const method = f.substring(0, f.indexOf('__'))
      const field = f.substring(f.indexOf('__') + 2)
      aggregationFields.push({
        method,
        field
      })
    })

    let layoutState = {}
    if (!this.data[layoutName].systemState[dataSetName]) {
      Vue.set(this.data[layoutName].systemState, dataSetName, {})
    }
    layoutState = this.data[layoutName].systemState[dataSetName]
    const tableExtraData : TableExtraInfo = {
      total: 0,
      pageNo: 1,
      pageSize,
      aggregationFields,
      aggregationRow: {},
      selectAll: false,
      selection: [],
      masterFilter: {} as IConditionGroup,
      queryName,
      showFields
    }
    for (const key in tableExtraData) {
      Vue.set(layoutState, key, tableExtraData[key])
    }
  }

  @Mutation
  private INIT_FORM_EXTRA_DATA (payload: any) : void {
    const { layoutName, dataSetName } = payload
    if (!this.data[layoutName].systemState[dataSetName]) {
      Vue.set(this.data[layoutName].systemState, dataSetName, {})
    }
  }

  @Action
  initTableExtraData (payload: any): void {
    this.INIT_TABLE_EXTRA_DATA(payload)
  }

  @Action
  initFormExtraData (payload: any): void {
    this.INIT_FORM_EXTRA_DATA(payload)
  }

  @Mutation
  private SET_TABLE_PAGE_NO ({ layoutName, dataSetName, pageNo } : any) : void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'pageNo', pageNo)
  }

  @Action
  setTablePageNo (payload: { layoutName: string, dataSetName: string, pageNo: number }) : void {
    this.SET_TABLE_PAGE_NO(payload)
  }

  @Mutation
  private SET_TEMP_TABLE_PAGE_NO ({ layoutName, dataSetName, tempPageNo } : any) : void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'tempPageNo', tempPageNo)
  }

  @Action
  setTempTablePageNo (payload: { layoutName: string, dataSetName: string, tempPageNo?: number }) : void {
    this.SET_TEMP_TABLE_PAGE_NO(payload)
  }

  @Mutation
  private SET_TABLE_PAGE_SIZE ({ layoutName, dataSetName, pageSize } : any) : void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'pageSize', pageSize)
  }

  @Action
  setTablePageSize (payload: any) : void {
    this.SET_TABLE_PAGE_SIZE(payload)
    if (payload.pageNo) this.setTablePageNo(payload)
  }

  @Mutation
  private SET_TEMP_TABLE_PAGE_SIZE ({ layoutName, dataSetName, tempPageSize } : any) : void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'tempPageSize', tempPageSize)
  }

  @Action
  setTempTablePageSize (payload: { layoutName: string, dataSetName: string, tempPageSize?: number, tempPageNo?: number }) : void {
    this.SET_TEMP_TABLE_PAGE_SIZE(payload)
    if (payload.tempPageNo) this.setTempTablePageNo(payload as any)
  }

  @Mutation
  SET_TABLE_TOTAL_BY ({ layoutName, dataSetName, getTotalBy }: { layoutName: string, dataSetName: string, getTotalBy: totalByType }) {
    // 现在 getTotalBy 可以存储 force-none 的值，这个值对后端的查询也是有意义的
    Vue.set(this.data[layoutName].systemState[dataSetName], 'getTotalBy', getTotalBy)
  }

  @Action
  setTableTotalBy (payload: { layoutName: string, dataSetName: string, getTotalBy: totalByType }) {
    this.SET_TABLE_TOTAL_BY(payload)
  }

  @Mutation
  SET_COUNTING (payload: { layoutName: string, dataSetName: string, counting: boolean }) {
    const { layoutName, dataSetName, counting } = payload
    if (this.data[layoutName].systemState[dataSetName]?.counting === undefined) {
      Vue.set(this.data[layoutName].systemState[dataSetName], 'counting', counting)
    } else {
      this.data[layoutName].systemState[dataSetName].counting = counting
    }
  }

  @Mutation
  LOAD_TABLE_FILTER_DATA (payload: QueryByFilterResponse) {
    const { layoutName, dataSetName, pageSize, rows, layoutFields, retrieveCallback } = payload
    if (!this.data[layoutName]?.systemState?.[dataSetName]) return
    const tempPageNo = this.data[layoutName].systemState[dataSetName].tempPageNo
    const tempPageSize = this.data[layoutName].systemState[dataSetName].tempPageSize

    // 成功查询后设置新的页码数
    if (tempPageNo) {
      this.data[layoutName].systemState[dataSetName].pageNo = tempPageNo
      this.data[layoutName].systemState[dataSetName].tempPageNo = undefined
    }
    if (tempPageSize) {
      this.data[layoutName].systemState[dataSetName].pageSize = tempPageSize
      this.data[layoutName].systemState[dataSetName].tempPageSize = undefined
    }

    const formatRows: IDataRow[] = rows.map((item: Record<string, any>, index: number) => {
      // 补齐后端不返回空值字段
      if (layoutFields) {
        const responseFields = Object.keys(item)
        const emptyFieldsMap = {}
        for (const fieldName of [...new Set(responseFields.concat(layoutFields))]) {
          if (!responseFields.includes(fieldName)) {
            emptyFieldsMap[fieldName] = ''
          }
        }
        item = { ...item, ...emptyFieldsMap }
      }
      return {
        currentData: item,
        originalData: JSON.parse(JSON.stringify(item)),
        rowPersist: '',
        rowSequence: index,
        isSelected: false,
        subDataSets: {},
        state: {}
      }
    })
    Vue.set(this.data[layoutName].dataSet[dataSetName], 'rows', formatRows)
    Vue.set(this.data[layoutName].systemState[dataSetName], 'selection', [])
    Vue.set(this.data[layoutName].systemState[dataSetName], 'selectAll', false)
    retrieveCallback && retrieveCallback()
  }

  @Mutation
  LOAD_TABLE_COUNT_DATA (payload: CountByFilterResponse) {
    const { layoutName, dataSetName, total, aggregation } = payload
    const getTotalBy = this.data[layoutName].systemState[dataSetName].getTotalBy
    if (getTotalBy === null) {
      // do nothing
    } else if (isNumber(total)) {
      Vue.set(this.data[layoutName].systemState[dataSetName], 'total', total)
    }

    if (aggregation) {
      Vue.set(this.data[layoutName].systemState[dataSetName], 'aggregationRow', aggregation)
    }
  }

  @Mutation
  SET_TABLE_COUNTING_STATE (payload: { layoutName: string, dataSetName: string, countState: string }) {
    const { layoutName, dataSetName, countState } = payload
    this.data[layoutName].systemState[dataSetName].countState = countState
  }

  @Action
  loadTableData (payload: {
    layoutName: string,
    encodedLayoutName: string
    dataSetName: string
    queryName: string
    layoutFields: any[],
    namespace: string
    retrieveType: 'retrieve'| 'paging'
    retrieveCallback: () => void
  }) : void {
    const { layoutName, encodedLayoutName, dataSetName, queryName, namespace, layoutFields, retrieveCallback } = payload
    // 拼凑查询条件，如果没有写lw-pagination，默认查询所有数据
    const ds = this.data[encodedLayoutName].systemState[dataSetName]
    const filter = getQueryFilter(ds)

    queryByFilter({ layoutName, namespace, queryName, filter }).then(res => {
      this.LOAD_TABLE_FILTER_DATA({ layoutName: encodedLayoutName, dataSetName, layoutFields, ...res.data.data, retrieveCallback })
    }, () => 0)

    if (payload.retrieveType === 'retrieve' &&
      filter.getTotalBy !== totalByType.FORCE_NONE &&
      (filter.pageSize !== -1 || filter?.aggregationFields?.length)
    ) {
      filter.countState = getUuid()
      this.SET_TABLE_COUNTING_STATE({ layoutName, dataSetName, countState: filter.countState as string })
      setTimeout(() => {
        if (this.data[layoutName].systemState[dataSetName].countState !== filter.countState) return
        this.SET_COUNTING({ layoutName, dataSetName, counting: true })
      }, 300)
      countByFilter({ layoutName, namespace, queryName, filter }, { loadingLevel: 'none' }).then((res) => {
        if (res.data.data.countState !== this.data[layoutName].systemState[dataSetName].countState) return
        this.SET_COUNTING({ layoutName, dataSetName, counting: false })
        this.SET_TABLE_COUNTING_STATE({ layoutName, dataSetName, countState: '' })
        this.LOAD_TABLE_COUNT_DATA({ layoutName: encodedLayoutName, dataSetName, ...res.data.data })
      }, () => {
        if (filter.countState !== this.data[layoutName].systemState[dataSetName].countState) return
        this.SET_COUNTING({ layoutName, dataSetName, counting: false })
        this.SET_TABLE_COUNTING_STATE({ layoutName, dataSetName, countState: '' })
      })
    }
  }

  @Action
  loadDataById (payload: QueryByIdResponse) {
    const { layoutName, encodedLayoutName, dataSetName, namespace, query: queryName, id, callback, failCallback } = payload
    queryById({ layoutName, namespace, queryName, id })
      .then(res => {
        const rowData = res.data.data
        const dataSet = this.data[encodedLayoutName].dataSet[dataSetName]
        const row = dataSet.rows[0]
        if (!rowData) {
          // 没有 data 直接抛错，说明根据 id 没找到，可能已经被人删除
          throw new Error(RequestError.RecordNotFound)
        } else {
          // 由于 query-by-id 返回的数据并不包含值为空的字段，所以不能保证所有表单的字段都存在
          // 但是表单在创建的时候会生成一个带有字段的空行
          // 校验一下，假如返回的数据中不含有某一个字段，则手动添加该字段
          // 填充后将返回的数据作为新的 currentData 进行赋值
          for (const key in row.currentData) {
            if (!Object.prototype.hasOwnProperty.call(rowData, key)) {
              // rowData[key] = row.currentData[key]
              rowData[key] = ''
            }
          }
        }
        row.currentData = rowData
        row.originalData = _.cloneDeep(rowData)
        if (callback && _.isFunction(callback)) {
          callback()
        }
      })
      .catch(e => {
        isFunction(failCallback) && failCallback(e.message)
      })
  }

  @Mutation
  UPDATE_LAYOUT_STATUS ({ layoutName = '', status = '' }: { layoutName: string, status: layoutStatus | '' }) {
    if (this.data[layoutName]) {
      this.data[layoutName].status = status
    }
  }

  @Action
  updateLayoutStatus (payload: {layoutName: string, status: layoutStatus | ''}) {
    this.UPDATE_LAYOUT_STATUS(payload)
  }

  @Mutation
  UPDATE_LAYOUT_EDITING_DATASET ({ layoutName = '', dataSet = '' }) {
    if (this.data[layoutName]?.editingDataSet || this.data[layoutName]?.editingDataSet === '') {
      this.data[layoutName].editingDataSet = dataSet ? `${layoutName}.${dataSet}` : ''
    }
  }

  @Action
  updateLayoutEditingDataSet (payload: {layoutName: string, dataSet: string}) {
    this.UPDATE_LAYOUT_EDITING_DATASET(payload)
  }

  @Mutation
  RESET_DATA_SET (payload: { layoutName: string; dataSetName: string }) {
    const { layoutName, dataSetName } = payload
    const ds = this.data[layoutName]?.dataSet[dataSetName]
    // 根据state下同名的数据中是否有selection判断当前是否是表格数据
    const isTableData = this.data[layoutName]?.systemState[dataSetName]?.selection
    if (isTableData) {
      ds.rows = []
    } else {
      const dataRow = ds.rows?.[0]
      dataRow?.currentData && resetDataRow(dataRow.currentData)
      dataRow?.originalData && resetDataRow(dataRow.originalData)
    }
  }

  @Action
  resetDataSet (payload: { layoutName: string; dataSetName: string }) {
    this.RESET_DATA_SET(payload)
  }

  @Mutation
  REMOVE_DATA_SET (payload: { layoutName: string; dataSetName: string }) {
    const { layoutName, dataSetName } = payload
    Vue.delete(this.data[layoutName]?.dataSet, dataSetName)
  }

  @Action
  removeDataSet (payload: { layoutName: string; dataSetName: string }) {
    this.REMOVE_DATA_SET(payload)
  }

  @Mutation
  SET_TABLE_SELECTION (payload: { layoutName: string; dataSetName: string; selection: Array<any> }) {
    const { layoutName, dataSetName, selection } = payload
    this.data[layoutName].systemState[dataSetName].selection = selection
  }

  @Action
  setTableSelection (payload: { layoutName: string; dataSetName: string; selection: Array<any> }) {
    this.SET_TABLE_SELECTION(payload)
  }

  @Mutation
  SET_TABLE_SELECT_ALL (payload: { layoutName: string; dataSetName: string; selectAll: boolean }) {
    const { layoutName, dataSetName, selectAll } = payload
    this.data[layoutName].systemState[dataSetName].selectAll = selectAll
  }

  @Action
  setTableSelectAll (payload: { layoutName: string; dataSetName: string; selectAll: boolean }) {
    this.SET_TABLE_SELECT_ALL(payload)
  }

  @Mutation
  ADD_DATASET_MASTER_FILTER (payload: { layoutName: string; dataSetName: string; masterFilter: IConditionGroup }) {
    const { layoutName, dataSetName, masterFilter } = payload
    this.data[layoutName].systemState[dataSetName].masterFilter = masterFilter
  }

  @Action
  addDataSetMasterFilter (payload: { layoutName: string; dataSetName: string; masterFilter: IConditionGroup }) {
    this.ADD_DATASET_MASTER_FILTER(payload)
  }
  // addDataSetQueryParams

  @Mutation
  ADD_DATASET_QUERY_PARAMS (payload: { layoutName: string; dataSetName: string; queryParams: Array<any> }) {
    const { layoutName, dataSetName, queryParams } = payload
    this.data[layoutName].systemState[dataSetName].queryParams = queryParams
  }

  @Action
  addDataSetQueryParams (payload: { layoutName: string; dataSetName: string; queryParams: Array<any> }) {
    this.ADD_DATASET_QUERY_PARAMS(payload)
  }

  @Mutation
  ADD_NEW_ITEM (payload: any) {
    const { layoutName, dataSetName, row } = payload
    this.data[layoutName].dataSet[dataSetName].rows.push(row)
  }

  @Action
  addNewItem (payload: any) {
    this.ADD_NEW_ITEM(payload)
  }

  @Mutation
  SET_QUERY_METADATA (payload: { layoutName: string; queryName: string; queryMetaData: Record<string, any>[] }) {
    const { layoutName, queryName, queryMetaData } = payload
    const queryMeta = this.resource[layoutName].queryMeta
    if (!queryMeta) {
      this.resource[layoutName].queryMeta = {}
    }
    this.resource[layoutName].queryMeta[queryName] = queryMetaData
  }

  @Action
  setQueryMetaData (payload: any) {
    this.SET_QUERY_METADATA(payload)
  }

  /**
   * tree组件对应的store特殊处理，包含 nodes selection属性
   */
  @Mutation
  INIT_TREE_DATA (payload: { layoutName: string; dataTreeName: string }) {
    const { layoutName, dataTreeName } = payload
    if (!this.data[layoutName].dataSet[dataTreeName]) {
      Vue.set(this.data[layoutName].dataSet, dataTreeName, { dataTreeName, nodes: [] })
    }
  }

  @Action
  initTreeData (payload: { layoutName: string; dataTreeName: string }) {
    this.INIT_TREE_DATA(payload)
  }

  @Action
  resetQuickFilter (payload: { layoutName: string, dataSetName: string }) {
    this.RESET_QUICK_FILTER(payload)
  }

  @Mutation
  RESET_QUICK_FILTER (payload: { layoutName: string, dataSetName: string }) {
    const { layoutName, dataSetName } = payload
    const filterData = this.data[layoutName].systemState[dataSetName].quickFilter.data
    for (const p in filterData) {
      filterData[p] = ''
    }
  }

  @Mutation
  CLEAR_LAYOUT_MODULE (): void {
    this.data = {}
    this.resource = {}
  }

  @Action
  clearLayoutModule (): void {
    this.CLEAR_LAYOUT_MODULE()
  }

  @Mutation
  SET_CUSTOM_STATE (payload: any): void {
    const { layoutName, stateName, state } = payload
    Vue.set(this.data[layoutName].state, stateName, state)
  }

  @Action
  setCustomState (payload: any): void {
    this.SET_CUSTOM_STATE(payload)
  }

  @Mutation
  SET_DETAIL_DATA_SETS (payload: { layoutName: string, headerDataSetName: string, dataSetName: string, queryName: string }): void {
    const { layoutName, headerDataSetName, dataSetName, queryName } = payload
    const resource = this.resource[layoutName]
    if (!resource.detailDataSets) {
      resource.detailDataSets = Vue.observable([])
    }
    resource.detailDataSets.push({
      headerDataSetName,
      detailDataSetName: dataSetName,
      detailQueryName: queryName
    })
  }

  @Action
  setDetailDataSets (payload: any): void {
    this.SET_DETAIL_DATA_SETS(payload)
  }

  @Action
  setTableUserSetting (payload: any) : void {
    this.SET_TABLE_USER_SETTING(payload)
  }

  @Mutation
  private SET_TABLE_USER_SETTING ({ layoutName, dataSetName, userTableSetting } : any) : void {
    Vue.set(this.data[layoutName].systemState[dataSetName], 'userTableSetting', userTableSetting)
  }

  @Action
  setUiWebSocketListener (payload: any) : void {
    this.SET_UI_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private SET_UI_WEBSOCKET_LISTENER ({ layoutName, type, name, customType } : any) : void {
    if (!this.data[layoutName].uiWebSocketListener) {
      const init = {
        open: {},
        close: {},
        customMessage: {}
      }
      this.data[layoutName].uiWebSocketListener = init
    }
    this.data[layoutName].uiWebSocketListener![type][name] = customType || name
  }

  @Action
  removeUiWebSocketListener (payload: any) : void {
    this.REMOVE_UI_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private REMOVE_UI_WEBSOCKET_LISTENER ({ layoutName, type, name } : any) : void {
    const listeners = this.data[layoutName].uiWebSocketListener?.[type]
    if (!listeners) return
    delete listeners[name]
  }

  @Action
  clearUiWebSocketListener (payload: any) : void {
    this.CLEAR_UI_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private CLEAR_UI_WEBSOCKET_LISTENER ({ layoutName } : any) : void {
    if (this.data[layoutName]?.uiWebSocketListener) {
      delete this.data[layoutName].uiWebSocketListener
    }
  }
}

export const LayoutModule = getModule(Layout)
