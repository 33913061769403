

















import { Component, Prop, Vue } from 'vue-property-decorator'
import FormControlComponent from '../FieldBoundComponent'

@Component
export default class FormDisplayText extends Vue {
  @Prop({ default: '' }) title !: string
  // 2023-4-18 使用 slot 插槽时，会将元素的换行符理解为空格，放入 slot 内，导致 Text 组件以前会有前后的空格
  @Prop({ default: '' }) value !: string

  $refs !: {
    text: HTMLElement
  }

  get formControl (): FormControlComponent | undefined {
    let parent = this.$parent
    while (parent && !(parent instanceof FormControlComponent)) {
      parent = parent.$parent
    }
    return parent
  }

  get href () {
    return this.formControl?.getFinalAttributeValue('href', { args: this.formControl.args })
  }

  get target () {
    return this.formControl?.getFinalAttributeValue('target', { args: this.formControl.args }) || '_blank'
  }

  get hasOnClick () {
    return this.formControl?.component.onClick
  }

  handleClick (evt: MouseEvent) {
    if (this.hasOnClick) {
      evt.preventDefault()
      this.formControl && this.formControl.runRunnableContent('onClick', { args: this.formControl.args })
    }
  }
}
