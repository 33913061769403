

























import { Component, Watch } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import logwire from '@/logwire'
import { queryByFilter } from '@/http/api'
import { attributeType, filterOperator, layoutStatus } from '@/utils/const'
import { checkValueIsEmpty, formatDataRow, formatMasterFilter } from '@/utils/data'
import Args from '@/models/Args'
import _ from 'lodash'
import ConditionGroup from '@/models/ConditionGroup'
import Condition from '@/models/Condition'

@Component({ name: 'LwRecursive' })
export default class LwRecursive extends FormControlComponent {
  dynamicProps = {
    value: 'value',
    label: 'label',
    children: 'children',
    checkStrictly: true
  }

  cascaderData = []
  selectedOptions = []
  selectedOptionsCopy: any = []

  currentNode: any
  selectedItemLabel = ''
  selectedItemLabels: any = []
  dataFromBelow = [] // 存放从最底层开始获取的副本
  selectedPath: Array<any> = []

  getFirstLevel () {
    // 有数据的情况下，不应该发下面的请求；由于有多页面状态，无法根据url中是否有id，因此根据Status判断
    const val = this.inputValue
    !val && this.form.status !== layoutStatus.VIEW && this.getData((datas: any) => {
      this.parseData(datas)
      this.cascaderData = datas
    }, false, false, true)
  }

  /**
   * 由于现有的dataset是根据页面生成，不再是数据获取后才显示组件。
   * 因此难以根据是否有数据来反向查找，现行的办法只能根据是否有id判断
  */
  @Watch('form.dataRow.id', { deep: true })
  handleReverseData (val: any, oldData: any) : void {
    const target = this.inputValue
    this.selectedItemLabel = ''
    this.selectedItemLabels = []
    this.currentNode = null
    this.selectedOptions = []
    if (val && target) {
      this.reverseAssignOptions(target, true)
    }
  }

  resetValue (): void {
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return
    const { field } = this.component
    const value = row.originalData[field]
    this.$set(this.form.dataRow, field, value)
    if (value) {
      this.selectedItemLabel = ''
      this.selectedItemLabels = []
      this.currentNode = null
      this.selectedOptions = []
      this.reverseAssignOptions(value)
    }
  }

  parseData (datas: []) {
    datas.map((item: any) => {
      item.children = []
    })
  }

  /**
   * 反查时执行的action
   * inputValue: 反查时的条件
   * isLastSelect： 是否是最后选择的一项
   * */
  reverseAssignOptions (inputValue: any, isLastSelect = false) : void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    if (inputValue) {
      self.getData((datas: any) => {
        // 根据字段值进行反向查找的时候，如果数据库中数据被删除，则初始化第一级并跳出递归
        if (datas.length === 0) {
          self.getData((datas: any) => {
            self.parseData(datas)
            self.cascaderData = datas
          }, false, false, true)
          return
        }
        self.selectedOptionsCopy.push(inputValue)
        self.selectedItemLabels.push(datas[0].label)
        const parentKey = self.component.parentIdFieldInDropDown
        const parentValue = datas[0][parentKey]
        const currentIdKey = self.component.valueFieldInDropDown
        self.getData((siblings: any) => {
          if (!isLastSelect) {
            const preVal = self.dataFromBelow[0][parentKey]
            siblings.forEach((item: any, idx: number) => {
              if (item[currentIdKey] === preVal) {
                item.children = self.dataFromBelow
              }
            })
            // eslint-disable-next-line no-prototype-builtins
            siblings.forEach((item: any) => { if (!item.hasOwnProperty('children')) item.children = [] })
          } else {
            self.parseData(siblings)
          }
          self.dataFromBelow = siblings
          if (checkValueIsEmpty(parentValue)) {
            self.selectedItemLabel = self.selectedItemLabels.reverse().join('/')
            self.selectedOptions = self.selectedOptionsCopy.reverse()
            self.cascaderData = self.dataFromBelow
            self.selectedOptionsCopy = self.dataFromBelow = []
          }
          parentValue && self.reverseAssignOptions(parentValue, false)
        }, true, true, false, parentValue)
      }, true, false, false, inputValue)
    }
  }

  // 选择有效时
  handleItemChange (val: string | any[]) : void {
    // 清空的时候不做任何操作
    if (val.length === 0) {
      this.form.dataRow[this.field] = ''
      const args = new Args(this.context, { row: formatDataRow(this.form.dataRow), evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
      this.component.onChange && this.runRunnableContent('onChange', { args })
      return
    } else {
      this.form.dataRow[this.field] = val[val.length - 1]
    }
    this.findCurNode(val[val.length - 1], this.cascaderData)
    // 当前点选的下拉项
    const selected = JSON.parse(JSON.stringify(this.currentNode))
    this.refactorLevelData(selected)
    let selectedPath = JSON.parse(JSON.stringify(this.cascaderData))
    this.findSelectedPath(JSON.parse(JSON.stringify(val)), selectedPath)
    // 完整的选择路径，包括每一项的所有内容， 例如 [{ label: '中国',value: 1, xxx }, { label: '江苏',value: 1, xxx }, { label: '南京',value: 1, xxx }]
    selectedPath = this.selectedPath
    selectedPath.forEach((p: any) => this.refactorLevelData(p))
    // 执行onChange
    const getSelectedOption = () => selectedPath
    const args = new Args(this.context, { row: formatDataRow(this.form.dataRow), getSelectedOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    this.component.onChange && this.runRunnableContent('onChange', { args })
  }

  findSelectedPath (val: any, levelData: any) {
    while (val.length) {
      let selectedData: any
      levelData.forEach((data: any) => {
        if (data.value === val[0]) {
          selectedData = data
        }
      })
      this.selectedPath.push(selectedData)
      val.splice(0, 1)
      this.findSelectedPath(val, selectedData.children)
    }
  }

  // 去掉层级数据的children属性
  refactorLevelData (Obj: { [x: string]: any }) {
    Obj && delete Obj.children
  }

  // 判断是否有缓存
  findCurNode (val: any, cascader: any[]) {
    cascader.forEach(child => {
      if (child.value === val) {
        this.currentNode = child
        return false
      }
      if (child.children && child.children.length) {
        this.findCurNode(val, child.children)
      }
    })
  }

  lazyLoad (val: string | any[]) {
    if (val) {
      this.findCurNode(val[val.length - 1], this.cascaderData)
    }
    this.getData((datas: any) => {
      this.parseData(datas)
      datas.length && (this.currentNode.children = datas)
    })
  }

  /**
   * queryObj: 查询条件
   * reverse：是否进行反向查找
   * querySiblings: 查询兄弟节点数据
   * root: 是否是第一层查询
   * reverseData: 反查时的条件
  */
  getData (cb: any, reverse = false, querySiblings = false, root = false, reverseData = '') {
    const { dropDownRetrieveAction, dropDownQuery, labelFieldInDropDown, valueFieldInDropDown, parentIdFieldInDropDown } = this.component
    // 如果是之前点击过的下拉项，不再重新发请求查询
    if (this.currentNode && this.currentNode.children.length > 0) return
    // 如果是第一层查询且此时级联组件需要的数据已经有值，也不再请求
    if (root && this.cascaderData.length) return
    const body: any = {
      getTotalBy: 'count',
      pageNo: 1,
      pageSize: 1000
    }
    let masterFilter = this.getFinalAttributeValue('dropDownMasterFilter', {
      args: this.args,
      type: attributeType.OBJECT
    })
    masterFilter = formatMasterFilter(masterFilter)
    if (masterFilter) {
      body.masterFilter = masterFilter
    }

    const queryParams = this.getFinalAttributeValue('dropDownQueryParams', {
      args: this.args,
      type: attributeType.OBJECT_ARRAY
    })
    if (queryParams) {
      body.queryParams = queryParams
    }

    if (reverse) {
      // 第一层没有 parentId
      if (parentIdFieldInDropDown) {
        const conditionGroup = new ConditionGroup()
        const condition = new Condition(querySiblings ? parentIdFieldInDropDown : valueFieldInDropDown, filterOperator.EQ, reverseData)
        conditionGroup.add(body.masterFilter, condition)
        body.masterFilter = conditionGroup.build()
      }
    } else {
      // 第一层没有 parentId
      if (root) {
        const conditionGroup = new ConditionGroup()
        const condition = new Condition(parentIdFieldInDropDown, filterOperator.IS_NULL)
        conditionGroup.add(body.masterFilter, condition)
        body.masterFilter = conditionGroup.build()
      } else {
        // valueFieldInDropDown一般也就是id
        const conditionGroup = new ConditionGroup()
        const condition = new Condition(parentIdFieldInDropDown, filterOperator.EQ, this.currentNode[valueFieldInDropDown])
        conditionGroup.add(body.masterFilter, condition)
        body.masterFilter = conditionGroup.build()
      }
    }
    const successCallback = (res: Record<string, any>) => {
      const rows = res.data.rows
      for (const index in rows) {
        const item = rows[index]
        const value = item.value
        if (!checkValueIsEmpty(value)) {
          item.value = value
        } else {
          item.value = item[valueFieldInDropDown]
        }
        item.label = item._html || item[labelFieldInDropDown]
      }
      cb && cb(rows)
    }
    const layoutName = this.editLayoutName || this.layoutName
    dropDownRetrieveAction
      ? logwire.network.doAction({ name: dropDownRetrieveAction, data: body, silent: true, successCallback })
      : queryByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: body }, { silent: true })
        .then(res => {
          successCallback(res.data)
        }, (e: any) => { console.error(e) })
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.selectedItemLabel
  }
}
